import React from 'react'
import { ISelectProps } from './Select.type'

export default function Select(props: ISelectProps) {
    return (
        <div
            className={`w-full flex flex-col lg:flex-row items-center ${
                props.noPadding === true ? '' : 'pb-5'
            }`}
        >
            {props.label && (
                <label
                    className="flex lg:mr-2 pr-4 items-center text-white dark:text-gray-light-text self-start lg:self-center"
                    htmlFor={props.id}
                >
                    {props.label}
                </label>
            )}
            <select
                className="h-10 flex w-full lg:w-auto flex-grow text-gray-100 rounded-md p-2 border borer-gray-100"
                name={props.name}
                id={props.id}
                onChange={(e) => props.onChange(e.currentTarget.value)}
                value={props.selectedValue}
            >
                {props.value.map((v: string | number) => (
                    <option value={v} key={v}>
                        {v}
                    </option>
                ))}
            </select>
        </div>
    )
}
