import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import BraydenCore from '@innosonian/brayden-core'
import Classroom from 'core/classroom'
import Button from 'view/component/Button/Button'
import Typography from 'view/component/Typography/Typography'
import MediaPlayer from 'view/component/MediaPlayer/MediaPlayer'
import FadeInContainer from '../../layout/FadeInContainer/FadeInContainer'
import { ReactComponent as TrainingButton } from 'resource/img/icons/trainingButton.svg'
import { ReactComponent as ClassroomIcon } from 'resource/img/icons/classroomButton.svg'
import { ReactComponent as Folder } from 'resource/img/icons/folder.svg'
import { isAuthenticated } from '@innosonian/brayden-core/build/src/auth'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'

export default function Home() {
    const { t } = useTranslation('locale')
    const isCameraConnected = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Classroom.Selector.getCameraConnectionStatus(state)
    )
    const manual = useSelector(
        BraydenCore.Entity.ClientContent.ClientContentSelector.getManual
    )
    const isClassroomConnected = useSelector(
        BraydenCore.Entity.Classroom.Selector.isConnected
    )
    const history = useHistory()

    const handleOpenManual = () => {
        if (manual !== undefined) {
            window.open(manual?.url, '_blank')
        }
    }
    const isClassroonAvailable = () =>
        isClassroomConnected === false && isAuthenticated() === true

    const handleJoinClassroom = async () => {
        if (!isClassroonAvailable()) {
            return
        }
        const isUserInvited = await Classroom.initRtcConnection()
        if (isUserInvited !== undefined) {
            history.push('/setup')
        } else {
            BraydenCore.Reducer.store.dispatch(
                BraydenCore.Entity.Modal.ModalAction.modalOpenRtcError()
            )
        }
    }
    const displayClassroomOption = () => {
        if (isCameraConnected) {
            return (
                <Button
                    fontSize="xl"
                    noShadow={true}
                    shape="rounded-mid"
                    disable={!isAuthenticated()}
                    color="blue"
                    onclick={() => history.push('/setup')}
                    size="xl"
                    padding="sm"
                    className="self-center"
                >
                    {t('Classroom.RejoinClassroom')}
                </Button>
            )
        } else {
            return (
                <Button
                    fontSize="xl"
                    noShadow={true}
                    shape="rounded-mid"
                    disable={!isAuthenticated()}
                    color="blue"
                    onclick={() => {}}
                    size="xl"
                    padding="sm"
                    className="self-center"
                >
                    {t('Classroom.TitleButton')}
                </Button>
            )
        }
    }
    return (
        <FadeInContainer className="w-full flex flex-col h-full pb-5">
            <div className="w-full h-full flex flex-col md:flex-row">
                <div className="w-full md:w-1/2 md:h-full flex flex-col md:pr-10 pb-10 md:pb-0">
                    <div
                        className={`w-full flex-grow flex flex-col border border-gray-100 cursor-pointer mb-10 justify-center items-center py-10 md:py-0`}
                        onClick={handleJoinClassroom}
                    >
                        <ClassroomIcon className="w-40 h-40 text-gray-100 dark:text-gray-light-icon mb-4 max-w-full max-h-full" />
                        {displayClassroomOption()}
                    </div>
                    <div
                        className="w-full flex-grow flex flex-col border border-gray-100 cursor-pointer justify-center items-center py-10 md:py-0"
                        onClick={() => history.push('/trainings')}
                    >
                        <TrainingButton className="w-40 h-40 text-gray-100 dark:text-gray-light-icon mb-4" />
                        <Button
                            fontSize="xl"
                            noShadow={true}
                            shape="rounded-mid"
                            disable={false}
                            color="secondary"
                            onclick={() => history.push('/trainings')}
                            size="xl"
                            padding="sm"
                            className="self-center"
                        >
                            {t('WebOverwrite.Train')}
                        </Button>
                    </div>
                </div>
                <div className="flex flex-col w-full md:w-1/2">
                    <div className="flex flex-col justify-center items center border border-gray-100 mb-10">
                        <MediaPlayer />
                    </div>
                    <div
                        className="w-full flex flex-grow justify-center border border-gray-100 items-center cursor-pointer "
                        onClick={handleOpenManual}
                    >
                        <Folder className="w-10 h-10 text-gray-150 dark:text-gray-light-default-text mr-3" />
                        <Typography>{t('WebOverwrite.Manual')}</Typography>
                    </div>
                </div>
            </div>
        </FadeInContainer>
    )
}
