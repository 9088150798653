import React, { Fragment, useEffect, useState } from 'react'
import { IProps } from './Lungs.type'
import './Lungs.scss'
import { useSelector } from 'react-redux'
import LungsGray from 'resource/lottie/lungs.json'
import LungsGreen from 'resource/lottie/lungs-green.json'
import LungsRed from 'resource/lottie/lungs-red.json'
import LungsTooFast from 'resource/lottie/lungs-too-fast.json'
import LungsTooFastGreen from 'resource/lottie/lungs-too-fast-green.json'
import LungsTooFastRed from 'resource/lottie/lungs-too-fast-red.json'
import Lottie from 'view/component/Lottie/Lottie'
import hook from 'hook'
import BraydenCore from '@innosonian/brayden-core'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'
import {
    ModelType,
    RTData,
} from '@innosonian/brayden-core/build/src/_bluetooth/bluetooth.interface'

type LUNGS_ANIMATION_TYPE =
    | 'TRANSPARENT'
    | 'TOO_BIG'
    | 'GOOD'
    | 'TOO_FAST'
    | 'TOO_FAST_GOOD'
    | 'TOO_FAST_TOO_BIG'
    | 'TOO_FEW'

export default function Lungs(props: IProps) {
    const [theme] = hook.useDarkMode()
    const calibrationResume = useSelector(
        BraydenCore.Entity.Calibration.CalibrationSelector.getCalibrationResume
    )
    const [currentColor, setCurrentColor] = useState(
        'TRANSPARENT' as LUNGS_ANIMATION_TYPE
    )
    const lastDataPushed = useSelector(
        BraydenCore.Entity.Session.SessionSelector.getLatestDataPushed
    )
    const [ventilationValue, setVentilationValue] = useState(0)
    const [testedBit, setTestedBit] = useState(false)
    const lastVentilationList = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Session.SessionSelector.getLastVentilationList(
            state,
            lastDataPushed !== undefined ? lastDataPushed[RTData.R_CNT] - 1 : 0
        )
    )
    const manikin = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Hardware.HardwareSelector.getConnectedHardwareById(
            state,
            BraydenCore.Entity.Session.SessionSelector.getSelectedManikin(state)
        )
    )

    hook.useDeepCompareEffect(() => {
        setVentilationValue((e) => {
            return BraydenCore.Calculation.RatioCalculation.getBiggestVentilationValue(
                lastVentilationList
            )
        })
        if (manikin.model !== ModelType.BRAYDEN_PRO) {
            lastVentilationList.forEach((rowData) => {
                if (
                    BraydenCore.Calculation.RatioCalculation.testBit(
                        rowData[RTData.R_SPD]
                    ) === true
                ) {
                    setTestedBit(true)
                }
            })
        }
    }, [lastVentilationList])

    useEffect(() => {
        if (ventilationValue === 0) {
            setCurrentColor('TRANSPARENT')
            return
        } else if (testedBit === true) {
            if (ventilationValue < calibrationResume.ventilationMinGood) {
                setCurrentColor('TOO_FAST')
            } else if (
                ventilationValue <= calibrationResume.ventilationMaxGood
            ) {
                setCurrentColor('TOO_FAST_GOOD')
            } else {
                setCurrentColor('TOO_FAST_TOO_BIG')
            }
        } else if (ventilationValue < calibrationResume.ventilationMinGood) {
            setCurrentColor('TOO_FEW')
        } else if (ventilationValue <= calibrationResume.ventilationMaxGood) {
            setCurrentColor('GOOD')
        } else {
            setCurrentColor('TOO_BIG')
        }

        const t = setTimeout(() => {
            setVentilationValue(0)
        }, 1000)
        return () => {
            clearTimeout(t)
        }
    }, [ventilationValue, testedBit, calibrationResume])

    const drawTheme = (theme: string) => {
        return (
            <Fragment>
                {currentColor === 'TOO_FEW' && (
                    <Lottie
                        loop={false}
                        animationData={LungsGray}
                        play={true}
                    />
                )}
                {currentColor === 'GOOD' && (
                    <Lottie
                        loop={false}
                        animationData={LungsGreen}
                        play={true}
                    />
                )}
                {currentColor === 'TOO_BIG' && (
                    <Lottie loop={false} animationData={LungsRed} play={true} />
                )}
                {currentColor === 'TOO_FAST' && (
                    <Lottie
                        loop={false}
                        animationData={LungsTooFast}
                        play={true}
                    />
                )}
                {currentColor === 'TOO_FAST_GOOD' && (
                    <Lottie
                        loop={false}
                        animationData={LungsTooFastGreen}
                        play={true}
                    />
                )}
                {currentColor === 'TOO_FAST_TOO_BIG' && (
                    <Lottie
                        loop={false}
                        animationData={LungsTooFastRed}
                        play={true}
                    />
                )}
            </Fragment>
        )
    }

    return (
        <div className="w-full h-full flex absolute ">
            <div
                className="absolute inset-0"
                style={{
                    width: `${props.width}px`,
                    height: `${props.height}px`,
                }}
            >
                {drawTheme(theme)}
            </div>
        </div>
    )
}
