import AgoraRTC, { ILocalVideoTrack, ILocalAudioTrack } from 'agora-rtc-sdk-ng'
import BraydenCore from '@innosonian/brayden-core'
import { LOCAL_STORAGE_KEY } from '@innosonian/brayden-core/build/src/_localStorage/localStorage.interface'
import Config from './config'

const screenClient = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' })
let screenTrack: ILocalVideoTrack | [ILocalVideoTrack, ILocalAudioTrack]

let rtcToken: string | undefined = undefined

async function createRtcToken(className: string, uid: string) {
    rtcToken = await Config.generateToken(
        `${process.env.REACT_APP_BASE_URL}/api/new/classroom/rtc/token`,
        className,
        `screen_${uid}`
    )
    return rtcToken
}

function isInstructor() {
    const localUid = localStorage.getItem(LOCAL_STORAGE_KEY.EMAIL) as string
    const clientId = localStorage.getItem(LOCAL_STORAGE_KEY.CLIENT_ID) as string
    return localUid === clientId
}

async function startScreenSharing(className: string, uid: string) {
    if (rtcToken === undefined) {
        return
    }
    try {
        const withAudioOption = isInstructor() ? 'enable' : 'disable'
        screenTrack = await AgoraRTC.createScreenVideoTrack(
            {
                encoderConfig: isInstructor() ? '720p_2' : '720p_1',
                optimizationMode: 'detail',
            },
            withAudioOption
        )

        await screenClient.join(
            Config.options.appId,
            className,
            rtcToken,
            `screen_${uid}`
        )
        await screenClient.publish(screenTrack)

        if (Array.isArray(screenTrack)) {
            screenTrack[0].on('track-ended', () => {})
        }
        BraydenCore.Reducer.store.dispatch(
            BraydenCore.Entity.Classroom.Action.connectScreen(true)
        )
    } catch (e) {
        await stopScreenSharing()
    }
}

async function stopScreenSharing() {
    const hasAudioTrack = (
        screenTrack: ILocalVideoTrack | [ILocalVideoTrack, ILocalAudioTrack]
    ) => {
        return Array.isArray(screenTrack)
    }
    if (hasAudioTrack(screenTrack)) {
        const screenTrackArr = screenTrack as [
            ILocalVideoTrack,
            ILocalAudioTrack
        ]
        screenTrackArr.forEach((track) => {
            track.stop()
            track.close()
        })
    } else {
        const screen = screenTrack as ILocalVideoTrack
        screen?.stop()
        screen?.close()
    }
    await screenClient?.leave()
    BraydenCore.Reducer.store.dispatch(
        BraydenCore.Entity.Classroom.Action.connectScreen(false)
    )
}

function getScreenClient() {
    return screenClient
}

const screen = {
    startScreenSharing,
    stopScreenSharing,
    getScreenClient,
    createRtcToken,
}

export default screen
