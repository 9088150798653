import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import hook from 'hook'
import Button from 'view/component/Button/Button'
import FallBack from 'view/component/FallBack/FallBack'
import HitIndicator from 'view/component/HitIndicator/HitIndicator'
import GuideText from 'view/component/GuideText/GuideText'
import BreathIndicator from 'view/component/BreathIndicator/BreathIndicator'
import AEDGuideline from 'view/component/AEDGuideline/AEDGuideline'
import TimeOff from 'view/component/TimeOff/TimeOff'
import CounterInfoBar from 'view/component/CounterInfoBar/CounterInfoBar'
import Countdown from 'view/component/Countdown/Countdown'
import Typography from 'view/component/Typography/Typography'
import Chronometer from '../../component/Chronometer/Chronometer'
import FadeInContainer from '../../layout/FadeInContainer/FadeInContainer'
import BraydenCore from '@innosonian/brayden-core'
import ButtonContainer from 'view/component/ButtonContainer/ButtonContainer'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'
import { RTData } from '@innosonian/brayden-core/build/src/_bluetooth/bluetooth.interface'
import { CardTrainingType } from '@innosonian/brayden-core/build/src/entity/Card/Card.interface'
import { ReactComponent as GuidelineIcon } from 'resource/img/icons/guideline.svg'
import { ModelCourseType } from '../../../../../brayden-core-js/build/src/entity/Session/Session.interface'

export default function Training() {
    const isClassroomConnected = useSelector(
        BraydenCore.Entity.Classroom.Selector.isConnected
    )
    const history = useHistory()
    const dispatch = useDispatch()
    const { t } = useTranslation('locale')
    const [showGuideline, setShowGuideline] = useState<boolean>(false)
    const getGuidelineEvent = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Session.SessionSelector.getGuideline(state)
    )
    const isSessionStarted = useSelector(
        BraydenCore.Entity.Session.SessionSelector.getSessionStart
    )
    const card = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Card.CardSelector.getCardById(
            state,
            BraydenCore.Http.HttpUri.parseUrl(window.location.pathname, 2)
        )
    )
    const lastDataPushed = useSelector(
        BraydenCore.Entity.Session.SessionSelector.getLatestDataPushed
    )
    const compressionList = useSelector(
        BraydenCore.Entity.Session.SessionSelector.getCompressionList
    )

    const finishCompression: number | undefined =
        card && card.stopCondition && card.stopCondition.finishCompression
            ? card.stopCondition.finishCompression
            : undefined
    const finishCycle: number | undefined =
        card && card.stopCondition && card.stopCondition.finishCycle
            ? card.stopCondition.finishCycle
            : undefined
    const exceedSeconds: number | undefined =
        card && card.stopCondition && card.stopCondition.exceedSeconds
            ? card.stopCondition.exceedSeconds
            : undefined
    const finishVentilation: number | undefined =
        card && card.stopCondition && card.stopCondition.finishVentilation
            ? card.stopCondition.finishVentilation
            : undefined
    const [clickStart, setClickStart] = useState(false)

    const selectedManikin = useSelector(
        BraydenCore.Entity.Session.SessionSelector.getSelectedManikin
    )

    const stopCb = useCallback(() => {
        if (isSessionStarted === true) {
            dispatch(
                BraydenCore.Entity.Session.SessionAction.rtStopRequest(
                    selectedManikin
                )
            )

            dispatch(
                BraydenCore.Entity.Result.ResultAction.getResultRequest(card, 1)
            )
            history.push('/result')
        }
    }, [card, dispatch, history, selectedManikin, isSessionStarted])
    useEffect(() => {
        if (getGuidelineEvent === 'Pads On') {
            setShowGuideline(true)
        }
        if (
            getGuidelineEvent === 'Shock Delivered' ||
            getGuidelineEvent === 'No Shock Advised'
        ) {
            setShowGuideline(false)
        }
    }, [getGuidelineEvent])
    useEffect(() => {
        dispatch(BraydenCore.Entity.Session.SessionAction.resetSession())
    }, [dispatch])

    useEffect(() => {
        dispatch(
            BraydenCore.Entity.Calibration.CalibrationAction.calibrationSetVentilation(
                card.guideLine,
                card.target as ModelCourseType
            )
        )
    }, [card.guideLine, card.target, dispatch])

    useEffect(() => {
        return () => {
            if (isSessionStarted === true) {
                dispatch(
                    BraydenCore.Entity.Session.SessionAction.rtStopRequest(
                        selectedManikin
                    )
                )
            }
        }
    }, [isSessionStarted, dispatch, selectedManikin])

    useEffect(() => {
        if (
            lastDataPushed !== undefined &&
            finishCompression !== undefined &&
            compressionList.length >= finishCompression
        ) {
            stopCb()
        }
    }, [lastDataPushed, dispatch, finishCompression, stopCb, compressionList])

    useEffect(() => {
        if (
            lastDataPushed !== undefined &&
            finishCycle !== undefined &&
            lastDataPushed[RTData.CYCLE] > finishCycle
        ) {
            stopCb()
        }
    }, [lastDataPushed, finishCycle, stopCb])

    useEffect(() => {
        if (
            lastDataPushed !== undefined &&
            finishVentilation !== undefined &&
            lastDataPushed[RTData.R_CNT] >= finishVentilation
        ) {
            stopCb()
        }
    }, [lastDataPushed, finishVentilation, stopCb])

    useEffect(() => {
        if (isSessionStarted === true) {
            setClickStart(true)
        } else {
            setClickStart(false)
        }
    }, [isSessionStarted])

    useEffect(() => {
        if (card.movie !== undefined && isClassroomConnected !== true) {
            dispatch(
                BraydenCore.Entity.Modal.ModalAction.modalPlayVideo(
                    card.movie.url
                )
            )
        }
        return () => {
            dispatch(BraydenCore.Entity.Modal.ModalAction.modalCloseVideo())
        }
    }, [card.movie, dispatch, isClassroomConnected])

    useEffect(() => {
        if (isSessionStarted === true) {
            setClickStart(true)
        }
    }, [isSessionStarted])

    if (hook.fallbackCatch([card])) {
        return <FallBack />
    }

    const StartOrStopText = () => {
        return isSessionStarted === false
            ? t('RealTimeTrainingPage.StartButton')
            : t('RealTimeTrainingPage.StopButton')
    }

    const startOrStopSession = () => {
        if (isSessionStarted === false) {
            setClickStart(true)
        } else {
            stopCb()
        }
    }

    const isItemActive = (itemType: string) => {
        const handleHitIndicatorActive = () =>
            card.type === CardTrainingType.VENTILATION_ONLY ? false : true
        const handleTimeOffActive = () =>
            card.type === CardTrainingType.VENTILATION_ONLY ? false : true
        const handleBreathIndicatorActive = () =>
            card.type === CardTrainingType.CHEST_COMP_ONLY ? false : true

        switch (itemType) {
            case 'HitIndicator':
                return handleHitIndicatorActive()
            case 'TimeOff':
                return handleTimeOffActive()
            case 'BreathIndicator':
                return handleBreathIndicatorActive()
            default:
                return true
        }
    }

    const displayGraphFeedBack = () => {
        return (
            <Fragment>
                <div className="flex flex-col md:flex-row">
                    <div className="w-full md:w-1/2 flex flex-col items-center justify-center order-1 md:order-2 pb-5 md:pb-0">
                        <Typography
                            className="pb-2"
                            type="h2"
                            alignment="center"
                        >
                            {card.cardTitle}
                        </Typography>
                        <div className="w-full flex items-center justify-center">
                            <Button
                                disable={isClassroomConnected}
                                shape="rounded-mid"
                                onclick={startOrStopSession}
                                className="self-center sm: w-64 md:w-40 "
                                color={
                                    isSessionStarted === true
                                        ? 'danger'
                                        : 'secondary'
                                }
                            >
                                {StartOrStopText()}
                            </Button>
                            <ButtonContainer selected={false} className="ml-4">
                                <GuidelineIcon
                                    className="w-10 h-10 text-gray-100 dark:text-gray-light-default-text cursor-pointer"
                                    onClick={() =>
                                        card.movie !== undefined &&
                                        dispatch(
                                            BraydenCore.Entity.Modal.ModalAction.modalPlayVideo(
                                                card.movie?.url
                                            )
                                        )
                                    }
                                />
                            </ButtonContainer>
                        </div>
                    </div>
                    <div className="w-full md:w-1/2 flex flex-col order-2 md:order-1">
                        <div className="w-full">
                            <GuideText />
                        </div>
                    </div>
                </div>
                <div className="w-full flex flex-col flex-grow md:flex-row mt-4 justify-around">
                    <div
                        className={`w-full mb-5 md:mb-0 flex flex-col ${
                            isItemActive('HitIndicator') === false
                                ? 'w-full'
                                : 'md:w-1/2'
                        }`}
                    >
                        <div className={`flex h-full flex-grow`}>
                            {showGuideline ? (
                                <AEDGuideline
                                    aedDirections={getGuidelineEvent}
                                />
                            ) : (
                                <BreathIndicator
                                    type={card.type as CardTrainingType}
                                />
                            )}
                        </div>
                        <div
                            className={`w-full flex-col md:flex-row mt-8 justify-around hidden ${
                                isClassroomConnected === false ? 'xl:flex' : ''
                            }`}
                        >
                            <CounterInfoBar
                                compVentRatio={card.compVentRatio}
                                exceedSeconds={exceedSeconds}
                                autoStop={card.autoStop}
                                stopCb={stopCb}
                                finishCompression={finishCompression}
                            />
                        </div>
                    </div>
                    <div
                        className={`relative w-full md:w-1/2 h-full border border-gray-100 p-5 flex flex-1 md:ml-8 ${
                            isItemActive('HitIndicator') === false
                                ? 'hidden'
                                : ''
                        }`}
                    >
                        <HitIndicator
                            globalView={false}
                            active={isItemActive('HitIndicator')}
                        />
                        <TimeOff active={isItemActive('TimeOff')} />
                    </div>
                </div>
                <div
                    className={`w-full flex flex-col md:flex-row mt-8 justify-around ${
                        isClassroomConnected === false ? 'xl:hidden' : ''
                    }`}
                >
                    <CounterInfoBar
                        compVentRatio={card.compVentRatio}
                        exceedSeconds={exceedSeconds}
                        autoStop={card.autoStop}
                        stopCb={stopCb}
                        finishCompression={finishCompression}
                    />
                </div>
            </Fragment>
        )
    }

    return (
        <FadeInContainer className="w-full h-full flex flex-col">
            {clickStart === true && <Countdown />}
            {card.feedback === 'graph' && displayGraphFeedBack()}
            {card.feedback !== 'graph' && (
                <div className="w-full h-full flex flex-col items-center justify-center">
                    <div className="w-full flex flex-col">
                        <Typography
                            className="pb-2"
                            type="h2"
                            alignment="center"
                        >
                            {card.cardTitle}
                        </Typography>
                        <Button
                            disable={isClassroomConnected}
                            onclick={() => startOrStopSession()}
                            className="self-center"
                            color={
                                isSessionStarted === true
                                    ? 'danger'
                                    : 'secondary'
                            }
                        >
                            {StartOrStopText()}
                        </Button>
                    </div>
                    <div className="w-full flex mt-20">
                        <Chronometer
                            title={t('RealTimeTrainingPage.Duration')}
                            timeout={
                                exceedSeconds === undefined
                                    ? 120
                                    : exceedSeconds
                            }
                            autoStop={true}
                            stopCb={stopCb}
                            fullScreen={true}
                        />
                    </div>
                </div>
            )}
        </FadeInContainer>
    )
}
