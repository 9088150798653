import React from 'react'
import { IModalContainerProps } from './ModalContainer.type'
import { ReactComponent as Cross } from 'resource/img/icons/cross.svg'
import Modal from 'react-modal'

export default function ModalContainer(
    props: React.PropsWithChildren<IModalContainerProps>
) {
    const onCloseClick = () => {
        if (props.onCloseClick !== undefined) {
            props.onCloseClick()
        }
    }
    const handleBgColor = () => {
        if (props.color) {
            return props.color
        }
        return 'bg-gray-1000 dark:bg-gray-light-bg'
    }
    return (
        <Modal
            isOpen={props.isOpen}
            style={{
                overlay: {
                    background: 'rgba(33, 35, 39, 0.5)',
                    zIndex: 10000,
                    overflow: 'scroll',
                },
                content: {
                    outline: 'none',
                },
            }}
            className={`${handleBgColor()} border border-gray-100 mt-24 mx-auto p-5 dark:shadow-dark ${
                props.className || 'w-11/12 md:w-11/12 lg:w-7/12'
            }`}
            contentLabel="Example Modal"
        >
            <div className="w-full h-full flex flex-col">
                {props.closeButton === true && (
                    <div className="w-full flex justify-end pb-2">
                        <div
                            className="rounded-full border border-gray-100 shadow-dark p-2 bg-gray-650 dark:bg-gray-light-default-button cursor-pointer"
                            onClick={onCloseClick}
                        >
                            <Cross className="w-5 h-5 text-gray-100" />
                        </div>
                    </div>
                )}
                <div className="w-full h-full ">{props.children}</div>
            </div>
        </Modal>
    )
}
