import BraydenCore from '@innosonian/brayden-core'
import usePrevious from 'hook/usePrevious'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import useResizeObserver from 'use-resize-observer'
import useSound from 'use-sound'
import { IProps } from './BreathIndicator.type'
import Lungs from './Lungs/Lungs'
import BodyContainer from './BodyContainer/BodyContainer'
import Bones from './Bones/Bones'
import RecoilAlert from './RecoilAlert/RecoilAlert'
import Metronome from 'view/component/Metronome/Metronome'
import { RTData } from '@innosonian/brayden-core/build/src/_bluetooth/bluetooth.interface'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'
import { CardTrainingType } from '@innosonian/brayden-core/build/src/entity/Card/Card.interface'

const useComponentDisplay = () => {
    const lastDataPushed = useSelector(
        BraydenCore.Entity.Session.SessionSelector.getLatestDataPushed
    )
    const [isCompression, setIsCompression] = useState(true)
    const [timeOutActive, setTimeoutActive] = useState(false)
    useEffect(() => {
        if (timeOutActive === false) {
            const actionEnum = BraydenCore.Calculation.RatioCalculation.isCompression(
                lastDataPushed
            )
            if (
                actionEnum ===
                BraydenCore.Calculation.RatioCalculation.CurrentAction
                    .COMPRESSION
            ) {
                setIsCompression(true)
                setTimeoutActive(true)
                setTimeout(() => {
                    setTimeoutActive(false)
                }, 2000)
            } else if (
                actionEnum ===
                BraydenCore.Calculation.RatioCalculation.CurrentAction
                    .VENTILATION
            ) {
                setIsCompression(false)
                setTimeoutActive(true)
                setTimeout(() => {
                    setTimeoutActive(false)
                }, 2000)
            } else {
                return
            }
        }
    }, [lastDataPushed, isCompression, timeOutActive])
    return isCompression
}

function isVentilationSpeedToFast(lastVentilationList: Uint8Array[]) {
    let tested = false
    lastVentilationList.forEach((rowData) => {
        if (
            BraydenCore.Calculation.RatioCalculation.testBit(
                rowData[RTData.R_SPD]
            ) === true
        ) {
            tested = true
        }
    })
    return tested
}

function handleWidth(width: number) {
    if (width < 400) {
        return 300
    }
    return width - 20
}

function handleHeight(height: number) {
    if (height < 400) {
        return 250
    }
    return height - 20
}

export default function BreathIndicator(props: IProps) {
    const isCompression = useComponentDisplay()
    const calibrationResume = useSelector(
        BraydenCore.Entity.Calibration.CalibrationSelector.getCalibrationResume
    )
    const [
        correctSound,
    ] = useSound(`${process.env.PUBLIC_URL}/audio/correct.wav`, { volume: 1 })
    const [
        incorrectSound,
    ] = useSound(`${process.env.PUBLIC_URL}/audio/incorrect.mp3`, { volume: 1 })
    const [underSound] = useSound(`${process.env.PUBLIC_URL}/audio/under.wav`, {
        volume: 1,
    })
    const lastVentilation = useSelector(
        BraydenCore.Entity.Session.SessionSelector.getLastVentilation
    )
    const previousVentilation = usePrevious(lastVentilation)
    const lastVentilationList = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Session.SessionSelector.getLastVentilationList(
            state,
            lastVentilation !== undefined
                ? lastVentilation[RTData.R_CNT] - 1
                : 0
        )
    )

    const { ref, width = 1, height = 1 } = useResizeObserver<HTMLImageElement>()

    useEffect(() => {
        if (props.type === CardTrainingType.CHEST_COMP_ONLY) {
            return
        }
        if (
            lastVentilation === undefined ||
            lastVentilationList === undefined ||
            lastVentilation === previousVentilation
        ) {
            return
        }
        const biggestVentilationValue = BraydenCore.Calculation.RatioCalculation.getBiggestVentilationValue(
            lastVentilationList
        )
        if (isVentilationSpeedToFast(lastVentilationList) === true) {
            incorrectSound()
        } else if (
            biggestVentilationValue < calibrationResume.ventilationMinGood
        ) {
            underSound()
        } else if (
            biggestVentilationValue >= calibrationResume.ventilationMinGood &&
            biggestVentilationValue <= calibrationResume.ventilationMaxGood
        ) {
            correctSound()
        } else {
            incorrectSound()
        }
    }, [
        lastVentilation,
        previousVentilation,
        correctSound,
        incorrectSound,
        underSound,
        calibrationResume,
        lastVentilationList,
        props,
    ])

    const shouldDisplayCompComponent = () => {
        if (props.type !== CardTrainingType.VENTILATION_ONLY) {
            if (isCompression === true) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }

    return (
        <div
            className={`border border-gray-100 mx-auto flex-1 w-full relative`}
        >
            <div className="w-24 h-24 absolute right-0 mt-6 mr-6">
                <Metronome />
            </div>
            <BodyContainer>
                <div ref={ref} className="w-full h-full relative">
                    {props.type !== CardTrainingType.CHEST_COMP_ONLY && (
                        <Lungs
                            width={width}
                            height={height}
                            counter={
                                lastVentilation !== undefined
                                    ? lastVentilation[RTData.R_CNT]
                                    : 0
                            }
                        />
                    )}

                    <div className="z-50 absolute w-full h-full">
                        {shouldDisplayCompComponent() === true && (
                            <Bones
                                width={handleWidth(width)}
                                height={handleHeight(height)}
                            />
                        )}
                        {shouldDisplayCompComponent() === true && (
                            <RecoilAlert
                                width={width - 20}
                                height={height - 20}
                            />
                        )}
                    </div>
                </div>
            </BodyContainer>
        </div>
    )
}
