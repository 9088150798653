import { useState } from 'react'
import { useSelector } from 'react-redux'
import BraydenCore from '@innosonian/brayden-core'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'
import { LOCAL_STORAGE_KEY } from '@innosonian/brayden-core/build/src/_localStorage/localStorage.interface'

export default function useForceMute() {
    const [prevAudioStatus, setPrevAudioStatus] = useState(false)

    const audioForceOff = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Classroom.Selector.isAudioForceOffOn(state)
    )
    const undoAudioForceOff = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Classroom.Selector.isCancelledAudioForceOff(state)
    )
    const userId = localStorage.getItem(LOCAL_STORAGE_KEY.EMAIL) as string
    const userVideoDetail = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Classroom.Selector.getUserById(state, userId)
    )
    function savePrevAudioStatus() {
        setPrevAudioStatus(userVideoDetail.soundEnabled)
    }

    return {
        audioForceOff,
        undoAudioForceOff,
        prevAudioStatus,
        savePrevAudioStatus,
    }
}
