import React, { Fragment } from 'react'
import { IProps } from '../CprQuality.type'
import BraydenCore from '@innosonian/brayden-core'
import { useTranslation } from 'react-i18next'
import {
    CanvasLayer,
    ComputedDatum,
    CustomCanvasLayerProps,
    ResponsiveLineCanvas,
    Serie,
} from '@nivo/line'
import { line } from 'resource/chart.style'
import Typography from 'view/component/Typography/Typography'
import hook from 'hook'

const MIN_TIME_OFF = 5000
const COLOR_LIGHT = ['#74E980', '#FFB46F', ' #83E8FE']
const COLOR_DARK = ['#079415', '#FA7800', '#00C2FF']

export default function QualityCpr(props: IProps) {
    const { cprQuality } = props
    const { t } = useTranslation('locale')
    const [theme] = hook.useDarkMode()

    const renderLegends = (
        shape: 'circle' | 'square',
        text: string,
        size: 'md' | 'xl',
        colorIdx: number
    ) => {
        return (
            <div className="flex flex-row mr-8 w-full md:w-2/4 lg:w-1/4  items-center">
                {shape === 'circle' && (
                    <div
                        className={`${
                            size === 'md' ? 'h-4 w-4' : 'h-4 w-4 md:h-8 md:w-8'
                        } rounded-full`}
                        style={{
                            backgroundColor:
                                theme === 'dark'
                                    ? COLOR_LIGHT[colorIdx]
                                    : COLOR_DARK[colorIdx],
                        }}
                    ></div>
                )}
                {shape === 'square' && (
                    <div
                        className={`h-5 w-10`}
                        style={{
                            backgroundColor:
                                theme === 'dark'
                                    ? COLOR_LIGHT[colorIdx]
                                    : COLOR_DARK[colorIdx],
                        }}
                    ></div>
                )}
                <Typography className="pl-3">{text}</Typography>
            </div>
        )
    }

    const isFirstTimeListGood = (datum: ComputedDatum[]) =>
        datum.length > 0 && datum[0].position.x > 10

    const createList = (data: ComputedDatum[]) => {
        const lists: ComputedDatum[][] = []
        let tmp: ComputedDatum[] = []
        data.forEach((d) => {
            if (d.data.y === 0) {
                tmp.push(d)
            } else {
                if (tmp.length > 0) {
                    lists.push(tmp)
                    tmp = []
                }
            }
        })
        if (tmp.length > 0) {
            lists.push(tmp)
        }
        if (lists.length > 0 && isFirstTimeListGood(lists[0]) === false) {
            lists.shift()
        }
        return lists
    }

    const createTimeList = (data: ComputedDatum[]) => {
        const lists: ComputedDatum[][] = []
        let tmp: ComputedDatum[] = []
        data.forEach((d) => {
            if (
                d.data.y !== undefined &&
                d.data.y !== null &&
                d.data.y >= props.metaData.q_cpr_threshold
            ) {
                tmp.push(d)
            } else {
                if (tmp.length > 0) {
                    lists.push(tmp)
                    tmp = []
                }
            }
        })
        if (tmp.length > 0) {
            lists.push(tmp)
        }
        return lists
    }

    const getTotalMs = () =>
        props.cprQuality.reduce((a, b) => a + b.evt_period_ms, 0)

    const drawSquare = (
        xa: number,
        xb: number,
        color: string,
        height: number,
        ctx: CanvasRenderingContext2D
    ) => {
        ctx.beginPath()
        ctx.rect(xa, height, xb, 10)
        ctx.fillStyle = color
        ctx.strokeStyle = color
        ctx.fill()
        ctx.stroke()
        ctx.closePath()
    }

    const createTimeOff: CanvasLayer = (_props: CustomCanvasLayerProps) => {
        const timeMsPerDot = Math.round(
            getTotalMs() / _props.series[0].data.length
        )
        const maxTicker = Math.round(MIN_TIME_OFF / timeMsPerDot)

        const arrayLists = createList(_props.series[1].data)
        const pointSize = _props.innerWidth / _props.series[0].data.length
        arrayLists.forEach((arr) => {
            if (arr.length < maxTicker) {
                drawSquare(
                    arr[0].position.x,
                    arr.length * pointSize,
                    theme === 'dark' ? '#FBF090' : '#9D6363',
                    _props.innerHeight - 8,
                    _props.ctx
                )
            } else {
                drawSquare(
                    arr[0].position.x,
                    maxTicker * pointSize,
                    theme === 'dark' ? '#FBF090' : '#9D6363',
                    _props.innerHeight - 8,
                    _props.ctx
                )
                drawSquare(
                    arr[maxTicker - 1].position.x,
                    (arr.length - maxTicker) * pointSize,
                    theme === 'dark' ? '#E5E5E5' : '#A2A2A2',
                    _props.innerHeight - 8,
                    _props.ctx
                )
            }
        })
    }

    const drawThresholdBackground: CanvasLayer = (
        _props: CustomCanvasLayerProps
    ) => {
        const percent = 100 - props.metaData.q_cpr_threshold
        _props.ctx.beginPath()
        _props.ctx.fillStyle = theme === 'dark' ? '#BEF7D3' : '#336043'
        _props.ctx.rect(
            0,
            0,
            _props.innerWidth,
            _props.innerHeight * (percent / 100)
        )
        _props.ctx.fill()
        _props.ctx.closePath()
    }

    const drawCompressionLine: CanvasLayer = (
        _props: CustomCanvasLayerProps
    ) => {
        _props.ctx.beginPath()
        _props.ctx.fillStyle = theme === 'dark' ? '#FFFFFF' : '#7C8597'
        const stripSize = (_props.innerHeight / 5) * 2
        _props.ctx.rect(0, _props.innerHeight - stripSize, _props.innerWidth, 2)
        _props.ctx.fill()
        _props.ctx.closePath()
    }

    const drawVentilationLine: CanvasLayer = (
        _props: CustomCanvasLayerProps
    ) => {
        _props.ctx.beginPath()
        _props.ctx.fillStyle = theme === 'dark' ? '#FFFFFF' : '#7C8597'
        const stripSize = _props.innerHeight / 5
        _props.ctx.rect(0, _props.innerHeight - stripSize, _props.innerWidth, 2)
        _props.ctx.fill()
        _props.ctx.closePath()
    }

    const createTimeLine: CanvasLayer = (_props: CustomCanvasLayerProps) => {
        const arrayLists = createTimeList(_props.series[0].data)
        const pointSize = _props.innerWidth / _props.series[0].data.length
        arrayLists.forEach((arr) => {
            _props.ctx.beginPath()
            _props.ctx.rect(
                arr[0].position.x,
                _props.innerHeight * (1 - props.metaData.q_cpr_threshold / 100),
                arr.length * pointSize,
                0.5
            )
            _props.ctx.fillStyle = theme === 'dark' ? '#C2FFFB' : '#37D79D'
            _props.ctx.strokeStyle = theme === 'dark' ? '#C2FFFB' : '#37D79D'
            _props.ctx.fill()
            _props.ctx.stroke()
            _props.ctx.closePath()
        })
    }
    const renderLineChart = (
        series: Serie[],
        option: {
            gridY: boolean
            colors: string[]
        }
    ) => {
        return (
            <div className="w-full h-full flex relative">
                <div className="flex w-full h-full items-center justify-center absolute z-50">
                    <ResponsiveLineCanvas
                        enableArea={true}
                        areaOpacity={0.4}
                        data={series}
                        curve="linear"
                        theme={line}
                        lineWidth={3}
                        yScale={{
                            max: 101,
                            min: 0,
                            type: 'linear',
                        }}
                        colors={option.colors}
                        margin={{
                            top: 0,
                            right: 0,
                            bottom: 0,
                            left: 0,
                        }}
                        isInteractive={false}
                        enableGridX={false}
                        enableGridY={false}
                        axisTop={null}
                        axisRight={null}
                        axisBottom={null}
                        axisLeft={null}
                        pointSize={6}
                        pointColor={{ theme: 'background' }}
                        pointBorderWidth={6}
                        layers={[
                            drawThresholdBackground,
                            drawVentilationLine,
                            drawCompressionLine,
                            'grid',
                            'axes',
                            'lines',
                            'markers',
                            'legends',
                            'areas',
                            createTimeOff,
                            createTimeLine,
                        ]}
                    />
                </div>
            </div>
        )
    }

    return (
        <Fragment>
            <div className="w-full rounded-lg bg-gray-850 dark:bg-gray-light-bg   mb-4">
                <div className="py-2 border-b border-gray-100 rounded-t-lg  bg-green-700 dark:bg-gray-light-bg">
                    <Typography
                        type="h3"
                        alignment="center"
                        color="white dark:text-gray-light-text"
                    >
                        {t('QualityOfCpr.QualityOfCpr.QualityOfCpr')}
                    </Typography>
                </div>
                <div className="w-full h-128 p-5">
                    {theme === 'light' &&
                        renderLineChart(
                            BraydenCore.Calculation.QualityCprCalculation.createQualityOfCprDetail(
                                cprQuality
                            ),
                            {
                                gridY: true,
                                colors: COLOR_DARK,
                            }
                        )}
                    {theme === 'dark' &&
                        renderLineChart(
                            BraydenCore.Calculation.QualityCprCalculation.createQualityOfCprDetail(
                                cprQuality
                            ),
                            {
                                gridY: true,
                                colors: COLOR_LIGHT,
                            }
                        )}
                </div>
            </div>
            <div className="w-full flex flex-col xl:flex-row">
                <div className="w-full flex flex-col md:flex-row pb-4 xl:pb-0">
                    <div className="w-full flex flex-col md:flex-row  px-10">
                        {renderLegends(
                            'circle',
                            t('QualityOfCpr.QualityOfCpr.QualityOfCpr'),
                            'xl',
                            0
                        )}
                        {renderLegends(
                            'circle',
                            t('QualityOfCpr.QualityCompression.QualityComp'),
                            'md',
                            1
                        )}
                        {renderLegends(
                            'circle',
                            t('QualityOfCpr.Oxygen.OxygenLvl'),
                            'md',
                            2
                        )}
                        <div className="flex items-center flex-grow w-full md:w-2/4 lg:w-1/4 items-center">
                            <div className="flex flex-row justify-center items-center">
                                <div className="w-10 h-2 bg-red-600 dark:bg-yellow-light bg-yellow-light"></div>
                                <div className="w-4 h-2 bg-gray-light-default-text"></div>
                            </div>
                            <Typography className="pl-3">
                                {t('QualityOfCpr.TimeOff.Legend')}
                            </Typography>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
