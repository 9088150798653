import React, { useState, useRef, useEffect } from 'react'
import useDimensions from 'react-cool-dimensions'
import Classroom from 'core/classroom'
import { useTranslation } from 'react-i18next'
import Typography from 'view/component/Typography/Typography'

export default function StepCamera({ manikinArea }: { manikinArea?: boolean }) {
    const [height, setHeight] = useState(0)
    const { t } = useTranslation('locale')
    const videoRef = useRef<HTMLDivElement>(null)

    const widthRef = useDimensions({
        onResize: ({ observe, unobserve, width }) => {
            const newWidth = width * 0.5
            const size = manikinArea ? 3 : 4
            if (width < 400) {
                setHeight((width / size) * 3)
            }

            setHeight((newWidth / size) * 3)
            unobserve()
            observe()
        },
    })

    useEffect(() => {
        if (videoRef.current !== null) {
            const video = Classroom.Video.getLocalVideo()
            if (video !== undefined) {
                video.play(videoRef.current, {
                    fit: 'contain',
                })
            }
        }
    })

    return (
        <div className="w-full flex flex-col px-20">
            <div className="w-full flex flex-col flex-grow relative ">
                <div
                    className="flex flex-col flex-grow w-full items-center h-full"
                    style={{
                        height: `${height}px`,
                    }}
                    ref={widthRef.observe}
                >
                    <div
                        ref={videoRef}
                        className={`w-full relative flex items-center h-full `}
                    ></div>
                </div>
                <div
                    className={`${
                        manikinArea ? 'invible' : 'invisible'
                    } absolute w-full h-1/3 border border-red-700 dark:border-red-light-default-button flex items-center bottom-0 left-0`}
                    style={{ height: '40%' }}
                >
                    <Typography
                        className="w-full"
                        alignment="center"
                        color="red-700"
                    >
                        {t('Classroom.ManikinPosition')}
                    </Typography>
                </div>
            </div>
        </div>
    )
}
