import React from 'react'
import { useTranslation } from 'react-i18next'
import VentilationCounter from 'view/component/VentilationCounter/VentilationCounter'
import Chronometer from '../Chronometer/Chronometer'
import CompressionCounter from '../CompressionCounter/CompressionCounter'
import CycleCounter from '../CycleCounter/CycleCounter'
import { IProps } from './CounterInfoBar.type'
export default function CounterInfoBar(props: IProps) {
    const cycleSize = 35
    const { t } = useTranslation('locale')

    return (
        <div className="w-full flex flex-col justify-between mt-5 md:mt-0 border border-gray-100">
            <div className="w-full flex flex-col p-5">
                <div className="w-full flex flex-col md:flex-row">
                    <div className="w-full md:w-1/4 justify-center p-5 flex md:flex-col border-b md:border-b-0 md:border-r border-gray-200 dark:border-gray-light-default-text">
                        <CycleCounter cycleSize={cycleSize} />
                    </div>
                    <div className="w-full md:w-1/4 justify-center p-5 flex md:flex-col border-b md:border-b-0 md:border-r border-gray-200 dark:border-gray-light-default-text">
                        <CompressionCounter />
                    </div>
                    <div className="w-full md:w-1/4 justify-center p-5 flex flex-col border-b md:border-b-0 md:border-r border-gray-200 dark:border-gray-light-default-text">
                        <Chronometer
                            title={t('RealTimeTrainingPage.Duration')}
                            timeout={props.exceedSeconds}
                            autoStop={props.autoStop}
                            stopCb={props.stopCb}
                        />
                    </div>
                    <div className="w-full md:w-1/4 justify-center">
                        <VentilationCounter />
                    </div>
                </div>
            </div>
        </div>
    )
}
