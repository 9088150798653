import React, { useRef, useState } from 'react'
import ModalContainer from '../ModalContainer/ModalContainer'
import { useSelector, useDispatch } from 'react-redux'
import BraydenCore from '@innosonian/brayden-core'
import Typography from 'view/component/Typography/Typography'
import { useTranslation } from 'react-i18next'
import { ReactComponent as AddIcon } from 'resource/img/icons/add.svg'
import Button from 'view/component/Button/Button'
import { ReactComponent as Cross } from 'resource/img/icons/cross.svg'
import { sagaPromise } from '@innosonian/brayden-core/build/src/_reducer/reducer'
import { importUser } from '@innosonian/brayden-core/build/src/entity/User/User.saga'
import { toast } from 'react-toastify'

export default function ImportUserModal() {
    const dispatch = useDispatch()
    const { t } = useTranslation('locale')
    const inputRef = useRef<HTMLInputElement>(null)
    const [file, setFile] = useState<File | null>(null)
    const [loading, setLoading] = useState(false)
    const isOpen = useSelector(
        BraydenCore.Entity.Modal.ModalSelector.isImportUser
    )

    const handleImportUser = async () => {
        if (file !== null) {
            try {
                setLoading(true)
                await sagaPromise(importUser, {
                    file,
                    filename: file.name,
                })
                toast(t('ToastManager.ImportUserSuccess'), {
                    type: 'success',
                })
            } catch (e) {
                toast(t('ToastManager.ImportUserError'), { type: 'error' })
            } finally {
                setFile(null)
                setLoading(false)
                dispatch(
                    BraydenCore.Entity.Modal.ModalAction.modalCloseImportUser()
                )
            }
        }
    }

    const handleSelectFile = () => {
        if (inputRef !== null && inputRef.current !== null) {
            inputRef.current.click()
        }
    }

    return (
        <ModalContainer
            isOpen={isOpen === true}
            closeButton={true}
            onCloseClick={() => {
                setFile(null)
                dispatch(
                    BraydenCore.Entity.Modal.ModalAction.modalCloseImportUser()
                )
            }}
        >
            <div className="flex h-full flex-col w-full items-center">
                <div className="w-full flex flex-grow-1 pt-10 pb-10">
                    <Typography alignment="center" type="h2" className="w-full">
                        {t('UserList.ImportUser')}
                    </Typography>
                </div>
                {file === null && (
                    <div className="w-full flex flex-col items-center justify-center">
                        <div
                            className="p-4 flex border-4 border-dashed rounded-lg border-gray-50  dark:border-gray-light-border mx-auto cursor-pointer"
                            onClick={handleSelectFile}
                        >
                            <AddIcon className="h-40 w-40 text-gray-50 dark:text-gray-light-border" />
                        </div>
                    </div>
                )}
                <div className="w-auto py-5">
                    <a href="files/import_example.xlsx" download>
                        <Button
                            disable={false}
                            onclick={() => console.log()}
                            className="items-center"
                            size="md"
                            fontSize="xs"
                            weight="normal"
                            shape="rounded-mid"
                            color="primary"
                            padding="xs"
                        >
                            {t('UserList.DownloadSample')}
                        </Button>
                    </a>
                </div>

                {file !== null && (
                    <div className="w-full flex flex-col justify-center">
                        <div className="p-2 flex justify-center items-center border border-gray-50 rounded-lg">
                            <Typography alignment="center">
                                {file.name}
                            </Typography>
                            <Cross
                                className="w-3 h-3 flex text-gray-100 ml-2 cursor-pointer"
                                onClick={() => setFile(null)}
                            />
                        </div>

                        <Button
                            className="mx-auto mt-4"
                            color="blue"
                            onclick={handleImportUser}
                            disable={loading}
                        >
                            {t('UserList.Import')}
                        </Button>
                    </div>
                )}
                <input
                    ref={inputRef}
                    className="hidden"
                    type="file"
                    accept=".xlsx"
                    onChange={(e) => {
                        const _src = e.target.files
                        if (_src !== null) {
                            setFile(_src[0])
                        }
                    }}
                />
            </div>
        </ModalContainer>
    )
}
