import React, { useState } from 'react'
import Button from 'view/component/Button/Button'
import ModalContainer from '../ModalContainer/ModalContainer'
import { useDispatch, useSelector } from 'react-redux'
import BraydenCore from '@innosonian/brayden-core'
import { useTranslation } from 'react-i18next'
import Typography from 'view/component/Typography/Typography'
import { sagaPromise } from '@innosonian/brayden-core/build/src/_reducer/reducer'
import { deleteUser } from '@innosonian/brayden-core/build/src/entity/User/User.saga'
import { toast } from 'react-toastify'

export default function ConfirmDeleteUser() {
    const { t } = useTranslation('locale')
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const selectedUserId = useSelector(
        BraydenCore.Entity.Modal.ModalSelector.getDeleteUserId
    )

    const handleDeleteUser = async () => {
        setLoading(true)
        try {
            await sagaPromise(deleteUser, selectedUserId as number)
            toast(t('ToastManager.DeleteUserSuccess'), { type: 'success' })
            dispatch(
                BraydenCore.Entity.Modal.ModalAction.modalCloseDeleteUserConfirmation()
            )
        } catch (e) {
            toast(t('ToastManager.DeleteUserError'), { type: 'error' })
        } finally {
            setLoading(false)
        }
    }

    return (
        <ModalContainer
            isOpen={selectedUserId !== undefined}
            closeButton={true}
            onCloseClick={() =>
                dispatch(
                    BraydenCore.Entity.Modal.ModalAction.modalCloseDeleteUserConfirmation()
                )
            }
        >
            <div className="flex flex-col h-full  w-full lg:px-10 justify-center items-center md:py-4">
                <div className="w-full pb-4">
                    <Typography>{t('UserList.ConfirmDeleteUser')}</Typography>
                </div>
                <div className="w-full flex flex-col">
                    <Typography>
                        {t('UserList.ConfirmDeleteUserContent')}
                    </Typography>
                </div>
                <div className="w-full flex justify-end">
                    <Button
                        disable={loading}
                        className="mr-2"
                        onclick={handleDeleteUser}
                        type="plain"
                        shape="rounded-mid"
                        padding="sm"
                        color="secondary"
                    >
                        {t('UserList.Delete')}
                    </Button>
                </div>
            </div>
        </ModalContainer>
    )
}
