import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { IProps } from './TimeOff.type'
import clockAnimationFirstPart from 'resource/lottie/clock-first.json'
import clockAnimationSecondPart from 'resource/lottie/clock-second.json'
import Lottie from '../Lottie/Lottie'
import './TimeOff.scss'
import BraydenCore from '@innosonian/brayden-core'
import { RTData } from '@innosonian/brayden-core/build/src/_bluetooth/bluetooth.interface'

export default function TimeOff(props: IProps) {
    const [display, setDisplay] = useState('hidden')
    const lastDataPushed = useSelector(
        BraydenCore.Entity.Session.SessionSelector.getLatestDataPushed
    )
    const isSessionStart = useSelector(
        BraydenCore.Entity.Session.SessionSelector.getSessionStart
    )
    const [loop, setLoop] = useState(false)
    useEffect(() => {
        if (
            lastDataPushed !== undefined &&
            isSessionStart &&
            display === 'hidden' &&
            lastDataPushed[RTData.C_RATE] === 0
        ) {
            setDisplay('flex')
        }
        if (
            lastDataPushed !== undefined &&
            isSessionStart &&
            display === 'flex' &&
            lastDataPushed[RTData.C_RATE] > 0
        ) {
            setDisplay('hidden')
            setLoop(false)
        }
    }, [lastDataPushed, setDisplay, isSessionStart, display])

    useEffect(() => {
        if (isSessionStart === false) {
            setDisplay('hidden')
            setLoop(false)
        }
    }, [isSessionStart])

    if (props.active === true) {
        return (
            <div
                className={`absolute w-full h-full bg-gray-900 dark:bg-gray-light-default-text z-40 bg-opacity-75 shadow-dark top-0 left-0 items-center ${display}`}
            >
                <div className="flex flex-col w-full h-full">
                    <div className="mx-auto w-full h-full object-fit">
                        {loop === false && (
                            <Lottie
                                loop={true}
                                animationData={clockAnimationSecondPart}
                                speed={1}
                                play={display === 'flex' ? true : false}
                                onLoopComplete={() => {
                                    setLoop(true)
                                    setDisplay('hidden')
                                }}
                            />
                        )}
                        {loop === true && (
                            <Lottie
                                loop={true}
                                animationData={clockAnimationFirstPart}
                                speed={1}
                                play={true}
                            />
                        )}
                    </div>
                </div>
            </div>
        )
    }
    return <div className="hidden"></div>
}
