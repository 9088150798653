import React from 'react'
import { IProps } from './Typography.type'
import { useSelector } from 'react-redux'
import BraydenCore from '@innosonian/brayden-core'

export default function Typography(props: React.PropsWithChildren<IProps>) {
    const isClassroomConnected = useSelector(
        BraydenCore.Entity.Classroom.Selector.isConnected
    )

    const handleWeight = () => {
        switch (props.weight) {
            case 'bold':
                return 'font-bold'
            case 'light':
                return 'font-light'
            case 'normal':
                return 'font-normal'
            default:
                return 'font-normal'
        }
    }

    const handleType = () => {
        switch (props.type) {
            case 'h':
                return isClassroomConnected === false ? 'text-6xl' : 'text-2xl'
            case 'h1':
                return 'text-xl xl:text-4xl lg:text-3xl'
            case 'h2':
                return isClassroomConnected === false
                    ? 'sm:text-3xl md:text-xl'
                    : 'sm:text-3xl md:text-xl'
            case 'h3':
                return isClassroomConnected === false ? 'text-xl' : 'text-base'
            case 'p':
                return 'sm:text-lg text-base'
            case 'span':
                return 'sm:text-base text-base'
            case 'caption':
                return 'sm:text-sm text-xs'
            case 'wild':
                return 'text-10xl'
            default:
                return 'text-base'
        }
    }

    const handleTextColor = () => {
        if (props.color !== undefined) {
            return `text-${props.color}`
        } else {
            return `text-white dark:text-gray-light-text`
        }
    }

    const handleClassName = () => {
        return props.className || ''
    }

    const handleAlignment = () => {
        switch (props.alignment) {
            case 'center':
                return 'text-center'
            case 'left':
                return 'text-left'
            case 'right':
                return 'text-right'
            default:
                return 'text-left'
        }
    }

    return (
        <div
            className={`${handleWeight()} ${handleTextColor()} ${handleType()} ${handleClassName()} ${handleAlignment()}`}
        >
            {props.children}
        </div>
    )
}
