import React, { PropsWithChildren, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import BraydenCore from '@innosonian/brayden-core'
import Typography from 'view/component/Typography/Typography'
import ClassroomLocalCommand from 'view/component/ClassroomLocalCommand/ClassroomLocalCommand'
import ClassroomCamera from 'view/component/ClassroomCamera/ClassroomCamera'
import LocalClassroomCamera from 'view/component/ClassroomCamera/LocalClassroomCamera'
import { ReactComponent as UnstableNetwork } from 'resource/img/icons/unstableNetwork.svg'
import { IProps } from './SideCameraView.type'
import ImageSlider from 'view/component/ImageSlider/ImageSlider'
import { ReactComponent as ManikinSetupLeft } from 'resource/img/icons/manikin-setup-behind.svg'
import { ReactComponent as ManikinSetupRight } from 'resource/img/icons/manikin-setup-front.svg'

export default function SideCameraView(props: PropsWithChildren<IProps>) {
    const { t } = useTranslation('locale')
    const [myNetwork, setMyNetwork] = useState('0')
    const adminUid = useSelector(
        BraydenCore.Entity.Classroom.Selector.getAdminUid
    )

    const myNetworkQuality = useSelector(
        BraydenCore.Entity.Classroom.Selector.getMyNetworkQuality
    )

    useEffect(() => {
        setMyNetwork(myNetworkQuality)
    }, [myNetworkQuality])

    const handleNetworkStatMessage = () => {
        if (myNetwork) {
            if (parseInt(myNetwork) > 2) {
                return (
                    <Typography
                        className="flex justify-center items-center"
                        alignment="center"
                        color="text-white"
                    >
                        <UnstableNetwork className="mr-2" />
                        {t('Classroom.UnstableNetwork')}
                    </Typography>
                )
            }
        }
    }
    const handleNetworkStatColor = () => {
        if (myNetwork) {
            if (parseInt(myNetwork) > 2) {
                return 'bg-red-light'
            } else {
                return 'bg-transparent'
            }
        }
    }

    return (
        <div className="flex flex-col h-full w-2/5 p-4 pl-4 border-l-4 border-gray-800 dark:border-gray-50 ">
            <div className="h-1/2">
                <ClassroomCamera
                    userId={adminUid === undefined ? '' : adminUid}
                    size="full"
                    type="Camera"
                />
            </div>
            {props.showImageSlider ? (
                <ImageSlider
                    className="h-1/2"
                    loopTimer={10000}
                    images={[<ManikinSetupLeft />, <ManikinSetupRight />]}
                />
            ) : (
                <>
                    <div
                        className={`w-full mt-10 h-5 flex justify-center items-center ${handleNetworkStatColor()}`}
                    >
                        {handleNetworkStatMessage()}
                    </div>
                    <div
                        className={`w-full flex-col h-1/2 flex border-t border-r border-l border-gray-100 `}
                    >
                        <LocalClassroomCamera isAdmin={false} />
                    </div>
                    <ClassroomLocalCommand />
                </>
            )}
        </div>
    )
}
