import React from 'react'
import { IProps } from './ShareScree.type'
import { useTranslation } from 'react-i18next'
import Typography from 'view/component/Typography/Typography'
import Button from 'view/component/Button/Button'
import { ReactComponent as ConnectingClassroom } from 'resource/img/icons/connectingClassroom.svg'

export default function ShareScreen(props: IProps) {
    const { t } = useTranslation('locale')
    return (
        <div className="flex flex-col w-full items-center">
            <ConnectingClassroom className="w-44 h-44 xl:w-50 xl:h-50 border-b border-gray-100 xl:py-1" />
            <Button
                shape="rounded-mid"
                disable={false}
                color="secondary"
                padding="sm"
                weight="bold"
                onclick={() => props.connectScreenShare()}
                className="self-center mt-10"
            >
                {t('Classroom.Setup.ScreenShare')}
            </Button>
            <div className="flex flex-col items-start mt-14">
                <Typography alignment="center" type="h2" className="pb-5">
                    {t('Classroom.Setup.GuideStepOne')}
                </Typography>
                <Typography alignment="center" type="h2" className="pb-5">
                    {t('Classroom.Setup.GuideStepTwo')}
                </Typography>
                <Typography alignment="center" type="h2" className="pb-5">
                    {t('Classroom.Setup.GuideStepThree')}
                </Typography>
            </div>
        </div>
    )
}
