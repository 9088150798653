import React from 'react'
import { useSelector, shallowEqual, useDispatch } from 'react-redux'
import { IProps } from './TrainingCardItem.type'
import { Link } from 'react-router-dom'
import Typography from '../Typography/Typography'
import { ReactComponent as CompressionIcon } from 'resource/img/icons/comp-icon.svg'
import { ReactComponent as VentilationIcon } from 'resource/img/icons/lungs.svg'
import { ReactComponent as CprIcon } from 'resource/img/icons/cpr-icon.svg'
import { ReactComponent as AssessmentIcon } from 'resource/img/icons/assessment-icon.svg'
import { motion, AnimatePresence } from 'framer-motion'
import {
    CardTrainingType,
    IParsedCard,
} from '@innosonian/brayden-core/build/src/entity/Card/Card.interface'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'
import BraydenCore from '@innosonian/brayden-core'

function handleTrainingIcon(type: string) {
    switch (type) {
        case CardTrainingType.VENTILATION_ONLY:
            return (
                <VentilationIcon className="text-gray-200 dark:text-gray-light-icon h-20 w-20" />
            )
        case CardTrainingType.CHEST_COMP_ONLY:
            return (
                <CompressionIcon className="text-gray-200 dark:text-gray-light-icon h-20 w-20" />
            )
        case CardTrainingType.CPR_TRAINING:
            return (
                <CprIcon className="text-gray-200 dark:text-gray-light-icon h-20 w-20" />
            )
        default:
            return (
                <AssessmentIcon className="text-gray-200 dark:text-gray-light-icon h-20 w-20" />
            )
    }
}

export default function TrainingCardItem(props: IProps) {
    const card = useSelector<IMainReducer, IParsedCard>(
        (state) =>
            BraydenCore.Entity.Card.CardSelector.getCardById(state, props.id),
        shallowEqual
    )
    const dispatch = useDispatch()

    return (
        <AnimatePresence exitBeforeEnter>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.25 }}
                className={` opacity-0 w-full  flex flex-grow-1 md:w-4/12 p-4${
                    props.className ? props.className : ''
                }`}
            >
                <Link
                    to={
                        !props.isClassroomConnected
                            ? `/trainings/${card.id}`
                            : '#'
                    }
                    onClick={() => {
                        dispatch(
                            BraydenCore.Entity.Session.SessionAction.selectTrainingCard(
                                card.id
                            )
                        )
                    }}
                    className={`w-full h-full flex relative ${
                        props.isClassroomConnected ? 'cursor-not-allowed' : ''
                    }`}
                >
                    <div
                        className="w-full p-4 stretch shadow-button dark:shadow-none rounded-md bg-gray-850 dark:bg-gray-light-bg border border-transparent dark:border-gray-100"
                        onClick={props.onClick}
                    >
                        <div className="w-full flex flex-col lg:flex-row items-center">
                            <div className="w-full flex lg:w-1/3 lg:pr-2 justify-center lg:justify-end">
                                {handleTrainingIcon(card.type)}
                            </div>
                            <div className="w-full flex lg:w-2/3 pt-4 lg:pt-0 justify-center">
                                <Typography type="h3" alignment="center">
                                    {card.cardTitle}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </Link>
            </motion.div>
        </AnimatePresence>
    )
}
