import Video from './video'
import Rtm from './rtm'
import Screen from './screen'
import BraydenCore from '@innosonian/brayden-core'
import { IWebRtcProvider } from '@innosonian/brayden-core/build/src/_webRtc/webRtc.interface'
import { LOCAL_STORAGE_KEY } from '@innosonian/brayden-core/build/src/_localStorage/localStorage.interface'
import { IHardware } from '@innosonian/brayden-core/build/src/entity/Hardware/Hardware.interface'

let leaveClassroomTimeout: number | undefined = undefined
const TIMER = 60000 * 25
let classroomName: string | undefined = undefined
let uid: string | undefined = undefined

export function setLeaveClassroomTimeout() {
    leaveClassroomTimeout = window.setTimeout(() => {
        leaveClassroom()
    }, TIMER)
}

export function removeLeaveClassroomTimeout() {
    if (leaveClassroom !== null) {
        window.clearTimeout(leaveClassroomTimeout)
        leaveClassroomTimeout = undefined
    }
}
async function initRtmConnection() {
    classroomName = localStorage.getItem(LOCAL_STORAGE_KEY.CLIENT_ID) as string
    uid = localStorage.getItem(LOCAL_STORAGE_KEY.EMAIL) as string
    await Rtm.connect(classroomName, uid)
    await Rtm.joinChannel(classroomName)
    Rtm.listen()
}
async function initRtcConnection() {
    classroomName = localStorage.getItem(LOCAL_STORAGE_KEY.CLIENT_ID) as string
    uid = localStorage.getItem(LOCAL_STORAGE_KEY.EMAIL) as string
    const rtcToken = await Screen.createRtcToken(classroomName, uid)
    return rtcToken
}
async function initScreenShare() {
    if (classroomName !== undefined && uid !== undefined) {
        await Screen.startScreenSharing(classroomName, uid)
    }
}

async function initAudioAndVideo() {
    const classroomName = localStorage.getItem(
        LOCAL_STORAGE_KEY.CLIENT_ID
    ) as string
    const uid = localStorage.getItem(LOCAL_STORAGE_KEY.EMAIL) as string
    Video.initListener()
    await Video.join(classroomName, uid)

    BraydenCore.Reducer.store.dispatch(
        BraydenCore.Entity.Classroom.Action.connectCamera(true)
    )
}

async function leaveClassroom(hardware?: IHardware) {
    Video.stopLocalMicroAndAudio()
    Video.leave()
    // if (Screen.getScreenClient().connectionState === 'CONNECTED') {
    await Screen.stopScreenSharing()
    // }
    await Rtm.leaveChannel()
    BraydenCore.Reducer.store.dispatch(
        BraydenCore.Entity.Classroom.Action.leaveClassRoom()
    )
    BraydenCore.Reducer.store.dispatch(
        BraydenCore.Entity.Classroom.Action.resetClassroomUser()
    )

    if (hardware !== undefined) {
        BraydenCore.Reducer.store.dispatch(
            BraydenCore.Entity.Session.SessionAction.disconnectManikinRequest(
                hardware.modelType
            )
        )
    }
}

const Classroom = {
    Video,
    Rtm,
    initRtcConnection,
    initRtmConnection,
    leaveClassroom,
    initScreenShare,
    initAudioAndVideo,
    Screen,
}

const provider: IWebRtcProvider = {
    joinClassRoom: initAudioAndVideo,
    leaveClassroom: leaveClassroom,
}

Video.createClient()

export default Classroom

export { provider }
