import React, { useState } from 'react'
import VideoPlayer from 'view/component/MediaPlayer/VideoPlayer/VideoPlayer'
import { useSelector, useDispatch } from 'react-redux'
import ModalContainer from '../ModalContainer/ModalContainer'
import BraydenCore from '@innosonian/brayden-core'

export default function VideoModal() {
    const dispatch = useDispatch()
    const [playing, setPlaying] = useState(false)
    const selectedSrc = useSelector(
        BraydenCore.Entity.Modal.ModalSelector.getVideoModalSrc
    )

    const handleClose = () =>
        dispatch(BraydenCore.Entity.Modal.ModalAction.modalCloseVideo())
    const handleVideoClick = () => setPlaying((e) => !e)

    return (
        <ModalContainer
            isOpen={
                selectedSrc !== undefined &&
                selectedSrc !== '-1' &&
                selectedSrc !== 'empty-file'
            }
            closeButton={true}
            onCloseClick={handleClose}
        >
            <div className="h-full w-full">
                <VideoPlayer
                    src={selectedSrc}
                    playing={playing}
                    onClick={handleVideoClick}
                />
            </div>
        </ModalContainer>
    )
}
