import React from 'react'
import { ReactComponent as Icon } from 'resource/img/logo-small.svg'
import { IProps } from './FallBack.type'
import FadeInContainer from '../../layout/FadeInContainer/FadeInContainer'
import { motion } from 'framer-motion'
export default function FallBack(props: IProps) {
    return (
        <FadeInContainer className="w-full h-screen bg-gray-900 dark:bg-gray-light-bg flex justify-center items-center">
            <motion.div
                className="w-40 h-40"
                animate={{ scale: 1.5, rotate: 360 }}
                transition={{
                    repeatType: 'reverse',
                    repeat: Infinity,
                    duration: 1,
                }}
            >
                <Icon className="w-40 h-40" />
            </motion.div>
        </FadeInContainer>
    )
}
