import React, { useEffect } from 'react'

function useOutsideAlerter(
    ref: React.RefObject<any>,
    callback: (event: MouseEvent) => void
) {
    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target)) {
                callback(event)
            }
        }

        document.addEventListener('mousedown', handleClickOutside)
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [ref, callback])
}

export default useOutsideAlerter
