import React, { useEffect } from 'react'

function useClickInside(
    ref: React.RefObject<any>,
    callback: (event: MouseEvent) => void
) {
    useEffect(() => {
        function handleClickInside(event: MouseEvent) {
            if (ref.current && ref.current.contains(event.target)) {
                callback(event)
            }
        }

        document.addEventListener('mousedown', handleClickInside)
        return () => {
            document.removeEventListener('mousedown', handleClickInside)
        }
    }, [ref, callback])
}

export default useClickInside
