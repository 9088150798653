import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Typography from 'view/component/Typography/Typography'
import ModalContainer from '../ModalContainer/ModalContainer'
import { ReactComponent as Config } from 'resource/img/config-manikin.svg'
import BraydenCore from '@innosonian/brayden-core'

export default function FirmwareUpdateModal() {
    const { t } = useTranslation('locale')
    const dispatch = useDispatch()
    const isFirmwareNeedUpdate = useSelector(
        BraydenCore.Entity.Modal.ModalSelector.getFirmwareUpdate
    )

    return (
        <ModalContainer
            isOpen={isFirmwareNeedUpdate === true}
            closeButton={true}
            onCloseClick={() =>
                dispatch(
                    BraydenCore.Entity.Modal.ModalAction.modalCloseFirmwareUpdate()
                )
            }
        >
            <div className="flex h-full text-gray-50 dark:text-gray-100 w-full lg:px-10 justify-center items-center">
                <div className="hidden md:flex md:w-1/3 lg:w-1/2">
                    <Config className="md:h-40 md:w-40 xl:h-96 xl:w-96" />
                </div>
                <div className="w-full lg:w-1/2 md:w-2/3 ">
                    <div className="pb-2">
                        <Typography color="gray-640" type="h3">
                            {t('Error.Title.FirmwareUpdate')}
                        </Typography>
                    </div>
                    <div className="w-full flex flex-grow-1">
                        <Typography color="gray-640">
                            {t('Error.FirmwareUpdate')}
                        </Typography>
                    </div>
                </div>
            </div>
        </ModalContainer>
    )
}
