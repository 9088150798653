import React, { PropsWithChildren } from 'react'
import Typography from '../../Typography/Typography'
import { IProps } from './TableContainer.type'

export default function TableContainer(props: PropsWithChildren<IProps>) {
    return (
        <div
            className={`flex flex-col flex-grow-1  border border-gray-100 items-stretch h-full w-full ${
                props.disable && props.disable === true ? 'opacity-25' : ''
            }`}
        >
            <div className="flex border-b border-white dark:border-gray-100">
                <div
                    className={`w-8 py-1 bg-${
                        props.color
                            ? props.color
                            : 'gray-100 dark:bg-gray-light-bg'
                    }`}
                ></div>
                <div
                    className={`w-full py-1 bg-gray-dark-bg dark:bg-gray-light-bg 
                    }`}
                >
                    <Typography
                        type="h3"
                        // handle specially for white mode with color
                        color={` ${
                            props.color
                                ? 'gray-light-secondary dark:text-gray-light-text'
                                : 'dark:text-gray-light-text'
                        }`}
                        alignment="center"
                        className="w-full"
                    >
                        {props.title}
                    </Typography>
                </div>
            </div>
            <div className="flex flex-col w-full  items-stretch flex-grow-1">
                <div className="w-full flex border-gray-900 dark:border-gray-light-border items-stretch flex-grow-1">
                    {props.children}
                </div>
            </div>
        </div>
    )
}
