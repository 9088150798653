import React from 'react'
import { IProps } from './Switch.type'
import './Switch.scss'

export default function Switch(props: IProps) {
    return (
        <div
            className={`flex items-center cursor-pointer rounded-xl bg-gray-100 w-20 border-2  border-white h-8 p-1 button-container ${
                props.active === true ? 'active-container' : ''
            }`}
            onClick={props.onclick}
        >
            <div
                className={`h-6 rounded-full bg-white  switch-button ${
                    props.active === true ? 'active-button' : 'inactive-button'
                }`}
            ></div>
        </div>
    )
}
