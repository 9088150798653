import React, { Fragment, useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import hook from 'hook'
import { IProps } from './TopBar.type'
import BraydenCore from '@innosonian/brayden-core'
import Button from '../Button/Button'
import classroom from 'core/classroom'
import ArrowBack from '../ArrowBack/ArrowBack'
import Typography from '../Typography/Typography'
import DropDown from '../DropDown/DropDown'
import Item from '../DropDown/Item/Item'
import { ReactComponent as Logo } from 'resource/img/logo.svg'
import { ReactComponent as LogoLight } from 'resource/img/logo-light.svg'
import { ReactComponent as LogoIcon } from 'resource/img/logo-small.svg'
import { ReactComponent as UserIcon } from 'resource/img/icons/user.svg'
import { ReactComponent as UserLightIcon } from 'resource/img/icons/user-light.svg'
import { ReactComponent as ArrowDownIcon } from 'resource/img/icons/arrow-down.svg'
import { ReactComponent as DarkIcon } from 'resource/img/icons/dark.svg'
import { ReactComponent as LightIcon } from 'resource/img/icons/light.svg'
import { ReactComponent as Muted } from 'resource/img/icons/muted.svg'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'

export default function TopBar(props: IProps) {
    const history = useHistory()
    const name = useSelector(BraydenCore.Entity.Client.ClientSelector.getName)
    const dispatch = useDispatch()
    const { t } = useTranslation('locale')
    const [colorTheme, setTheme] = hook.useDarkMode()
    const [defaultLogo, setDefaultLogo] = useState(false)

    const surname = useSelector(
        BraydenCore.Entity.Client.ClientSelector.getSurname
    )
    const manikinId = useSelector(
        BraydenCore.Entity.Session.SessionSelector.getSelectedManikin
    )

    const hardware = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Hardware.HardwareSelector.getConnectedHardwareById(
            state,
            manikinId as string
        )
    )
    const classroomStarted = useSelector(
        BraydenCore.Entity.Classroom.Selector.isConnected
    )
    const clientId = useSelector(
        BraydenCore.Entity.Client.ClientSelector.getGeneralClientId
    )
    const isLogoAvailable = useSelector(
        BraydenCore.Entity.Client.ClientSelector.isLogoAvailable
    )
    const logo = useSelector(
        BraydenCore.Entity.Client.ClientSelector.getClientLogo
    )
    const { audioForceOff } = hook.useForceMute()

    useEffect(() => {
        const getLogo = async () => {
            const subDomain = window.location.hostname.split('.')[0]
            const subDomainTail = subDomain.split('-')[1]
            if (subDomainTail) {
                await BraydenCore.Reducer.sagaPromise(
                    BraydenCore.Entity.Client.getClientLogo,
                    subDomainTail
                )
                if (isLogoAvailable) {
                    setDefaultLogo(false)
                } else {
                    setDefaultLogo(true)
                }
            } else {
                setDefaultLogo(true)
            }
        }
        getLogo()
    }, [dispatch, logo.menu, isLogoAvailable])

    const _isReduxAuth = () => {
        return clientId !== 'chrome' && clientId !== ''
    }

    const stopClassroom = async () => {
        await classroom.leaveClassroom(hardware)
        history.push('/home')
    }

    const displayConnectButton = () => {
        return (
            <div
                className="flex items-center justify-end"
                onClick={() => history.push('/login')}
            >
                {colorTheme === 'light' && <UserIcon className="ml-4" />}
                {colorTheme === 'dark' && <UserLightIcon className="ml-4" />}
                <Typography className="ml-4">{t('TopBar.LogIn')}</Typography>
            </div>
        )
    }

    const displayDropDown = () => {
        return (
            <DropDown
                topBar={
                    <div className="h-full flex items-center">
                        {colorTheme === 'light' && (
                            <UserIcon className="ml-4" />
                        )}
                        {colorTheme === 'dark' && (
                            <UserLightIcon className="ml-4" />
                        )}
                        <div className="flex items-center">
                            <Typography
                                type="p"
                                className="pl-4 hidden md:flex"
                            >
                                {name} {surname}
                            </Typography>
                            <ArrowDownIcon className="ml-4 text-gray-50 dark:text-gray-light-default-text" />
                        </div>
                    </div>
                }
            >
                <Fragment>
                    <Item
                        text={t('TopBar.TrainingRecord')}
                        onClick={(e) => {
                            history.push('/records')
                        }}
                    ></Item>
                    <Item
                        text={t('TopBar.LogOut')}
                        onClick={(e) => {
                            BraydenCore.history.getProvider().push('/login')
                            dispatch(
                                BraydenCore.Entity.Oauth.Action.oauthLogOutRequest()
                            )
                        }}
                    ></Item>
                </Fragment>
            </DropDown>
        )
    }

    return (
        <nav className="w-full flex justify-start bg-gray-800 dark:bg-gray-light-top-bar items-center p-2 md:py-5 lg:py-2 md:px-5 ">
            <div className="flex w-full items-center">
                <ArrowBack />
                <Link to={clientId === 'chrome' ? '/' : '/home'}>
                    {colorTheme === 'light' &&
                        !defaultLogo &&
                        isLogoAvailable && <img src={logo?.menu} alt="logo" />}
                    {colorTheme === 'light' &&
                        defaultLogo &&
                        !isLogoAvailable && (
                            <Logo className="hidden md:flex w-40" />
                        )}
                    {colorTheme === 'dark' &&
                        !defaultLogo &&
                        isLogoAvailable && <img src={logo?.menu} alt="logo" />}
                    {colorTheme === 'dark' &&
                        defaultLogo &&
                        !isLogoAvailable && (
                            <LogoLight className="hidden md:flex w-40" />
                        )}
                    {defaultLogo && !isLogoAvailable && (
                        <LogoIcon className="flex md:hidden w-10" />
                    )}
                </Link>

                <div className="flex flex justify-self-end items-center h-full ml-auto cursor-pointer">
                    {audioForceOff && <Muted className="w-8 h-8 mr-2" />}
                    {classroomStarted === true && (
                        <Button
                            disable={false}
                            size="sm"
                            color="blue"
                            padding="xs"
                            onclick={stopClassroom}
                            className="self-center"
                        >
                            {t('Classroom.Leave')}
                        </Button>
                    )}

                    {colorTheme === 'dark' && (
                        <DarkIcon
                            className="w-10 h-10 text-yellow-800 ml-4"
                            onClick={() => setTheme('light')}
                        />
                    )}
                    {colorTheme === 'light' && (
                        <LightIcon
                            className="w-10 h-10 text-gray-light-default-text ml-4"
                            onClick={() => setTheme('dark')}
                        />
                    )}

                    {_isReduxAuth() === true && displayDropDown()}
                    {_isReduxAuth() === false && displayConnectButton()}
                </div>
            </div>
        </nav>
    )
}
