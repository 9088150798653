import React from 'react'
import Typography from 'view/component/Typography/Typography'

export default function BrowserValidator(props: React.PropsWithChildren<{}>) {
    if (window.navigator.bluetooth === undefined) {
        return (
            <div className="w-full h-screen bg-gray-900 flex flex-col items-center p-64">
                <Typography type="h3">
                    Brayden online is not available on your current Browser.
                </Typography>
                <Typography type="h3">
                    Please use the latest version of Chrome or any browser with
                    bluetooth protocol.
                </Typography>
            </div>
        )
    }
    return <div className="w-full">{props.children}</div>
}
