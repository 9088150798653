import { ResponsiveBar } from '@nivo/bar'
import React from 'react'
import { useTranslation } from 'react-i18next'
import hook from 'hook'
import TableContainer from 'view/component/Table/TableContainer/TableContainer'
import Typography from 'view/component/Typography/Typography'
import { isDevStage } from 'helper/getProdStage'
import { IProps } from './MetricCycle.type'
import FadeInContainer from '../../../layout/FadeInContainer/FadeInContainer'
import { CardTrainingType } from '@innosonian/brayden-core/build/src/entity/Card/Card.interface'

export default function MetricCycle(props: IProps) {
    const { t } = useTranslation('locale')
    const [colorTheme] = hook.useDarkMode()
    const theme = {
        textColor: 'white',
        fontSize: 14,
        tooltip: {
            container: {
                background: 'white',
                color: '#2B2E36',
                fontSize: 'inherit',
                borderRadius: '2px',
                boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
                padding: '5px 9px',
            },
        },
    }

    const createBarChartValue = (
        itemName: string
    ): { type: number; value: number }[] => {
        if (
            props.resultCycle !== null &&
            props.resultCycle[itemName] !== undefined &&
            props.resultCycle[itemName].ByCycle !== undefined
        ) {
            return props.resultCycle[itemName].ByCycle.map(
                (val: number, idx: number) => {
                    if (isDevStage()) {
                        return {
                            type: idx + 1,
                            cycle: idx + 1 === 2 ? 0 : val,
                            aed: idx + 1 === 2 ? val : 0,
                        }
                    }
                    return {
                        type: idx + 1,
                        cycle: val,
                    }
                }
            )
        }
        return []
    }

    const createBarChartItem = (
        itemName: string,
        translationText: string,
        color: string,
        colorHex: string,
        colorHexDark: string,
        disable: boolean
    ) => {
        const chartData = createBarChartValue(itemName)
        const scoreSize =
            chartData.length === 0 ? 6 : 6 - Math.round(chartData.length / 4)

        return (
            <FadeInContainer className={`w-full`}>
                <TableContainer
                    title={t(translationText)}
                    color={color}
                    margin={false}
                    disable={disable}
                >
                    {disable === false && (
                        <div className="flex flex-col md:flex-row w-full py-2">
                            <div
                                className={`w-full md:w-${scoreSize}/12 flex justify-center md:justify-end`}
                            >
                                <div className="md:pr-16 md:ml-auto">
                                    <Typography
                                        type="p"
                                        color="white dark:text-gray-light-text"
                                        alignment="center"
                                    >
                                        {t('ResultDetail.Score')}
                                    </Typography>
                                    {props.resultCycle !== null &&
                                        props.resultCycle[itemName] &&
                                        props.resultCycle[itemName].Overall !==
                                            undefined && (
                                            <Typography
                                                type="h2"
                                                color="gray-100 dark:text-gray-light-text"
                                                alignment="center"
                                            >
                                                {
                                                    props.resultCycle[itemName]
                                                        .Overall
                                                }
                                            </Typography>
                                        )}
                                    {(props.resultCycle === null ||
                                        props.resultCycle[itemName] ===
                                            undefined) && (
                                        <Typography
                                            type="h2"
                                            color="gray-100 dark:text-gray-light-text"
                                            alignment="center"
                                        >
                                            0
                                        </Typography>
                                    )}
                                </div>
                            </div>
                            <div className=" w-full md:w-2/3 flex flex-row items-center px-8 border-l-0 md:border-l-2 border-gray-200 dark:border-gray-light-border">
                                <div className="w-full flex border-gray-200 justify-center md:justify-start">
                                    <div
                                        className={`h-16 flex items-center border-t border-b border-gray-200 dark:border-gray-light-border`}
                                    >
                                        <Typography type="p">Cycle:</Typography>
                                        <div
                                            className="h-full"
                                            style={{
                                                width: `${
                                                    26 * (chartData.length + 1)
                                                }px`,
                                            }}
                                        >
                                            <ResponsiveBar
                                                theme={theme}
                                                layout="vertical"
                                                data={chartData}
                                                indexBy="type"
                                                keys={['cycle', 'aed']}
                                                borderWidth={1}
                                                borderColor={
                                                    colorTheme === 'light'
                                                        ? colorHex
                                                        : colorHexDark
                                                }
                                                colors={[
                                                    colorTheme === 'light'
                                                        ? colorHex
                                                        : colorHexDark,
                                                ]}
                                                defs={[
                                                    {
                                                        id: 'lines-pattern',
                                                        type: 'patternLines',
                                                        background: 'inherit',
                                                        color: 'white',
                                                        size: 4,
                                                        padding: 1,
                                                        stagger: true,
                                                    },
                                                ]}
                                                fill={[
                                                    {
                                                        match: {
                                                            id: 'aed',
                                                        },
                                                        id: 'lines-pattern',
                                                    },
                                                ]}
                                                enableGridX={false}
                                                enableGridY={false}
                                                axisLeft={null}
                                                axisBottom={null}
                                                animate={true}
                                                motionStiffness={90}
                                                motionDamping={15}
                                                enableLabel={false}
                                                labelSkipWidth={0}
                                                labelSkipHeight={0}
                                                margin={{
                                                    top: 5,
                                                    right: 0,
                                                    bottom: 5,
                                                    left: 0,
                                                }}
                                                padding={0.3}
                                                maxValue={100}
                                                minValue={0}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </TableContainer>
            </FadeInContainer>
        )
    }
    return (
        <FadeInContainer className="w-full flex flex-col h-full">
            <div className="h-full w-full flex flex-col md:flex-row">
                <div className="flex flex-col w-full md:w-1/2 md:mr-4">
                    {/* <div className="flex flex-col flex"> */}
                    <div className="w-full mb-4 h-1/4">
                        {createBarChartItem(
                            'CompressionDepth',
                            'ResultDetail.CompDepth',
                            'red-800 dark:bg-red-light',
                            '#FF0000',
                            '#D73A26',
                            props.trainingType ===
                                CardTrainingType.VENTILATION_ONLY
                        )}
                    </div>
                    <div className="w-full mb-4 flex">
                        {createBarChartItem(
                            'CompressionRate',
                            'ResultDetail.CompRate',
                            'orange-800 dark:bg-orange-light',
                            '#FF7F40',
                            '#F1AA3A',
                            props.trainingType ===
                                CardTrainingType.VENTILATION_ONLY
                        )}
                    </div>
                    {/* </div> */}

                    <div className="w-full mb-4 ">
                        {createBarChartItem(
                            'CompressionNo',
                            'ResultDetail.Comp',
                            'yellow-800 dark:bg-yellow-light',
                            '#FFE601',
                            '#FFE81A',
                            props.trainingType ===
                                CardTrainingType.VENTILATION_ONLY
                        )}
                    </div>
                    <div className="w-full mb-4 md:mb-0 ">
                        {createBarChartItem(
                            'ScoreOfCCF',
                            'ResultDetail.CCF',
                            'blue-sea dark:bg-blue-light-sea',
                            '#00ADD6',
                            '#3A90A5',
                            false
                        )}
                    </div>
                </div>
                <div className="flex h-full flex-col w-full md:w-1/2 ">
                    <div className="w-full mb-4 ">
                        {createBarChartItem(
                            'Recoil',
                            'ResultDetail.CompRelease',
                            'green-800 dark:bg-green-light',
                            '#00C04D',
                            '#42A739',
                            props.trainingType ===
                                CardTrainingType.VENTILATION_ONLY
                        )}
                    </div>
                    <div className="w-full mb-4">
                        {createBarChartItem(
                            'HandPosition',
                            'ResultDetail.HandPos',
                            'purple-900 dark:bg-purple-light-blue',
                            '#6C5EC3',
                            '#8778DD',
                            props.trainingType ===
                                CardTrainingType.VENTILATION_ONLY
                        )}
                    </div>
                    <div className="w-full mb-4">
                        {createBarChartItem(
                            'VentilationVolume',
                            'ResultDetail.VentVol',
                            'purple-800 dark:bg-purple-light',
                            '#BE42BE',
                            '#BE42BE',
                            props.trainingType ===
                                CardTrainingType.CHEST_COMP_ONLY
                        )}
                    </div>
                    <div className="w-full">
                        {createBarChartItem(
                            'VentilationRate',
                            'ResultDetail.VentFreq',
                            'blue-800 dark:bg-blue-light',
                            '#4C729F',
                            '#3C84D7',
                            props.trainingType ===
                                CardTrainingType.CHEST_COMP_ONLY
                        )}
                    </div>
                </div>
            </div>
        </FadeInContainer>
    )
}
