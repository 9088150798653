import React, { Fragment, PropsWithChildren, useEffect, useState } from 'react'
import useResizeObserver from 'use-resize-observer'
import { ReactComponent as Body } from 'resource/img/body.svg'
import { ReactComponent as BabyBody } from 'resource/img/baby-body.svg'
import { useSelector } from 'react-redux'
import BraydenCore from '@innosonian/brayden-core'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'

export default function BodyContainer(props: PropsWithChildren<{}>) {
    const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>()
    const [adultViewBox, setAdultViewBox] = useState({
        x1: 0,
        x2: 0,
        y1: 445,
        y2: 355,
    })
    const [childViewBox, setChildViewBox] = useState({
        x1: 0,
        x2: 0,
        y1: 487,
        y2: 355,
    })
    const selectedManikin = useSelector(
        BraydenCore.Entity.Session.SessionSelector.getSelectedManikin
    )
    const manikinDetail = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Hardware.HardwareSelector.getConnectedHardwareById(
            state,
            selectedManikin
        )
    )
    useEffect(() => {
        if (width <= 361) {
            setAdultViewBox({
                x1: 74,
                x2: 0,
                y1: 300,
                y2: 275,
            })
            setChildViewBox({
                x1: 103,
                x2: 0,
                y1: 290,
                y2: 275,
            })
        } else {
            setAdultViewBox({
                x1: 0,
                x2: 0,
                y1: 445,
                y2: 355,
            })
            setChildViewBox({
                x1: 0,
                x2: 0,
                y1: 487,
                y2: 355,
            })
        }
    }, [width])

    const getManikinShape = () => {
        if (manikinDetail !== undefined && manikinDetail.type === 'Infant') {
            return 'Infant'
        }
        return 'Adult'
    }

    return (
        <div className="w-full h-full" ref={ref}>
            <div className="relative h-full">
                {getManikinShape() === 'Infant' && (
                    <Fragment>
                        <BabyBody
                            className="absolute text-gray-100 dark:text-gray-light-svg"
                            width={width}
                            height={height}
                            viewBox={`${childViewBox.x1} ${childViewBox.x2} ${childViewBox.y1} ${childViewBox.y2}`}
                        />
                        <div className="w-full h-full px-8 pt-20 md:pt-10 lg:px-20 lg:pt-16 xl:px-16">
                            {props.children}
                        </div>
                    </Fragment>
                )}

                {getManikinShape() === 'Adult' && (
                    <Fragment>
                        <Body
                            className="absolute text-gray-100 dark:text-gray-light-svg"
                            width={width}
                            height={height}
                            viewBox={`${adultViewBox.x1} ${adultViewBox.x2} ${adultViewBox.y1} ${adultViewBox.y2}`}
                        />
                        <div className="w-full h-full px-8 pt-20 lg:px-20 lg:pt-16 xl:px-16">
                            {props.children}
                        </div>
                    </Fragment>
                )}
            </div>
        </div>
    )
}
