import React, { useRef } from 'react'
import { ReactComponent as MenuIcon } from 'resource/img/icons/menu.svg'
import { IResponsiveAdminTopBarProps } from './ResponsiveAdminTopBar.type'
import hook from 'hook'
import { Link } from 'react-router-dom'
import Typography from 'view/component/Typography/Typography'
import { useDispatch, useSelector } from 'react-redux'
import BraydenCore from '@innosonian/brayden-core'
import { ReactComponent as UsersIcon } from 'resource/img/icons/users.svg'
import { ReactComponent as Classroom } from 'resource/img/icons/classroomButton.svg'
import { ReactComponent as Configuration } from 'resource/img/icons/configuration.svg'
import { ReactComponent as CertificateIcon } from 'resource/img/icons/certificate.svg'
import { ReactComponent as Chart } from 'resource/img/icons/chart.svg'
import { ReactComponent as RecordConfig } from 'resource/img/icons/recordConfig.svg'
import { ReactComponent as LogoutIcon } from 'resource/img/icons/logout.svg'
import { ReactComponent as AdminManagement } from 'resource/img/icons/admin-management.svg'
import { ReactComponent as DarkIcon } from 'resource/img/icons/dark.svg'
import { ReactComponent as LightIcon } from 'resource/img/icons/light.svg'
import { useTranslation } from 'react-i18next'
import { motion, AnimatePresence } from 'framer-motion'
export default function ResponsiveAdminTopBar(
    props: IResponsiveAdminTopBarProps
) {
    const dispatch = useDispatch()
    const [colorTheme, setTheme] = hook.useDarkMode()
    const { t } = useTranslation('locale')
    const ref = useRef<HTMLDivElement | null>(null)
    hook.useClickOutside(ref, () => {
        props.onClose()
    })
    const clientType = useSelector(
        BraydenCore.Entity.Oauth.Selector.getClientType
    )

    const isAdmin = () =>
        clientType === 'admin' || clientType === 'organization_admin'

    return (
        <AnimatePresence exitBeforeEnter>
            <motion.div
                initial={{ opacity: 1, x: 300 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 300 }}
                transition={{ duration: 0.5 }}
                className="absolute h-screen w-1/2 bg-gray-800 dark:bg-gray-light-top-bar top-0 right-0 flex flex-col p-4 shadow-dark z-50"
                ref={ref}
            >
                <div className="w-full flex justify-end">
                    <MenuIcon
                        className="w-10 h-10 text-gray-100 dark:text-gray-light-text transform rotate-90"
                        onClick={() => props.onClose()}
                    />
                </div>
                <div className="flex flex-col flex-grow px-4 justify-start">
                    <Link
                        to="/stats"
                        className="mr-4 flex w-full items-center p-4 border-b border-gray-50 dark:border-gray-100 "
                    >
                        <Chart className="h-6 w-6 text-gray-100 dark:text-gray-light-default-button mr-2" />
                        <Typography>{t('TopBar.Stats')}</Typography>
                    </Link>
                    <Link
                        to="/users"
                        className="mr-4 flex w-full items-center p-4 border-b border-gray-50 dark:border-gray-100 "
                    >
                        <UsersIcon className="h-6 w-6 text-gray-100 dark:text-gray-light-default-button mr-2" />
                        <Typography>{t('TopBar.Users')}</Typography>
                    </Link>
                    <Link
                        to="/record-config"
                        className="mr-4 flex w-full items-center p-4 border-b border-gray-50 dark:border-gray-100 "
                    >
                        <RecordConfig className="h-6 w-6 text-gray-100 dark:text-gray-light-default-button mr-2" />
                        <Typography>{t('TopBar.Record')}</Typography>
                    </Link>
                    <Link
                        to="/classroom"
                        className="mr-4 flex w-full items-center p-4 border-b border-gray-50 dark:border-gray-100 "
                    >
                        <Classroom className="h-6 w-6 text-gray-100 dark:text-gray-light-default-button mr-2" />
                        <Typography>{t('TopBar.Classroom')}</Typography>
                    </Link>
                    <Link
                        to="/config"
                        className="mr-4 flex w-full items-center p-4 border-b border-gray-50 dark:border-gray-100 "
                    >
                        <Configuration className="h-6 w-6 text-gray-100 dark:text-gray-light-default-button mr-2" />
                        <Typography>{t('TopBar.Config')}</Typography>
                    </Link>

                    {isAdmin() && (
                        <Link
                            to="/adminManagement"
                            className="mr-4 flex w-full items-center p-4 border-b border-gray-50 dark:border-gray-100 "
                        >
                            <AdminManagement className="h-6 w-6 text-gray-100 dark:text-gray-light-default-button mr-2" />
                            <Typography>{t('TopBar.Admin')}</Typography>
                        </Link>
                    )}
                    {isAdmin() && (
                        <Link
                            to="/certificates"
                            className="mr-4 flex w-full items-center p-4 border-b border-gray-50 dark:border-gray-100 "
                        >
                            <CertificateIcon className="h-6 w-6 text-gray-100 dark:text-gray-light-default-button mr-2" />
                            <Typography>{t('TopBar.Certificate')}</Typography>
                        </Link>
                    )}
                    <div className="w-full flex flex-col self-end mt-auto">
                        <div className="mr-4 flex w-full items-center p-4 border-b border-gray-50 dark:border-gray-100 ">
                            <div
                                className="flex"
                                onClick={() => {
                                    BraydenCore.history
                                        .getProvider()
                                        .push('/login')
                                    dispatch(
                                        BraydenCore.Entity.Oauth.Action.oauthLogOutRequest()
                                    )
                                }}
                            >
                                <LogoutIcon className="h-10 w-10 text-gray-100 dark:text-gray-light-default-button mr-2" />
                                <Typography className="hidden md:flex self-center">
                                    {t('TopBar.LogOut')}
                                </Typography>
                            </div>
                            <div className="flex self-end ml-auto">
                                {colorTheme === 'dark' && (
                                    <DarkIcon
                                        className="w-10 h-10 text-yellow-800"
                                        onClick={() => setTheme('light')}
                                    />
                                )}
                                {colorTheme === 'light' && (
                                    <LightIcon
                                        className="w-10 h-10 text-gray-light-text"
                                        onClick={() => setTheme('dark')}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </motion.div>
        </AnimatePresence>
    )
}
