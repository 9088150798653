import React, { useState, useEffect, useRef } from 'react'
import Button from 'view/component/Button/Button'
import Spinner from 'view/component/Spinner/Spinner'
import Typography from 'view/component/Typography/Typography'
import { ReactComponent as Check } from 'resource/img/icons/check.svg'
import Card from 'view/component/Card/Card'
import BarChart from 'view/component/Chart/Bar/Bar'
import Switch from 'view/component/Button/Switch/Switch'
import TableContainer from 'view/component/Table/TableContainer/TableContainer'
import TableContent from 'view/component/Table/TableContent/TableContent'
import PercentPie from 'view/component/Chart/PercentPie/PercentPie'
import Tabs from 'view/component/Tabs/Tabs'
import RadarChart from 'view/component/Chart/Radar/Radar'
import { ReactComponent as AssessmentIcon } from 'resource/img/icons/assessment-icon.svg'
import { ResponsiveBar } from '@nivo/bar'
import { toast } from 'react-toastify'
import ListContainer from 'view/component/ListContainer/ListContainer'
import FloatableContainer from 'view/component/FloatableContainer/FloatableContainer'

export default function ComponentDisplay() {
    const [stopAnime, setStopAnime] = useState(true)
    const [direction, setDirection] = useState(1)
    const [height, setHeight] = useState(0)
    console.log(height)
    const [active, setActive] = useState(true)
    const videoRef = useRef<HTMLVideoElement>(null)
    const videoRefBis = useRef<HTMLVideoElement>(null)
    useEffect(() => {
        const interval = setInterval(() => {
            const _x = Math.floor(Math.random() * 100)
            if (_x < 50) {
                setHeight(0)
            } else if (_x < 70) {
                setHeight(50)
            } else {
                setHeight(100)
            }
        }, 2000)
        return () => {
            clearInterval(interval)
        }
    })

    const clickAnime = () => {
        if (!stopAnime) {
            setDirection(direction * -1)
        }
        setStopAnime(false)
    }

    const [tabs, setTabs] = useState('test')

    const chartData = [
        { type: 'Score off CCF', overAll: 56 },
        { type: 'Hand position', overAll: 80 },
        { type: 'Compression No', overAll: 0.1 },
        { type: 'Ventilation volume', overAll: 0 },
        { type: 'Ventilation rate', overAll: 0.1 },
        { type: 'Compression depth', overAll: 100 },
        { type: 'Compression release', overAll: 70 },
        { type: 'Compression rate', overAll: 96 },
    ]

    const theme = {
        textColor: '#7C8597',
        fontSize: 14,
        tooltip: {
            container: {
                background: 'white',
                color: '#2B2E36',
                fontSize: 'inherit',
                borderRadius: '2px',
                boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
                padding: '5px 9px',
            },
        },
    }

    const getMediaStream = async () => {
        const media = await navigator.mediaDevices.getUserMedia({
            video: true,
            audio: false,
        })
        if (videoRef.current !== null && videoRefBis.current !== null) {
            videoRef.current.srcObject = media
            videoRefBis.current.srcObject = media
        }
    }

    return (
        <div className="w-full">
            <FloatableContainer
                width={400}
                height={250}
                baseLeft={0}
                baseTop={0}
            >
                <video
                    ref={videoRef}
                    className="w-full h-full"
                    autoPlay
                    playsInline
                    controls={false}
                />
            </FloatableContainer>
            <FloatableContainer
                width={400}
                height={250}
                baseLeft={405}
                baseTop={0}
            >
                <video
                    ref={videoRefBis}
                    className="w-full h-full"
                    autoPlay
                    playsInline
                    controls={false}
                />
            </FloatableContainer>
            <button disable={false} onClick={getMediaStream}>
                select media stream
            </button>
            <div className="w-full">
                <button
                    onClick={() =>
                        toast(
                            <div className="flex w-full items-center justify-center">
                                <AssessmentIcon className="h-10 w-10 text-white pr-4" />{' '}
                                hello
                            </div>,
                            { type: 'info' }
                        )
                    }
                >
                    click
                </button>
            </div>
            <div className="w-full pt-10">
                <Switch active={active} onclick={() => setActive(!active)} />
            </div>
            <div className="w-full">
                <div className="h-10" onClick={clickAnime}></div>
            </div>
            <div className="w-full flex">
                <div className="w-1/2 flex flex-col">
                    <Typography>default Typography </Typography>
                    <Typography type="h1">H1 Typography </Typography>
                    <Typography type="h2">H2 Typography </Typography>
                    <Typography type="h3">H3 Typography </Typography>
                    <Typography type="p">p Typography </Typography>
                    <Typography type="span">span Typography </Typography>
                </div>
                <div className="w-1/2 flex flex-col">
                    <Typography type="h3">
                        default Typography weight{' '}
                    </Typography>
                    <Typography type="h3" weight="bold">
                        bold Typography{' '}
                    </Typography>
                    <Typography type="h3" weight="light">
                        Light Typography{' '}
                    </Typography>
                </div>
            </div>
            <div className="w-full pt-5 flex justify-around flex-wrap">
                <div className="flex items-center">
                    <Typography type="h3" className="mr-2">
                        Basic spinner{' '}
                    </Typography>
                    <Spinner />
                </div>
                <div className="flex items-center">
                    <Typography type="h3" className="mr-2">
                        xl spinner{' '}
                    </Typography>
                    <Spinner size="xl" />
                </div>
                <div className="flex items-center">
                    <Typography type="h3" className="mr-2">
                        sm spinner{' '}
                    </Typography>
                    <Spinner size="sm" />
                </div>
                <div className="flex items-center">
                    <Typography type="h3" className="mr-2">
                        md spinner{' '}
                    </Typography>
                    <Spinner size="sm" />
                </div>
            </div>
            <div className="w-full flex justify-around items-center pt-5">
                <Button disable={false} onclick={() => {}}>
                    Basic button
                </Button>
                <Button disable={false} onclick={() => {}} type="outline">
                    Outline button
                </Button>
                <Button disable={false} onclick={() => {}} icon={<Check />}>
                    Icon button
                </Button>
                <Button disable={false} onclick={() => {}} icon={<Check />}>
                    Icon button
                </Button>
            </div>

            <div className="w-full flex justify-around flex-wrap items-center pt-5">
                <Card padding title="BASIC CARD">
                    <div className="flex w-full justify-around items-center">
                        <div className="w-1/4">
                            <Typography>
                                Lorem ipsum dolor sit amet, consectetur
                                adipiscing elit, sed do eiusmod tempor
                                incididunt ut labore et dolore magna aliqua. Ut
                                enim ad minim veniam, quis nostrud exercitation
                                ullamco laboris nisi ut aliquip ex ea commodo
                                consequat. Duis aute irure dolor in
                                reprehenderit in voluptate velit esse cillum
                                dolore eu fugiat nulla pariatur. Excepteur sint
                                occaecat cupidatat non proident, sunt in culpa
                                qui officia deserunt mollit anim id est laborum
                            </Typography>
                        </div>
                        <Card
                            padding
                            title="2/3 CARD"
                            width="w-2/3"
                            items="center"
                        >
                            <Spinner size="xl" />
                        </Card>
                    </div>
                </Card>
            </div>
            <div className="flex w-full h-128">
                <div className="w-1/2 flex">
                    <BarChart
                        stack="horizontal"
                        data={chartData}
                        index="type"
                        keys={['overAll']}
                        displayLegend={true}
                    />
                </div>
                <div className="w-1/2 flex">
                    <RadarChart
                        data={chartData}
                        index="type"
                        keys={['overAll']}
                        displayLegend={false}
                    />
                </div>
            </div>
            <div className="w-full">
                <Button disable={false} onclick={() => {}}>
                    TEST
                </Button>
                <Button disable={false} onclick={() => {}} color="danger">
                    TEST
                </Button>
                <Button disable={false} onclick={() => {}} color="secondary">
                    TEST
                </Button>
                <Button disable={false} onclick={() => {}} color="warning">
                    TEST
                </Button>
            </div>
            <div className="w-full pt-10"></div>
            <div className="w-full pt-10">
                <Switch active={active} onclick={() => setActive(!active)} />
            </div>
            <div className="w-full flex py-5 ">
                <Tabs
                    link={[
                        {
                            title: 'test',
                            active: tabs === 'test',
                            onClick: () => setTabs('test'),
                        },
                        {
                            title: 'test1',
                            active: tabs === 'test1',
                            onClick: () => setTabs('test1'),
                        },
                        {
                            title: 'test2',
                            active: tabs === 'test2',
                            onClick: () => setTabs('test2'),
                        },
                    ]}
                />
            </div>
            <div className="w-full flex flex-wrap">
                <TableContainer color="green-500" title="test" margin>
                    <TableContent
                        items={[
                            {
                                title: { value: 'subtitle', color: 'white' },
                                value: '100',
                                border: true,
                            },
                            {
                                title: { value: 'subtitle2', color: 'white' },
                                value: '100',
                                border: true,
                            },
                            {
                                title: { value: 'subtitle3', color: 'white' },
                                value: '100',
                                border: false,
                            },
                        ]}
                    />
                </TableContainer>
                <TableContainer color="orange-800" title="test" margin>
                    <TableContent
                        items={[
                            {
                                value: '100',
                                border: false,
                            },
                        ]}
                    />
                </TableContainer>
                <TableContainer color="gray-600" title="test" margin>
                    <TableContent
                        items={[
                            {
                                value: '100',
                                border: true,
                            },
                            {
                                title: { value: 'subtitle2', color: 'white' },
                                value: '100',
                                border: false,
                            },
                        ]}
                    />
                </TableContainer>
                <TableContainer color="green-500" title="test" margin={false}>
                    <div> test</div>
                </TableContainer>
            </div>
            <div className="w-full flex py-5 flex flex-wrap">
                <div className="w-full md:w-1/2 lg:w-1/4 h-72 mr-0 md:mr-5 ">
                    <TableContainer
                        color="green-500"
                        title="test"
                        margin={true}
                    >
                        <PercentPie percent={45} color="#84AC53" text="test" />
                    </TableContainer>
                </div>
                <div className="w-full md:w-1/2 lg:w-1/4 h-72 mr-0 lg:mr-5 ">
                    <TableContainer
                        color="green-500"
                        title="test"
                        margin={true}
                    >
                        <PercentPie percent={25} color="#84AC53" text="test" />
                    </TableContainer>
                </div>
                <div className="w-full md:w-1/2 lg:w-1/4 h-72 ">
                    <TableContainer
                        color="green-500"
                        title="test"
                        margin={true}
                    >
                        <PercentPie percent={95} color="#84AC53" text="test" />
                    </TableContainer>
                </div>
                <div className="w-full md:w-1/2 lg:w-1/4 h-72">
                    <TableContainer
                        color="green-500"
                        title="test"
                        margin={false}
                    >
                        <PercentPie percent={5} color="#84AC53" text="test" />
                    </TableContainer>
                </div>
            </div>
            <div className="w-full flex">
                <div className="w-1/2 mr-3">
                    <div className={`w-full`}>
                        <TableContainer
                            title={'test'}
                            color={'red-900'}
                            margin={false}
                        >
                            <div className="flex flex-col md:flex-row w-full py-2">
                                <div className="w-full md:w-4/12 flex justify-end">
                                    <div className="pr-16 ml-auto">
                                        <Typography
                                            type="p"
                                            color="white"
                                            alignment="center"
                                        >
                                            {'Score'}
                                        </Typography>

                                        <Typography
                                            type="h2"
                                            color="gray-100"
                                            alignment="center"
                                        >
                                            0
                                        </Typography>
                                    </div>
                                </div>
                                <div className=" w-full md:w-2/3 border-r-0 border-gray-100 flex flex-row items-center px-8 border-l-2 border-gray-200 ">
                                    <div className="w-full flex border-gray-200">
                                        <div className="h-16 flex items-center w-8/12 border-t border-b border-gray-200">
                                            <Typography type="p">
                                                Cycle:
                                            </Typography>
                                            <ResponsiveBar
                                                theme={theme}
                                                layout="vertical"
                                                data={[
                                                    { type: 1, value: 90 },
                                                    { type: 2, value: 70 },
                                                    { type: 3, value: 50 },
                                                    { type: 4, value: 50 },
                                                    { type: 5, value: 50 },
                                                    { type: 6, value: 50 },
                                                    { type: 7, value: 50 },
                                                    { type: 8, value: 50 },
                                                    { type: 9, value: 50 },
                                                ]}
                                                indexBy="type"
                                                keys={['value']}
                                                colors={'#542424'}
                                                enableGridX={false}
                                                enableGridY={false}
                                                axisLeft={null}
                                                axisBottom={null}
                                                animate={true}
                                                motionStiffness={90}
                                                motionDamping={15}
                                                enableLabel={false}
                                                labelSkipWidth={0}
                                                labelSkipHeight={0}
                                                margin={{
                                                    top: 5,
                                                    right: 0,
                                                    bottom: 5,
                                                    left: 0,
                                                }}
                                                padding={0.3}
                                                maxValue={100}
                                                minValue={0}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TableContainer>
                    </div>
                </div>
                <div className="w-1/2">
                    <div className={`w-full`}>
                        <TableContainer
                            title={'test'}
                            color={'red-900'}
                            margin={false}
                        >
                            <div className="flex w-full py-2">
                                <div className="ml-auto">
                                    <div className="w-full md:w-5/12 flex justify-between flex-col pr-16">
                                        <Typography
                                            type="p"
                                            color="white"
                                            alignment="center"
                                        >
                                            {'Score'}
                                        </Typography>

                                        <Typography
                                            type="h2"
                                            color="gray-100"
                                            alignment="center"
                                        >
                                            53
                                        </Typography>
                                    </div>
                                </div>
                                <div className=" w-full md:w-7/12 border-r-0 border-gray-100 flex flex-row items-center px-8 border-l-2 border-gray-200 ">
                                    <div className="w-full flex border-gray-200">
                                        <div className="h-16 flex items-center w-7/12 border-t border-b border-gray-200">
                                            <Typography type="p">
                                                Cycle:
                                            </Typography>
                                            <ResponsiveBar
                                                theme={theme}
                                                layout="vertical"
                                                data={[
                                                    { type: 1, value: 90 },
                                                    { type: 2, value: 70 },
                                                    { type: 3, value: 50 },
                                                    { type: 4, value: 50 },
                                                    { type: 5, value: 50 },
                                                ]}
                                                indexBy="type"
                                                keys={['value']}
                                                colors={'#542424'}
                                                enableGridX={false}
                                                enableGridY={false}
                                                axisLeft={null}
                                                axisBottom={null}
                                                animate={true}
                                                motionStiffness={90}
                                                motionDamping={15}
                                                enableLabel={false}
                                                labelSkipWidth={0}
                                                labelSkipHeight={0}
                                                margin={{
                                                    top: 5,
                                                    right: 0,
                                                    bottom: 5,
                                                    left: 0,
                                                }}
                                                padding={0.3}
                                                maxValue={100}
                                                minValue={0}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </TableContainer>
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-col mt-10 ">
                <div className="w-full flex">
                    <ListContainer
                        cardList={[
                            { value: 1, title: 'test1' },
                            { value: 1, title: 'test2' },
                            { value: 1, title: 'test3' },
                        ]}
                        cycleTitle="Compression"
                        cycleList={[
                            { cycle: 1 },
                            { cycle: 1 },
                            { cycle: 1 },
                            { cycle: 1 },
                        ]}
                        expended={
                            <div className="w-full flex">
                                <div className="flex w-full h-full items-center justify-center">
                                    TODO
                                </div>
                            </div>
                        }
                        collapsed={
                            <div className="w-full flex">
                                <div className="flex w-full h-full items-center justify-center">
                                    TODO
                                </div>
                            </div>
                        }
                    ></ListContainer>
                </div>
                <div className="w-full flex">
                    <ListContainer
                        cardList={[
                            { value: 1, title: 'test4' },
                            { value: 1, title: 'test5' },
                            { value: 1, title: 'test6' },
                        ]}
                        cycleTitle="Compression"
                        cycleList={[{ cycle: 1 }, { cycle: 1 }]}
                        expended={
                            <div className="w-full flex">
                                <div className="flex w-full h-full items-center justify-center">
                                    TODO
                                </div>
                            </div>
                        }
                        collapsed={
                            <div className="w-full flex">
                                <div className="flex w-full h-full items-center justify-center">
                                    TODO
                                </div>
                            </div>
                        }
                    ></ListContainer>
                </div>
            </div>
        </div>
    )
}
