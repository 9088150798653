import React from 'react'
import { IProps } from './AEDGuideline.type'
import Typography from 'view/component/Typography/Typography'

function Guideline({
    direction,
    className,
}: {
    direction: string
    className: string
}) {
    return (
        <Typography
            className={`${className} p-10`}
            type="h2"
            alignment="center"
        >
            {direction}
        </Typography>
    )
}
export default function AEDGuideline(props: IProps) {
    const GuidelineSenario = [
        'Pads On',
        'Pads Off',
        'Stay Away Patient',
        'Heart Rhythm',
        'Shock Advised',
        'Press Shock Button',
        'Shock Delivered',
        'No Shock Advised',
    ]
    return (
        <div
            className={`border border-gray-100 mx-auto flex-1 w-full relative flex-col items-center`}
        >
            {GuidelineSenario.map((direction, i) => (
                <Guideline
                    key={i}
                    direction={direction}
                    className={
                        props.aedDirections === direction
                            ? 'border-2 border-white dark:border-gray-50 '
                            : 'border-none'
                    }
                />
            ))}
        </div>
    )
}
