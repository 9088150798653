import React from 'react'
import { useTranslation } from 'react-i18next'
import Typography from 'view/component/Typography/Typography'
import Button from 'view/component/Button/Button'
import ModalContainer from '../ModalContainer/ModalContainer'
import { ReactComponent as ClassroomUnavailable } from 'resource/img/icons/classroomUnavailable.svg'
import { useDispatch, useSelector } from 'react-redux'
import BraydenCore from '@innosonian/brayden-core'

export default function ClassroomPermissionErrorModal() {
    const dispatch = useDispatch()
    const { t } = useTranslation('locale')
    const isOpen = useSelector(
        BraydenCore.Entity.Modal.ModalSelector.isClassroomPermissionError
    )
    const handleClose = async () => {
        dispatch(
            BraydenCore.Entity.Modal.ModalAction.modalCloseClassroomPermission()
        )
    }

    return (
        <ModalContainer isOpen={isOpen} closeButton={false}>
            <div className="flex flex-col h-full  w-full lg:px-10 justify-center items-center md:py-16">
                <ClassroomUnavailable className="w-40 h-40 mb-4 border-b border-gray-100" />
                <Typography alignment="center" className="pb-5">
                    {t('Classroom.PermissionError')}
                </Typography>
                <Typography alignment="center" className="pb-5">
                    {t('Classroom.PermissionErrorExplanation')}
                </Typography>
                <Button
                    disable={false}
                    shape="rounded-mid"
                    color="danger"
                    padding="sm"
                    onclick={() => handleClose()}
                    className="self-center"
                >
                    {t('Classroom.Cancel')}
                </Button>
            </div>
        </ModalContainer>
    )
}
