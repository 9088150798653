import AgoraRTM, { RtmChannel, RtmClient, RtmTextMessage } from 'agora-rtm-sdk'
import Config from './config'
import BraydenCore from '@innosonian/brayden-core'
import { RTM_MESSAGE } from './classroom.interface'
import Classroom from './index'
import { history } from 'core/history/history.provider'
let client: RtmClient | undefined = undefined
let channel: RtmChannel | undefined = undefined
let isLogin = false

async function connect(channelName: string, uid: string) {
    if (client !== undefined) return
    client = AgoraRTM.createInstance(Config.options.appId, {
        enableLogUpload: false,
    })
    try {
        const token = await Config.generateToken(
            `${process.env.REACT_APP_BASE_URL}/api/new/classroom/rtm/token`,
            channelName,
            uid
        )
        await client.login({ uid: uid, token: token })
        isLogin = true
    } catch (e) {
        console.error(e)
    }
}

async function joinChannel(channelName: string) {
    if (channel !== undefined) return
    if (client !== undefined && isLogin) {
        channel = client.createChannel(channelName)
        await channel.join()
    }
}

async function leaveChannel() {
    if (channel !== undefined) {
        await channel.leave()
        channel = undefined
    }
    if (client !== undefined && isLogin === true) {
        await client.logout()
        isLogin = false
        client = undefined
    }
}

function listen() {
    if (client !== undefined) {
        client.on('MessageFromPeer', (message, peerId) => {
            try {
                if (message.messageType === 'TEXT') {
                    if (JSON.parse(message.text).type === 'KICK_OUT') {
                        Classroom.leaveClassroom()
                        history.push('/')
                        BraydenCore.Reducer.store.dispatch(
                            BraydenCore.Entity.Modal.ModalAction.modalOpenClassroomKickOut()
                        )
                    } else {
                        console.error('RECEIVE COMMAND?')
                        BraydenCore.Reducer.store.dispatch(
                            BraydenCore.Entity.Classroom.Action.commandReceived(
                                JSON.parse(message.text as string)
                            )
                        )
                    }
                }
            } catch (e) {
                console.log(e, message)
            }
        })
        client.on('ConnectionStateChanged', function (state, reason) {
            console.error(`${state} has changed`)
        })
    }
    if (channel !== undefined) {
        channel.on('ChannelMessage', (message: any) => {
            try {
                BraydenCore.Reducer.store.dispatch(
                    BraydenCore.Entity.Classroom.Action.commandReceived(
                        JSON.parse(message.text)
                    )
                )
            } catch (e) {
                console.log(e, message)
            }
        })
    }
}

function filterMessage(message: RTM_MESSAGE) {
    if (message !== 'FORCE_OFF_AUDIO' && message !== 'UNDO_FORCE_OFF_AUDIO') {
        BraydenCore.Reducer.store.dispatch(
            BraydenCore.Entity.Classroom.Action.setClassroomAction(message)
        )
    }
    return
}
function sendMessage(
    message: RTM_MESSAGE,
    payload?: { [key: string]: string }
) {
    if (channel !== undefined) {
        filterMessage(message)
        const rtmMessage: RtmTextMessage = {
            text: JSON.stringify({
                type: message,
                payload: {
                    ...payload,
                },
            }),
        }
        channel.sendMessage(rtmMessage)
    }
}

function sendPrivateMessage(
    userId: string,
    message: RTM_MESSAGE,
    payload?: { [key: string]: string }
) {
    if (client !== undefined) {
        const rtmMessage: RtmTextMessage = {
            text: JSON.stringify({
                type: message,
                payload: {
                    ...payload,
                },
            }),
        }
        client.sendMessageToPeer(rtmMessage, userId)
    }
}

const socketCommunication = {
    connect: connect,
    joinChannel: joinChannel,
    leaveChannel: leaveChannel,
    listen: listen,
    sendMessage: sendMessage,
    sendPrivateMessage: sendPrivateMessage,
}

export default socketCommunication
