import React from 'react'
import { useTranslation } from 'react-i18next'
import RadarChart from 'view/component/Chart/Radar/Radar'
import TableContainer from 'view/component/Table/TableContainer/TableContainer'
import TableContent from 'view/component/Table/TableContent/TableContent'
import Typography from 'view/component/Typography/Typography'
import { IProps } from './Summary.type'
import FadeInContainer from '../../../layout/FadeInContainer/FadeInContainer'
import { IResultByCycle } from '@innosonian/brayden-core/build/src/entity/Result/Result.interface'
import { CardTrainingType } from '@innosonian/brayden-core/build/src/entity/Card/Card.interface'

export default function Summary(props: IProps) {
    const { t } = useTranslation('locale')
    const mmToCm = (value: number) => (value > 0 ? value / 10 : value)
    const avgSpeedRatio = (value: number) =>
        value > 0 ? Math.round((value / 1000) * 10) / 10 : 0
    const formatChartData = (resultCriteria: IResultByCycle) => {
        const result = [
            {
                type: t('OverallResultPage.ScoreOfCCF'),
                overAll: resultCriteria.ScoreOfCCF.Overall || 0,
            },
            {
                type: t('OverallResultPage.HandPosition'),
                overAll: resultCriteria.HandPosition.Overall || 0,
            },
            {
                type: t('OverallResultPage.NoOfCompressions'),
                overAll: resultCriteria.CompressionNo.Overall || 0,
            },
            {
                type: t('OverallResultPage.VentilationVolume'),
                overAll: resultCriteria.VentilationVolume.Overall || 0,
            },
            {
                type: t('OverallResultPage.VentilationFrequency'),
                overAll: resultCriteria.VentilationRate.Overall || 0,
            },
            {
                type: t('OverallResultPage.CompressionDepth'),
                overAll: resultCriteria.CompressionDepth.Overall || 0,
            },
            {
                type: t('OverallResultPage.CompressionRelease'),
                overAll: resultCriteria.Recoil.Overall || 0,
            },
            {
                type: t('OverallResultPage.CompressionRate'),
                overAll: resultCriteria.CompressionRate.Overall || 0,
            },
        ]
        if (props.resultRescue !== undefined) {
            result.splice(4, 0, {
                type: t('OverallResultPage.RescueVentilation'),
                overAll: props.resultRescue.ScoreInitRescueBreath || 0,
            })
        }
        return result
    }
    return (
        <FadeInContainer className="flex flex-col lg:flex-row w-full">
            <div className="w-full lg:w-1/2 mb-5 lg:mb-0 lg:mr-5 border border-gray-100 lg:py-5 px-10">
                <div className="w-full pb-4 border-b-2 border-gray-100 dark:border-gray-light-border">
                    <Typography type="h2" alignment="center">
                        {t('ResultDetail.OverallPerf')}
                    </Typography>
                </div>
                {props.resultCycle !== null && (
                    <RadarChart
                        data={formatChartData(props.resultCycle)}
                        index="type"
                        keys={['overAll']}
                        displayLegend={false}
                    />
                )}
            </div>
            <div className="w-full flex flex-col lg:w-1/2">
                <div className="mb-5 flex flex-grow-1">
                    <div className="flex w-full border border-gray-100 py-4">
                        <TableContent
                            items={[
                                {
                                    title: {
                                        value: t('ResultDetail.Duration'),
                                        color: 'gray-100',
                                    },
                                    value: props.resultSummary.EventTime,
                                    xl: true,
                                    border: true,
                                },
                                {
                                    title: {
                                        value: t('ResultDetail.Cycle'),
                                        color: 'gray-100',
                                    },
                                    value:
                                        props.trainingType ===
                                        CardTrainingType.CPR_TRAINING
                                            ? props.resultSummary.CycleNum
                                            : '-',
                                    xl: true,
                                    border: true,
                                },
                                {
                                    title: {
                                        value: t('ResultDetail.PercentCCF'),
                                        color: 'gray-100',
                                    },
                                    value:
                                        props.trainingType !==
                                        CardTrainingType.VENTILATION_ONLY
                                            ? props.resultSummary['%CCF'] + '%'
                                            : '-',
                                    xl: true,
                                    border: false,
                                },
                            ]}
                        />
                    </div>
                </div>
                <div className="mb-5 flex flex-grow-1">
                    <TableContainer
                        title={t('ResultDetail.Comps')}
                        margin={false}
                        disable={
                            props.trainingType ===
                            CardTrainingType.VENTILATION_ONLY
                        }
                    >
                        <div className="w-full flex flex-col justify-around">
                            <TableContent
                                disable={
                                    props.trainingType ===
                                    CardTrainingType.VENTILATION_ONLY
                                }
                                items={[
                                    {
                                        title: {
                                            value: t('ResultDetail.AvgDepth'),
                                            color: 'gray-100',
                                        },
                                        value:
                                            mmToCm(
                                                props.resultSummary
                                                    .CompressionDepth
                                            ) + ' cm',
                                        xl: true,
                                        border: true,
                                    },
                                    {
                                        title: {
                                            value: t('ResultDetail.Count'),
                                            color: 'gray-100',
                                        },
                                        value:
                                            props.resultSummary.CompressionNo,
                                        xl: true,
                                        border: true,
                                    },
                                    {
                                        title: {
                                            value: t('ResultDetail.AvgTimeOff'),
                                            color: 'gray-100',
                                        },
                                        value:
                                            props.resultSummary.HandsOffTime +
                                            ' sec',
                                        xl: true,
                                        border: false,
                                    },
                                ]}
                            />
                            <div className="w-full border-t-4 border-gray-900 dark:border-gray-light-border mt-2 pt-2">
                                <TableContent
                                    disable={
                                        props.trainingType ===
                                        CardTrainingType.VENTILATION_ONLY
                                    }
                                    items={[
                                        {
                                            title: {
                                                value: t(
                                                    'ResultDetail.AvgRate'
                                                ),
                                                color: 'gray-100',
                                            },
                                            value:
                                                props.resultSummary
                                                    .CompressionRate + ' / min',
                                            xl: true,
                                            border: true,
                                        },
                                        {
                                            title: {
                                                value: t(
                                                    'ResultDetail.HandPosAccuracy'
                                                ),
                                                color: 'gray-100',
                                            },
                                            value:
                                                props.resultSummary
                                                    .Handposition + '%',
                                            xl: true,
                                            border: false,
                                        },
                                    ]}
                                />
                            </div>
                        </div>
                    </TableContainer>
                </div>
                <div className=" flex flex-grow-1">
                    <TableContainer
                        title={t('ResultDetail.Ventilations')}
                        margin={false}
                        disable={
                            props.trainingType ===
                            CardTrainingType.CHEST_COMP_ONLY
                        }
                    >
                        <TableContent
                            disable={
                                props.trainingType ===
                                CardTrainingType.CHEST_COMP_ONLY
                            }
                            items={[
                                {
                                    title: {
                                        value: t('ResultDetail.AvgVolume'),
                                        color: 'gray-100',
                                    },
                                    value:
                                        props.resultSummary.VentilationVolume +
                                        ' ml',
                                    xl: true,
                                    border: true,
                                },
                                {
                                    title: {
                                        value: t('ResultDetail.Total'),
                                        color: 'gray-100',
                                    },
                                    value: props.resultSummary.VentilationNo,
                                    xl: true,
                                    border: true,
                                },
                                {
                                    title: {
                                        value: t('ResultDetail.AvgSpeed'),
                                        color: 'gray-100',
                                    },
                                    value:
                                        avgSpeedRatio(
                                            props.resultSummary.VentilationSpd
                                        ) + ' sec',
                                    xl: true,
                                    border: false,
                                },
                            ]}
                        />
                    </TableContainer>
                </div>
                {props.resultRescue !== undefined && (
                    <div className=" flex flex-grow-1 pt-5">
                        <TableContainer
                            title={t('ResultDetail.RescueVentilations')}
                            margin={false}
                            disable={
                                props.trainingType ===
                                CardTrainingType.CHEST_COMP_ONLY
                            }
                        >
                            <TableContent
                                disable={
                                    props.trainingType ===
                                    CardTrainingType.CHEST_COMP_ONLY
                                }
                                items={[
                                    {
                                        title: {
                                            value: t('ResultDetail.AvgVolume'),
                                            color: 'gray-100',
                                        },
                                        value:
                                            props.resultRescue
                                                .InitRescueBreathVolume
                                                .AvgVolume + 'ml',
                                        xl: true,
                                        border: true,
                                    },
                                    {
                                        title: {
                                            value: t('ResultDetail.Total'),
                                            color: 'gray-100',
                                        },
                                        value:
                                            props.resultRescue
                                                .InitRescueBreathNo.Count,
                                        xl: true,
                                        border: true,
                                    },
                                    {
                                        title: {
                                            value: t('ResultDetail.AvgSpeed'),
                                            color: 'gray-100',
                                        },
                                        value:
                                            avgSpeedRatio(
                                                props.resultRescue
                                                    .InitRescueBreathSpd
                                                    .AvgSpeed
                                            ) + ' sec',
                                        xl: true,
                                        border: false,
                                    },
                                ]}
                            />
                        </TableContainer>
                    </div>
                )}
            </div>
        </FadeInContainer>
    )
}
