import React from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { ReactComponent as BackNav } from 'resource/img/back-nav.svg'
import BraydenCore from '@innosonian/brayden-core'

export function getCurrentIdx(pathname: string, endpointList: string[]) {
    return endpointList.findIndex((endpoint) => {
        const rgxString = endpoint.replace('*', '.*')
        const _regex = new RegExp(`^${rgxString}$`)
        return _regex.test(pathname) === true
    })
}

export default function ArrowBack() {
    const endpointList = [
        '/',
        '/home',
        '/trainings',
        '/trainings/*',
        '/result',
        '/result/details',
        '/records',
        '/records/*',
    ]
    const history = useHistory()
    const location = useLocation()
    const hardwareIdList = useSelector(
        BraydenCore.Entity.Hardware.HardwareSelector.getConnectedHardwareIdList
    )

    const displayArrowBack = () => {
        if (location.pathname === '/' || location.pathname === '/login') {
            return false
        }
        if (
            location.pathname === '/home' &&
            BraydenCore.Authentication.isAuthenticated() === true
        ) {
            return false
        }
        return true
    }

    const handleBack = () => {
        const currentIdx = getCurrentIdx(location.pathname, endpointList)
        if (
            currentIdx > 0 &&
            endpointList[currentIdx] === '/trainings' &&
            hardwareIdList.length < 2
        ) {
            return history.push('/home')
        }
        if (endpointList[currentIdx] === '/records') {
            return history.push('/home')
        }
        if (currentIdx > 0 && endpointList[currentIdx] !== '/result') {
            return history.push(endpointList[currentIdx - 1])
        }
        if (currentIdx > 0 && endpointList[currentIdx] === '/result') {
            return history.goBack()
        }
    }

    if (displayArrowBack() === true) {
        return (
            <BackNav
                className="w-10 cursor-pointer text-gray-50 dark:text-gray-light-default-text"
                onClick={handleBack}
            />
        )
    }
    return <div className="w-10"></div>
}
