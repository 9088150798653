import { IConfigProvider } from '@innosonian/brayden-core/build/src/config/config.interface'

const configProvider: IConfigProvider = {
    CLIENT_ID: process.env.REACT_APP_CLIENT_ID as string,
    CLIENT_PASS_CODE: parseInt(
        process.env.REACT_APP_CLIENT_PASS_CODE as string
    ),
    BASE_URL: process.env.REACT_APP_BASE_URL as string,
    CLIENT_RESULT_URL: process.env.REACT_APP_CLIENT_RESULT_URL as string,
    RESULT_BIN_NAME: process.env.REACT_APP_RESULT_BIN_NAME as string,
    TRANSLATION_URL: process.env.REACT_APP_TRANSLATION_URL as string,
    DEBUG: process.env.REACT_APP_DEBUG === 'TRUE',
    FIRMWARE_VERSION_BABY: parseFloat(
        process.env.REACT_APP_FIRMWARE_VERSION_BABY as string
    ),
    FIRMWARE_VERSION_ADULT: parseFloat(
        process.env.REACT_APP_FIRMWARE_VERSION_ADULT as string
    ),
    VERSION: 'NATIVE',
    APP_SOCKET_URL: '',
    AGORA_VIDEO_UID: process.env.REACT_APP_AGORA_VIDEO_UID as string,
}

export default configProvider
