import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import BraydenCore from '@innosonian/brayden-core'
import Input from 'view/component/Input/Input'
import ModalContainer from '../ModalContainer/ModalContainer'
import Typography from 'view/component/Typography/Typography'
import Button from 'view/component/Button/Button'

export default function AEDConfigModal({
    isOpen,
    onClick,
}: {
    isOpen: boolean
    onClick: () => void
}) {
    const { t } = useTranslation('locale')
    const dispatch = useDispatch()
    const [language, setLanguage] = useState<string>('korean')
    const [volume, setVolume] = useState<number>(3)

    const connectedAED = useSelector(
        BraydenCore.Entity.Session.SessionSelector.getSelectedAED
    )

    const updateConfigData = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (connectedAED !== undefined) {
            dispatch(
                BraydenCore.Entity.Session.SessionAction.aedConfigSend(
                    connectedAED,
                    { volume: volume, lang: language }
                )
            )
        }
    }

    return (
        <ModalContainer
            isOpen={isOpen}
            closeButton={true}
            onCloseClick={() => onClick()}
            color="bg-gray-900 dark:bg-gray-light-bg"
            className="w-11/12 lg:w-4/12 absolute right-0 top-12 mr-10"
        >
            <Typography
                type="h2"
                weight="bold"
                className={`${
                    connectedAED ? 'invisible' : 'visible'
                } pr-0 md:pr-5  md:mr-0`}
            >
                {t('Please connect AED')}
            </Typography>

            <form
                className="w-full flex flex-col py-10"
                onSubmit={updateConfigData}
            >
                <div className="w-full flex flex-row pb-10">
                    <input
                        id="korean"
                        type="radio"
                        name="language"
                        value="korean"
                        checked={language === 'korean'}
                        onChange={(e) => setLanguage(e.target.value)}
                    ></input>
                    <label htmlFor="korean">
                        <Typography
                            type="h2"
                            weight="bold"
                            className="pr-0 md:pr-5 md:mr-0 ml-2"
                        >
                            {t('Korean')}
                        </Typography>
                    </label>
                    <input
                        id="english"
                        name="language"
                        type="radio"
                        value="english"
                        checked={language === 'english'}
                        onChange={(e) => setLanguage(e.target.value)}
                    ></input>
                    <label htmlFor="english">
                        <Typography
                            type="h2"
                            weight="bold"
                            className="pr-0 md:pr-5 md:mr-0 ml-2"
                        >
                            {t('English')}
                        </Typography>
                    </label>
                </div>
                <div className="w-full flex flex-row pb-10">
                    <Typography
                        type="h2"
                        weight="bold"
                        className="pr-0 md:pr-5  md:mr-0"
                    >
                        {t('Volume')}
                    </Typography>
                    <Input
                        noMargin={true}
                        autocomplete="off"
                        type="number"
                        value={volume}
                        placeholder={volume.toString()}
                        min={0}
                        max={5}
                        onChange={(e) => {
                            setVolume(Number(e.target.value))
                        }}
                    />
                </div>
                <Button
                    onclick={(e) => updateConfigData(e)}
                    buttonType="submit"
                    className=""
                    disable={false}
                    color={'blue'}
                    shape="rounded-mid"
                    size="sm"
                    padding="sm"
                    weight="bold"
                >
                    {t('Send')}
                </Button>
            </form>
        </ModalContainer>
    )
}
