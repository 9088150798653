import React from 'react'
import Lottie from '../Lottie/Lottie'
import LottieMetronome from 'resource/lottie/metronome.json'
import LottieMetronomeLight from 'resource/lottie/metronome-light.json'
import { useSelector } from 'react-redux'
import BraydenCore from '@innosonian/brayden-core'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'
import { CardTrainingType } from '@innosonian/brayden-core/build/src/entity/Card/Card.interface'
import hook from 'hook'

export default function Metronome() {
    const [theme] = hook.useDarkMode()
    const isSessionStarted = useSelector(
        BraydenCore.Entity.Session.SessionSelector.getSessionStart
    )
    const cardId = useSelector(
        BraydenCore.Entity.Session.SessionSelector.getCurrentTrainingCardId
    )
    const card = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Card.CardSelector.getCardById(state, cardId)
    )
    return (
        <div className="w-full">
            {card.type !== CardTrainingType.VENTILATION_ONLY &&
                theme === 'light' && (
                    <Lottie
                        animationData={LottieMetronome}
                        play={isSessionStarted}
                        loop={true}
                        speed={1}
                    />
                )}
            {card.type !== CardTrainingType.VENTILATION_ONLY &&
                theme === 'dark' && (
                    <Lottie
                        animationData={LottieMetronomeLight}
                        play={isSessionStarted}
                        loop={true}
                        speed={1}
                    />
                )}
            {card.type === CardTrainingType.VENTILATION_ONLY && (
                <div className="h-6 md:h-8 lg:h-10"></div>
            )}
        </div>
    )
}
