import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Typography from '../Typography/Typography'
import BraydenCore from '@innosonian/brayden-core'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'
import { CardTrainingType } from '@innosonian/brayden-core/build/src/entity/Card/Card.interface'
import { RTData } from '@innosonian/brayden-core/build/src/_bluetooth/bluetooth.interface'

export default function CompressionCounter() {
    const compressionList = useSelector(
        BraydenCore.Entity.Session.SessionSelector.getCompressionList
    )
    const currentTrainingCardId = useSelector(
        BraydenCore.Entity.Session.SessionSelector.getCurrentTrainingCardId
    )
    const currentCard = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Card.CardSelector.getCardById(
            state,
            currentTrainingCardId
        )
    )
    const lastDataPushed = useSelector(
        BraydenCore.Entity.Session.SessionSelector.getLatestDataPushed
    )
    const { t } = useTranslation('locale')
    const displayStopCondition = () => {
        if (
            currentCard.stopCondition &&
            currentCard.stopCondition.finishCompression
        ) {
            return ` / ${currentCard.stopCondition.finishCompression}`
        }
    }
    return (
        <div className="w-full flex flex-row md:flex-col">
            <div className="w-1/2 md:w-full">
                <Typography type="h3" alignment="center">
                    {t('RealTimeTrainingPage.Pressed')}
                </Typography>
            </div>
            <div className="w-1/2 md:w-full">
                {currentCard.type === CardTrainingType.CHEST_COMP_ONLY && (
                    <Typography type="h3" alignment="center">
                        {compressionList.length}
                        {displayStopCondition()}
                    </Typography>
                )}
                {currentCard.type === CardTrainingType.CPR_TRAINING && (
                    <Typography type="h3" alignment="center">
                        {lastDataPushed === undefined
                            ? 0
                            : lastDataPushed[RTData.C_CNT]}
                        {displayStopCondition()}
                    </Typography>
                )}
                {currentCard.type === CardTrainingType.VENTILATION_ONLY && (
                    <Typography type="h3" alignment="center">
                        0
                    </Typography>
                )}
            </div>
        </div>
    )
}
