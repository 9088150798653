import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Button from 'view/component/Button/Button'
import Typography from 'view/component/Typography/Typography'
import { useDispatch } from 'react-redux'
import Input from 'view/component/Input/Input'
import { useSelector } from 'react-redux'
import Spinner from 'view/component/Spinner/Spinner'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'
import BraydenCore from '@innosonian/brayden-core'
import { AUTHENTICATE } from '@innosonian/brayden-core/build/src/entity/Oauth/Oauth.type'

export default function UserLogin() {
    const params = new URLSearchParams(window.location.search)
    const [client, setClient] = useState(params.get('client_id') || '')
    const [email, setEmail] = useState(params.get('email') || '')
    const [passCode, setPassCode] = useState(params.get('code') || '')
    const [error, setError] = useState(false)
    const { t } = useTranslation('locale')
    const history = useHistory()
    const dispatch = useDispatch()

    const logInApiState = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Error.ErrorSelector.getApiState(state, AUTHENTICATE)
    )
    const handleConnect = async (e: React.MouseEvent<any>) => {
        e.preventDefault()
        setError(false)
        dispatch(
            BraydenCore.Entity.Oauth.Action.oauthAuthenticateRequest(
                client,
                email,
                passCode
            )
        )
    }

    useEffect(() => {
        if (logInApiState === 'FAIL') {
            setError(true)
        }
    }, [logInApiState])

    return (
        <form className="w-full flex flex-col md:p-4 justify-end items-right">
            <Typography
                type="caption"
                alignment="right"
                className={`mx-auto mb-1 w-full ${
                    error === true ? 'visible' : 'invisible'
                }`}
            >
                {t('Login.Error')}
            </Typography>
            <Input
                label={t('Login.Institution')}
                labelPosition="left"
                type="text"
                value={client}
                onChange={(e) => setClient(e.target.value)}
                className="mb-5"
                autocomplete="nickname"
            ></Input>
            <Input
                label={t('Login.UserID')}
                labelPosition="left"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="mb-5"
                autocomplete="off"
            ></Input>
            <Input
                label={t('Login.Code')}
                labelPosition="left"
                type="password"
                value={passCode}
                onChange={(e) => setPassCode(e.target.value)}
                className="mb-5"
                autocomplete="off"
            ></Input>
            <div className="flex w-full justify-between mt-4 gap-2">
                {logInApiState !== 'REQUEST' && (
                    <Button
                        disable={logInApiState === 'REQUEST'}
                        onclick={(e) => {
                            handleConnect(e)
                        }}
                        shape="rounded-mid"
                        size="sm"
                        noShadow={true}
                        type="outline"
                        color="secondary"
                        textColor="gray-700 dark:text-gray-light-secondary-text"
                        padding="sm"
                    >
                        {t('Login.LogIn')}
                    </Button>
                )}
                {logInApiState === 'REQUEST' && (
                    <div className="flex w-1/2 items-center justify-center">
                        <Spinner size="sm" />
                    </div>
                )}
                <Button
                    disable={false}
                    onclick={() => {
                        history.push('/')
                    }}
                    shape="rounded-mid"
                    size="sm"
                    noShadow={true}
                    type="outline"
                    color="gray"
                    padding="sm"
                >
                    {t('Login.Cancel')}
                </Button>
            </div>
        </form>
    )
}
