import React from 'react'
import Button from 'view/component/Button/Button'
import FadeInContainer from 'view/layout/FadeInContainer/FadeInContainer'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { ReactComponent as UsersIcon } from 'resource/img/icons/users.svg'
import { ReactComponent as Classroom } from 'resource/img/icons/classroomButton.svg'
import { ReactComponent as Configuration } from 'resource/img/icons/configuration.svg'
import { ReactComponent as Chart } from 'resource/img/icons/chart.svg'

export default function HomeAdmin() {
    const { t } = useTranslation('locale')
    const history = useHistory()

    return (
        <FadeInContainer className="w-full flex flex-col h-full pb-5">
            <div className="w-full h-full flex flex-col md:flex-row">
                <div className="w-full md:w-1/2 md:h-full flex flex-col md:pr-10 pb-10 md:pb-0">
                    <div
                        className="w-full flex-grow flex flex-col border border-gray-100 cursor-pointer mb-10 justify-center items-center py-10 md:py-0"
                        onClick={() => history.push('/classroom')}
                    >
                        <Classroom className="w-40 h-40 max-w-full max-h-full text-gray-100 dark:text-gray-light-icon" />
                        <Button
                            disable={false}
                            color="blue"
                            onclick={() => history.push('/classroom')}
                            size="sm"
                            padding="sm"
                            className="self-center"
                        >
                            {t('TopBar.Classroom')}
                        </Button>
                    </div>
                    <div
                        className="w-full flex-grow flex flex-col border border-gray-100 cursor-pointer justify-center items-center py-10 md:py-0"
                        onClick={() => history.push('/users')}
                    >
                        <UsersIcon className="w-40 h-40 mb-4 text-gray-100 dark:text-gray-light-icon" />
                        <Button
                            disable={false}
                            color="secondary"
                            onclick={() => history.push('/users')}
                            size="sm"
                            padding="sm"
                            className="self-center"
                        >
                            {t('TopBar.Users')}
                        </Button>
                    </div>
                </div>
                <div className="flex flex-col w-full md:w-1/2">
                    <div
                        className="w-full flex-grow flex flex-col justify-center items-center border border-gray-100 py-10 mb-10 md:py-0 cursor-pointer"
                        onClick={() => history.push('/stats')}
                    >
                        <Chart className="w-40 h-40 text-gray-100 dark:text-gray-light-icon mb-4" />
                        <Button
                            disable={false}
                            color="danger"
                            onclick={() => history.push('/stats')}
                            size="sm"
                            padding="sm"
                            className="self-center"
                        >
                            {t('TopBar.Stats')}
                        </Button>
                    </div>
                    <div
                        className="w-full flex-grow flex flex-col justify-center items-center border border-gray-100 py-10 md:py-0"
                        onClick={() => {
                            history.push('/config')
                        }}
                    >
                        <Configuration className="w-40 h-40 text-gray-100 dark:text-gray-light-icon mb-4" />
                        <Button
                            disable={false}
                            color="warning"
                            onclick={() => {
                                history.push('/config')
                            }}
                            size="sm"
                            padding="sm"
                            className="self-center"
                        >
                            {t('TopBar.Config')}
                        </Button>
                    </div>
                </div>
            </div>
        </FadeInContainer>
    )
}
