import React, { PropsWithChildren } from 'react'
import { IProps } from './FadeInContainer.type'
import { motion } from 'framer-motion'

export default function FadeInContainer(props: PropsWithChildren<IProps>) {
    return (
        <motion.div
            className={props.className}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.25 }}
        >
            {props.children}
        </motion.div>
    )
}
