import { IBufferProvider } from '@innosonian/brayden-core/build/src/_buffer/buffer.interface'

function create<V>(buffer: V) {
    return Buffer.from(buffer)
}

const provider: IBufferProvider = {
    create: create,
}

export default provider
