import React, { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from '../Typography/Typography'
import { ReactComponent as Back } from 'resource/img/icons/back.svg'
import { ReactComponent as Next } from 'resource/img/icons/next.svg'
import { ReactComponent as Play } from 'resource/img/icons/play.svg'
import { ReactComponent as Prev } from 'resource/img/icons/prev.svg'
import { ReactComponent as Stop } from 'resource/img/icons/stop.svg'
import { ReactComponent as Up } from 'resource/img/icons/up.svg'
import { useDispatch, useSelector } from 'react-redux'
import VideoPlayer from './VideoPlayer/VideoPlayer'
import BraydenCore from '@innosonian/brayden-core'

export default function MediaPlayer() {
    const [playing, setPlaying] = useState(false)
    const { t } = useTranslation('locale')
    const mediaList = useSelector(
        BraydenCore.Entity.ClientContent.ClientContentSelector.getMedia
    )
    const currentMediaIdx = useSelector(
        BraydenCore.Entity.ClientContent.ClientContentSelector
            .getCurrentMediaIdx
    )
    const play = () => {
        setPlaying(true)
    }

    const pause = () => {
        setPlaying(false)
    }
    const goBackward = (e: React.MouseEvent<SVGSVGElement>) =>
        window.dispatchEvent(new CustomEvent('videoGoBackward'))

    const goForward = (e: React.MouseEvent<SVGSVGElement>) =>
        window.dispatchEvent(new CustomEvent('videoGoForward'))

    const handleVideoClick = () => {
        setPlaying((p) => !p)
    }

    const dispatch = useDispatch()
    return (
        <Fragment>
            <div className="w-full flex h-auto relative h-0 p-4 xl:mb-0 cursor-pointer relative">
                <VideoPlayer
                    src={
                        mediaList[currentMediaIdx] !== undefined
                            ? mediaList[currentMediaIdx].url
                            : undefined
                    }
                    playing={playing}
                    onClick={handleVideoClick}
                />
            </div>
            <div className="w-full  flex flex-row">
                <div className="w-full flex flex-col pb-8 items-center">
                    <div className="w-full  pb-5">
                        <Typography alignment="center">
                            {/* {mediaList[currentMediaIdx] !== undefined &&
                                mediaList[currentMediaIdx].id.replace(
                                    /([A-Z])/g,
                                    ' $1'
                                )} */}
                            {t('WebOverwrite.MovieIntro')}
                        </Typography>
                    </div>
                    <div className="w-full lg:w-2/3 flex justify-between px-4">
                        <Back
                            className="w-8 h-8 text-gray-150 cursor-pointer duration-500 dark:text-red-light"
                            onClick={() =>
                                dispatch(
                                    BraydenCore.Entity.ClientContent.ClientContentAction.prevMedia()
                                )
                            }
                        />
                        <Prev
                            className="w-8 h-8 text-gray-150 cursor-pointer duration-500 dark:text-red-light"
                            onClick={goBackward}
                        />
                        {playing === false && (
                            <Play
                                className="w-8 h-8 text-gray-150 cursor-pointer duration-500 dark:text-red-light"
                                onClick={play}
                            />
                        )}
                        {playing === true && (
                            <Stop
                                className="w-8 h-8 text-gray-150 cursor-pointer duration-500 dark:text-red-light"
                                onClick={pause}
                            />
                        )}
                        <Next
                            className="w-8 h-8 text-gray-150 cursor-pointer duration-500 dark:text-red-light"
                            onClick={goForward}
                        />
                        <Up
                            className="w-8 h-8 text-gray-150 cursor-pointer duration-500 dark:text-red-light"
                            onClick={() =>
                                dispatch(
                                    BraydenCore.Entity.ClientContent.ClientContentAction.nextMedia()
                                )
                            }
                        />
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
