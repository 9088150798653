import React, { PropsWithChildren } from 'react'
import { IButtonContainerProps } from './ButtonContainer.type'

export default function ButtonContainer(
    props: PropsWithChildren<IButtonContainerProps>
) {
    return (
        <button
            className={`flex p-2 rounded-md hover:bg-gray-760 shadow-button dark:shadow-button-light active:shadow-button-hover dark:active:shadow-button-light-hover active:bg-gray-960 dark:active:bg-gray-light-button-click dark:hover:bg-gray-light-secondary ${
                props.className ? props.className : ''
            }`}
        >
            {props.children}
        </button>
    )
}
