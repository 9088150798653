import React, { Fragment } from 'react'

import ErrorModal from 'view/component/Modal/ErrorModal/ErrorModal'
import VideoModal from 'view/component/Modal/VideoModal/VideoModal'
import CertificationModal from './CertificationModal/CertificationModal'
import ClassroomConnectionErrorModal from './ClassroomConnectionErrorModal/ClassroomConnectionErrorModal'
import CreateUserModal from './CreateUserModal/CreateUserModal'
import EditProfile from './EditProfile/EditProfile'
import FirmwareUpdateModal from './FirmwareUpdateModal/FirmwareUpdateModal'
import ManikinDetailModal from './ManikinDetailModal/ManikinDetailModal'
import ConfirmDeleteUser from './ConfirmDeleteUser/ConfirmDeleteUser'
import ClassroomPermissionErrorModal from './ClassroomPermissionErrorModal/ClassroomPermissionErrorModal'
import ConfirmHardDeleteUser from './ConfirmHardDeleteUser/ConfirmHardDeleteUser'
import EditClientProfile from './EditClientProfile/EditClientProfile'
import CreateUserClientModal from './CreateUserClientModal/CreateUserClientModal'
import ConfirmDeleteUserClient from './ConfirmDeleteUserClient/ConfirmDeleteUserClient'
import ConfirmHardDeleteUserClient from './ConfirmHardDeleteUserClient/ConfirmHardDeleteUserClient'
import ClassroomKickedOutModal from './ClassroomKickedOutModal/ClassroomKickedOutModal'
import ImportUserModal from './ImportUserModal/ImportUserModal'
import GuidelineModal from './GuidelineModal/GuidelineModal'
import CreateCardModal from './CreateCardModal/CreateCardModal'
import EmailCheckModal from './EmailCheckModal/EmailCheckModal'

export default function ModalManager() {
    return (
        <Fragment>
            <ErrorModal />
            <VideoModal />
            <CertificationModal />
            <ManikinDetailModal />
            <FirmwareUpdateModal />
            <ClassroomConnectionErrorModal />
            <EditProfile />
            <CreateUserModal />
            <ConfirmDeleteUser />
            <ConfirmHardDeleteUser />
            <EditClientProfile />
            <CreateUserClientModal />
            <ConfirmDeleteUserClient />
            <ConfirmHardDeleteUserClient />
            <ClassroomPermissionErrorModal />
            <ClassroomKickedOutModal />
            <ImportUserModal />
            <GuidelineModal />
            <CreateCardModal />
            <EmailCheckModal />
        </Fragment>
    )
}
