import React, { useEffect, useState } from 'react'
import ModalContainer from '../ModalContainer/ModalContainer'
import BraydenCore from '@innosonian/brayden-core'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'view/component/Button/Button'
import { useTranslation } from 'react-i18next'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'
import Input from 'view/component/Input/Input'
import Typography from 'view/component/Typography/Typography'
import { sagaPromise } from '@innosonian/brayden-core/build/src/_reducer/reducer'
import { updateUserClient } from '@innosonian/brayden-core/build/src/entity/UserClient/UserClient.saga'
import { toast } from 'react-toastify'

export default function EditClientProfile() {
    const dispatch = useDispatch()
    const userClientId = useSelector(
        BraydenCore.Entity.Modal.ModalSelector.getUserClientId
    )
    const user = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.UserClient.Selector.getUserClientById(
            state,
            userClientId as number
        )
    )

    const { t } = useTranslation('locale')
    const [name, setName] = useState(user.client_name)
    const [passcode, setPasscode] = useState(user.passcode)
    const [isError, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setName(user.client_name)
        setPasscode(user.passcode)
    }, [user])

    const updateAndSaveUser = async () => {
        setError(false)
        setLoading(true)
        try {
            await sagaPromise(updateUserClient, {
                name: name,
                passcode: passcode,
                id: userClientId as number,
            })
            toast(t('ToastManager.UpdateUserClientSuccess'), {
                type: 'success',
            })
            dispatch(
                BraydenCore.Entity.Modal.ModalAction.modalCloseEditClientProfile()
            )
        } catch (e) {
            setError(true)
        } finally {
            setLoading(false)
        }
    }

    return (
        <ModalContainer isOpen={userClientId !== undefined} closeButton={false}>
            <div className="flex flex-col h-full  w-full lg:px-10 justify-center items-center md:py-4">
                <div className="w-full pb-4">
                    <Typography type="h2" alignment="center">
                        {t('UserClientList.EditProfile')}
                    </Typography>
                </div>
                <div className="w-full flex flex-col">
                    {isError && (
                        <Typography
                            color="red-700"
                            className="w-full my-2"
                            alignment="center"
                        >
                            Error
                        </Typography>
                    )}
                    <Input
                        label={t('UserClientList.Name')}
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="mb-5"
                        autocomplete="off"
                    ></Input>
                    <Input
                        label={t('UserClientList.PassCode')}
                        type="text"
                        value={passcode}
                        onChange={(e) => setPasscode(e.target.value)}
                        className="mb-5"
                        autocomplete="off"
                    ></Input>
                </div>
                <div className="w-full flex justify-end">
                    <Button
                        disable={loading}
                        className="mr-2"
                        onclick={updateAndSaveUser}
                        type="plain"
                        shape="rounded-mid"
                        padding="sm"
                        color="secondary"
                    >
                        {t('UserList.Save')}
                    </Button>
                    <Button
                        disable={false}
                        onclick={() => {
                            setError(false)
                            dispatch(
                                BraydenCore.Entity.Modal.ModalAction.modalCloseEditClientProfile()
                            )
                        }}
                        type="plain"
                        shape="rounded-mid"
                        padding="sm"
                        color="danger"
                    >
                        {t('UserList.Cancel')}
                    </Button>
                </div>
            </div>
        </ModalContainer>
    )
}
