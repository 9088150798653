import React, { useState } from 'react'
import Button from 'view/component/Button/Button'
import ModalContainer from '../ModalContainer/ModalContainer'
import { useDispatch, useSelector } from 'react-redux'
import BraydenCore from '@innosonian/brayden-core'
import { useTranslation } from 'react-i18next'
import Typography from 'view/component/Typography/Typography'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'
import Input from 'view/component/Input/Input'
import Select from 'view/component/Select/Select'
import { IParsedCard } from '@innosonian/brayden-core/build/src/entity/Card/Card.interface'
import { GUIDE_LINE } from '@innosonian/brayden-core/build/src/entity/Calibration/Calibration.interface'

export default function CreateCardModal() {
    const { t } = useTranslation('locale')
    const dispatch = useDispatch()
    const isOpen = useSelector(
        BraydenCore.Entity.Modal.ModalSelector.isCreateCardOpen
    )
    const [error, setError] = useState(false)
    const [updatedCard, setUpdatedCard] = useState<Omit<IParsedCard, 'id'>>({
        cardTitle: '',
        type: 'CPR Training',
        target: '',
        qcprThreshold: null,
        feedback: 'graph',
        autoStop: false,
        stopCondition: undefined,
        compVentRatio: '30:2',
        rescueVentilations: false,
        certification: false,
        displayIcon: {
            type: true,
            feedback: true,
            certification: true,
        },
        movie: undefined,
        guideLine: GUIDE_LINE.ARC,
        subPassThreshold: undefined,
        subAssessment: undefined,
        realtimeFeedbackMessage: [],
    })
    const [stopCondition, setStopCondition] = useState<{
        finishCycle: number | undefined
        finishCompression: number | undefined
        finishVentilation: number | undefined
        exceedSeconds: number
    }>({
        finishCycle: undefined,
        finishCompression: undefined,
        finishVentilation: undefined,
        exceedSeconds: 120,
    })
    const [movieData, setMovieData] = useState<{
        type: string | undefined
        ContentID: string | undefined
        url: string | undefined
    }>({
        type: undefined,
        ContentID: undefined,
        url: undefined,
    })
    const [updateMovie, setUpdateMovie] = useState(false)

    const apiState = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Error.ErrorSelector.getApiState(
            state,
            BraydenCore.Entity.Card.CardType.CREATE_CARD
        )
    )

    const createCard = () => {
        if (updatedCard.cardTitle === '') {
            setError(true)
            return
        }
        if (isOpen) {
            setError(false)
            dispatch(
                BraydenCore.Entity.Card.CardAction.createCardRequest(
                    updatedCard.autoStop === true
                        ? { ...updatedCard, stopCondition }
                        : updatedCard,
                    isOpen
                )
            )
        }
    }

    const displayAutoStopParameters = () => {
        return (
            <div className="w-full flex flex-wrap pl-8 border-b border-gray-50 dark:border-gray-light-border pt-2 pb-5">
                <div className="w-full md:w-1/2 flex flex-col items-center md:pr-6">
                    <Typography className="w-full" alignment="left">
                        {t('Config.FinishCycle')}
                    </Typography>
                    <Input
                        noMargin={true}
                        autocomplete="off"
                        type="number"
                        value={stopCondition.finishCycle || 0}
                        onChange={(e) => {
                            const { value } = e.target
                            setStopCondition((t) => {
                                return {
                                    ...t,
                                    finishCycle:
                                        value === '0' || isNaN(parseInt(value))
                                            ? undefined
                                            : parseInt(value),
                                }
                            })
                        }}
                    />
                </div>
                <div className="w-full md:w-1/2 flex flex-col items-center md:pl-2">
                    <Typography className="w-full" alignment="left">
                        {t('Config.FinishCompression')}
                    </Typography>
                    <Input
                        noMargin={true}
                        autocomplete="off"
                        type="number"
                        value={stopCondition.finishCompression || 0}
                        onChange={(e) => {
                            const { value } = e.target
                            setStopCondition((t) => {
                                return {
                                    ...t,
                                    finishCompression:
                                        value === '0' || isNaN(parseInt(value))
                                            ? undefined
                                            : parseInt(value),
                                }
                            })
                        }}
                    />
                </div>
                <div className="w-full md:w-1/2 flex flex-col items-center md:pr-6">
                    <Typography className="w-full" alignment="left">
                        {t('Config.FinishVentilation')}
                    </Typography>
                    <Input
                        noMargin={true}
                        autocomplete="off"
                        type="number"
                        value={stopCondition.finishVentilation || 0}
                        onChange={(e) => {
                            const { value } = e.target
                            setStopCondition((t) => {
                                return {
                                    ...t,
                                    finishVentilation:
                                        value === '0' || isNaN(parseInt(value))
                                            ? undefined
                                            : parseInt(value),
                                }
                            })
                        }}
                    />
                </div>
                <div className="w-full md:w-1/2 flex flex-col items-center md:pl-2">
                    <Typography className="w-full" alignment="left">
                        {t('Config.ExceedSecond')}
                    </Typography>
                    <Input
                        noMargin={true}
                        autocomplete="off"
                        type="number"
                        value={stopCondition.exceedSeconds || 0}
                        onChange={(e) => {
                            const { value } = e.target
                            setStopCondition((t) => {
                                return {
                                    ...t,
                                    exceedSeconds:
                                        value === '0' || isNaN(parseInt(value))
                                            ? 120
                                            : parseInt(value),
                                }
                            })
                        }}
                    />
                </div>
            </div>
        )
    }

    const handleMovieUpdate = () => {
        return (
            <div className="w-full flex flex-wrap pl-8 pt-2 pb-5">
                <div className="w-full  flex md:w-1/2 flex-col items-center pr-4">
                    <Typography className="w-full" alignment="left">
                        {t('Config.MovieType')}
                    </Typography>
                    <Input
                        noMargin={true}
                        autocomplete="off"
                        type="text"
                        value={movieData.type || ''}
                        onChange={(e) => {
                            const { value } = e.target
                            setMovieData((t) => {
                                return {
                                    ...t,
                                    type: value,
                                }
                            })
                        }}
                    />
                </div>
                <div className="w-full  flex  md:w-1/2  flex-col items-center pl-2">
                    <Typography className="w-full" alignment="left">
                        {t('Config.FinishCompression')}
                    </Typography>
                    <Input
                        noMargin={true}
                        autocomplete="off"
                        type="text"
                        value={movieData.ContentID || ''}
                        onChange={(e) => {
                            const { value } = e.target
                            setMovieData((t) => {
                                return {
                                    ...t,
                                    ContentID: value,
                                }
                            })
                        }}
                    />
                </div>
                <div className="w-full flex flex-col items-center">
                    <Typography className="w-full" alignment="left">
                        {t('Config.MovieUrl')}
                    </Typography>
                    <Input
                        noMargin={true}
                        autocomplete="off"
                        type="text"
                        value={movieData.url || ''}
                        onChange={(e) => {
                            const { value } = e.target
                            setMovieData((t) => {
                                return {
                                    ...t,
                                    url: value,
                                }
                            })
                        }}
                    />
                </div>
            </div>
        )
    }

    return (
        <ModalContainer
            isOpen={isOpen !== undefined}
            closeButton={true}
            onCloseClick={() =>
                dispatch(
                    BraydenCore.Entity.Modal.ModalAction.modalCloseCreateCard()
                )
            }
        >
            <div className="h-full w-full flex flex-col">
                <div className="w-full flex justify-center">
                    {error && (
                        <Typography color="red-700 dark:red-light">
                            {t('Config.Error')}
                        </Typography>
                    )}
                </div>
                <div className="w-full flex flex-col md:flex-row px-2 pb-2">
                    <div className="w-full flex flex-col  md:w-1/2 items-center md:pr-2">
                        <Typography className="w-full" alignment="left">
                            {t('Config.Title')}
                        </Typography>
                        <Input
                            noMargin={true}
                            autocomplete="off"
                            type="text"
                            value={updatedCard.cardTitle}
                            onChange={(e) => {
                                const { value } = e.target
                                setUpdatedCard((t) => {
                                    return {
                                        ...t,
                                        cardTitle: value,
                                    }
                                })
                            }}
                        />
                    </div>
                    <div className="w-full flex flex-col  md:w-1/2 items-center md:pl-4">
                        <Typography className="w-full" alignment="left">
                            {t('Config.Type')}
                        </Typography>
                        <Select
                            noPadding={true}
                            id="type"
                            selectedValue={updatedCard.type}
                            name="type"
                            value={[
                                'Ventilation only',
                                'CPR Training',
                                'Chest compression only',
                            ]}
                            onChange={(e) =>
                                setUpdatedCard((t) => {
                                    return {
                                        ...t,
                                        type: e as string,
                                    }
                                })
                            }
                        />
                    </div>
                </div>
                <div className="w-full flex flex-col md:flex-row px-2 pb-2">
                    <div className="w-full flex flex-col">
                        <Typography className="w-full" alignment="left">
                            {t('Config.CompVent')}
                        </Typography>
                        <Select
                            noPadding={true}
                            id="type"
                            selectedValue={updatedCard.compVentRatio}
                            name="type"
                            value={['30:2', '15:2']}
                            onChange={(e) =>
                                setUpdatedCard((t) => {
                                    return {
                                        ...t,
                                        compVentRatio: e as string,
                                    }
                                })
                            }
                        />
                    </div>
                </div>
                <div className="w-full flex flex-col md:flex-row px-2 pb-2">
                    <div className="w-full flex flex-col  md:w-1/2 items-center md:pr-2">
                        <Typography className="w-full" alignment="left">
                            {t('Config.Feedback')}
                        </Typography>
                        <Select
                            noPadding={true}
                            id="type"
                            selectedValue={updatedCard.feedback}
                            name="type"
                            value={['graph', 'timer']}
                            onChange={(e) =>
                                setUpdatedCard((t) => {
                                    return {
                                        ...t,
                                        feedback: e as string,
                                    }
                                })
                            }
                        />
                    </div>
                    <div className="w-full flex flex-col md:w-1/2 items-center md:pl-2">
                        <Typography className="w-full" alignment="left">
                            {t('Config.Certification')}
                        </Typography>
                        <Select
                            noPadding={true}
                            id="certification"
                            selectedValue={
                                updatedCard.certification === true
                                    ? 'true'
                                    : 'false'
                            }
                            name="certification"
                            value={['true', 'false']}
                            onChange={(e) =>
                                setUpdatedCard((t) => {
                                    return {
                                        ...t,
                                        certification:
                                            e === 'true' ? true : false,
                                    }
                                })
                            }
                        />
                    </div>
                </div>
                <div className="w-full flex flex-col md:flex-row px-2 pb-2">
                    <div className="w-full flex flex-col  md:w-1/2 items-center md:pr-2">
                        <Typography className="w-full" alignment="left">
                            {t('Config.Threshold')}
                        </Typography>
                        <Input
                            noMargin={true}
                            autocomplete="off"
                            type="number"
                            value={
                                updatedCard.qcprThreshold === null
                                    ? 0
                                    : updatedCard.qcprThreshold
                            }
                            onChange={(e) => {
                                const { value } = e.target
                                setUpdatedCard((t) => {
                                    return {
                                        ...t,
                                        qcprThreshold: parseInt(value),
                                    }
                                })
                            }}
                        />
                    </div>
                    <div className="w-full flex flex-col  md:w-1/2 items-center md:pl-2">
                        <Typography className="w-full" alignment="left">
                            {t('Config.Guideline')}
                        </Typography>
                        <Select
                            noPadding={true}
                            id="autoStop"
                            selectedValue={updatedCard.guideLine}
                            name="autoStop"
                            value={[
                                GUIDE_LINE.AHA,
                                GUIDE_LINE.ARC,
                                GUIDE_LINE.ERC,
                                GUIDE_LINE.VERSION,
                            ]}
                            onChange={(e) =>
                                setUpdatedCard((t) => {
                                    return {
                                        ...t,
                                        guideLine: e as GUIDE_LINE,
                                    }
                                })
                            }
                        />
                    </div>
                </div>
                <div className="w-full flex flex-col md:flex-row px-2 pb-2">
                    <div className="w-full flex flex-col items-center">
                        <Typography className="w-full" alignment="left">
                            {t('Config.AutoStop')}
                        </Typography>
                        <Select
                            noPadding={true}
                            id="autoStop"
                            selectedValue={
                                updatedCard.autoStop === true ? 'true' : 'false'
                            }
                            name="autoStop"
                            value={['true', 'false']}
                            onChange={(e) =>
                                setUpdatedCard((t) => {
                                    return {
                                        ...t,
                                        autoStop: e === 'true' ? true : false,
                                    }
                                })
                            }
                        />
                        {updatedCard.autoStop === true &&
                            displayAutoStopParameters()}
                    </div>
                </div>
                <div className="w-full flex flex-col md:flex-row px-2 pb-2">
                    <div className="w-full flex flex-col items-center">
                        <Typography className="w-full" alignment="left">
                            {t('Config.Movie')}
                        </Typography>
                        <Select
                            noPadding={true}
                            id="movie"
                            selectedValue={
                                updateMovie === true ? 'true' : 'false'
                            }
                            name="certification"
                            value={['true', 'false']}
                            onChange={(e) =>
                                setUpdateMovie(e === 'true' ? true : false)
                            }
                        />
                        {updateMovie === true && handleMovieUpdate()}
                    </div>
                </div>
                <div className="w-full flex pt-2 justify-end px-2">
                    <Button
                        disable={apiState === 'REQUEST'}
                        noShadow={true}
                        type="outline"
                        shape="rounded-mid"
                        padding="xs"
                        color="blue"
                        className="self-right"
                        onclick={() => {
                            createCard()
                        }}
                    >
                        {t('Config.Create')}
                    </Button>
                </div>
            </div>
        </ModalContainer>
    )
}
