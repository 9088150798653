import React, { useEffect } from 'react'
import BraydenCore from '@innosonian/brayden-core'
import { useSelector } from 'react-redux'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'
import { toast, TypeOptions } from 'react-toastify'
import Typography from '../Typography/Typography'
import { useTranslation } from 'react-i18next'

export const ToastContainer = (text: string, type: TypeOptions) =>
    toast(
        <div className="flex w-full items-center justify-center">
            <Typography color="white">{text}</Typography>
        </div>,
        { type: type }
    )

export default function ToasterManager() {
    const { t } = useTranslation('locale')
    const sendCertificateApiState = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Error.ErrorSelector.getApiState(
            state,
            BraydenCore.Entity.Certificate.Types.SEND_USER_CERTIFICATE
        )
    )

    useEffect(() => {
        if (sendCertificateApiState === 'SUCCESS') {
            ToastContainer(t('ToastManager.CertificateSent'), 'success')
        }
        if (sendCertificateApiState === 'FAIL') {
            ToastContainer(t('ToastManager.CertificateSentError'), 'error')
        }
    }, [sendCertificateApiState, t])

    return <div className="hidden"></div>
}
