import React, { PropsWithChildren } from 'react'
import { IProps } from './SetupStep.type'
import { motion } from 'framer-motion'

export default function SetupStep(props: PropsWithChildren<IProps>) {
    if (!props.isConnected) {
        return null
    }
    return (
        <motion.div
            className={` ${props.className} flex flex-col w-full h-full p-10 pl-0 items-center justify-center`}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
        >
            {props.children}
        </motion.div>
    )
}
