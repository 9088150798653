import BraydenCore from '@innosonian/brayden-core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '../Typography/Typography'

export default function Countdown() {
    const dispatch = useDispatch()
    const [counter, setCounter] = useState(3)
    const [display, setDisplay] = useState('absolute')
    const selectedManikin = useSelector(
        BraydenCore.Entity.Session.SessionSelector.getSelectedManikin
    )
    console.error('selectedManikin', selectedManikin)
    useEffect(() => {
        if (counter <= 0) {
            setDisplay('hidden')
            dispatch(
                BraydenCore.Entity.Session.SessionAction.rtStartRequest(
                    selectedManikin
                )
            )
        }
        if (counter > 0) {
            const t = setTimeout(() => {
                setCounter(counter - 1)
            }, 1000)
            return () => {
                clearTimeout(t)
            }
        }
    }, [counter, setCounter, dispatch, selectedManikin])
    return (
        <div
            className={`${display} z-50 w-screen h-screen bg-gray-900  dark:bg-gray-light-bg inset-0`}
        >
            <div className="flex w-full h-full items-center">
                <Typography type="wild" alignment="center" className="w-full">
                    {counter}
                </Typography>
            </div>
        </div>
    )
}
