import axios, { AxiosRequestConfig } from 'axios'
import BraydenCore from '@innosonian/brayden-core'
import uuid from 'uuid'
import { isDevStage, isBetaStage } from 'helper/getProdStage'

const getAppId = () => {
    if (isDevStage()) {
        return '4649b0b912014241b964f9ecc2069f19'
    }
    if (isBetaStage()) {
        return '228838145e394e3192f7d5ba09672bfb'
    }
    return '228838145e394e3192f7d5ba09672bfb'
}
const options = {
    appId: getAppId(),
}

async function generateToken(
    endpoint: string,
    channelName: string,
    uid: string
) {
    const requestConfig: AxiosRequestConfig = {
        url: endpoint,
        method: 'POST',
        data: {
            channel: channelName,
            uid: uid,
        },
    }
    try {
        const result = await axios(requestConfig)
        return result.data.token
    } catch (err) {
        BraydenCore.Reducer.store.dispatch(
            BraydenCore.Entity.Modal.ModalAction.modalOpenRtcError()
        )
    }
}

function generateUid() {
    return uuid.v4()
}

const config = { options, generateToken, generateUid }

export default config
