import React from 'react'
import { IProps } from './Spinner.type'
import './Spinner.css'
import hook from 'hook'
export default function Spinner(props: IProps) {
    const [theme] = hook.useDarkMode()
    const handleSize = () => {
        switch (props.size) {
            case 'sm':
                return 'h-10 w-10'
            case 'md':
                return 'h-32 w-32'
            case 'lg':
                return 'h-48 w-48'
            case 'xl':
                return 'h-64 w-64'
            default:
                return 'h-32 w-32'
        }
    }

    const handleBorderSize = () => {
        switch (props.size) {
            case 'sm':
                return 'border-2 border-t-2'
            case 'md':
                return 'border-4 border-t-4'
            case 'lg':
                return 'border-8 border-t-8'
            case 'xl':
                return 'border-8 border-t-8'
            default:
                return 'border-8 border-t-8'
        }
    }

    return (
        <div
            style={{
                borderTopColor: theme === 'light' ? '#805ad5' : '#BE90BE',
            }}
            className={`loader ease-linear rounded-full ${handleBorderSize()} ${handleSize()}`}
        ></div>
    )
}
