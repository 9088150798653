import React, { useState } from 'react'
import ModalContainer from '../ModalContainer/ModalContainer'
import BraydenCore from '@innosonian/brayden-core'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'view/component/Button/Button'
import { useTranslation } from 'react-i18next'
import Input from 'view/component/Input/Input'
import Typography from 'view/component/Typography/Typography'
import { createUser } from '@innosonian/brayden-core/build/src/entity/User/User.saga'
import { sagaPromise } from '@innosonian/brayden-core/build/src/_reducer/reducer'
import { toast } from 'react-toastify'

export default function CreateUserModal() {
    const dispatch = useDispatch()
    const { t } = useTranslation('locale')
    const isOpen = useSelector(
        BraydenCore.Entity.Modal.ModalSelector.createUser
    )
    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [isError, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    const updateAndSaveUser = async () => {
        setError(false)
        setLoading(true)
        try {
            await sagaPromise(createUser, {
                email,
                surname,
                name,
                password,
            })
            toast(t('ToastManager.CreateUserSuccess'), { type: 'success' })
            dispatch(
                BraydenCore.Entity.Modal.ModalAction.modalCloseCreateUser()
            )
        } catch (e) {
            setError(true)
        } finally {
            setLoading(false)
        }
    }

    return (
        <ModalContainer
            isOpen={isOpen}
            closeButton={true}
            onCloseClick={() => {
                setError(false)
                dispatch(
                    BraydenCore.Entity.Modal.ModalAction.modalCloseCreateUser()
                )
            }}
        >
            <div className="flex flex-col h-full  w-full lg:px-10 justify-center items-center md:py-4">
                <div className="w-full pb-4">
                    <Typography type="h2" alignment="center">
                        {t('UserList.CreateProfile')}
                    </Typography>
                </div>
                <div className="w-full flex flex-col">
                    <div className="text-right">
                        <Typography
                            className={`w-full my-2 ${
                                isError === true ? 'visible' : 'invisible'
                            }`}
                            alignment="right"
                            type="caption"
                        >
                            {t('UserList.CreateUserEmailError')}
                        </Typography>
                    </div>

                    <Input
                        label={t('UserList.Name')}
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="mb-5"
                        autocomplete="off"
                    ></Input>
                    <Input
                        label={t('UserList.Surname')}
                        type="text"
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                        className="mb-5"
                        autocomplete="off"
                    ></Input>
                    <Input
                        label={t('UserList.Email')}
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="mb-5"
                        autocomplete="off"
                    ></Input>
                    <Input
                        label={t('UserList.Password')}
                        type="text"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="mb-5"
                        autocomplete="off"
                    ></Input>
                </div>
                <div className="w-full flex justify-end">
                    <Button
                        disable={loading}
                        className="mr-2"
                        onclick={updateAndSaveUser}
                        type="plain"
                        shape="rounded-mid"
                        padding="sm"
                        color="secondary"
                    >
                        {t('UserList.Create')}
                    </Button>
                </div>
            </div>
        </ModalContainer>
    )
}
