import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, useHistory } from 'react-router-dom'
import Button from 'view/component/Button/Button'
import FadeInContainer from '../../layout/FadeInContainer/FadeInContainer'
import { ReactComponent as LogoLight } from 'resource/img/logo-light.svg'
import { ReactComponent as Logo } from 'resource/img/logo.svg'
import BraydenCore from '@innosonian/brayden-core'
import { AnimatePresence, motion } from 'framer-motion'
import hook from '../../../hook/index'

const imgList = [
    'cover1.jpg',
    'cover2.jpg',
    'cover3.jpg',
    'cover4.jpg',
    'cover5.jpg',
    'cover6.jpg',
    'cover7.jpg',
    'cover8.jpg',
]
const LOOP_TIMER = 10000

const variants = {
    enter: (direction: number) => {
        return {
            scale: 1,
            x: direction > 0 ? 1000 : -1000,
            opacity: 0,
        }
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1,
        scale: 1.15,
    },
    exit: (direction: number) => {
        return {
            scale: 1.15,
            zIndex: 0,
            x: direction < 0 ? 1000 : -1000,
            opacity: 0,
        }
    },
}

export default function Welcome() {
    const dispatch = useDispatch()
    const [colorTheme] = hook.useDarkMode()
    const { t } = useTranslation('locale')
    const history = useHistory()
    const [currentImgIdx, setCurrentImgIdx] = useState(0)
    const [defaultLogo, setDefaultLogo] = useState(false)

    const clientId = useSelector(
        BraydenCore.Entity.Client.ClientSelector.getGeneralClientId
    )

    const isLogoAvailable = useSelector(
        BraydenCore.Entity.Client.ClientSelector.isLogoAvailable
    )
    const logo = useSelector(
        BraydenCore.Entity.Client.ClientSelector.getClientLogo
    )

    const handleStart = async () => {
        if (clientId === '') {
            await BraydenCore.Reducer.sagaPromise(
                BraydenCore.Entity.Client.getClient,
                {
                    clientId: process.env.REACT_APP_CLIENT_ID as string,
                    passcode: process.env.REACT_APP_CLIENT_PASS_CODE as string,
                }
            )
        }
        history.push('/home')
    }
    useEffect(() => {
        const getLogo = async () => {
            const subDomain = window.location.hostname.split('.')[0]
            const subDomainTail = subDomain.split('-')[1]
            if (subDomainTail) {
                await BraydenCore.Reducer.sagaPromise(
                    BraydenCore.Entity.Client.getClientLogo,
                    subDomainTail
                )
                if (isLogoAvailable) {
                    setDefaultLogo(false)
                } else {
                    setDefaultLogo(true)
                }
            } else {
                setDefaultLogo(true)
            }
        }
        getLogo()
    }, [dispatch, logo.menu, isLogoAvailable])

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentImgIdx((idx) => {
                if (imgList[idx + 1] === undefined) {
                    return 0
                }
                return idx + 1
            })
        }, LOOP_TIMER)
        return () => {
            clearInterval(interval)
        }
    })

    if (BraydenCore.Authentication.isAuthenticated() === true) {
        return <Redirect to="/home" />
    }

    return (
        <FadeInContainer className="w-full flex flex-col h-full">
            <div className="w-full h-full flex flex-col relative overflow-hidden items-center justify-center">
                <AnimatePresence initial={false} custom={1}>
                    <motion.div
                        custom={1}
                        key={currentImgIdx}
                        variants={variants}
                        initial="enter"
                        animate="center"
                        exit="exit"
                        transition={{
                            x: { type: 'spring', stiffness: 200, damping: 30 },
                            opacity: { duration: 0.2 },
                            scale: { duration: 9.99 },
                        }}
                        className="w-full h-full absolute z-0 bg-center bg-cover"
                        style={{
                            backgroundImage: `url(${process.env.PUBLIC_URL}/img/${imgList[currentImgIdx]})`,
                        }}
                    />
                </AnimatePresence>
                <div className="mx-auto justify-center items-center flex flex-col z-50 bg-gray-900 dark:bg-gray-light-bg p-12 h-90 rounded-md">
                    {colorTheme === 'dark' &&
                        !defaultLogo &&
                        isLogoAvailable && (
                            <img src={logo?.menu} alt="logo" className="w-64" />
                        )}
                    {colorTheme === 'dark' &&
                        defaultLogo &&
                        !isLogoAvailable && <LogoLight className="w-64 mb-5" />}
                    {colorTheme === 'light' &&
                        !defaultLogo &&
                        isLogoAvailable && (
                            <img src={logo?.menu} alt="logo" className="w-64" />
                        )}
                    {colorTheme === 'light' &&
                        defaultLogo &&
                        !isLogoAvailable && <Logo className="w-64 mb-5" />}
                    <Button
                        disable={false}
                        onclick={() => {
                            handleStart()
                        }}
                        color="warning"
                        shape="rounded-mid"
                        size="sm"
                        className="mx-auto mt-8"
                        fontSize="md"
                    >
                        {t('Welcome.Start')}
                    </Button>
                    <Button
                        color="secondary"
                        disable={false}
                        onclick={() => {
                            history.push('/login')
                        }}
                        shape="rounded-mid"
                        size="sm"
                        className="mx-auto mt-4"
                        fontSize="md"
                    >
                        {t('Welcome.Login')}
                    </Button>
                </div>
            </div>
        </FadeInContainer>
    )
}
