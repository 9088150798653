import React, { useEffect, useState, useRef } from 'react'
import Typography from 'view/component/Typography/Typography'
import { useTranslation } from 'react-i18next'
import { ReactComponent as AddIcon } from 'resource/img/icons/add.svg'
import { ReactComponent as Cross } from 'resource/img/icons/cross.svg'
import { useDispatch, useSelector } from 'react-redux'
import BraydenCore from '@innosonian/brayden-core'
import { CertificateType } from '@innosonian/brayden-core/build/src/entity/Certificate/Certificate.interface'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'
import Input from 'view/component/Input/Input'
import TextArea from 'view/component/TextArea/TextArea'
import Select from 'view/component/Select/Select'
import Button from 'view/component/Button/Button'
import FadeInContainer from 'view/layout/FadeInContainer/FadeInContainer'
import { RouteComponentProps, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

export default function UpdateCertificate(
    props: RouteComponentProps<{ type: CertificateType }>
) {
    const dispatch = useDispatch()
    const history = useHistory()
    const inputRef = useRef<HTMLInputElement>(null)
    const { t } = useTranslation('locale')
    const [img, setImg] = useState<File | undefined>(undefined)
    const [objectUrl, setObjectUrl] = useState<string | undefined>(undefined)
    const saveApiState = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Error.ErrorSelector.getApiState(
            state,
            BraydenCore.Entity.Certificate.Types.CREATE_OR_UPDATE_CERTIFICATE
        )
    )
    const selectedCertificate = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Certificate.Selector.getCertificateById(
            state,
            props.match.params.type
        )
    )
    const [certificate, setCertificate] = useState({
        title: '',
        text1: '',
        text2: '',
        text3: '',
        text4: '',
        nameField1: '',
        nameField2: '',
        dateFormat: 'd / m / Y',
        number: '',
        monthDuration: 12,
    })
    const [error, setError] = useState(false)
    const clientId = useSelector(BraydenCore.Entity.Oauth.Selector.getId)

    const [currentSrc, setCurrentSrc] = useState<string | undefined>(
        `${
            BraydenCore.configManager.getProvider().BASE_URL
        }/api/new/certificate/logo/${clientId}/${
            props.match.params.type
        }?${Date.now()}`
    )

    useEffect(() => {
        if (selectedCertificate && selectedCertificate.certificate) {
            setCertificate({
                title: selectedCertificate.certificate.title,
                text1: selectedCertificate.certificate.text1,
                text2: selectedCertificate.certificate.text2,
                text3: selectedCertificate.certificate.text3,
                text4: selectedCertificate.certificate.text4,
                nameField1: selectedCertificate.certificate.name_field1,
                nameField2: selectedCertificate.certificate.name_field2,
                dateFormat: selectedCertificate.certificate.date_format,
                number: selectedCertificate.certificate.number,
                monthDuration: selectedCertificate.certificate.month_duration,
            })
        }
    }, [selectedCertificate])

    useEffect(() => {
        dispatch(
            BraydenCore.Entity.Certificate.Action.GetCertificateRequest(
                props.match.params.type
            )
        )
    }, [dispatch, props.match.params.type])

    useEffect(() => {
        if (saveApiState === 'SUCCESS') {
            dispatch(
                BraydenCore.Entity.Error.ErrorAction.resetApiState(
                    BraydenCore.Entity.Certificate.Types
                        .CREATE_OR_UPDATE_CERTIFICATE
                )
            )
            history.push('/certificates')
        }
    }, [saveApiState, dispatch, history])

    useEffect(() => {
        if (currentSrc !== undefined && img !== undefined) {
            dispatch(
                BraydenCore.Entity.Certificate.Action.uploadCertificateImgRequest(
                    props.match.params.type,
                    img
                )
            )
        }
    }, [currentSrc, img, dispatch, props.match.params.type])

    const handleSelectImg = () => {
        if (inputRef !== null && inputRef.current !== null) {
            inputRef.current.click()
        }
    }

    const handleDateFormat = () => {
        switch (certificate.dateFormat) {
            case 'Day / Month / Year':
                return 'd / m / Y'
            case 'Month / Day / Year':
                return 'm / d / Y'
            default:
                return 'Y / m / d'
        }
    }

    const isFormValid = () => {
        if (
            certificate.title === '' ||
            certificate.text1 === '' ||
            certificate.text2 === '' ||
            certificate.text3 === '' ||
            certificate.text4 === '' ||
            certificate.nameField1 === '' ||
            certificate.nameField2 === '' ||
            certificate.dateFormat === '' ||
            certificate.number === ''
        ) {
            return false
        }
        return true
    }

    const createOrSave = () => {
        setError(false)
        if (isFormValid() === true && currentSrc !== undefined) {
            dispatch(
                BraydenCore.Entity.Certificate.Action.CreateOrUpdateCertificateRequest(
                    props.match.params.type,
                    {
                        title: certificate.title,
                        text1: certificate.text1,
                        text2: certificate.text2,
                        text3: certificate.text3,
                        text4: certificate.text4,
                        name_field1: certificate.nameField1,
                        name_field2: certificate.nameField2,
                        date_format: handleDateFormat(),
                        number: certificate.number,
                        month_duration: certificate.monthDuration,
                    }
                )
            )
        } else {
            setError(true)
        }
    }

    const displayImgPicker = () => {
        return (
            <div
                className="p-4 flex border-4 border-dashed border-red-800 rounded-lg  mx-auto cursor-pointer"
                onClick={handleSelectImg}
            >
                <AddIcon className="h-40 w-40 text-gray-50 dark:text-gray-light-border" />
            </div>
        )
    }
    const handleImgPreview = () => {
        return (
            <div className="relative flex mx-auto h-40 border border-gray-50 dark:border-gray-light-border rounded-lg p-4 border-dashed">
                <div
                    className="rounded-full shadow-dark p-2 bg-gray-650  dark:bg-gray-light-default-button cursor-pointer absolute top-0 right-0 mt-2 mr-2"
                    onClick={() => {
                        setCurrentSrc(undefined)
                        setImg(undefined)
                        setObjectUrl(undefined)
                        URL.revokeObjectURL(objectUrl as string)
                    }}
                >
                    <Cross className="w-5 h-5 dark:text-gray-light-default-text" />
                </div>
                <img src={currentSrc} alt={'logo'} />
            </div>
        )
    }

    const renderInput = (value: string, field: string) => {
        return (
            <Input
                noMargin={true}
                onChange={(e) => {
                    const value = e.target.value
                    setCertificate((i) => {
                        return {
                            ...i,
                            [field]: value || '',
                        }
                    })
                }}
                type="text"
                value={value}
                autocomplete="off"
            />
        )
    }

    const renderTextArea = (value: string, field: string) => {
        return (
            <TextArea
                onChange={(e) => {
                    const value = e
                    setCertificate((i) => {
                        return {
                            ...i,
                            [field]: value,
                        }
                    })
                }}
                value={value}
            />
        )
    }

    const renderSelect = (
        value: string[],
        selectedValue: string,
        field: string
    ) => {
        return (
            <Select
                onChange={(e) =>
                    setCertificate((i) => {
                        return {
                            ...i,
                            [field]: e as string,
                        }
                    })
                }
                value={value}
                selectedValue={selectedValue}
                id="t"
                name="t"
            />
        )
    }

    return (
        <FadeInContainer className="w-full h-full flex flex-col">
            <div
                className={`w-full flex flex-col p-4 dark:bg-gray-light-top-bar bg-gray-800 mt-4 rounded-lg  xl:w-1/2 mx-auto`}
            >
                <div className="flex flex-col">
                    <Typography alignment="center">
                        {t('Certificate.Certificate')}
                    </Typography>
                    {error === true && (
                        <Typography
                            alignment="center"
                            color="red-700 dark:text-red-light"
                        >
                            {t('Certificate.Error')}
                        </Typography>
                    )}
                    <div className="w-full flex flex-col border-gray-50 border rounded-lg mt-2 p-4 dark:border-gray-light-border">
                        <div className="w-full flex-flex-col p-2">
                            {renderInput(certificate.title, 'title')}
                        </div>
                        <div className="w-full flex">
                            <div className="w-1/2 flex flex-col p-2">
                                <div className="w-full pb-4">
                                    {renderTextArea(certificate.text1, 'text1')}
                                </div>
                                {renderSelect(
                                    ['Name', 'Surname', 'Email', 'Other1'],
                                    certificate.nameField1,
                                    'nameField1'
                                )}
                            </div>
                            <div className="w-1/2 flex flex-col p-2">
                                <div className="w-full pb-4">
                                    {renderTextArea(certificate.text2, 'text2')}
                                </div>
                                {renderSelect(
                                    ['Name', 'Surname', 'Email', 'Other1'],
                                    certificate.nameField2,
                                    'nameField2'
                                )}
                            </div>
                        </div>
                        <div className="w-full flex flex-col p-2">
                            {renderTextArea(certificate.text3, 'text3')}
                            <div className="w-full flex p-2">
                                {currentSrc === undefined && displayImgPicker()}
                                {currentSrc !== undefined && handleImgPreview()}
                            </div>
                        </div>
                        <div className="w-full flex">
                            <div className="w-1/2 flex flex-col p-2">
                                {renderSelect(
                                    [
                                        'Day / Month / Year',
                                        'Month / Day / Year',
                                        'Year / Month/ Day',
                                    ],
                                    certificate.dateFormat,
                                    'dateFormat'
                                )}
                            </div>
                            <div className="w-1/2 flex flex-col p-2">
                                {renderInput(certificate.number, 'number')}
                            </div>
                        </div>
                        <div className="w-full flex">
                            <div className="w-1/2 p-2">
                                {renderTextArea(certificate.text4, 'text4')}
                            </div>
                        </div>
                    </div>
                    <input
                        ref={inputRef}
                        className="hidden"
                        type="file"
                        accept="image/jpeg, image/png"
                        onChange={(e) => {
                            const _src = e.target.files
                            if (_src !== null && _src.length >= 1) {
                                if (_src[0].size < 1000000) {
                                    setImg(_src[0])
                                    setCurrentSrc(URL.createObjectURL(_src[0]))
                                } else {
                                    toast(
                                        <div className="flex w-full items-center justify-center">
                                            <Typography color="white">
                                                {t('ToastManager.ImageToBig')}
                                            </Typography>
                                        </div>,
                                        { type: 'error' }
                                    )
                                }
                            }
                        }}
                    />
                </div>
            </div>
            <div className={`w-full flex flex-col justify-center `}>
                <div className="mx-auto pt-10">
                    <Button
                        color="blue"
                        disable={saveApiState === 'REQUEST'}
                        onclick={() => createOrSave()}
                    >
                        {t('Certificate.Save')}
                    </Button>
                    <Button
                        disable={false}
                        color="danger"
                        onclick={() => history.push('/certificates')}
                        className="ml-2"
                    >
                        {t('Certificate.Cancel')}
                    </Button>
                </div>
            </div>
        </FadeInContainer>
    )
}
