import React from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import BraydenCore from '@innosonian/brayden-core'
import classroom from 'core/classroom'
import Typography from 'view/component/Typography/Typography'
import Button from 'view/component/Button/Button'
import ModalContainer from '../ModalContainer/ModalContainer'
import { ReactComponent as ClassroomUnavailable } from 'resource/img/icons/classroomUnavailable.svg'

export default function ClassroomConnectionErrorModal() {
    const { t } = useTranslation('locale')
    const dispatch = useDispatch()

    const handleClose = async () => {
        dispatch(BraydenCore.Entity.Modal.ModalAction.modalCloseRtcError())
        try {
            BraydenCore.Reducer.store.dispatch(
                BraydenCore.Entity.Classroom.Action.connectScreen(false)
            )
            await classroom.Screen.stopScreenSharing()
        } catch (e) {
            classroom.leaveClassroom()
        }
    }

    const isOpen = useSelector(
        BraydenCore.Entity.Modal.ModalSelector.getRtcError
    )

    return (
        <ModalContainer isOpen={isOpen} closeButton={false}>
            <div className="flex flex-col h-full  w-full lg:px-10 justify-center items-center md:py-16">
                <ClassroomUnavailable className="w-40 h-40 mb-4 border-b border-gray-100" />
                <Typography alignment="center" className="pb-5">
                    {t('Classroom.Error')}
                </Typography>
                <div className="w-full flex justify-around">
                    <Button
                        disable={false}
                        shape="rounded-mid"
                        color="danger"
                        padding="sm"
                        onclick={() => handleClose()}
                    >
                        {t('Classroom.Cancel')}
                    </Button>
                </div>
            </div>
        </ModalContainer>
    )
}
