import React, { PropsWithChildren, useEffect, useState } from 'react'
import { IProps } from './DropDown.type'

export default function DropDown(props: PropsWithChildren<IProps>) {
    const [display, setDisplay] = useState('hidden')
    const handleDisplay = () => {
        if (display === 'hidden') {
            setDisplay('flex')
        } else {
            setDisplay('hidden')
        }
    }

    const clickEvent = () => {
        if (display === 'flex') {
            setDisplay('hidden')
        }
    }

    useEffect(() => {
        window.addEventListener('click', clickEvent)
        return () => {
            window.removeEventListener('click', clickEvent)
        }
    })
    return (
        <div className="relative" onClick={() => handleDisplay()}>
            {props.topBar}
            <div
                className={`flex-col w-full absolute mt-5 lg:mt-2 bg-gray-800 dark:bg-gray-light-top-bar ${
                    props.noShadow === true ? '' : 'shadow-dark'
                } ${display} z-50`}
            >
                {props.children}
            </div>
        </div>
    )
}
