import React, { Component, Fragment } from 'react'
import Button from 'view/component/Button/Button'
import Typography from 'view/component/Typography/Typography'
import { IProps, IState } from './ErrorBoundary.type'
import { ReactComponent as Logo } from 'resource/img/logo.svg'

export default class ErrorBoundary extends Component<IProps, IState> {
    constructor(props: IProps) {
        super(props)
        this.state = {
            errors: false,
            errorInfo: '',
            error: '',
        }
    }

    static getDerivedStateFromError() {
        const _state: IState = { errors: true, errorInfo: '', error: '' }
        return _state
    }

    componentDidCatch(error: Error, errorInfo: any) {
        this.setState({
            errorInfo: errorInfo,
            error: error,
        })
    }

    reload = () => {
        window.location.reload()
    }

    isDebug = () => {
        const isDebug = process.env.REACT_DEBUG as string
        return isDebug === 'true'
    }

    render() {
        if (this.state.errors === true) {
            return (
                <div className="w-screen h-screen bg-gray-900 flex flex-col items-center">
                    <Logo className="flex w-56 mt-56" />
                    <Typography className="mx-auto mt-10">
                        Oups, an error occurred, please reload the page!
                    </Typography>

                    <div className="flex items-center mt-10">
                        <Button
                            onclick={this.reload}
                            color="danger"
                            disable={false}
                        >
                            click here to reload
                        </Button>
                    </div>
                    {this.isDebug() === true && (
                        <Fragment>
                            <div className="text-white">
                                {this.state.error.toString()}
                            </div>
                            <div className="text-white">
                                {this.state.errorInfo.componentStack}
                            </div>
                        </Fragment>
                    )}
                </div>
            )
        }
        return this.props.children
    }
}
