import { IHistory } from '@innosonian/brayden-core/build/src/_history/history.interface'
import { createBrowserHistory } from 'history'

const history = createBrowserHistory<any>({})
const historyProvider: IHistory = {
    push: (path: string) => history.push(path),
}
export { history }

export default historyProvider
