import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import hook from 'hook'
import BraydenCore from '@innosonian/brayden-core'
import FadeInContainer from '../../layout/FadeInContainer/FadeInContainer'
import ClientLogin from 'view/component/Login/ClientLogin'
import UserLogin from 'view/component/Login/UserLogin'
import ButtonContainer from 'view/component/ButtonContainer/ButtonContainer'
import Typography from 'view/component/Typography/Typography'
import { ReactComponent as Logo } from 'resource/img/logo.svg'
import { ReactComponent as LogoLight } from 'resource/img/logo-light.svg'
import { ReactComponent as AdminIcon } from 'resource/img/icons/admin.svg'
import { ReactComponent as UserIcon } from 'resource/img/icons/user-login.svg'

export default function LogIn() {
    const [isUserLogin, setIsUserLogin] = useState(true)
    const [theme] = hook.useDarkMode()
    const dispatch = useDispatch()
    const [defaultLogo, setDefaultLogo] = useState(false)
    const { t } = useTranslation('locale')

    const isLogoAvailable = useSelector(
        BraydenCore.Entity.Client.ClientSelector.isLogoAvailable
    )
    const logo = useSelector(
        BraydenCore.Entity.Client.ClientSelector.getClientLogo
    )

    useEffect(() => {
        const getLogo = async () => {
            const subDomain = window.location.hostname.split('.')[0]
            const subDomainTail = subDomain.split('-')[1]
            if (subDomainTail) {
                await BraydenCore.Reducer.sagaPromise(
                    BraydenCore.Entity.Client.getClientLogo,
                    subDomainTail
                )
                if (isLogoAvailable) {
                    setDefaultLogo(false)
                } else {
                    setDefaultLogo(true)
                }
            } else {
                setDefaultLogo(true)
            }
        }
        getLogo()
    }, [dispatch, logo.menu, isLogoAvailable])

    return (
        <FadeInContainer className="w-full flex flex-col justify-center items-center h-full">
            <div className="w-full md:w-2/3 lg:w-1/2 xl:w-5/12 mx-auto flex flex-col border border-gray-100  rounded-md">
                <div
                    className={`flex justify-center items-center relative h-32`}
                >
                    {theme === 'light' && !defaultLogo && isLogoAvailable && (
                        <img
                            src={logo?.smallLogin}
                            alt="logo"
                            className="w-56"
                        />
                    )}
                    {theme === 'light' && defaultLogo && !isLogoAvailable && (
                        <Logo className="w-48 mx-auto my-8" />
                    )}
                    {theme === 'dark' && !defaultLogo && isLogoAvailable && (
                        <img
                            src={logo?.smallLogin}
                            alt="logo"
                            className="w-56"
                        />
                    )}
                    {theme === 'dark' && defaultLogo && !isLogoAvailable && (
                        <LogoLight className="w-48 mx-auto my-8" />
                    )}
                    <div className="absolute xl:w-1/4 top-0 right-0 mt-4 mr-4">
                        {isUserLogin && (
                            <ButtonContainer
                                selected={false}
                                className="mx-auto"
                            >
                                <AdminIcon
                                    className="w-10 h-10 p-1"
                                    onClick={() => setIsUserLogin((e) => !e)}
                                />
                            </ButtonContainer>
                        )}
                        {!isUserLogin && (
                            <ButtonContainer
                                selected={false}
                                className="mx-auto"
                            >
                                <UserIcon
                                    className="w-10 h-10 p-1"
                                    onClick={() => setIsUserLogin((e) => !e)}
                                />
                            </ButtonContainer>
                        )}
                        <Typography
                            alignment="center"
                            className="pt-2"
                            weight="bold"
                        >
                            {!isUserLogin
                                ? t('Login.Student')
                                : t('Login.Admin')}
                        </Typography>
                    </div>
                </div>
                <div className="w-full flex justify-center">
                    <ButtonContainer selected={false}>
                        {!isUserLogin && (
                            <AdminIcon className="w-20 h-20 p-1" />
                        )}
                        {isUserLogin && <UserIcon className="w-20 h-20 p-1" />}
                    </ButtonContainer>
                </div>
                <Typography alignment="center" className="pt-2" weight="bold">
                    {isUserLogin ? t('Login.Student') : t('Login.Admin')}
                </Typography>
                <div className="w-full px-4 flex xl:px-12 py-6">
                    {isUserLogin === true && <UserLogin />}
                    {isUserLogin === false && <ClientLogin />}
                </div>
            </div>
        </FadeInContainer>
    )
}
