import { useState } from 'react'
export default function useToggle(
    initial: boolean
): [boolean, (control?: boolean) => void] {
    const [toggle, setToggle] = useState<boolean>(false)

    const handleToggle = (control?: boolean) => {
        if (control) {
            setToggle(control)
        } else {
            setToggle((prev) => !prev)
        }
    }

    return [toggle, handleToggle]
}
