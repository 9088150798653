import React, { useEffect, useRef, useState } from 'react'
import useDimensions from 'react-cool-dimensions'
import Classroom from 'core/classroom'
import { ReactComponent as UserCamera } from 'resource/img/icons/userCamera.svg'
import { useSelector } from 'react-redux'
import BraydenCore from '@innosonian/brayden-core'

export default function LocalClassroomCamera(props: { isAdmin: boolean }) {
    const videoRef = useRef<HTMLDivElement>(null)
    const [height, setHeight] = useState(0)

    const isConnected = useSelector(
        BraydenCore.Entity.Classroom.Selector.getCameraConnectionStatus
    )
    const widthRef = useDimensions({
        onResize: ({ observe, unobserve, width }) => {
            const newWidth = width * 0.6
            if (width < 400) {
                setHeight((width / 4) * 3)
            }

            setHeight((newWidth / 4) * 3)
            unobserve()
            observe()
        },
    })
    useEffect(() => {
        if (videoRef.current !== null) {
            const video = Classroom.Video.getLocalVideo()
            if (video !== undefined) {
                video.play(videoRef.current, {
                    fit: props.isAdmin ? 'cover' : 'contain',
                })
            }
        }
    }, [videoRef, isConnected, props.isAdmin])

    const handleDisplayCamera = () => {
        return (
            <div
                ref={videoRef}
                className={`w-full relative flex items-center h-full`}
            >
                {isConnected === false && (
                    <div className="w-full h-full bg-black flex justify-center items-center">
                        <UserCamera className="w-20 h-20" />
                    </div>
                )}
            </div>
        )
    }

    return (
        <div
            className={`flex flex-col md:flex-row w-full h-full flex-grow p-3`}
            ref={widthRef.observe}
            style={{ height: `${height}px` }}
        >
            {handleDisplayCamera()}
        </div>
    )
}
