import { ResponsiveBar } from '@nivo/bar'
import React from 'react'
import useResizeObserver from 'use-resize-observer'
import { IProps } from './Bar.type'
import hook from 'hook'

export default function Bar(props: IProps) {
    const { ref, width = 1 } = useResizeObserver<HTMLImageElement>()
    const [colorTheme] = hook.useDarkMode()
    const handleColors = () => {
        const obj: { [key: string]: any } = {}
        props.data.forEach((_key) => {
            if (_key.overAll < 60) {
                obj[_key[props.index]] =
                    colorTheme === 'light' ? '#FF0000' : '#D73A26'
            } else if (_key.overAll >= 60 && _key.overAll < 80) {
                obj[_key[props.index]] =
                    colorTheme === 'light' ? '#FF0000' : '#D73A26'
            } else if (_key.overAll >= 80 && _key.overAll < 90) {
                obj[_key[props.index]] =
                    colorTheme === 'light' ? '#FF7F40' : '#F1AA3A'
            } else {
                obj[_key[props.index]] =
                    colorTheme === 'light' ? '#00C04D' : '#42A739'
            }
        })
        return obj
    }

    const colorsObj = handleColors()
    const theme = {
        textColor: colorTheme === 'light' ? 'white' : '#ABABAB',
        fontSize: 14,
        tooltip: {
            container: {
                background: 'white',
                color: '#2B2E36',
                fontSize: 'inherit',
                borderRadius: '2px',
                boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
                padding: '5px 9px',
            },
        },
        axis: {
            domain: {
                line: {
                    stroke: '#777777',
                    strokeWidth: 1,
                },
            },
            ticks: {
                line: {
                    stroke: '#777777',
                    strokeWidth: 1,
                },
            },
        },
        grid: {
            line: {
                stroke: '#dddddd',
                strokeWidth: 1,
            },
        },
    }

    const handleMargin = () => {
        if (width < 580) {
            return 170
        } else if (width < 1270) {
            return 215
        } else {
            return 220
        }
    }

    const handleLabelPos = (d: number) => {
        if (d < 1) {
            return (
                (Math.round(1 as number) + 2) * ((width - handleMargin()) / 100)
            )
        }
        return (Math.round(d as number) + 5) * ((width - handleMargin()) / 100)
    }

    return (
        <div className="w-full h-full" ref={ref}>
            <ResponsiveBar
                theme={theme}
                layout={props.stack}
                data={props.data}
                indexBy={props.index}
                keys={props.keys}
                colors={(bar) => colorsObj[bar.indexValue]}
                enableGridX={true}
                enableGridY={false}
                axisBottom={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                }}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                }}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                enableLabel
                labelSkipWidth={0}
                labelSkipHeight={0}
                margin={{ top: 5, right: 30, bottom: 40, left: 150 }}
                padding={0.5}
                maxValue={108}
                minValue={0}
                labelTextColor={colorTheme === 'light' ? 'white' : '#ABABAB'}
                labelFormat={(d) =>
                    (
                        <tspan x={handleLabelPos(d as number)}>
                            {Math.round(d as number)}
                        </tspan>
                    ) as any
                }
            />
        </div>
    )
}
