import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BraydenCore from '@innosonian/brayden-core'
import FadeInContainer from '../../layout/FadeInContainer/FadeInContainer'
import Typography from 'view/component/Typography/Typography'
import { useTranslation } from 'react-i18next'
import Button from 'view/component/Button/Button'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import Select from 'view/component/Select/Select'
import moment from 'moment'
import {
    downloadRecord,
    updateRecordConfiguration,
} from '@innosonian/brayden-core/build/src/entity/Record/Record.saga'
import { sagaPromise } from '@innosonian/brayden-core/build/src/_reducer/reducer'

const DMY = 'DAY / MONTH / YEAR'
const MDY = 'MONTH / DAY / YEAR'
const YMD = 'YEAR / MONTH / DAY'

export default function RecordConfiguration() {
    const { t } = useTranslation('locale')
    const dispatch = useDispatch()
    const configuration = useSelector(
        BraydenCore.Entity.Record.RecordSelector.getRecordConfiguration
    )
    const [loading, setLoading] = useState(false)
    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())
    const [timeFormat, setTimeFormat] = useState('')
    const [radioList, setRadioList] = useState(
        configuration.checklist_result.radio_list.map((radio) => {
            return {
                ...radio,
                data: radio.data.map((data, idx) => {
                    return {
                        ...data,
                        selected: idx === 0,
                    }
                }),
            }
        })
    )

    useEffect(() => {
        dispatch(
            BraydenCore.Entity.Record.RecordAction.getRecordConfigurationRequest()
        )
    }, [dispatch])

    useEffect(() => {
        setRadioList(
            configuration.checklist_result.radio_list.map((radio) => {
                return {
                    ...radio,
                    data: radio.data.map((data, idx) => {
                        return {
                            ...data,
                            selected: idx === 0,
                        }
                    }),
                }
            })
        )
    }, [configuration.checklist_result.radio_list])

    const updateCheckbox = async (idx: number, checked: boolean) => {
        const _checkboxList = configuration.checklist_result.checkbox_list
        _checkboxList[idx] = {
            ..._checkboxList[idx],
            is_checked: checked,
        }
        try {
            setLoading(true)
            sagaPromise(updateRecordConfiguration, {
                ...configuration,
                checklist_result: {
                    ...configuration.checklist_result,
                    checkbox_list: _checkboxList,
                },
            })
        } catch (e) {
        } finally {
            setLoading(false)
        }
    }

    const handleTimeFormat = (timeFormat: string) => {
        if (timeFormat === YMD) {
            return 'Ymd'
        } else if (timeFormat === DMY) {
            return 'dmY'
        } else {
            return 'mdY'
        }
    }

    const handleDownload = async () => {
        const query = {
            from: moment(startDate).format('YYYY-MM-DD'),
            to: moment(endDate).format('YYYY-MM-DD'),
            time_format: handleTimeFormat(timeFormat),
            time_zone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            show_option:
                radioList[0].data.find((d) => d.selected === true)?.value ||
                'off',
            quotes:
                radioList[1].data.find((d) => d.selected === true)?.value ||
                'Only Numeric',
            header:
                radioList[2].data.find((d) => d.selected === true)?.value ||
                'Yes',
            manikin:
                radioList[3].data.find((d) => d.selected === true)?.value ||
                'all',
        }
        try {
            setLoading(true)
            await sagaPromise(downloadRecord, query)
        } catch (e) {
        } finally {
            setLoading(false)
        }
    }

    const displayCheckbox = (
        checkbox: {
            title: string
            description: string
            name: string
            is_checked: boolean
        },
        idx: number
    ) => {
        return (
            <div
                className="w-full lg:w-1/2 flex items-center"
                key={checkbox.title}
                onClick={() => updateCheckbox(idx, !checkbox.is_checked)}
            >
                <input
                    type="checkbox"
                    checked={checkbox.is_checked}
                    className="mr-2"
                />
                <Typography>{checkbox.title}</Typography>
            </div>
        )
    }

    const displayRadio = (
        radio: {
            title: string
            name: string
            description: string
            data: {
                selected: boolean
                value: string
                query:
                    | {
                          [key: string]: string
                      }
                    | []
            }[]
        },
        idx: number
    ) => {
        return (
            <div
                className="w-full pb-4 lg:pr-4 flex self-stretch"
                key={radio.title}
            >
                <div className="w-full flex items-center p-4 border border-gray-100 self-stretch">
                    <div className="w-full md:w-1/4">
                        <Typography type="h3" className="mr-10">
                            {radio.title}
                        </Typography>
                    </div>
                    <div className="flex flex-col w-full md:w-3/4">
                        {radio.data.map((data) => (
                            <div
                                className="flex items-center"
                                key={data.value}
                                onClick={() => {
                                    const tmpArray = [...radioList]
                                    const tmpRadio = { ...tmpArray[idx] }
                                    tmpRadio.data = tmpRadio.data.map((d) => {
                                        return {
                                            ...d,
                                            selected:
                                                d.value === data.value
                                                    ? !d.selected
                                                    : false,
                                        }
                                    })
                                    tmpArray[idx] = tmpRadio
                                    setRadioList(tmpArray)
                                }}
                            >
                                <input
                                    type="radio"
                                    value={data.value}
                                    checked={data.selected === true}
                                    className="mr-2"
                                />
                                <Typography>{data.value}</Typography>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }

    return (
        <FadeInContainer className="w-full h-full flex flex-col">
            <div className="w-full flex justify-center my-4">
                <Typography type="h2">{t('RecordConfig.Title')}</Typography>
            </div>
            <div className="w-full flex flex-col lg:flex-row p-4 border border-gray-100 mb-4">
                <div className="flex flex-col lg:mr-4">
                    <Typography>{t('RecordConfig.StartDate')}</Typography>
                    <DatePicker
                        value={startDate.toDateString()}
                        selected={startDate}
                        onChange={(date: Date) => {
                            if (date > endDate) {
                                setEndDate(date)
                            }
                            setStartDate(date as Date)
                        }}
                        className="rounded-lg h-10 text-gray-100 pl-1 border border-gray-100"
                    />
                </div>
                <div className="flex flex-col lg:mr-4">
                    <Typography>{t('RecordConfig.EndDate')}</Typography>
                    <DatePicker
                        value={endDate.toDateString()}
                        selected={endDate}
                        onChange={(date: Date) => {
                            if (date > startDate) {
                                setEndDate(date)
                            }
                        }}
                        className="rounded-lg h-10 text-gray-100 pl-1 border border-gray-100"
                    />
                </div>
                <div className="flex flex-col lg:mr-4">
                    <Typography>{t('RecordConfig.TimeFormat')}</Typography>
                    <Select
                        noPadding={true}
                        value={[DMY, MDY, YMD]}
                        selectedValue={timeFormat}
                        onChange={(e) => setTimeFormat(e as string)}
                        id="time format"
                        name="time format"
                    />
                </div>
            </div>
            <div className="w-full flex flex-col lg:flex-row">
                <div className="w-full lg:w-1/2 flex flex-col justify-items-stretch">
                    {radioList.map((radio, idx) => displayRadio(radio, idx))}
                </div>
                <div className="w-full lg:w-1/2 flex flex-wrap p-4 border border-gray-100 justify-between">
                    {configuration.checklist_result.checkbox_list.map(
                        (checkbox, idx) => displayCheckbox(checkbox, idx)
                    )}
                </div>
            </div>
            <div className="w-full flex my-10 justify-center">
                <Button
                    color="blue"
                    onclick={() => handleDownload()}
                    disable={loading}
                >
                    {t('RecordConfig.Download')}
                </Button>
            </div>
        </FadeInContainer>
    )
}
