import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'
i18n.use(initReactI18next)
    .use(LanguageDetector)
    .use(Backend)
    .init(
        {
            fallbackLng: 'en',
            debug: false,
            defaultNS: 'locale',
            ns: ['locale'],
            backend: {
                loadPath: `https://brayden-language-test.s3.eu-central-1.amazonaws.com/Brayden_Online_Webapp/{{lng}}/{{ns}}.json`,
                crossDomain: true,
            },
            react: {
                wait: true,
                useSuspense: true,
            },
        },
        () => {
            console.log('finish loading')
        }
    )
export default i18n
