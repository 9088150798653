import hook from 'hook'
import React, { Fragment, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import PumpGreen from 'resource/lottie/pump-green.json'
import Lottie from 'view/component/Lottie/Lottie'
import './RecoilAlert.scss'
import { IProps } from './RecoilAlert.type'
import PumpRed from 'resource/lottie/pump-red.json'
import BraydenCore from '@innosonian/brayden-core'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'
import {
    ModelType,
    RTData,
} from '@innosonian/brayden-core/build/src/_bluetooth/bluetooth.interface'
import { HAND_POS } from '@innosonian/brayden-core/build/src/_calculation/calculation.interface'

export default function RecoilAlert(props: IProps) {
    const lastDataPushed = useSelector(
        BraydenCore.Entity.Session.SessionSelector.getLatestDataPushed
    )
    const calibrationResume = useSelector(
        BraydenCore.Entity.Calibration.CalibrationSelector.getCalibrationResume
    )
    const hardware = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Hardware.HardwareSelector.getConnectedHardwareById(
            state,
            BraydenCore.Entity.Session.SessionSelector.getSelectedManikin(state)
        )
    )
    const [display, setDisplay] = useState('hidden')
    const [gotTo, setGoto] = useState(0)
    const previousGoto = hook.usePrevious(gotTo)
    const [recoilValid, setRecoilValid] = useState(true)
    const compressionList = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Session.SessionSelector.getLastCompressionList(
            state,
            lastDataPushed !== undefined ? lastDataPushed[RTData.C_CNT] - 1 : 0,
            lastDataPushed !== undefined ? lastDataPushed[RTData.CYCLE] : 0
        )
    )

    hook.useDeepCompareEffect(() => {
        if (lastDataPushed !== undefined) {
            const biggestValue = BraydenCore.Calculation.RatioCalculation.getBiggestCompValue(
                [lastDataPushed]
            )
            const ratio = BraydenCore.Calculation.RatioCalculation.getRatio(
                calibrationResume.recoilMin,
                calibrationResume.shallowMax,
                biggestValue
            )
            if (ratio < 0) {
                setGoto(0)
            } else if (ratio > 1) {
                setGoto(120)
            } else {
                setGoto(Math.floor(120 * ratio))
            }
        }
    }, [lastDataPushed, calibrationResume])

    hook.useDeepCompareEffect(() => {
        setRecoilValid(
            BraydenCore.Calculation.RecoilCalculation.isRecoilValid(
                compressionList,
                calibrationResume
            )
        )
    }, [compressionList])

    useEffect(() => {
        const handlePositionType = () => {
            if (hardware !== undefined) {
                return hardware.modelType === ModelType.BRAYDEN_BABY_PRO
                    ? 'bit'
                    : 'normal'
            }
            return 'normal'
        }

        if (lastDataPushed === undefined) {
            setDisplay('hidden')
        } else if (lastDataPushed[RTData.C_RATE] === 0) {
            setDisplay('hidden')
        } else if (
            BraydenCore.Calculation.RatioCalculation.getHandPosition(
                lastDataPushed,
                handlePositionType()
            ) === HAND_POS.DOWN
        ) {
            setDisplay('hidden')
        } else {
            setDisplay('flex')
        }
    }, [lastDataPushed, setDisplay, display, hardware])

    const displayAnimation = (pumpAnimation: object) => {
        if (previousGoto === gotTo) {
            return <Lottie animationData={pumpAnimation} goTo={gotTo} />
        }
        return (
            <Lottie
                animationData={pumpAnimation}
                segments={[previousGoto, gotTo]}
                play={true}
                speed={1.8}
            />
        )
    }

    return (
        <div
            className={`flex flex-col mx-auto lg:mt-0  mx-auto  absolute ${display}`}
            style={{
                left: '50%',
                transform: 'translate(-50%, 0%)',
                width: `${props.width - 10}px`,
                height: `${props.height - 10}px`,
            }}
        >
            <Fragment>
                {recoilValid === true && displayAnimation(PumpGreen)}
                {recoilValid === false && displayAnimation(PumpRed)}
            </Fragment>
        </div>
    )
}
