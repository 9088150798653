import BraydenCore from '@innosonian/brayden-core'
import { CardTrainingType } from '@innosonian/brayden-core/build/src/entity/Card/Card.interface'
import { RTData } from '@innosonian/brayden-core/build/src/_bluetooth/bluetooth.interface'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Typography from '../Typography/Typography'
import { IProps } from './CycleCounter.type'

export default function CycleCounter(props: IProps) {
    const { t } = useTranslation('locale')
    const lastDataPushed = useSelector(
        BraydenCore.Entity.Session.SessionSelector.getLatestDataPushed
    )
    const currentTrainingCardId = useSelector(
        BraydenCore.Entity.Session.SessionSelector.getCurrentTrainingCardId
    )
    const currentCard = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Card.CardSelector.getCardById(
            state,
            currentTrainingCardId
        )
    )
    return (
        <div className="w-full justify-center flex flex-row md:flex-col">
            <div className="w-1/2 md:w-full">
                <Typography type="h3" alignment="center">
                    {t('RealTimeTrainingPage.Cycle')}
                </Typography>
            </div>
            <div className="w-1/2 md:w-full">
                {currentCard.type === CardTrainingType.CPR_TRAINING && (
                    <Typography type="h3" alignment="center">
                        {lastDataPushed !== undefined &&
                            lastDataPushed[RTData.CYCLE]}
                        {lastDataPushed === undefined && 0}
                    </Typography>
                )}
                {currentCard.type !== CardTrainingType.CPR_TRAINING && (
                    <Typography type="h3" alignment="center">
                        -
                    </Typography>
                )}
            </div>
        </div>
    )
}
