import React, { Fragment, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import hook from 'hook'
import BraydenCore from '@innosonian/brayden-core'
import Typography from '../Typography/Typography'
import DropDown from '../DropDown/DropDown'
import Item from '../DropDown/Item/Item'
import ResponsiveAdminTopBar from './ResponsiveAdminTopBar/ResponsiveAdminTopBar'
import { Link } from 'react-router-dom'
import { ReactComponent as Logo } from 'resource/img/logo.svg'
import { ReactComponent as LogoLight } from 'resource/img/logo-light.svg'
import { ReactComponent as LogoIcon } from 'resource/img/logo-small.svg'
import { ReactComponent as UserIcon } from 'resource/img/icons/user.svg'
import { ReactComponent as UserLightIcon } from 'resource/img/icons/user-light.svg'
import { ReactComponent as ArrowDownIcon } from 'resource/img/icons/arrow-down.svg'
import { ReactComponent as DarkIcon } from 'resource/img/icons/dark.svg'
import { ReactComponent as LightIcon } from 'resource/img/icons/light.svg'
import { ReactComponent as UsersIcon } from 'resource/img/icons/users.svg'
import { ReactComponent as Classroom } from 'resource/img/icons/classroomButton.svg'
import { ReactComponent as Configuration } from 'resource/img/icons/configuration.svg'
import { ReactComponent as CertificateIcon } from 'resource/img/icons/certificate.svg'
import { ReactComponent as Chart } from 'resource/img/icons/chart.svg'
import { ReactComponent as RecordConfig } from 'resource/img/icons/recordConfig.svg'
import { ReactComponent as AdminManagement } from 'resource/img/icons/admin-management.svg'
import { ReactComponent as MenuIcon } from 'resource/img/icons/menu.svg'

export default function AdminTopBar() {
    const [isMenuDropOpen, setIsMenuDropOpen] = useState(false)
    const [defaultLogo, setDefaultLogo] = useState(false)
    const name = useSelector(BraydenCore.Entity.Client.ClientSelector.getName)
    const clientType = useSelector(
        BraydenCore.Entity.Oauth.Selector.getClientType
    )
    const isSwitched = useSelector(
        BraydenCore.Entity.Oauth.Selector.getIsSwitched
    )
    const dispatch = useDispatch()
    const surname = useSelector(
        BraydenCore.Entity.Client.ClientSelector.getSurname
    )
    const { t } = useTranslation('locale')
    const clientId = useSelector(
        BraydenCore.Entity.Client.ClientSelector.getGeneralClientId
    )
    const [colorTheme, setTheme] = hook.useDarkMode()

    const isLogoAvailable = useSelector(
        BraydenCore.Entity.Client.ClientSelector.isLogoAvailable
    )
    const logo = useSelector(
        BraydenCore.Entity.Client.ClientSelector.getClientLogo
    )
    useEffect(() => {
        const getLogo = async () => {
            const subDomain = window.location.hostname.split('.')[0]
            const subDomainTail = subDomain.split('-')[1]
            if (subDomainTail) {
                await BraydenCore.Reducer.sagaPromise(
                    BraydenCore.Entity.Client.getClientLogo,
                    subDomainTail
                )
                if (isLogoAvailable) {
                    setDefaultLogo(false)
                } else {
                    setDefaultLogo(true)
                }
            } else {
                setDefaultLogo(true)
            }
        }
        getLogo()
    }, [dispatch, logo.menu, isLogoAvailable])

    const displayDropDown = () => {
        return (
            <DropDown
                topBar={
                    <div className="h-full flex items-center">
                        {colorTheme === 'light' && (
                            <UserIcon className="ml-4 lg:ml-2" />
                        )}
                        {colorTheme === 'dark' && (
                            <UserLightIcon className="ml-4 lg:ml-2" />
                        )}
                        <div className="flex items-center">
                            <Typography
                                type="p"
                                className="pl-4 hidden md:flex"
                            >
                                {name} {surname}
                            </Typography>
                            <ArrowDownIcon className="ml-2 text-gray-50 dark:text-gray-light-default-text" />
                        </div>
                    </div>
                }
            >
                <Fragment>
                    <Item
                        text={t('TopBar.LogOut')}
                        onClick={(e) => {
                            BraydenCore.history.getProvider().push('/login')
                            dispatch(
                                BraydenCore.Entity.Oauth.Action.oauthLogOutRequest()
                            )
                        }}
                    ></Item>
                </Fragment>
            </DropDown>
        )
    }

    const isAdmin = () =>
        clientType === 'admin' || clientType === 'organization_admin'

    return (
        <nav className="w-full flex justify-start bg-gray-800 dark:bg-gray-light-top-bar items-center p-2 md:py-5 lg:py-2 md:px-5 ">
            <div className="flex w-full items-center">
                <Link
                    to={clientId === 'chrome' ? '/' : '/home'}
                    className={`w-40`}
                >
                    {colorTheme === 'light' &&
                        !defaultLogo &&
                        isLogoAvailable && <img src={logo?.menu} alt="logo" />}
                    {colorTheme === 'light' &&
                        defaultLogo &&
                        !isLogoAvailable && (
                            <Logo className="flex lg:hidden xl:flex w-40" />
                        )}
                    {colorTheme === 'dark' &&
                        !defaultLogo &&
                        isLogoAvailable && <img src={logo?.menu} alt="logo" />}
                    {colorTheme === 'dark' &&
                        defaultLogo &&
                        !isLogoAvailable && (
                            <LogoLight className="flex lg:hidden xl:flex w-40" />
                        )}
                    {defaultLogo && !isLogoAvailable && (
                        <LogoIcon className="hidden lg:flex xl:hidden w-10" />
                    )}
                </Link>
                <div className="w-full hidden lg:flex">
                    <div className="flex flex-grow px-4 justify-start">
                        <Link
                            to="/stats"
                            className="xl:mr-4 mr-2 flex justify-center items-center "
                        >
                            <Chart className="h-6 w-6 white dark:text-gray-900 mr-2" />
                            <Typography color="white dark:text-gray-light-text">
                                {t('TopBar.Stats')}
                            </Typography>
                        </Link>
                        <Link
                            to="/users"
                            className="xl:mr-4 mr-2 flex justify-center items-center "
                        >
                            <UsersIcon className="h-6 w-6 white dark:text-gray-900 mr-2" />
                            <Typography color="white dark:text-gray-light-text">
                                {t('TopBar.Users')}
                            </Typography>
                        </Link>
                        <Link
                            to="/record-config"
                            className="xl:mr-4 mr-2 flex justify-center items-center "
                        >
                            <RecordConfig className="h-6 w-6 white dark:text-gray-900 mr-2" />
                            <Typography color="white dark:text-gray-light-text">
                                {t('TopBar.Record')}
                            </Typography>
                        </Link>
                        <Link
                            to="/classroom"
                            className="xl:mr-4 mr-2 flex justify-center items-center "
                        >
                            <Classroom className="h-6 w-6 white dark:text-gray-900 mr-2" />
                            <Typography color="white dark:text-gray-light-text">
                                {t('TopBar.Classroom')}
                            </Typography>
                        </Link>

                        <Link
                            to="/config"
                            className="xl:mr-4 mr-2 flex justify-center items-center "
                        >
                            <Configuration className="h-6 w-6 white dark:text-gray-900 mr-2" />
                            <Typography color="white dark:text-gray-light-text">
                                {t('TopBar.Config')}
                            </Typography>
                        </Link>

                        <Link
                            to="/certificates"
                            className="xl:mr-4 mr-2 flex justify-center items-center "
                        >
                            <CertificateIcon className="h-6 w-6 white dark:text-gray-900 mr-2" />
                            <Typography color="white dark:text-gray-light-text">
                                {t('TopBar.Certificate')}
                            </Typography>
                        </Link>

                        {isAdmin() && (
                            <Link
                                to="/adminManagement"
                                className="xl:mr-4 mr-2 flex justify-center items-center "
                            >
                                <AdminManagement className="h-6 w-6 white dark:text-gray-900 mr-2" />
                                <Typography color="white dark:text-gray-light-text">
                                    {t('TopBar.Admin')}
                                </Typography>
                            </Link>
                        )}
                    </div>
                    {isSwitched === true && (
                        <div
                            className="flex justify-center items-center mr-2 p-1 cursor-pointer border-b border-gray-light-border"
                            onClick={() =>
                                dispatch(
                                    BraydenCore.Entity.Oauth.Action.switchLogOut()
                                )
                            }
                        >
                            <Typography color="white dark:text-gray-light-text">
                                switch log out
                            </Typography>
                        </div>
                    )}

                    <div className="flex flex justify-self-end items-center h-full ml-auto  cursor-pointer">
                        {colorTheme === 'dark' && (
                            <DarkIcon
                                className="w-10 h-10 text-yellow-800 lg:ml-0 ml-4"
                                onClick={() => setTheme('light')}
                            />
                        )}
                        {colorTheme === 'light' && (
                            <LightIcon
                                className="w-10 h-10 text-gray-light-default-text lg:ml-0 ml-4"
                                onClick={() => setTheme('dark')}
                            />
                        )}
                        {displayDropDown()}
                    </div>
                </div>
            </div>
            <div className="flex w-full lg:hidden justify-end">
                <MenuIcon
                    className="w-10 h-10 white dark:text-gray-light-default-text"
                    onClick={() => setIsMenuDropOpen((e) => !e)}
                />
                {isMenuDropOpen === true && (
                    <ResponsiveAdminTopBar
                        onClose={() => setIsMenuDropOpen(false)}
                    />
                )}
            </div>
        </nav>
    )
}
