import usePrevious from './usePrevious'
import fallbackCatch from './needFallBack'
import useDeepCompareEffect from './useDeepCompareEffect'
import useWindowSize from './useWindowSize'
import useDarkMode from './useDarkMode'
import useClickOutside from './useClickOutside'
import useClickInside from './useClickInside'
import useToggle from './useToggle'
import useForceMute from './useForceMute'

const hook = {
    usePrevious,
    fallbackCatch,
    useDeepCompareEffect,
    useWindowSize,
    useDarkMode,
    useClickOutside,
    useClickInside,
    useToggle,
    useForceMute,
}

export default hook
