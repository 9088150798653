import React, { useState, useEffect } from 'react'
import Typography from 'view/component/Typography/Typography'
import { ReactComponent as Play } from 'resource/img/icons/play.svg'
import { IProps } from './VideoPlayer.type'
import useResizeObserver from 'use-resize-observer'
import moment from 'moment'
import { useCallbackRef } from 'use-callback-ref'
import Spinner from 'view/component/Spinner/Spinner'
import useDimensions from 'react-cool-dimensions'

const VideoPlayer = (props: IProps) => {
    const formatMin = (sec: number) => {
        const duration = moment.duration(sec, 'seconds')
        return moment.utc(duration.as('milliseconds')).format('mm:ss')
    }
    const [videoDim, setVideoDim] = useState([0, 0]) // width x height
    const [displaySpinner, setDisplaySpinner] = useState(true)
    const [timeBarWidth, setTimeBarWidth] = useState(0)
    const [currentTime, setCurrentTime] = useState(0)
    const playerRef = useCallbackRef<HTMLVideoElement>(null, (newRef) => {
        if (newRef !== null && !isNaN(newRef.duration)) {
            const timeRatio = newRef.currentTime / newRef.duration
            setTimeBarWidth(width * timeRatio)
        }
    })
    const { ref, width = 1 } = useResizeObserver<HTMLDivElement>()
    const videoDimensions = useDimensions({
        onResize: ({ observe, unobserve, width }) => {
            setVideoDim([width, width / 2])
            unobserve()
            observe()
        },
    })
    const handleTimeBarClick = (
        event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
        if (
            ref.current !== undefined &&
            ref.current !== null &&
            playerRef !== null &&
            playerRef.current !== null &&
            !isNaN(playerRef.current.duration)
        ) {
            const x = event.pageX - ref.current.getBoundingClientRect().left
            const _newTime = (playerRef.current.duration / width) * x
            setTimeBarWidth(x)
            playerRef.current.currentTime = _newTime
            setCurrentTime(_newTime)
        }
    }

    useEffect(() => {
        if (ref.current !== null && playerRef.current !== null) {
            const widthRatio = currentTime / playerRef.current.duration
            setTimeBarWidth(ref.current.offsetWidth * widthRatio)
        }
    }, [currentTime, playerRef, width, ref])

    useEffect(() => {
        if (props.playing === true && playerRef.current !== null) {
            playerRef.current.play()
        }
        if (props.playing === false && playerRef.current !== null) {
            playerRef.current.pause()
        }
    }, [props.playing, playerRef])

    useEffect(() => {
        const goBackward = () => {
            if (playerRef.current !== null) {
                playerRef.current.currentTime -= 10
            }
        }
        const goForward = () => {
            if (playerRef.current !== null) {
                playerRef.current.currentTime += 10
            }
        }
        window.addEventListener('videoGoForward', goForward)
        window.addEventListener('videoGoBackward', goBackward)
        return () => {
            window.removeEventListener('videoGoForward', goForward)
            window.removeEventListener('videoGoBackward', goBackward)
        }
    })
    useEffect(() => {
        if (playerRef.current && !props.playing) {
            playerRef.current.load()
        }
    }, [props.src, props.playing, playerRef])

    return (
        <div
            className="w-full h-full flex flex-col relative"
            ref={videoDimensions.observe}
        >
            <video
                preload="auto"
                style={{
                    width: `${videoDim[0]}px`,
                    height: `${videoDim[1]}px`,
                }}
                ref={playerRef}
                onClick={props.onClick}
                onTimeUpdate={(ev) => {
                    setCurrentTime(ev.currentTarget.currentTime)
                }}
                onCanPlayThrough={() => setDisplaySpinner(false)}
                onWaiting={() => setDisplaySpinner(true)}
                onLoadedData={() => setDisplaySpinner(false)}
            >
                {props.src !== undefined && <source src={props.src} />}
            </video>
            {displaySpinner === true && (
                <div
                    className="absolute flex items-center justify-center"
                    style={{
                        width: `${videoDim[0]}px`,
                        height: `${videoDim[1]}px`,
                    }}
                >
                    <Spinner size="md" />
                </div>
            )}
            <div
                className={`absolute flex items-center justify-center ${
                    props.playing === true ? 'hidden' : ''
                }`}
                style={{
                    width: `${videoDim[0]}px`,
                    height: `${videoDim[1]}px`,
                }}
            >
                <Play
                    className={`w-10 h-10 md:w-20 md:h-20 m-auto text-gray-800 dark:text-gray-light-default-text`}
                    onClick={props.onClick}
                />
            </div>
            <div className="w-full h-10 flex flex-col mb-10">
                <div className="flex w-full justify-between">
                    <Typography type="span">
                        {playerRef.current !== null
                            ? formatMin(playerRef.current.currentTime)
                            : '00:00'}
                    </Typography>
                    <Typography type="span">
                        {playerRef.current !== null &&
                        !isNaN(playerRef.current.duration)
                            ? formatMin(playerRef.current.duration)
                            : '00:00'}
                    </Typography>
                </div>
                <div
                    className="h-2 w-full bg-gray-100 dark:bg-gray-light-svg"
                    ref={ref}
                    onClick={handleTimeBarClick}
                >
                    <div
                        style={{ width: `${timeBarWidth}px` }}
                        className="h-2 bg-red-700 cursor-pointer"
                    ></div>
                </div>
            </div>
        </div>
    )
}

export default VideoPlayer
