import { useEffect, useState } from 'react'

export default function useDarkMode(): [
    string,
    React.Dispatch<React.SetStateAction<string>>
] {
    const [theme, setTheme] = useState(
        localStorage.getItem('darkMode') === 'dark' ? 'dark' : 'light'
    )
    useEffect(() => {
        const root = window.document.documentElement
        root.classList.remove(theme === 'dark' ? 'light' : 'dark')
        root.classList.add(theme)
        localStorage.setItem('darkMode', theme)
        const event = new CustomEvent('theme', { detail: theme })
        document.dispatchEvent(event)
    }, [theme])

    const _eventHandler = (ev: any) => setTheme(ev.detail)

    useEffect(() => {
        document.addEventListener('theme', _eventHandler)
        return () => document.removeEventListener('theme', _eventHandler)
    })

    return [theme, setTheme]
}
