import React, { Fragment, useState } from 'react'
import ModalContainer from '../ModalContainer/ModalContainer'
import { useSelector } from 'react-redux'
import BraydenCore from '@innosonian/brayden-core'
import { useTranslation } from 'react-i18next'
import Typography from 'view/component/Typography/Typography'
import CreateGeneralAdmin from './CreaterUserClientForms/CreateGeneralAdmin'
import CreateCoach from './CreaterUserClientForms/CreateCoach'
import CreateOrganizationAdmin from './CreaterUserClientForms/CreateOrganizationAdmin'
import CreateBasicUserClient from './CreaterUserClientForms/CreateBasicUserClient'

export default function CreateUserClientModal() {
    const { t } = useTranslation('locale')
    const isOpen = useSelector(
        BraydenCore.Entity.Modal.ModalSelector.createUserClient
    )
    const currentClientType = useSelector(
        BraydenCore.Entity.Oauth.Selector.getClientType
    )
    const [clientType, setClientType] = useState('general')

    const displayClientType = () => {
        return (
            <div className="w-full flex flex-col">
                <div className="w-full flex">
                    <div className="w-full flex items-center justify-start">
                        <input
                            className="mr-2"
                            value="general"
                            type="radio"
                            checked={clientType === 'general'}
                            onChange={(e) => setClientType(e.target.value)}
                        />
                        <Typography>{t('UserClientList.General')}</Typography>
                    </div>
                    <div className="w-full flex items-center justify-start">
                        <input
                            className="mr-2"
                            value="coach"
                            type="radio"
                            checked={clientType === 'coach'}
                            onChange={(e) => setClientType(e.target.value)}
                        />
                        <Typography>{t('UserClientList.Coach')}</Typography>
                    </div>
                    <div className="w-full flex items-center justify-start">
                        <input
                            className="mr-2"
                            value="organization_admin"
                            type="radio"
                            checked={clientType === 'organization_admin'}
                            onChange={(e) => setClientType(e.target.value)}
                        />
                        <Typography>
                            {t('UserClientList.OrganizationAdmin')}
                        </Typography>
                    </div>
                </div>
            </div>
        )
    }

    const handleAdminForms = () => {
        return (
            <Fragment>
                {displayClientType()}
                {clientType === 'general' && <CreateGeneralAdmin />}
                {clientType === 'coach' && <CreateCoach />}
                {clientType === 'organization_admin' && (
                    <CreateOrganizationAdmin />
                )}
            </Fragment>
        )
    }

    return (
        <ModalContainer isOpen={isOpen} closeButton={false}>
            <div className="flex flex-col h-full  w-full lg:px-10 justify-center items-center md:py-4">
                <div className="w-full pb-4 justify-center">
                    <Typography alignment="center">
                        {t('UserClientList.CreateProfile')}
                    </Typography>
                </div>
                {currentClientType === 'admin' && handleAdminForms()}
                {currentClientType !== 'admin' && <CreateBasicUserClient />}
            </div>
        </ModalContainer>
    )
}
