import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Typography from 'view/component/Typography/Typography'
import { useDispatch, useSelector } from 'react-redux'
import FallBack from 'view/component/FallBack/FallBack'
import Button from 'view/component/Button/Button'
import { useHistory } from 'react-router-dom'
import HitIndicator from 'view/component/HitIndicator/HitIndicator'
import AdminFullScreenShare from 'view/layout/AdminFullScreenShare/AdminFullScreenShare'
import Bar from 'view/component/Chart/Bar/Bar'
import BraydenCore from '@innosonian/brayden-core'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'
import { IResultByCycle } from '@innosonian/brayden-core/build/src/entity/Result/Result.interface'
import { IIterableObject } from '@innosonian/brayden-core/build/src/_interface/IterableObject.interface'
import { isBetaTester } from '@innosonian/brayden-core/build/src/auth'
import { CardTrainingType } from '@innosonian/brayden-core/build/src/entity/Card/Card.interface'

export default function Result() {
    const history = useHistory()
    const { t } = useTranslation('locale')
    const clientID = useSelector(
        BraydenCore.Entity.Client.ClientSelector.getGeneralClientId
    )
    const card = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Card.CardSelector.getCardById(
            state,
            BraydenCore.Entity.Session.SessionSelector.getCurrentTrainingCardId(
                state
            )
        )
    )
    const resultId = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Result.ResultSelector.getResultId(state)
    )
    const record = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Record.RecordSelector.getRecordById(state, resultId)
    )
    const getResultState = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Error.ErrorSelector.getApiState(
            state,
            '@RESULT/GET_RESULT'
        )
    )
    const dispatch = useDispatch()
    const isClassroomConnected = useSelector(
        BraydenCore.Entity.Classroom.Selector.isConnected
    )

    const formatChartData = (resultCriteria: IResultByCycle) => {
        const result = [
            {
                type: t('OverallResultPage.ScoreOfCCF'),
                overAll: resultCriteria.ScoreOfCCF.Overall || 0.1,
            },
            {
                type: t('OverallResultPage.HandPosition'),
                overAll: resultCriteria.HandPosition.Overall || 0.1,
            },
            {
                type: t('OverallResultPage.NoOfCompressions'),
                overAll: resultCriteria.CompressionNo.Overall || 0.1,
            },
            {
                type: t('OverallResultPage.VentilationVolume'),
                overAll: resultCriteria.VentilationVolume.Overall || 0.1,
            },
            {
                type: t('OverallResultPage.VentilationFrequency'),
                overAll: resultCriteria.VentilationRate.Overall || 0.1,
            },
            {
                type: t('OverallResultPage.CompressionDepth'),
                overAll: resultCriteria.CompressionDepth.Overall || 0.1,
            },
            {
                type: t('OverallResultPage.CompressionRelease'),
                overAll: resultCriteria.Recoil.Overall || 0.1,
            },
            {
                type: t('OverallResultPage.CompressionRate'),
                overAll: resultCriteria.CompressionRate.Overall || 0.1,
            },
        ]
        return result
    }

    useEffect(() => {
        if (
            card !== undefined &&
            card.certification === true &&
            record.ResultSummary &&
            record.ResultSummary.JudgResult === 'Pass'
        ) {
            dispatch(
                BraydenCore.Entity.Modal.ModalAction.modalOpenCertification()
            )
        }
    })

    if (card === undefined) {
        history.push('/home')
    }

    if (getResultState === 'REQUEST') {
        return <FallBack />
    }

    const chartData = formatChartData(record.ResultByCycle)

    const handleOverallResultColor = () => {
        if (
            record.ResultSummary &&
            record.ResultSummary.JudgResult === 'Pass'
        ) {
            return 'green-600 dark:text-green-light-default-button'
        }
        return 'orange-800 dark:text-orange-light-default-button'
    }

    return (
        <AdminFullScreenShare
            isUserWaiting={true}
            showSideCameraView={false}
            showImageSlider={false}
            className="w-full h-full flex flex-col"
        >
            <div className="w-full h-2/6 flex flex-col flex-wrap md:flex-row items-center xl:items-stretch lg:flex-nowrap md:justify-between gap-5 xl:p-0 mb-4">
                <Typography
                    type="h2"
                    className="w-full pb-5 lg:hidden"
                    color="white dark:text-gray-1000"
                    alignment="center"
                >
                    {t('OverallResultPage.Title')}
                </Typography>
                <div className="flex p-4 lg:h-full  flex-row lg:flex-col w-full lg:w-1/6 justify-start lg:p-5 border border-gray-100">
                    <div className="flex flex-col justify-center items-center ">
                        <Typography
                            type="h3"
                            className="mr-2 md:mr-0"
                            alignment="center"
                        >
                            {t('OverallResultPage.Score')}
                        </Typography>
                    </div>

                    <Typography
                        type="h"
                        alignment="center"
                        color={handleOverallResultColor()}
                    >
                        {record.ResultByCycle.ScoreByCycle.Overall}
                    </Typography>
                </div>

                <div className="flex w-full h-full flex-col p-4 border border-gray-100">
                    <div className="w-full hidden lg:flex">
                        <Typography
                            type="h2"
                            className="w-full pb-5"
                            color="white dark:text-gray-1000"
                            alignment="center"
                        >
                            {t('OverallResultPage.Title')}
                        </Typography>
                    </div>
                    {record.Guide_prompts.map((guide) => (
                        <Typography type="h3" key={guide} className="pb-2">
                            {guide}
                        </Typography>
                    ))}
                </div>
                <div
                    className={
                        'flex h-full flex-col w-full items-center lg:w-3/6 xl:w-2/6 gap-4'
                    }
                >
                    <Button
                        disable={isClassroomConnected}
                        onclick={() => {
                            history.push('/trainings')
                        }}
                        size="full"
                        color="blue"
                        shape="rounded-mid"
                        className="mx-auto lg:mx-none flex flex-grow"
                    >
                        {t('WebOverwrite.GoTrainingList')}
                    </Button>
                    <Button
                        disable={isClassroomConnected}
                        className="mx-auto mx-auto lg:mx-none flex flex-grow"
                        onclick={() => {
                            history.push(`/records/${resultId}`)
                        }}
                        size="full"
                        shape="rounded-mid"
                        color="secondary"
                    >
                        {t('WebOverwrite.DetailResultLink')}
                    </Button>
                    {isBetaTester(clientID) === true &&
                        card.type === CardTrainingType.CPR_TRAINING && (
                            <Button
                                disable={isClassroomConnected}
                                onclick={() => {
                                    history.push(
                                        `/records/${resultId}/cpr-quality`
                                    )
                                }}
                                color="warning"
                                size="full"
                                shape="rounded-mid"
                                className="mx-auto lg:mx-none mx-auto lg:mx-none flex flex-grow"
                            >
                                {t('Record.Navigation.CprQuality')}
                            </Button>
                        )}

                    <Button
                        disable={isClassroomConnected}
                        onclick={() => {
                            history.push(`/trainings/${card.id}`)
                        }}
                        size="full"
                        color="danger"
                        className="mx-auto lg:mx-none mx-auto lg:mx-none flex flex-grow"
                        shape="rounded-mid"
                    >
                        {t('OverallResultPage.Fail')}
                    </Button>
                </div>
            </div>
            <div className="flex lg:h-3/5  xl:h-4/6 h-full flex-col flex-grow lg:flex-row">
                <div
                    className={`flex w-full border border-gray-100 p-5 mb-6 lg:mb-0 lg:w-3/5 lg:mr-8`}
                >
                    <Bar
                        stack="horizontal"
                        data={chartData as IIterableObject[]}
                        index="type"
                        keys={['overAll']}
                        displayLegend={true}
                    />
                </div>
                <div
                    className={`flex w-full lg:h-auto border border-gray-100 p-5 md:mb-0 lg:w-2/5`}
                >
                    <HitIndicator active={true} globalView={true} />
                </div>
            </div>
        </AdminFullScreenShare>
    )
}
