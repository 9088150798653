import { useEffect, useRef } from 'react'
import isEqual from 'lodash/isEqual'

function deepCompare<T>(a: T, b: T) {
    return isEqual(a, b)
}

function useCompareMemoize<T>(value: T) {
    const ref = useRef<T>()
    if (!deepCompare(ref.current, value)) {
        ref.current = value
    }
    return ref.current
}
export default function useDeepCompareEffect(
    callback: () => void,
    dependencies: any[]
) {
    // eslint-disable-next-line
    useEffect(callback, dependencies.map(useCompareMemoize))
}
