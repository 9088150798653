import React from 'react'
import { IProps } from './TrainingCardItem.type'
import { useSelector } from 'react-redux'
import BraydenCore from '@innosonian/brayden-core'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'
import Typography from '../Typography/Typography'
import { motion, AnimatePresence } from 'framer-motion'
import { CardTrainingType } from '@innosonian/brayden-core/build/src/entity/Card/Card.interface'
import { ReactComponent as CompressionIcon } from 'resource/img/icons/comp-icon.svg'
import { ReactComponent as VentilationIcon } from 'resource/img/icons/lungs.svg'
import { ReactComponent as CprIcon } from 'resource/img/icons/cpr-icon.svg'
import { ReactComponent as AssessmentIcon } from 'resource/img/icons/assessment-icon.svg'
import { ReactComponent as GraphIcon } from 'resource/img/icons/graph.svg'
import { ReactComponent as TimerIcon } from 'resource/img/icons/timer.svg'
import { ReactComponent as AutoStopIcon } from 'resource/img/icons/auto-stop.svg'
import { ReactComponent as CertIcon } from 'resource/img/icons/cert.svg'
import Button from '../Button/Button'
import { useTranslation } from 'react-i18next'

export default function TrainingCardItemConfig(props: IProps) {
    const { t } = useTranslation('locale')
    const card = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Card.CardSelector.getCardById(state, props.id)
    )

    function handleTrainingIcon(type: string) {
        switch (type) {
            case CardTrainingType.VENTILATION_ONLY:
                return <VentilationIcon className="text-gray-50 h-10 w-10" />
            case CardTrainingType.CHEST_COMP_ONLY:
                return <CompressionIcon className="text-gray-50 h-10 w-10" />
            case CardTrainingType.CPR_TRAINING:
                return <CprIcon className="text-gray-50 h-10 w-10" />
            default:
                return <AssessmentIcon className="text-gray-50 h-10 w-10" />
        }
    }

    function handleFeedBackType(feedback: string) {
        if (feedback === 'graph') {
            return <GraphIcon className="text-gray-50 h-10 w-10" />
        } else {
            return <TimerIcon className="text-gray-50 h-10 w-10" />
        }
    }

    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.25 }}
                className={` opacity-0 w-full flex flex-col p-4 shadow-dark cursor-pointer rounded-lg`}
            >
                <div className="w-full items-center mb-4">
                    <Typography alignment="center">{card.cardTitle}</Typography>
                </div>
                <div className="w-full flex justify-around border-t border-gray-50 dark:border-gray-light-border py-4">
                    {handleTrainingIcon(card.type)}
                    {handleFeedBackType(card.feedback)}
                    {card.autoStop === true && (
                        <AutoStopIcon className="text-gray-50 h-10 w-10" />
                    )}
                </div>
                <div className="w-full flex justify-around border-t border-gray-50 dark:border-gray-light-border pt-4 items-center">
                    <Typography>{card.guideLine}</Typography>
                    <Typography>{card.compVentRatio}</Typography>
                    <CertIcon
                        className={`h-10 w-10 ${
                            card.certification === false
                                ? 'opacity-50 text-red-700 '
                                : 'text-gray-50 '
                        }`}
                    />
                </div>
                {props.isUpdate === false && (
                    <div className="w-full flex justify-around pt-4">
                        <Button
                            disable={false}
                            noShadow={true}
                            type="outline"
                            shape="rounded-mid"
                            padding="xs"
                            color="blue"
                            className="mr-2"
                            onclick={() => props.onUpdateClick(props.id)}
                        >
                            {t('Config.Update')}
                        </Button>
                        <Button
                            disable={false}
                            type="outline"
                            noShadow={true}
                            shape="rounded-mid"
                            padding="xs"
                            color="danger"
                            onclick={() =>
                                props.onDeleteOrCancelClick(props.id)
                            }
                        >
                            {t('Config.Delete')}
                        </Button>
                    </div>
                )}
                {props.isUpdate === true && (
                    <div className="w-full flex justify-around pt-4">
                        <Button
                            disable={false}
                            type="outline"
                            noShadow={true}
                            shape="rounded-mid"
                            padding="xs"
                            color="danger"
                            onclick={() =>
                                props.onDeleteOrCancelClick(props.id)
                            }
                        >
                            {t('Config.Cancel')}
                        </Button>
                    </div>
                )}
            </motion.div>
        </AnimatePresence>
    )
}
