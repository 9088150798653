import React, { useState } from 'react'
import FadeInContainer from 'view/layout/FadeInContainer/FadeInContainer'
import { useDispatch, useSelector } from 'react-redux'
import BraydenCore from '@innosonian/brayden-core'
import { ModelCourseType } from '@innosonian/brayden-core/build/src/entity/Session/Session.interface'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'
import ManikinCarousel from 'view/component/ManikinCarousel/ManikinCarousel'
import TrainingCardItemConfig from 'view/component/TrainingCardItem/TrainingCardItemConfig'
import { ReactComponent as AddIcon } from 'resource/img/icons/add.svg'
import { motion } from 'framer-motion'
import UpdateCard from 'view/component/UpdateCard/UpdateCard'

export default function Configuration() {
    const dispatch = useDispatch()
    const [currentTrainCourse, setCurrentTrainCourse] = useState<
        ModelCourseType
    >('Adult')
    const trainCourse = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.TrainCourse.TrainCourseSelector.getTrainCourseById(
            state,
            currentTrainCourse
        )
    )
    const [selectedCardId, setSelectedCardId] = useState<undefined | string>(
        undefined
    )
    return (
        <FadeInContainer className="w-full h-full flex flex-col xl:w-2/3 mx-auto">
            <ManikinCarousel
                allManikin={true}
                isConnectable={false}
                onIdxUpdate={(e) => setCurrentTrainCourse(e)}
                defaultValue="Adult"
            />
            <div className="w-full flex flex-wrap justify-center items-center">
                {selectedCardId === undefined &&
                    trainCourse.cards.map((cardId) => (
                        <div className="w-full md:w-1/3 p-2 mb-4" key={cardId}>
                            <TrainingCardItemConfig
                                isUpdate={false}
                                id={cardId}
                                onUpdateClick={(e) => setSelectedCardId(e)}
                                onDeleteOrCancelClick={() =>
                                    dispatch(
                                        BraydenCore.Entity.Card.CardAction.deleteCardRequest(
                                            cardId,
                                            currentTrainCourse
                                        )
                                    )
                                }
                            />
                        </div>
                    ))}
                {selectedCardId === undefined &&
                    currentTrainCourse !== undefined &&
                    trainCourse.cards.length < 6 && (
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.25 }}
                            className="opacity-0 w-1/3 flex justify-center items-center"
                        >
                            <AddIcon
                                className="h-40 w-40 text-gray-50 cursor-pointer"
                                onClick={() =>
                                    dispatch(
                                        BraydenCore.Entity.Modal.ModalAction.modalOpenCreateCard(
                                            currentTrainCourse
                                        )
                                    )
                                }
                            />
                        </motion.div>
                    )}
            </div>
            {selectedCardId !== undefined && (
                <div className="w-full self-stretch flex items-stretch">
                    <UpdateCard
                        trainingType={currentTrainCourse}
                        id={selectedCardId}
                        onClose={() => setSelectedCardId(undefined)}
                    />
                </div>
            )}
        </FadeInContainer>
    )
}
