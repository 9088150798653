import React from 'react'
import Typography from 'view/component/Typography/Typography'
import { IProps } from './Item.type'

export default function Item(props: IProps) {
    return (
        <div
            className="w-full flex py-3 px-4 bg-gray-800 border-t-2 border-gray-900 dark:border-gray-light-border dark:bg-gray-light-top-bar cursor-pointer"
            onClick={(e) => props.onClick(e)}
        >
            <Typography type="p" className="w-full" alignment="center">
                {props.text}
            </Typography>
        </div>
    )
}
