import React, { useEffect } from 'react'
import { useLocation, useRouteMatch } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import hook from 'hook'
import TopBar from 'view/component/TopBar/TopBar'
import Typography from 'view/component/Typography/Typography'
import BraydenCore from '@innosonian/brayden-core'
import AdminTopBar from 'view/component/TopBar/AdminTopBar'
import AdminFullScreenShare from '../AdminFullScreenShare/AdminFullScreenShare'
import { version } from '@innosonian/brayden-core/package.json'
import { version as webVersion } from '../../../../package.json'
import { IProps } from './MainLayout.type'
import Classroom from 'core/classroom'

export default function MainLayout(props: React.PropsWithChildren<IProps>) {
    const location = useLocation()
    let recordsPage = useRouteMatch('/records/:id')
    const { t } = useTranslation('locale')
    const isClassroomConnected = useSelector(
        BraydenCore.Entity.Classroom.Selector.isConnected
    )
    const isAdmin = useSelector(
        BraydenCore.Entity.Client.ClientSelector.getIsAdmin
    )

    const {
        audioForceOff,
        undoAudioForceOff,
        prevAudioStatus,
        savePrevAudioStatus,
    } = hook.useForceMute()

    useEffect(() => {
        if (audioForceOff) {
            savePrevAudioStatus()
            Classroom.Video.muteLocalMicro()
            toast(t('You are muted'), { type: 'error', autoClose: 2000 })
        }
        if (undoAudioForceOff) {
            if (prevAudioStatus) {
                Classroom.Video.unMuteLocalMicro()
            }
            return
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [audioForceOff, undoAudioForceOff])

    const handleMargin = () => {
        if (location.pathname === '/setup') {
            return 'px-8 lg:px-10'
        }
        if (location.pathname === '/') {
            return ''
        } else {
            return 'px-8 pt-8 xl:px-10'
        }
    }

    return (
        <div className="w-full h-screen bg-gray-900 dark:bg-gray-light-bg text-white overflow-auto">
            <div className="flex flex-col mx-auto h-full flex-grow">
                {isAdmin === true && <AdminTopBar />}
                {isAdmin === false && <TopBar />}
                {location.pathname === '/result' || recordsPage ? (
                    <AdminFullScreenShare
                        showSideCameraView={isClassroomConnected}
                        showImageSlider={false}
                        isUserWaiting={false}
                        marginSize="small"
                        containerSize="large"
                        className="h-full w-full "
                    >
                        {props.children}
                    </AdminFullScreenShare>
                ) : (
                    <div className={`${handleMargin()} flex-grow-1`}>
                        {props.children}
                    </div>
                )}
                <div className="w-full py-2 flex-shrink">
                    <Typography color="gray-200" alignment="center">
                        © Copyright 2020 Innosonian, Inc. All Rights Reserved{' '}
                        version core-{version} web-{webVersion}
                    </Typography>
                </div>
            </div>
        </div>
    )
}
