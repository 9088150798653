import React from 'react'
import Typography from '../Typography/Typography'
import { IProps } from './Card.type'

export default function Card(props: React.PropsWithChildren<IProps>) {
    const handleRounded = () => {
        switch (props.rounded) {
            case 'none':
                return ''
            case 'md':
                return 'rounded'
            case 'lg':
                return 'rounded-lg'
            default:
                return 'rounded-lg'
        }
    }

    const handleShadow = () => {
        switch (props.shadow) {
            case 'sm':
                return 'shadow'
            case 'md':
                return 'shadow-md'
            case 'lg':
                return 'shadow-lg'
            case 'xl':
                return 'shadow-xl'
            default:
                return 'shadow-lg'
        }
    }

    const handleItemAlign = () => {
        switch (props.items) {
            case 'center':
                return 'items-center'
            case 'left':
                return 'items-left'
            case 'right':
                return 'items-right'
            default:
                return 'items-center'
        }
    }

    const handleTitle = () => {
        if (props.title !== undefined) {
            return (
                <div className="w-full p-3 bg-gray-800 rounded-t-lg">
                    <Typography type="h3" color="white">
                        {props.title}
                    </Typography>
                </div>
            )
        }
        return <span className="hidden"></span>
    }

    const handleWidth = () => {
        return props.width !== undefined ? props.width : 'w-full'
    }

    const handlePadding = () => {
        return props.padding === true ? 'p-5' : ''
    }

    return (
        <div
            className={`my-3 flex flex-col ${handleRounded()} ${handleShadow()} ${handleWidth()}`}
        >
            {handleTitle()}
            <div
                className={`flex flex-wrap ${handlePadding()} justify-around ${handleItemAlign()}`}
            >
                {props.children}
            </div>
        </div>
    )
}
