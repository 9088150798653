import BraydenCore from '@innosonian/brayden-core'
import { RTData } from '@innosonian/brayden-core/build/src/_bluetooth/bluetooth.interface'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'
import hook from 'hook'
import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import Typography from '../Typography/Typography'

function getPreviousCcnt(
    lastChestCompression: Uint8Array | undefined,
    pos: number
) {
    if (lastChestCompression === undefined) {
        return -1
    }
    return lastChestCompression[pos] - 1
}

export default function GuideText() {
    const { t } = useTranslation('locale')
    const [hasEventIndication, setHasEventIndication] = useState<boolean>(false)

    const calibrationResume = useSelector(
        BraydenCore.Entity.Calibration.CalibrationSelector.getCalibrationResume
    )
    const isSessionStart = useSelector(
        BraydenCore.Entity.Session.SessionSelector.getSessionStart
    )
    const lastDataPushed = useSelector(
        BraydenCore.Entity.Session.SessionSelector.getLatestDataPushed
    )
    const lastCompressionList = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Session.SessionSelector.getLastCompressionList(
            state,
            getPreviousCcnt(lastDataPushed, RTData.C_CNT),
            lastDataPushed !== undefined ? lastDataPushed[RTData.CYCLE] : 0
        )
    )
    const getGuidelineEvent = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Session.SessionSelector.getGuideline(state)
    )

    const card = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Card.CardSelector.getCardById(
            state,
            BraydenCore.Entity.Session.SessionSelector.getCurrentTrainingCardId(
                state
            )
        )
    )
    const manikin = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Hardware.HardwareSelector.getConnectedHardwareById(
            state,
            BraydenCore.Entity.Session.SessionSelector.getSelectedManikin(state)
        )
    )
    const lastVentilationList = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Session.SessionSelector.getLastVentilationList(
            state,
            lastDataPushed !== undefined ? lastDataPushed[RTData.R_CNT] - 1 : 0
        )
    )
    const [msg, setMsg] = useState({
        text: 'hands_off',
        color: 'orange-800',
    })

    hook.useDeepCompareEffect(() => {
        setHasEventIndication(false)
        setMsg(
            BraydenCore.Calculation.FeedbackManagement.getMsg(
                BraydenCore.Calculation.RatioCalculation.CurrentAction
                    .COMPRESSION,
                lastCompressionList,
                calibrationResume,
                card,
                manikin.model
            )
        )
    }, [calibrationResume, lastCompressionList])

    hook.useDeepCompareEffect(() => {
        setHasEventIndication(false)
        setMsg(
            BraydenCore.Calculation.FeedbackManagement.getMsg(
                BraydenCore.Calculation.RatioCalculation.CurrentAction
                    .VENTILATION,
                lastVentilationList,
                calibrationResume,
                card,
                manikin.model
            )
        )
    }, [calibrationResume, lastVentilationList])

    useEffect(() => {
        setHasEventIndication(false)
        if (getGuidelineEvent) {
            setHasEventIndication(true)
            setMsg({ text: getGuidelineEvent, color: 'orange-800' })
        }
    }, [getGuidelineEvent])

    return (
        <div className="w-full h-20 flex border border-gray-100">
            <Typography
                type="h2"
                className="w-full flex justify-center items-center"
                alignment="center"
                color={msg.color}
            >
                {isSessionStart === false &&
                    !hasEventIndication &&
                    t('RealTimeTrainingPage.StartAssesement')}
                {isSessionStart === true &&
                    !hasEventIndication &&
                    t(`RealtimeFeedBackMessage.${msg.text}`)}
                {hasEventIndication === true && t(msg.text)}
            </Typography>
        </div>
    )
}
