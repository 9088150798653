import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import Typography from 'view/component/Typography/Typography'

export default function Forbidden() {
    const { t } = useTranslation('locale')
    return (
        <div className="w-full h-full flex justify-center items-center">
            <div className="flex flex-col w-full p-5 flex justify-center items-center">
                <Typography type="h1" weight="bold" className="">
                    {t('HttpError.Forbidden')}
                </Typography>
                <Typography
                    type="p"
                    weight="light"
                    className="cursor-pointer hover:text-blue-800 underline"
                >
                    <Link to="/">{t('HttpError.Redirect')}</Link>
                </Typography>
            </div>
        </div>
    )
}
