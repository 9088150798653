import React, { useState } from 'react'
import { ResponsiveRadar } from '@nivo/radar'
import { IProps } from './Radar.type'
import hook from 'hook'
import useDimensions from 'react-cool-dimensions'

function handleDotColor(
    dot: {
        index: string
        key: string
        value: number
        color: string
    },
    theme: string
) {
    if (dot.value < 60) {
        return theme === 'light' ? '#892222' : '#D65A5A'
    } else if (dot.value >= 60 && dot.value < 80) {
        return theme === 'light' ? '#892222' : '#D65A5A'
    } else if (dot.value >= 80 && dot.value < 90) {
        return theme === 'light' ? '#E38E47' : '#FEB089'
    } else {
        return theme === 'light' ? '#9DE800' : '#91BF7D'
    }
}

export default function RadarChart(props: IProps) {
    const [radarDim, setRadarDim] = useState([0, 0])
    const containerRef = useDimensions({
        onResize: ({ observe, unobserve, width }) => {
            setRadarDim([width, width / 2])
            unobserve()
            observe()
        },
    })
    const [colorTheme] = hook.useDarkMode()
    const theme = {
        // background: '#ffffff',
        textColor: colorTheme === 'light' ? 'white' : '#727272',
        fontSize: 14,
        tooltip: {
            container: {
                background: 'white',
                color: '#2B2E36',
                fontSize: 'inherit',
                borderRadius: '2px',
                boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
                padding: '5px 9px',
            },
        },
        axis: {
            domain: {
                line: {
                    stroke: '#777777',
                    strokeWidth: 1,
                },
            },
            ticks: {
                line: {
                    stroke: '#777777',
                    strokeWidth: 1,
                },
            },
        },
        grid: {
            line: {
                stroke: '#dddddd',
                strokeWidth: 1,
            },
        },
    }

    return (
        <div className="flex w-full" ref={containerRef.observe}>
            <div
                className="w-full"
                style={{
                    height: `${radarDim[1]}px`,
                }}
            >
                <ResponsiveRadar
                    data={props.data}
                    keys={props.keys}
                    indexBy={props.index}
                    maxValue="auto"
                    margin={{ top: 70, right: 80, bottom: 50, left: 80 }}
                    curve="linearClosed"
                    borderWidth={2}
                    borderColor={{ from: 'color' }}
                    gridLevels={5}
                    gridShape="circular"
                    gridLabelOffset={36}
                    enableDots={true}
                    dotSize={10}
                    dotColor={(dot: any) => {
                        return handleDotColor(dot, colorTheme)
                    }}
                    dotBorderWidth={2}
                    dotBorderColor={{ from: 'color' }}
                    enableDotLabel={true}
                    dotLabel="value"
                    dotLabelYOffset={-12}
                    colors={['#ababab']}
                    fillOpacity={0.5}
                    blendMode="normal"
                    animate={true}
                    isInteractive={true}
                    motionDamping={10}
                    motionStiffness={10}
                    theme={theme}
                    legends={
                        props.displayLegend === true
                            ? [
                                  {
                                      anchor: 'top-left',
                                      direction: 'column',
                                      translateX: -50,
                                      translateY: -40,
                                      itemWidth: 80,
                                      itemHeight: 20,
                                      symbolSize: 12,
                                      symbolShape: 'circle',
                                      itemTextColor: '#718096',
                                  },
                              ]
                            : []
                    }
                />
            </div>
        </div>
    )
}
