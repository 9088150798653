import React from 'react'
import Typography from 'view/component/Typography/Typography'
import { IProps } from './TableContent.type'

export default function TableContent(props: IProps) {
    const handleBorder = (border: boolean) =>
        border === true
            ? 'border-b-2 md:border-b-0 md:border-r-2 border-gray-600 dark:border-gray-light-border'
            : ''

    return (
        <div className="w-full flex flex-col  md:flex-row">
            {(props.disable === undefined || props.disable === false) &&
                props.items.map((item) => (
                    <div
                        className={`flex flex-col w-full py-2 justify-around ${handleBorder(
                            item.border
                        )}`}
                        key={item.title ? item.title.value : item.value}
                    >
                        <div className="w-full">
                            {item.title !== undefined && (
                                <Typography
                                    type="p"
                                    color={item.title.color}
                                    alignment="center"
                                >
                                    {item.title.value}
                                </Typography>
                            )}
                        </div>
                        <div className="w-full">
                            <Typography
                                type={item.xl ? 'h2' : 'h3'}
                                alignment="center"
                            >
                                {item.value}
                            </Typography>
                        </div>
                    </div>
                ))}
        </div>
    )
}
