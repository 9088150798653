import { useSelector } from 'react-redux'
import { IProps } from './ManikinRequired.type'
import { Redirect, Route } from 'react-router-dom'
import React from 'react'
import BraydenCore from '@innosonian/brayden-core'

export default function ManikinRequiredRoute({
    component: Component,
    ...rest
}: IProps) {
    const connectedHardwareList = useSelector(
        BraydenCore.Entity.Hardware.HardwareSelector.getConnectedHardwareIdList
    )
    if (connectedHardwareList.length > 0) {
        return <Route {...rest} render={(props) => <Component {...props} />} />
    }
    if (BraydenCore.Authentication.isAuthenticated() === true) {
        return <Redirect to="/home" />
    }
    return <Redirect to="/" />
}
