import React, { PropsWithChildren, useRef, useState } from 'react'
import { IProps } from './FloatableContainer.type'
import { ReactComponent as FullScreen } from 'resource/img/icons/full-screen.svg'

export default function FloatableContainer(props: PropsWithChildren<IProps>) {
    const containerRef = useRef<HTMLDivElement>(null)
    const [isOnDrag, setIsOnDrag] = useState(false)
    const [x, setX] = useState(props.baseLeft)
    const [y, setY] = useState(props.baseTop)
    const [mouseX, setMouseX] = useState(0)
    const [mouseY, setMouseY] = useState(0)
    const [isFullScreen, setIsFullScreen] = useState(false)

    const handleMove = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (
            containerRef.current !== null &&
            isOnDrag === true &&
            e.clientX !== mouseX &&
            e.clientY !== mouseY
        ) {
            const newX = x + (e.clientX - mouseX)
            const newY = y + (e.clientY - mouseY)
            setX(newX)
            setY(newY)
            setMouseX(e.clientX)
            setMouseY(e.clientY)
        }
    }

    const mouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        setMouseX(e.clientX)
        setMouseY(e.clientY)
        setIsOnDrag(true)
    }

    return (
        <div
            ref={containerRef}
            style={{
                width: isFullScreen === true ? `100%` : `${props.width}px`,
                height: isFullScreen === true ? `100%` : `${props.height}px`,
                zIndex: isFullScreen === true ? 999 : 1000,
                top: `${isFullScreen === true ? 0 : y}px`,
                left: `${isFullScreen === true ? 0 : x}px`,
            }}
            onMouseDown={mouseDown}
            onMouseUp={() => setIsOnDrag(false)}
            onMouseLeave={() => setIsOnDrag(false)}
            onMouseMove={(e) => handleMove(e)}
            className={`absolute bg-gray-900 cursor-pointer
            ${props.className ? props.className : ''}`}
        >
            <div className="w-full h-full flex flex-col relative">
                <FullScreen
                    className={`h-6 w-6 absolute z-50 ${
                        isFullScreen === true ? 'transform rotate-180' : ''
                    }`}
                    style={{ right: 0 }}
                    onClick={() => setIsFullScreen((f) => !f)}
                ></FullScreen>
                {props.children}
            </div>
        </div>
    )
}
