import React, { useEffect, useRef, useState } from 'react'
import { IProps } from './ClassroomCamera.type'
import { useSelector } from 'react-redux'
import useDimensions from 'react-cool-dimensions'
import BraydenCore from '@innosonian/brayden-core'
import Classroom from 'core/classroom'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'
import { ReactComponent as Mic } from 'resource/img/icons/microphone.svg'
import { ReactComponent as MicMuted } from 'resource/img/icons/muted.svg'
import { ReactComponent as UserCamera } from 'resource/img/icons/userCamera.svg'
import { ReactComponent as ScreenShare } from 'resource/img/icons/screen-share.svg'

export default function ClassroomCamera(props: IProps) {
    const videoRef = useRef<HTMLDivElement>(null)
    const [height, setHeight] = useState(0)
    const userVideoDetail = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Classroom.Selector.getUserById(state, props.userId)
    )

    const ref = useDimensions({
        onResize: ({ observe, unobserve, width }) => {
            setHeight((width / 3) * 3)
            unobserve()
            observe()
        },
    })

    useEffect(() => {
        if (
            props.type === 'Camera' &&
            videoRef.current !== null &&
            userVideoDetail.videoEnabled === true
        ) {
            Classroom.Video.getVideoTrackByUid(props.userId).play(
                videoRef.current,
                {
                    fit: 'contain',
                }
            )
        }
    }, [userVideoDetail.videoEnabled, props.userId, props.size, props.type])

    useEffect(() => {
        if (
            props.type === 'Screen' &&
            videoRef.current !== null &&
            userVideoDetail.screenEnabled === true
        ) {
            Classroom.Video.getVideoTrackByUid(`screen_${props.userId}`).play(
                videoRef.current,
                {
                    fit: 'contain',
                }
            )
        }
    }, [userVideoDetail.screenEnabled, props.userId, props.size, props.type])

    const handleWidth = () => {
        switch (props.size) {
            case 'sm':
                return ''
            default:
                return 'w-full'
        }
    }
    const handlePadding = () => {
        switch (props.size) {
            case 'full':
                return 'p-3'
            default:
                return 'p-0'
        }
    }
    const handleDefaultVideoBg = () =>
        userVideoDetail.videoEnabled === false ? 'bg-black' : ''
    const handleStyle = () => {
        if (props.size === 'sm') {
            return {
                height: `${height}px`,
                width: `200px`,
            }
        }
        if (props.size === 'full') {
            return {
                height: `100%`,
                width: `100%`,
            }
        }
        return { height: `100%`, width: `100%` }
    }
    const handleDisplayCamera = () => {
        return (
            <div
                ref={videoRef}
                className={`w-full h-full ${handleDefaultVideoBg()} relative flex items-center`}
                style={handleStyle()}
            >
                <div
                    className={`${
                        props.size === 'md' ? 'w-8 h-8' : 'w-6 h-6'
                    } absolute bottom-0 mb-2 ml-2 z-40`}
                >
                    {userVideoDetail.soundEnabled === false ? (
                        <MicMuted className="h-full w-full" />
                    ) : (
                        <Mic className="h-full w-full text-green-800" />
                    )}
                </div>

                {userVideoDetail.videoEnabled === false &&
                    props.type === 'Camera' && (
                        <UserCamera
                            className={`text-gray-100 ${
                                props.size === 'md' ? 'w-20 h-20' : 'w-10 h-10'
                            } mx-auto`}
                        />
                    )}
                {userVideoDetail.screenEnabled === false &&
                    props.type === 'Screen' && (
                        <ScreenShare
                            className={`text-gray-100 ${
                                props.size === 'md' ? 'w-20 h-20' : 'w-10 h-10'
                            } mx-auto`}
                        />
                    )}
            </div>
        )
    }

    return (
        <div
            className={`flex flex-col md:flex-row h-full ${handleWidth()} ${handlePadding()}  border border-gray-100`}
            ref={ref.observe}
        >
            {handleDisplayCamera()}
        </div>
    )
}
