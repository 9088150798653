import AgoraRTC, {
    IAgoraRTCClient,
    IAgoraRTCRemoteUser,
    ILocalAudioTrack,
    ILocalVideoTrack,
    IRemoteAudioTrack,
    IRemoteVideoTrack,
    NetworkQuality,
    UID,
} from 'agora-rtc-sdk-ng'
import * as Error from './error'
import Config from './config'
import BraydenCore from '@innosonian/brayden-core'
import { removeLeaveClassroomTimeout, setLeaveClassroomTimeout } from '.'
import { LOCAL_STORAGE_KEY } from '@innosonian/brayden-core/build/src/_localStorage/localStorage.interface'

let client: IAgoraRTCClient | undefined = undefined
let localMicro: ILocalAudioTrack | undefined = undefined
let localVideo: ILocalVideoTrack | undefined = undefined
let videoStreamList: { uid: UID; track: IRemoteVideoTrack }[] = []
let audioStreamList: { uid: UID; track: IRemoteAudioTrack }[] = []
let isClientAlreadyInit = false
let myNetworkQuality = ''

function stopLocalMicroAndAudio() {
    if (localVideo !== undefined && localMicro !== undefined) {
        localMicro.close()
        localVideo.close()
        localVideo = undefined
        localMicro = undefined
        BraydenCore.Reducer.store.dispatch(
            BraydenCore.Entity.Classroom.Action.connectCamera(false)
        )
    }
}

async function createLocalMicroAndAudio(userId: string) {
    localMicro = await AgoraRTC.createMicrophoneAudioTrack()
    localVideo = await AgoraRTC.createCameraVideoTrack({
        encoderConfig: {
            width: 640,
            height: { ideal: 480, min: 400, max: 500 },
            frameRate: 15,
            bitrateMin: 600,
            bitrateMax: 1000,
        },
    })
    handleAudioDispatch(userId)
}

function createClient() {
    console.error('CREATE CLIENT')
    client = AgoraRTC.createClient({ mode: 'rtc', codec: 'vp8' })
}

async function join(className: string, uid: string) {
    if (client === undefined) {
        throw Error.CLIENT_NOT_CREATED
    }
    if (localVideo === undefined || localMicro === undefined) {
        throw Error.MICRO_OR_VIDEO_NOT_INITIALIZED
    }
    const token = await Config.generateToken(
        `${process.env.REACT_APP_BASE_URL}/api/new/classroom/rtc/token`,
        className,
        uid
    )
    await client.join(Config.options.appId, className, token, uid)
    await client.publish([localVideo, localMicro])
}

async function leave() {
    if (client !== undefined) {
        // stopLocalMicroAndAudio()
        await client.leave()
        videoStreamList.splice(0, videoStreamList.length)
        audioStreamList.splice(0, audioStreamList.length)
    } else {
        throw Error.CLIENT_NOT_CREATED
    }
}
function handleAudioDispatch(userId: string) {
    const clientID = BraydenCore.localStorageManager
        .getProvider()
        .getItem(LOCAL_STORAGE_KEY.CLIENT_ID)
    if (clientID !== null && clientID === userId) {
        BraydenCore.Reducer.store.dispatch(
            BraydenCore.Entity.Classroom.Action.adminJoinAudio(userId)
        )
    } else {
        BraydenCore.Reducer.store.dispatch(
            BraydenCore.Entity.Classroom.Action.userJoinAudio(userId)
        )
    }
}
function handleAudioSubscribe(user: IAgoraRTCRemoteUser) {
    if (user.audioTrack !== undefined) {
        user.audioTrack.play()
        user.audioTrack.setVolume(80)
        audioStreamList.push({ uid: user.uid, track: user.audioTrack })
        handleAudioDispatch(user.uid.toString())
    }
}

function handleVideoSubscribe(user: IAgoraRTCRemoteUser) {
    if (user.videoTrack !== undefined) {
        videoStreamList.push({ uid: user.uid, track: user.videoTrack })
        const clientID = BraydenCore.localStorageManager
            .getProvider()
            .getItem(LOCAL_STORAGE_KEY.CLIENT_ID)
        if (clientID !== null && clientID === user.uid) {
            BraydenCore.Reducer.store.dispatch(
                BraydenCore.Entity.Classroom.Action.adminJoinVideo(
                    user.uid.toString()
                )
            )
            removeLeaveClassroomTimeout()
        } else {
            if (user.uid.toString() === `screen_${clientID}`) {
                BraydenCore.Reducer.store.dispatch(
                    BraydenCore.Entity.Classroom.Action.userJoinVideo(
                        user.uid.toString()
                    )
                )
            } else if (user.uid.toString().startsWith('screen_')) {
                BraydenCore.Reducer.store.dispatch(
                    BraydenCore.Entity.Classroom.Action.userJoinScreenShare(
                        user.uid.toString().replace('screen_', '')
                    )
                )
            } else {
                BraydenCore.Reducer.store.dispatch(
                    BraydenCore.Entity.Classroom.Action.userJoinVideo(
                        user.uid.toString()
                    )
                )
            }
        }
    }
}

function isMySharedScreen(user: IAgoraRTCRemoteUser) {
    const uid = localStorage.getItem(LOCAL_STORAGE_KEY.EMAIL) as string
    const myUid = user.uid
        .toString()
        .substring(user.uid.toString().indexOf('_') + 1)
    return myUid === uid
}

async function userPublished(
    user: IAgoraRTCRemoteUser,
    mediaType: 'audio' | 'video'
) {
    if (isMySharedScreen(user)) {
        return
    }

    if (client !== undefined) {
        await client.subscribe(user, mediaType)
    }

    if (mediaType === 'video') {
        console.warn('VIDEO SUB')
        handleVideoSubscribe(user)
    }
    if (mediaType === 'audio') {
        console.warn('AUDIO SUB')
        handleAudioSubscribe(user)
    }
}

async function userLeft(user: IAgoraRTCRemoteUser) {
    console.error('WARNING USER REMOVED')
    audioStreamList = audioStreamList.filter((audio) => audio.uid !== user.uid)
    videoStreamList = videoStreamList.filter((video) => video.uid !== user.uid)
    const clientID = BraydenCore.localStorageManager
        .getProvider()
        .getItem(LOCAL_STORAGE_KEY.CLIENT_ID)
    if (clientID !== null && clientID === user.uid) {
        BraydenCore.Reducer.store.dispatch(
            BraydenCore.Entity.Classroom.Action.adminLeave(user.uid)
        )
        setLeaveClassroomTimeout()
    } else {
        if (user.uid.toString().includes('screen_') !== true) {
            BraydenCore.Reducer.store.dispatch(
                BraydenCore.Entity.Classroom.Action.userLeave(
                    user.uid.toString()
                )
            )
            BraydenCore.Reducer.store.dispatch(
                BraydenCore.Entity.Classroom.Action.userLeaveAudio(
                    user.uid.toString()
                )
            )
            BraydenCore.Reducer.store.dispatch(
                BraydenCore.Entity.Classroom.Action.userLeaveScreenShare(
                    user.uid.toString()
                )
            )
        } else {
            BraydenCore.Reducer.store.dispatch(
                BraydenCore.Entity.Classroom.Action.userLeaveScreenShare(
                    user.uid.toString().replace('screen_', '')
                )
            )
            BraydenCore.Reducer.store.dispatch(
                BraydenCore.Entity.Classroom.Action.userLeave(
                    user.uid.toString()
                )
            )
        }
    }
}

function handleAudioUnsubscribe(user: IAgoraRTCRemoteUser) {
    const clientID = BraydenCore.localStorageManager
        .getProvider()
        .getItem(LOCAL_STORAGE_KEY.CLIENT_ID)
    if (clientID !== null && clientID === user.uid) {
        BraydenCore.Reducer.store.dispatch(
            BraydenCore.Entity.Classroom.Action.adminLeaveAudio(
                user.uid.toString()
            )
        )
    } else {
        BraydenCore.Reducer.store.dispatch(
            BraydenCore.Entity.Classroom.Action.userLeaveAudio(
                user.uid.toString()
            )
        )
    }
}

function handleVideoUnsubscribe(user: IAgoraRTCRemoteUser) {
    const clientID = BraydenCore.localStorageManager
        .getProvider()
        .getItem(LOCAL_STORAGE_KEY.CLIENT_ID)
    if (clientID !== null && clientID === user.uid) {
        BraydenCore.Reducer.store.dispatch(
            BraydenCore.Entity.Classroom.Action.adminLeaveVideo(
                user.uid.toString()
            )
        )
    } else {
        if (user.uid.toString() === `screen_${clientID}`) {
            BraydenCore.Reducer.store.dispatch(
                BraydenCore.Entity.Classroom.Action.userLeaveVideo(
                    user.uid.toString()
                )
            )
        }
        if (user.uid.toString().startsWith('screen_')) {
            BraydenCore.Reducer.store.dispatch(
                BraydenCore.Entity.Classroom.Action.userLeaveScreenShare(
                    user.uid.toString().replace('screen_', '')
                )
            )
        }
    }
}
async function userUnpublished(
    user: IAgoraRTCRemoteUser,
    mediaType: 'audio' | 'video'
) {
    if (mediaType === 'audio') {
        handleAudioUnsubscribe(user)
    }
    if (mediaType === 'video') {
        handleVideoUnsubscribe(user)
    }
}
async function getNetworkQuality(quality: NetworkQuality) {
    const clientID = BraydenCore.localStorageManager
        .getProvider()
        .getItem(LOCAL_STORAGE_KEY.CLIENT_ID)
    if (clientID !== null) {
        const qualityData = Math.min(
            quality.downlinkNetworkQuality,
            quality.uplinkNetworkQuality
        )
        if (myNetworkQuality === '') {
            myNetworkQuality = qualityData.toString()
            BraydenCore.Reducer.store.dispatch(
                BraydenCore.Entity.Classroom.Action.addMyNetworkQuality(
                    myNetworkQuality
                )
            )
        }
        if (myNetworkQuality !== qualityData.toString()) {
            BraydenCore.Reducer.store.dispatch(
                BraydenCore.Entity.Classroom.Action.addMyNetworkQuality(
                    qualityData.toString()
                )
            )

            myNetworkQuality = qualityData.toString()
        }
    }
}

function initListener() {
    if (client === undefined) {
        throw Error.CLIENT_NOT_CREATED
    }
    if (isClientAlreadyInit === false) {
        client.on('user-published', userPublished)
        client.on('network-quality', getNetworkQuality)
        client.on('user-unpublished', userUnpublished)
        client.on('user-left', userLeft)

        isClientAlreadyInit = true
    }
}

function getLocalMicro() {
    return localMicro
}

function getLocalVideo() {
    return localVideo
}

function muteLocalMicro() {
    const userId = BraydenCore.localStorageManager
        .getProvider()
        .getItem(LOCAL_STORAGE_KEY.EMAIL) as string
    if (localMicro !== undefined) {
        localMicro.setMuted(true)
        BraydenCore.Reducer.store.dispatch(
            BraydenCore.Entity.Classroom.Action.userLeaveAudio(userId)
        )
    }
}
function unMuteLocalMicro() {
    const userId = BraydenCore.localStorageManager
        .getProvider()
        .getItem(LOCAL_STORAGE_KEY.EMAIL) as string
    if (localMicro !== undefined) {
        localMicro.setMuted(false)
        BraydenCore.Reducer.store.dispatch(
            BraydenCore.Entity.Classroom.Action.userJoinAudio(userId)
        )
    }
}

function getAudioTrackByUid(uid: UID) {
    const audio = audioStreamList.find((_audio) => _audio.uid === uid)
    if (audio !== undefined) {
        return audio.track
    }
    throw Error.AUDIO_NOT_FOUND
}
function getVideoTrackByUid(uid: UID) {
    const video = videoStreamList.find((_video) => _video.uid === uid)
    if (video !== undefined) {
        return video.track
    }
    throw Error.VIDEO_NOT_FOUND
}

const classRoomRTC = {
    getVideoTrackByUid,
    getAudioTrackByUid,
    muteLocalMicro,
    unMuteLocalMicro,
    getLocalMicro,
    getLocalVideo,
    initListener,
    join,
    leave,
    createClient,
    createLocalMicroAndAudio,
    stopLocalMicroAndAudio,
}

export default classRoomRTC
