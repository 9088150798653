import React from 'react'
import { Route, Switch } from 'react-router-dom'
import NotFound from 'view/page/Error/NotFound/NotFound'
import Forbidden from './Forbidden/Forbbiden'

export default function Router() {
    return (
        <Switch>
            <Route path="/not-found" component={NotFound} />
            <Route path="/forbidden" component={Forbidden} />
            <Route component={NotFound} />
        </Switch>
    )
}
