import BraydenCore from '@innosonian/brayden-core'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Typography from '../Typography/Typography'
import { IProps } from './Chronometer.type'

export default function Chronometer(props: IProps) {
    const isSessionStarted = useSelector(
        BraydenCore.Entity.Session.SessionSelector.getSessionStart
    )
    const dispatch = useDispatch()
    const [currentTime, setCurrentTime] = useState(0)
    const { timeout, stopCb, autoStop } = props
    const selectedManikin = useSelector(
        BraydenCore.Entity.Session.SessionSelector.getSelectedManikin
    )

    useEffect(() => {
        let t: any
        const timer = () =>
            setTimeout(() => {
                setCurrentTime(currentTime + 1)
            }, 1000)
        if (isSessionStarted === true) {
            t = timer()
        }
        if (isSessionStarted === false) {
            setCurrentTime(0)
        }
        if (timeout && timeout <= currentTime) {
            dispatch(
                BraydenCore.Entity.Session.SessionAction.rtStopRequest(
                    selectedManikin
                )
            )
            if (stopCb) {
                stopCb()
            }
        }
        return () => {
            clearTimeout(t)
        }
    }, [
        isSessionStarted,
        timeout,
        autoStop,
        currentTime,
        dispatch,
        stopCb,
        selectedManikin,
    ])

    const formatTimer = () => {
        const formatValue = (value: number) =>
            value < 10 ? `0${value}` : `${value}`

        if (props.timeout) {
            const tmp = props.timeout - currentTime
            const minute = Math.floor(tmp / 60)
            const second = tmp - minute * 60
            return `${formatValue(minute)} : ${formatValue(second)}`
        } else {
            const minute = Math.floor(currentTime / 60)
            const second = currentTime - minute * 60
            return `${formatValue(minute)} : ${formatValue(second)}`
        }
    }

    const handleContainerClassName = () =>
        props.fullScreen === true ? 'w-full' : 'w-1/2 md:w-full'

    return (
        <div
            className={`w-full flex ${
                props.fullScreen === true ? 'flex-col' : 'flex-row'
            } md:flex-col justify-center`}
        >
            <div className={handleContainerClassName()}>
                <Typography
                    type={props.fullScreen === true ? 'h2' : 'h3'}
                    alignment="center"
                >
                    {props.title}
                </Typography>
            </div>
            <div className={handleContainerClassName()}>
                <Typography
                    type={props.fullScreen === true ? 'h' : 'h3'}
                    alignment="center"
                >
                    {formatTimer()}
                </Typography>
            </div>
        </div>
    )
}
