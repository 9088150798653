import React from 'react'
import useResizeObserver from 'use-resize-observer'
import Typography from 'view/component/Typography/Typography'
import { IProps } from './PercentPie.type'

export default function PercentPie(props: IProps) {
    const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>()
    const radius = height / 2 - 14 * 2 < 0 ? 0 : height / 2 - 14 * 2
    const circumference = radius * 2 * Math.PI
    const offset = circumference - (props.percent / 100) * circumference
    return (
        <div className="w-full h-full relative" ref={ref}>
            <svg className="progress-ring" height={height} width={width}>
                <circle
                    className="progress-ring__circle"
                    fill="transparent"
                    r={radius}
                    cx={width / 2}
                    cy={height / 2}
                    stroke="#8A8F9A"
                    strokeWidth="14"
                />
                <circle
                    className="progress-ring__circle"
                    fill="transparent"
                    r={radius}
                    cx={width / 2}
                    cy={height / 2}
                    stroke={props.color}
                    strokeWidth="14"
                    strokeDasharray={`${circumference} ${circumference}`}
                    strokeDashoffset={offset}
                />
            </svg>
            <div className="absolute w-full h-full flex flex-col flex-grow-1 items-center justify-center inset-0">
                <Typography type="h2">{props.percent}</Typography>
                <Typography type="h3">{props.text}</Typography>
            </div>
        </div>
    )
}
