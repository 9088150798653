import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import Button from 'view/component/Button/Button'
import Tabs from 'view/component/Tabs/Tabs'
import Typography from 'view/component/Typography/Typography'
import CprMetricMedia from '../../component/ResultDetailPart/CprMetricMedia/CprMetricMedia'
import MetricCycle from '../../component/ResultDetailPart/MetricCycle/MetricCycle'
import Summary from '../../component/ResultDetailPart/Summary/Summary'
import FadeInContainer from '../../layout/FadeInContainer/FadeInContainer'
import BraydenCore from '@innosonian/brayden-core'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'
import { CardTrainingType } from '@innosonian/brayden-core/build/src/entity/Card/Card.interface'
import { isBetaTester } from '@innosonian/brayden-core/build/src/auth'

export default function ResultDetail() {
    const { t } = useTranslation('locale')
    const history = useHistory()

    const [selectedTabs, setSelectedTabs] = useState<
        'summary' | 'cpr' | 'cycle'
    >('summary')
    const clientID = useSelector(
        BraydenCore.Entity.Client.ClientSelector.getGeneralClientId
    )
    const record = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Record.RecordSelector.getRecordById(
            state,
            window.location.pathname.split('/')[2]
        )
    )
    const resultId = useSelector(
        BraydenCore.Entity.Result.ResultSelector.getResultId
    )
    const isClassroomConnected = useSelector(
        BraydenCore.Entity.Classroom.Selector.isConnected
    )
    const detailedResults = useSelector(
        BraydenCore.Entity.Result.ResultSelector.getDetailedResultByCategory
    )

    useEffect(() => {
        setSelectedTabs(detailedResults)
    }, [detailedResults])

    const handleOverallResultColor = () => {
        if (
            record.ResultSummary &&
            record.ResultSummary.JudgResult === 'Pass'
        ) {
            return 'green-600 dark:text-green-light-default-button'
        }
        return 'orange-800 dark:text-orange-light-default-button'
    }
    return (
        <FadeInContainer className="w-full h-full flex flex-col">
            <div className="w-full flex flex-col lg:flex-row  lg:mb-5">
                <div className="w-full flex flex-col lg:w-4/5 justify-between ">
                    <div className="w-full h-full flex sm:mb-5 md:mb-5 border border-gray-100 items-center">
                        <div className="w-full md:flex  items-start">
                            <div className="flex flex-col w-full md:w-1/3 items-center gap-5 ">
                                <Typography type="h3">
                                    {t('OverallResultPage.Score')}
                                </Typography>
                                <Typography
                                    type="h2"
                                    color={handleOverallResultColor()}
                                >
                                    {record.ResultByCycle !== null &&
                                        record.ResultByCycle.ScoreByCycle
                                            .Overall}
                                </Typography>
                            </div>
                            <div className="flex flex-col items-center gap-5">
                                <Typography
                                    type="h3"
                                    alignment="center"
                                    className="pb-2"
                                >
                                    {t('ResultDetail.Title')}
                                </Typography>
                                <Typography type="p" alignment="center">
                                    {t('ResultDetail.SubTitle')}
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex flex-col md:flex-row mb-5 md:mb-0">
                        <Tabs
                            link={[
                                {
                                    title: t('ResultDetail.Tabs.Summary'),
                                    active: selectedTabs === 'summary',
                                    onClick: () => setSelectedTabs('summary'),
                                },
                                {
                                    title: t('ResultDetail.Tabs.Cpr'),
                                    active: selectedTabs === 'cpr',
                                    onClick: () => setSelectedTabs('cpr'),
                                },
                                {
                                    title: t('ResultDetail.Tabs.Cycle'),
                                    active: selectedTabs === 'cycle',
                                    onClick: () => setSelectedTabs('cycle'),
                                    disable:
                                        record.Usage.Type !==
                                        CardTrainingType.CPR_TRAINING,
                                },
                            ]}
                        />
                    </div>
                </div>
                <div className="gap-4 flex flex-col my-3 lg:m-0 w-full lg:pl-4 lg:w-2/5 xl:w-2/5 lg:mx-auto items-center justify-between">
                    <Button
                        disable={isClassroomConnected === true}
                        shape="rounded-mid"
                        onclick={() => {
                            history.push(`/trainings`)
                        }}
                        color="blue"
                        size="full"
                        fontSize="sm"
                        textColor="white dark:white"
                        className=" mx-auto lg:mx-none flex flex-grow"
                    >
                        {t('WebOverwrite.GoTrainingList')}
                    </Button>
                    {resultId === record._id && (
                        <Button
                            disable={isClassroomConnected === true}
                            shape="rounded-mid"
                            onclick={() => {
                                history.push(`/result`)
                            }}
                            color="secondary"
                            size="full"
                            className=" mx-auto lg:mx-none"
                            fontSize="sm"
                        >
                            {t('WebOverwrite.ResultLink')}
                        </Button>
                    )}
                    {isBetaTester(clientID) === true &&
                        record.Usage.Type === CardTrainingType.CPR_TRAINING && (
                            <Button
                                disable={isClassroomConnected === true}
                                shape="rounded-mid"
                                onclick={() => {
                                    history.push(
                                        `/records/${record._id}/cpr-quality`
                                    )
                                }}
                                color="warning"
                                size="full"
                                className=" mx-auto lg:mx-none"
                                fontSize="sm"
                            >
                                {t('Record.Navigation.CprQuality')}
                            </Button>
                        )}
                    {BraydenCore.Authentication.isAuthenticated() === true && (
                        <Button
                            disable={isClassroomConnected === true}
                            shape="rounded-mid"
                            onclick={() => {
                                history.push('/records')
                            }}
                            color="danger"
                            size="full"
                            className="mx-auto lg:mx-none flex flex-grow"
                            fontSize="sm"
                        >
                            {t('Record.Navigation.GoBack')}
                        </Button>
                    )}
                </div>
            </div>
            <div className="w-full flex flex-grow">
                {selectedTabs === 'summary' && (
                    <Summary
                        trainingType={record.Usage.Type}
                        resultCycle={record.ResultByCycle}
                        resultSummary={record.ResultSummary}
                        resultRescue={record.ResultInitRescue}
                    />
                )}
                {selectedTabs === 'cpr' && (
                    <CprMetricMedia
                        trainingType={record.Usage.Type}
                        resultCriteria={record.ResultByCriteria}
                    />
                )}
                {selectedTabs === 'cycle' && (
                    <MetricCycle
                        trainingType={record.Usage.Type}
                        resultCycle={record.ResultByCycle}
                    />
                )}
            </div>
        </FadeInContainer>
    )
}
