import React from 'react'
import ModalContainer from '../ModalContainer/ModalContainer'
import BraydenCore from '@innosonian/brayden-core'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Typography from 'view/component/Typography/Typography'

export default function ClassroomKickedOutModal() {
    const dispatch = useDispatch()
    const { t } = useTranslation('locale')
    const isOpen = useSelector(
        BraydenCore.Entity.Modal.ModalSelector.isClassroomKickOut
    )

    return (
        <ModalContainer
            isOpen={isOpen}
            closeButton={true}
            onCloseClick={() => {
                dispatch(
                    BraydenCore.Entity.Modal.ModalAction.modalCloseClassroomKickOut()
                )
            }}
        >
            <div className="flex flex-col h-full  w-full lg:px-10 justify-center items-center md:py-4">
                <div className="w-full p-4">
                    <Typography type="h2" alignment="center" className="w-full">
                        {t('Classroom.KickOutTitle')}
                    </Typography>
                </div>
                <div className="w-full p-4">
                    <Typography alignment="center" type="h3">
                        {t('Classroom.KickOutContent')}
                    </Typography>
                </div>
            </div>
        </ModalContainer>
    )
}
