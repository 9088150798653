import React from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import Result from 'view/page/Result/Result'

export default function ResultRouter() {
    const { path } = useRouteMatch()
    return (
        <Switch>
            <Route path={`${path}`} exact component={Result} />
        </Switch>
    )
}
