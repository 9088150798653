import React, { memo, useRef, useEffect, useState } from 'react'
import lottie from 'lottie-web/build/player/lottie_svg.min.js'
import isEqual from 'lodash/isEqual'

import { IProps, AnimationItem } from './Lottie.type'

const Lottie: React.FC<IProps> = memo(
    ({
        animationData,
        play = null,
        speed = 1,
        direction = 1 as 1 | -1,
        segments: segmentsIn = null,
        goTo = null,
        renderer,
        loop = true,
        rendererSettings: rendererSettingsIn = {},
        onLoopComplete,
        onEnterFrame = () => {},
        onSegmentStart = () => {},
        ...props
    }) => {
        const animElementRef = useRef<any>()
        const animRef = useRef<AnimationItem | null>(null)
        const [segments, setSegments] = useState(segmentsIn)
        const [rendererSettings, setRendererSettings] = useState(
            rendererSettingsIn
        )

        useEffect(() => {
            if (animRef.current !== null && onLoopComplete !== undefined) {
                animRef.current.addEventListener('loopComplete', onLoopComplete)
            }
            return () => {
                if (animRef.current !== null && onLoopComplete !== undefined) {
                    animRef.current.removeEventListener('loopComplete')
                }
            }
        }, [onLoopComplete])

        useEffect(() => {
            if (!isEqual(segments, segmentsIn)) {
                setSegments(segmentsIn)
            }
        }, [segmentsIn, segments])

        useEffect(() => {
            if (!isEqual(rendererSettings, rendererSettingsIn)) {
                setRendererSettings(rendererSettingsIn)
            }
        }, [rendererSettingsIn, rendererSettings])

        useEffect(() => {
            animRef.current = lottie.loadAnimation({
                animationData,
                container: animElementRef.current,
                renderer,
                loop: loop,
                autoplay: false,
                rendererSettings,
            })

            return () => {
                if (animRef.current !== null) {
                    animRef.current.destroy()
                    animRef.current = null
                }
            }
        }, [loop, renderer, rendererSettings, animationData])

        useEffect(() => {
            if (animRef.current !== null) {
                animRef.current.loop = loop
            }
        }, [loop])

        useEffect(() => {
            if (animRef.current !== null) {
                if (play === true) {
                    if (segments) {
                        if (direction === 1) {
                            animRef.current.playSegments(segments, true)
                        } else {
                            animRef.current.playSegments(
                                [segments[1], segments[0] as any],
                                true
                            )
                        }
                    } else {
                        animRef.current.resetSegments(true)
                        animRef.current.play()
                    }
                } else if (play === false) {
                    animRef.current.pause()
                }
            }
        }, [play, segments, direction])

        useEffect(() => {
            if (animRef.current !== null && !Number.isNaN(speed)) {
                animRef.current.setSpeed(speed)
            }
        }, [speed])

        useEffect(() => {
            if (animRef.current !== null) {
                animRef.current.setDirection(direction)
            }
        }, [direction])

        useEffect(() => {
            if (animRef.current === null || goTo === null) {
                return
            }
            animRef.current.goToAndStop(goTo, true)
        }, [goTo])

        return <div {...props} className="w-full h-full" ref={animElementRef} />
    }
)

export default Lottie
