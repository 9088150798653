import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import Lottie from 'view/component/Lottie/Lottie'
import { useDispatch, useSelector } from 'react-redux'
import { AnimatePresence } from 'framer-motion'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'
import BraydenCore from '@innosonian/brayden-core'
import Classroom from 'core/classroom'
import SetupStep from 'view/layout/SetupStep/SetupStep'
import AdminFullScreenShare from 'view/layout/AdminFullScreenShare/AdminFullScreenShare'
import StepCamera from 'view/component/Step/StepCamera'
import Button from 'view/component/Button/Button'
import ShareScreen from '../../component/ShareScreen/ShareScreen'
import Typography from 'view/component/Typography/Typography'
import ManikinCarousel from 'view/component/ManikinCarousel/ManikinCarousel'
import ProgressBar from 'resource/lottie/progress-bar.json'
import CheckMark from 'resource/lottie/check_mark.json'
import { LOCAL_STORAGE_KEY } from '@innosonian/brayden-core/build/src/_localStorage/localStorage.interface'

export default function SetUp() {
    const dispatch = useDispatch()
    const { t } = useTranslation('locale')
    const [isUserReady, setIsUserReady] = useState(false)
    const userId = localStorage.getItem(LOCAL_STORAGE_KEY.EMAIL) as string
    const isClassroomConnected = useSelector(
        BraydenCore.Entity.Classroom.Selector.isConnected
    )
    const isManikinConnected = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Classroom.Selector.getManikinConnectionStatus(state)
    )
    const isScreenConnected = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Classroom.Selector.getScreenConnectionStatus(state)
    )
    const isCameraConnected = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Classroom.Selector.getCameraConnectionStatus(state)
    )
    const babyProConnected = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Hardware.HardwareSelector.getManikinByType(
            state,
            'Infant'
        )
    )
    const adultConnected = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Hardware.HardwareSelector.getManikinByType(
            state,
            'Adult'
        )
    )

    const juniorConnected = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Hardware.HardwareSelector.getManikinByType(
            state,
            'Child'
        )
    )
    useEffect(() => {
        const joinCamera = async () => {
            await Classroom.initRtmConnection()
            await Classroom.Video.createLocalMicroAndAudio(userId)
            await Classroom.initAudioAndVideo()
        }
        if (!isCameraConnected) {
            joinCamera()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (adultConnected !== undefined) {
            dispatch(
                BraydenCore.Entity.Session.SessionAction.selectManikin(
                    adultConnected as string
                )
            )
        }
        if (babyProConnected !== undefined) {
            dispatch(
                BraydenCore.Entity.Session.SessionAction.selectManikin(
                    babyProConnected as string
                )
            )
        }
        if (juniorConnected !== undefined) {
            dispatch(
                BraydenCore.Entity.Session.SessionAction.selectManikin(
                    juniorConnected as string
                )
            )
        }
    }, [dispatch, adultConnected, babyProConnected, juniorConnected])

    const handleScreenShare = async () => {
        if (isScreenConnected === false) {
            try {
                await Classroom.initScreenShare()
            } catch (e) {
                if (e.code !== undefined && e.code === 'PERMISSION_DENIED') {
                    dispatch(
                        BraydenCore.Entity.Modal.ModalAction.modalOpenClassroomPermission()
                    )
                }
                dispatch(
                    BraydenCore.Entity.Classroom.Action.connectScreen(false)
                )
            }
        } else {
            await Classroom.Screen.stopScreenSharing()
            dispatch(BraydenCore.Entity.Classroom.Action.connectScreen(false))
        }
    }

    return (
        <AnimatePresence>
            {isClassroomConnected ? (
                <AdminFullScreenShare
                    showImageSlider={isManikinConnected}
                    showSideCameraView={isClassroomConnected}
                    isUserWaiting={isUserReady}
                    className="h-full"
                >
                    <SetupStep
                        className="p-20 relative"
                        isConnected={isCameraConnected && !isScreenConnected}
                    >
                        <div className="w-1/2 mb-4 absolute top-0 pt-6">
                            <Lottie
                                loop={false}
                                animationData={ProgressBar}
                                play={isCameraConnected}
                                segments={[0, 100]}
                            />
                        </div>
                        <Typography type="h1" weight="bold" className="mb-10">
                            {t('Classroom.Setup.ScreenSetup')}
                        </Typography>
                        <ShareScreen connectScreenShare={handleScreenShare} />
                    </SetupStep>
                    <SetupStep
                        isConnected={isScreenConnected && !isManikinConnected}
                        className="p-20 relative"
                    >
                        <div className="w-1/2 mb-4 absolute top-0 pt-6">
                            <Lottie
                                loop={false}
                                animationData={ProgressBar}
                                play={isScreenConnected}
                                segments={[100, 200]}
                            />
                        </div>
                        <Typography type="h1" weight="bold" className=" my-14">
                            {t('Classroom.Setup.ManikinSetup')}
                        </Typography>
                        <ManikinCarousel
                            onIdxUpdate={() => console.log()}
                            defaultValue="Adult"
                        />
                    </SetupStep>
                    <SetupStep
                        isConnected={
                            isScreenConnected &&
                            isManikinConnected &&
                            !isUserReady
                        }
                        className="relative"
                    >
                        <div className="w-1/2 mb-4 absolute top-0 pt-6">
                            <Lottie
                                loop={false}
                                animationData={ProgressBar}
                                play={isManikinConnected}
                                segments={[200, 360]}
                            />
                        </div>
                        <Typography type="h1" weight="bold" className="mb-10">
                            {t('Classroom.Setup.ManikinAdjust')}
                        </Typography>
                        <StepCamera manikinArea={true} />
                        <div>
                            <Button
                                shape="rounded-mid"
                                disable={false}
                                color="secondary"
                                padding="sm"
                                weight="bold"
                                className="mt-10"
                                onclick={() => {
                                    setIsUserReady(true)
                                }}
                            >
                                {t('Classroom.Setup.Ready')}
                            </Button>
                        </div>
                    </SetupStep>
                    {isManikinConnected &&
                        isScreenConnected &&
                        isCameraConnected &&
                        isUserReady && (
                            <div className="flex flex-col h-full items-center p-20 justify-center">
                                <div className="w-1/3">
                                    <Lottie
                                        loop={false}
                                        animationData={CheckMark}
                                        play={isUserReady}
                                        segments={[0, 360]}
                                    />
                                </div>
                                <div className="flex flex-col items-center">
                                    <Typography
                                        type="h1"
                                        weight="bold"
                                        className="mb-10"
                                    >
                                        {t('Classroom.Setup.ReadyMessage')}
                                    </Typography>
                                    <Typography
                                        type="h3"
                                        weight="bold"
                                        className="mb-10"
                                    >
                                        {t('Classroom.Setup.ReadySubMessage')}
                                    </Typography>
                                </div>
                            </div>
                        )}
                </AdminFullScreenShare>
            ) : (
                <SetupStep
                    className="flex flex-col gap-10 w-full items-center pl-20 pr-20"
                    isConnected={isCameraConnected}
                >
                    <Typography type="h1" weight="bold">
                        {t('Classroom.Setup.CameraSetup')}
                    </Typography>
                    <StepCamera />
                    <div className="flex w-full justify-center mt-10">
                        <Button
                            shape="rounded-mid"
                            disable={false}
                            color="secondary"
                            padding="sm"
                            size="sm"
                            weight="bold"
                            onclick={() =>
                                BraydenCore.Reducer.store.dispatch(
                                    BraydenCore.Entity.Classroom.Action.joinClassRoom()
                                )
                            }
                        >
                            {t('Classroom.Setup.Next')}
                        </Button>
                    </div>
                </SetupStep>
            )}
        </AnimatePresence>
    )
}
