import React from 'react'
import ListItem from '../ListItem/ListItem'
import { IUserListItemProps } from './UserClientListItem.type'
import { useDispatch, useSelector } from 'react-redux'
import BraydenCore from '@innosonian/brayden-core'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'
import { ReactComponent as EditProfile } from 'resource/img/icons/edit-profile.svg'
import { ReactComponent as Delete } from 'resource/img/icons/cross.svg'
import { ReactComponent as Up } from 'resource/img/icons/up.svg'
import { ReactComponent as Switch } from 'resource/img/icons/switch.svg'

import Typography from '../Typography/Typography'
import { sagaPromise } from '@innosonian/brayden-core/build/src/_reducer/reducer'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { restoreUserClient } from '@innosonian/brayden-core/build/src/entity/UserClient/UserClient.saga'

export default function UserClientListItem(props: IUserListItemProps) {
    const { t } = useTranslation('locale')
    const user = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.UserClient.Selector.getUserClientById(
            state,
            props.id
        )
    )
    const clientType = useSelector(
        BraydenCore.Entity.Oauth.Selector.getClientType
    )
    const clientId = useSelector(BraydenCore.Entity.Oauth.Selector.getClientId)
    const isSwitched = useSelector(
        BraydenCore.Entity.Oauth.Selector.getIsSwitched
    )
    const dispatch = useDispatch()

    const canSwitch = () => {
        return (
            (clientType === 'admin' || clientType === 'organization_admin') &&
            isSwitched === false
        )
    }

    const handleRestore = async () => {
        try {
            await sagaPromise(restoreUserClient, user.id)
            toast(t('ToastManager.RestoreUserClientSuccess'), {
                type: 'success',
            })
        } catch (e) {
            toast(t('ToastManager.RestoreUserClientError'), {
                type: 'error',
            })
        }
    }

    return (
        <ListItem>
            <div className="flex justify-center w-1/5 overflow-hidden">
                <Typography className="truncate">{user.client_name}</Typography>
            </div>
            <div className="flex justify-center w-1/5 overflow-hidden">
                <Typography className="truncate">{user.rank_name}</Typography>
            </div>
            <div className="flex justify-center w-1/5 overflow-hidden">
                <Typography className="truncate">
                    {user.client_type_name}
                </Typography>
            </div>
            <div className="flex justify-center w-1/5 overflow-hidden">
                <Typography className="truncate">
                    {user.organization_name}
                </Typography>
            </div>
            <div className="flex justify-center w-1/5 overflow-hidden">
                <Typography className="truncate">{user.clientID}</Typography>
            </div>
            <div className="flex justify-center w-1/5 overflow-hidden">
                <Typography
                    className="bg-black text-transparent hover:bg-transparent hover:text-red-800 truncate"
                    color="transparent"
                >
                    {user.passcode}
                </Typography>
            </div>
            {clientId === user.clientID && (
                <div className="flex justify-center w-1/5"></div>
            )}
            {props.isDeleted === false && clientId !== user.clientID && (
                <div className="flex justify-center w-1/5">
                    <EditProfile
                        className="cursor-pointer rounded-full p-2 w-8 h-8 bg-orange-800 dark:bg-orange-light text-white dark:text-white mr-2"
                        onClick={() =>
                            dispatch(
                                BraydenCore.Entity.Modal.ModalAction.modalOpenEditClientProfile(
                                    props.id
                                )
                            )
                        }
                    ></EditProfile>
                    {canSwitch() === true && clientId !== user.clientID && (
                        <Switch
                            className="cursor-pointer rounded-full p-2 w-8 h-8 bg-orange-800 dark:bg-orange-light text-white dark:text-white mr-2"
                            aria-label="Delete"
                            onClick={() =>
                                dispatch(
                                    BraydenCore.Entity.Oauth.Action.switchUserRequest(
                                        props.id
                                    )
                                )
                            }
                        ></Switch>
                    )}
                    <Delete
                        className="cursor-pointer rounded-full p-2 w-8 h-8 bg-orange-800 dark:bg-orange-light text-white dark:text-white"
                        aria-label="Delete"
                        onClick={() =>
                            dispatch(
                                BraydenCore.Entity.Modal.ModalAction.modalOpenDeleteUserClientConfirmation(
                                    props.id
                                )
                            )
                        }
                    ></Delete>
                </div>
            )}
            {props.isDeleted === true && clientId !== user.clientID && (
                <div className="flex justify-center w-1/5">
                    <Up
                        className="cursor-pointer rounded-full p-2 w-8 h-8 bg-green-800 dark:bg-green-light text-white dark:text-white mr-2"
                        onClick={handleRestore}
                    ></Up>
                    <Delete
                        className="cursor-pointer rounded-full p-2 w-8 h-8 bg-red-800 dark:bg-red-light text-white dark:text-white"
                        aria-label="Delete"
                        onClick={() =>
                            dispatch(
                                BraydenCore.Entity.Modal.ModalAction.modalOpenHardDeleteUserClientConfirmation(
                                    props.id
                                )
                            )
                        }
                    ></Delete>
                </div>
            )}
        </ListItem>
    )
}
