import React, { useEffect, useState } from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import { IProps } from './ImageSlider.type'

export default function ImageSlider(props: IProps) {
    const [[currentImgIdx, direction], setCurrentImgIdx] = useState([0, 0])
    const loopTimer = props.loopTimer
    const maxPage = props.images.length - 1

    const paginate = (newDirection: number) => {
        setCurrentImgIdx([currentImgIdx + newDirection, newDirection])
    }

    const variants = {
        enter: (direction: number) => {
            return {
                scale: 1,
                x: direction > 0 ? '100%' : '-100%',
                opacity: 0,
            }
        },
        center: {
            zIndex: 1,
            x: 0,
            opacity: 1,
            scale: 1.15,
        },
        exit: (direction: number) => {
            return {
                zIndex: 0,
                scale: 1,
                x: direction > 0 ? '-100%' : '100%',
                opacity: 0,
            }
        },
    }

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentImgIdx >= maxPage) {
                paginate(direction > 0 ? -1 : 0)
            } else if (currentImgIdx <= 0) {
                paginate(direction <= 0 ? +1 : 0)
            } else {
                if (direction < 0) {
                    paginate(-1)
                }
                if (direction > 0) {
                    paginate(+1)
                }
            }
        }, loopTimer)
        return () => {
            clearInterval(interval)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loopTimer, currentImgIdx])

    return (
        <div
            className={`${props.className} w-full my-0 flex flex-col items-center py-14 h-full overflow-hidden`}
        >
            <div className="relative h-100 w-1/2">
                <AnimatePresence initial={false} custom={direction}>
                    <motion.div
                        key={currentImgIdx}
                        custom={direction}
                        variants={variants}
                        initial="enter"
                        animate="center"
                        exit="exit"
                        transition={{
                            opacity: { duration: 2 },
                            duration: 2,
                        }}
                    >
                        <div className="absolute h-full w-full left-2/4">
                            {props.images[currentImgIdx]}
                        </div>
                    </motion.div>
                </AnimatePresence>
            </div>
        </div>
    )
}
