import React, { useState } from 'react'
import Button from 'view/component/Button/Button'
import ModalContainer from '../ModalContainer/ModalContainer'
import { useDispatch, useSelector } from 'react-redux'
import BraydenCore from '@innosonian/brayden-core'
import { useTranslation } from 'react-i18next'
import Typography from 'view/component/Typography/Typography'
import { sagaPromise } from '@innosonian/brayden-core/build/src/_reducer/reducer'
import { deleteUserClient } from '@innosonian/brayden-core/build/src/entity/UserClient/UserClient.saga'
import { toast } from 'react-toastify'

export default function ConfirmDeleteUserClient() {
    const { t } = useTranslation('locale')
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const selectedUserClientId = useSelector(
        BraydenCore.Entity.Modal.ModalSelector.getDeleteUserClientId
    )

    const deleteUser = async () => {
        if (selectedUserClientId !== undefined) {
            setLoading(true)
            try {
                await sagaPromise(deleteUserClient, selectedUserClientId)
                toast(t('ToastManager.DeleteUserClientSuccess'), {
                    type: 'success',
                })
                setLoading(false)
            } catch (e) {
                toast(t('ToastManager.DeleteUserClientError'), {
                    type: 'error',
                })
            } finally {
                setLoading(false)
                dispatch(
                    BraydenCore.Entity.Modal.ModalAction.modalCloseDeleteUserClientConfirmation()
                )
            }
        }
    }

    return (
        <ModalContainer
            isOpen={selectedUserClientId !== undefined}
            closeButton={true}
            onCloseClick={() =>
                dispatch(
                    BraydenCore.Entity.Modal.ModalAction.modalCloseDeleteUserClientConfirmation()
                )
            }
        >
            <div className="flex flex-col h-full  w-full lg:px-10 justify-center items-center md:py-4">
                <div className="w-full pb-4">
                    <Typography>
                        {t('UserClientList.ConfirmDeleteUserClient')}
                    </Typography>
                </div>
                <div className="w-full flex flex-col">
                    <Typography>
                        {t('UserClientList.ConfirmDeleteUserClientContent')}
                    </Typography>
                </div>
                <div className="w-full flex justify-end">
                    <Button
                        disable={loading}
                        className="mr-2"
                        onclick={deleteUser}
                        type="plain"
                        shape="rounded-mid"
                        padding="sm"
                        color="secondary"
                    >
                        {t('UserClientList.Delete')}
                    </Button>
                </div>
            </div>
        </ModalContainer>
    )
}
