import React from 'react'
import { useTranslation } from 'react-i18next'
import TableContainer from 'view/component/Table/TableContainer/TableContainer'
import TableContent from 'view/component/Table/TableContent/TableContent'
import { IProps } from './CprMetricMedia.type'
import FadeInContainer from '../../../layout/FadeInContainer/FadeInContainer'
import { CardTrainingType } from '@innosonian/brayden-core/build/src/entity/Card/Card.interface'
export default function CprMetricMedia(props: IProps) {
    const { t } = useTranslation('locale')
    return (
        <FadeInContainer className="w-full h-full flex flex-col justify-between">
            <div className="flex flex-col md:flex-row mb-0 md:mb-2 flex-grow">
                <div className="w-full md:w-1/2 md:mr-4 mb-5 md:mb-0 ">
                    <TableContainer
                        color="red-800 dark:bg-red-light"
                        title={t('ResultDetail.CompDepth')}
                        margin={false}
                        disable={
                            props.trainingType ===
                            CardTrainingType.VENTILATION_ONLY
                        }
                    >
                        <TableContent
                            items={[
                                {
                                    title: {
                                        value: t('ResultDetail.TooShallow'),
                                        color:
                                            'gray-100 dark:text-gray-light-text',
                                    },
                                    value:
                                        props.resultCriteria !== null &&
                                        props.resultCriteria.CompressionDepth[
                                            '%_TooShallow'
                                        ] !== undefined
                                            ? props.resultCriteria
                                                  .CompressionDepth[
                                                  '%_TooShallow'
                                              ] + '%'
                                            : 0 + '%',
                                    border: true,
                                },
                                {
                                    title: {
                                        value: t('ResultDetail.Good'),
                                        color: 'green-light dark:green-light',
                                    },
                                    value:
                                        props.resultCriteria !== null &&
                                        props.resultCriteria.CompressionDepth[
                                            '%_Good'
                                        ] !== undefined
                                            ? props.resultCriteria
                                                  .CompressionDepth['%_Good'] +
                                              '%'
                                            : 0 + '%',
                                    xl: true,
                                    border: true,
                                },
                                {
                                    title: {
                                        value: t('ResultDetail.TooDeep'),
                                        color:
                                            'gray-100 dark:text-gray-light-text',
                                    },
                                    value:
                                        props.resultCriteria !== null &&
                                        props.resultCriteria.CompressionDepth[
                                            '%_TooDeep'
                                        ] !== undefined
                                            ? props.resultCriteria
                                                  .CompressionDepth[
                                                  '%_TooDeep'
                                              ] + '%'
                                            : 0 + '%',
                                    border: false,
                                },
                            ]}
                            disable={
                                props.trainingType ===
                                CardTrainingType.VENTILATION_ONLY
                            }
                        />
                    </TableContainer>
                </div>
                <div className="w-full md:w-1/2 mb-5 md:mb-0">
                    <TableContainer
                        color="green-800 dark:bg-green-light"
                        title={t('ResultDetail.CompRelease')}
                        margin={false}
                        disable={
                            props.trainingType ===
                            CardTrainingType.VENTILATION_ONLY
                        }
                    >
                        <TableContent
                            disable={
                                props.trainingType ===
                                CardTrainingType.VENTILATION_ONLY
                            }
                            items={[
                                {
                                    title: {
                                        value: t(
                                            'ResultDetail.IncompleteRelease'
                                        ),
                                        color:
                                            'gray-100 dark:text-gray-light-text',
                                    },
                                    value:
                                        props.resultCriteria !== null &&
                                        props.resultCriteria.Recoil[
                                            '%_Incomplete'
                                        ] !== undefined
                                            ? props.resultCriteria.Recoil[
                                                  '%_Incomplete'
                                              ] + '%'
                                            : 0 + '%',
                                    border: true,
                                },
                                {
                                    title: {
                                        value: t('ResultDetail.Good'),
                                        color: 'green-light dark:green-light',
                                    },
                                    value:
                                        props.resultCriteria !== null &&
                                        props.resultCriteria.Recoil[
                                            '%_Good'
                                        ] !== undefined
                                            ? props.resultCriteria.Recoil[
                                                  '%_Good'
                                              ] + '%'
                                            : 0 + '%',
                                    xl: true,
                                    border: false,
                                },
                            ]}
                        />
                    </TableContainer>
                </div>
            </div>
            <div className="flex flex-col md:flex-row mb-0 md:mb-2 flex-grow">
                <div className="w-full md:w-1/2 md:mr-4 mb-5 md:mb-0">
                    <TableContainer
                        color="orange-800 dark:bg-orange-light"
                        title={t('ResultDetail.CompRate')}
                        margin={false}
                        disable={
                            props.trainingType ===
                            CardTrainingType.VENTILATION_ONLY
                        }
                    >
                        <TableContent
                            disable={
                                props.trainingType ===
                                CardTrainingType.VENTILATION_ONLY
                            }
                            items={[
                                {
                                    title: {
                                        value: t('ResultDetail.TooSlow'),
                                        color:
                                            'gray-100 dark:text-gray-light-text',
                                    },
                                    value:
                                        props.resultCriteria !== null &&
                                        props.resultCriteria.CompressionRate[
                                            '%_TooSlow'
                                        ] !== undefined
                                            ? props.resultCriteria
                                                  .CompressionRate[
                                                  '%_TooSlow'
                                              ] + '%'
                                            : 0 + '%',
                                    border: true,
                                },
                                {
                                    title: {
                                        value: t('ResultDetail.Good'),
                                        color: 'green-light dark:green-light',
                                    },
                                    value:
                                        props.resultCriteria !== null &&
                                        props.resultCriteria.CompressionRate[
                                            '%_Good'
                                        ] !== undefined
                                            ? props.resultCriteria
                                                  .CompressionRate['%_Good'] +
                                              '%'
                                            : 0 + '%',
                                    xl: true,
                                    border: true,
                                },
                                {
                                    title: {
                                        value: t('ResultDetail.TooFast'),
                                        color:
                                            'gray-100 dark:text-gray-light-text',
                                    },
                                    value:
                                        props.resultCriteria !== null &&
                                        props.resultCriteria.CompressionRate[
                                            '%_TooFast'
                                        ] !== undefined
                                            ? props.resultCriteria
                                                  .CompressionRate[
                                                  '%_TooFast'
                                              ] + '%'
                                            : 0 + '%',
                                    border: false,
                                },
                            ]}
                        />
                    </TableContainer>
                </div>
                <div className="w-full md:w-1/2 mb-5 md:mb-0">
                    <TableContainer
                        color="purple-800 dark:bg-purple-light-blue"
                        title={t('ResultDetail.HandPos')}
                        margin={false}
                        disable={
                            props.trainingType ===
                            CardTrainingType.VENTILATION_ONLY
                        }
                    >
                        <TableContent
                            disable={
                                props.trainingType ===
                                CardTrainingType.VENTILATION_ONLY
                            }
                            items={[
                                {
                                    title: {
                                        value: t('ResultDetail.Incorrect'),
                                        color:
                                            'gray-100 dark:text-gray-light-text',
                                    },
                                    value:
                                        props.resultCriteria !== null &&
                                        props.resultCriteria.HandPosition[
                                            '%_IncorrectLR'
                                        ] !== undefined
                                            ? props.resultCriteria.HandPosition[
                                                  '%_IncorrectLR'
                                              ] +
                                              props.resultCriteria.HandPosition[
                                                  '%_IncorrectStomach'
                                              ] +
                                              '%'
                                            : 0 + '%',
                                    border: true,
                                },
                                {
                                    title: {
                                        value: t('ResultDetail.Good'),
                                        color: 'green-light dark:green-light',
                                    },
                                    value:
                                        props.resultCriteria !== null &&
                                        props.resultCriteria.HandPosition[
                                            '%_Good'
                                        ] !== undefined
                                            ? props.resultCriteria.HandPosition[
                                                  '%_Good'
                                              ] + '%'
                                            : 0 + '%',
                                    xl: true,
                                    border: false,
                                },
                            ]}
                        />
                    </TableContainer>
                </div>
            </div>
            <div className="flex flex-col md:flex-row mb-0 md:mb-2 flex-grow">
                <div className="w-full md:w-1/2 md:mr-4 mb-5 md:mb-0">
                    <TableContainer
                        color="yellow-800 dark:bg-yellow-light"
                        title={t('ResultDetail.Comp')}
                        margin={false}
                        disable={
                            props.trainingType ===
                            CardTrainingType.VENTILATION_ONLY
                        }
                    >
                        <TableContent
                            disable={
                                props.trainingType ===
                                CardTrainingType.VENTILATION_ONLY
                            }
                            items={[
                                {
                                    title: {
                                        value: t('ResultDetail.TooFew'),
                                        color:
                                            'gray-100 dark:text-gray-light-text',
                                    },
                                    value:
                                        props.resultCriteria !== null &&
                                        props.resultCriteria.CompressionNo[
                                            '%_TooFew'
                                        ] !== undefined
                                            ? props.resultCriteria
                                                  .CompressionNo['%_TooFew'] +
                                              '%'
                                            : 0 + '%',
                                    border: true,
                                },
                                {
                                    title: {
                                        value: t('ResultDetail.Good'),
                                        color: 'green-light dark:green-light',
                                    },
                                    value:
                                        props.resultCriteria !== null &&
                                        props.resultCriteria.CompressionNo[
                                            '%_Good'
                                        ] !== undefined
                                            ? props.resultCriteria
                                                  .CompressionNo['%_Good'] + '%'
                                            : 0 + '%',
                                    xl: true,
                                    border: true,
                                },
                                {
                                    title: {
                                        value: t('ResultDetail.TooMany'),
                                        color:
                                            'gray-100 dark:text-gray-light-text',
                                    },
                                    value:
                                        props.resultCriteria !== null &&
                                        props.resultCriteria.CompressionNo[
                                            '%_TooMany'
                                        ] !== undefined
                                            ? props.resultCriteria
                                                  .CompressionNo['%_TooMany'] +
                                              '%'
                                            : 0 + '%',
                                    border: false,
                                },
                            ]}
                        />
                    </TableContainer>
                </div>
                <div className="w-full md:w-1/2 mb-5 md:mb-0">
                    <TableContainer
                        color="purple-900 dark:bg-purple-light"
                        title={t('ResultDetail.VentVol')}
                        margin={false}
                        disable={
                            props.trainingType ===
                            CardTrainingType.CHEST_COMP_ONLY
                        }
                    >
                        <TableContent
                            disable={
                                props.trainingType ===
                                CardTrainingType.CHEST_COMP_ONLY
                            }
                            items={[
                                {
                                    title: {
                                        value: t('ResultDetail.TooLittle'),
                                        color:
                                            'gray-100 dark:text-gray-light-text',
                                    },
                                    value:
                                        props.resultCriteria !== null &&
                                        props.resultCriteria.VentilationVolume[
                                            '%_TooLittle'
                                        ] !== undefined
                                            ? props.resultCriteria
                                                  .VentilationVolume[
                                                  '%_TooLittle'
                                              ] + '%'
                                            : 0 + '%',
                                    border: true,
                                },
                                {
                                    title: {
                                        value: t('ResultDetail.Good'),
                                        color: 'green-light dark:green-light',
                                    },
                                    value:
                                        props.resultCriteria !== null &&
                                        props.resultCriteria.VentilationVolume[
                                            '%_Good'
                                        ] !== undefined
                                            ? props.resultCriteria
                                                  .VentilationVolume['%_Good'] +
                                              '%'
                                            : 0 + '%',
                                    xl: true,
                                    border: true,
                                },
                                {
                                    title: {
                                        value: t('ResultDetail.TooMuch'),
                                        color:
                                            'gray-100 dark:text-gray-light-text',
                                    },
                                    value:
                                        props.resultCriteria !== null &&
                                        props.resultCriteria.VentilationVolume[
                                            '%_TooMuch'
                                        ] !== undefined
                                            ? props.resultCriteria
                                                  .VentilationVolume[
                                                  '%_TooMuch'
                                              ] + '%'
                                            : 0 + '%',
                                    border: false,
                                },
                            ]}
                        />
                    </TableContainer>
                </div>
            </div>
            <div className="flex flex-col md:flex-row flex-grow">
                <div className="w-full md:w-1/2 md:mr-4 mb-5 md:mb-0">
                    <TableContainer
                        color="blue-sea dark:bg-blue-light-sea"
                        title={t('ResultDetail.CCF')}
                        margin={false}
                        disable={
                            props.trainingType ===
                            CardTrainingType.VENTILATION_ONLY
                        }
                    >
                        <TableContent
                            disable={
                                props.trainingType ===
                                CardTrainingType.VENTILATION_ONLY
                            }
                            items={[
                                {
                                    value:
                                        props.resultCriteria !== null
                                            ? props.resultCriteria.ScoreOfCCF
                                            : 0,
                                    border: false,
                                    xl: true,
                                },
                            ]}
                        />
                    </TableContainer>
                </div>
                <div className="w-full md:w-1/2">
                    <TableContainer
                        color="blue-800 dark:bg-blue-light"
                        title={t('ResultDetail.VentFreq')}
                        margin={false}
                        disable={
                            props.trainingType ===
                            CardTrainingType.CHEST_COMP_ONLY
                        }
                    >
                        <TableContent
                            disable={
                                props.trainingType ===
                                CardTrainingType.CHEST_COMP_ONLY
                            }
                            items={[
                                {
                                    title: {
                                        value: t('ResultDetail.TooSlow'),
                                        color:
                                            'gray-100 dark:text-gray-light-text',
                                    },
                                    value:
                                        props.resultCriteria !== null &&
                                        props.resultCriteria.VentilationRate[
                                            '%_InFrequently'
                                        ] !== undefined
                                            ? props.resultCriteria
                                                  .VentilationRate[
                                                  '%_InFrequently'
                                              ] + '%'
                                            : 0 + '%',
                                    border: true,
                                },
                                {
                                    title: {
                                        value: t('ResultDetail.Good'),
                                        color: 'green-light dark:green-light',
                                    },
                                    value:
                                        props.resultCriteria !== null &&
                                        props.resultCriteria.VentilationRate[
                                            '%_Good'
                                        ] !== undefined
                                            ? props.resultCriteria
                                                  .VentilationRate['%_Good'] +
                                              '%'
                                            : 0 + '%',
                                    xl: true,
                                    border: true,
                                },
                                {
                                    title: {
                                        value: t('ResultDetail.TooFast'),
                                        color:
                                            'gray-100 dark:text-gray-light-text',
                                    },
                                    value:
                                        props.resultCriteria !== null &&
                                        props.resultCriteria.VentilationRate[
                                            '%_TooFrequently'
                                        ] !== undefined
                                            ? props.resultCriteria
                                                  .VentilationRate[
                                                  '%_TooFrequently'
                                              ] + '%'
                                            : 0 + '%',
                                    border: false,
                                },
                            ]}
                        />
                    </TableContainer>
                </div>
            </div>
        </FadeInContainer>
    )
}
