import React, { PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import BraydenCore from '@innosonian/brayden-core'
import FadeInContainer from '../FadeInContainer/FadeInContainer'
import ClassroomCamera from 'view/component/ClassroomCamera/ClassroomCamera'
import SideCameraView from 'view/component/SideCameraView/SideCameraVidew'
import { IProps } from './AdminFullScreenShare.type'

export default function AdminFullScreenShare(props: PropsWithChildren<IProps>) {
    const isFullScreen = useSelector(
        BraydenCore.Entity.Classroom.Selector.isAdminFullScreen
    )
    const adminUid = useSelector(
        BraydenCore.Entity.Classroom.Selector.getAdminUid
    )

    const handleMargin = () => {
        if (props.marginSize === 'small') {
            return 'p-4'
        }
        return
    }
    const handleContainerSize = () => {
        if (props.containerSize === 'medium') {
            return 'w-3/5'
        }
        return 'w-4/5'
    }

    return (
        <FadeInContainer className={`${props.className} flex flex-row w-ful `}>
            <div
                className={`${handleContainerSize()} flex flex-col h-full w-full `}
            >
                {isFullScreen === true && props.isUserWaiting ? (
                    <>
                        <ClassroomCamera
                            userId={`screen_${adminUid}`}
                            size="full"
                            type="Camera"
                        />
                    </>
                ) : (
                    <div className={`${handleMargin()} h-full`}>
                        {props.children}
                    </div>
                )}
            </div>
            {props.showSideCameraView && (
                <SideCameraView
                    showImageSlider={
                        props.showImageSlider && !props.isUserWaiting
                    }
                />
            )}
        </FadeInContainer>
    )
}
