import BraydenCore from '@innosonian/brayden-core'
import usePrevious from 'hook/usePrevious'
import React, { RefObject, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import useResizeObserver from 'use-resize-observer'
import { IProps } from './HitIndicator.type'
import { useTranslation } from 'react-i18next'
import { ICalibrationResume } from '@innosonian/brayden-core/build/src/entity/Calibration/Calibration.interface'
import { RTData } from '@innosonian/brayden-core/build/src/_bluetooth/bluetooth.interface'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'
import { CardTrainingType } from '@innosonian/brayden-core/build/src/entity/Card/Card.interface'

function getPos(
    cRateData: number,
    cDepthData: number,
    squareWidth: number,
    squareHeight: number,
    calibrationResume: ICalibrationResume
) {
    const x = BraydenCore.Calculation.RatioCalculation.getSpeedRatio(
        cRateData,
        squareWidth,
        calibrationResume
    )
    const y = BraydenCore.Calculation.RatioCalculation.getDepthRatio(
        cDepthData,
        squareHeight,
        calibrationResume
    )
    return [x, y]
}

function getPreviousCcnt(lastChestCompression: Uint8Array | undefined) {
    if (lastChestCompression === undefined) {
        return -1
    }
    return lastChestCompression[RTData.C_CNT] - 1
}

function handleColor(
    cRate: number,
    compVal: number,
    calibrationResume: ICalibrationResume
) {
    if (
        cRate <= calibrationResume.speedGoodMax &&
        cRate >= calibrationResume.speedGoodMin &&
        compVal <= calibrationResume.shallowGoodMax &&
        compVal >= calibrationResume.shallowMin
    ) {
        return '#81D043'
    }
    return '#C4C4C4'
}

function drawGlobalHitView(
    canvasRef: RefObject<HTMLCanvasElement>,
    width: number,
    height: number,
    calibrationResume: ICalibrationResume,
    dataPushed: Uint8Array[]
) {
    if (canvasRef.current !== null) {
        const ctx = canvasRef.current.getContext('2d')
        if (ctx !== null && dataPushed.length !== 0) {
            const squareWidth = width / 3
            const squareHeight = height / 3
            for (
                let i = 1;
                i <= dataPushed[dataPushed.length - 1][RTData.CYCLE];
                i++
            ) {
                const cycleData = BraydenCore.Calculation.RatioCalculation.getCycle(
                    dataPushed,
                    i
                )
                if (cycleData.length > 2) {
                    for (
                        let y = 0;
                        y <= cycleData[cycleData.length - 2][RTData.C_CNT];
                        y++
                    ) {
                        const dataList = cycleData.filter(
                            (elem) => elem[RTData.C_CNT] === y
                        )
                        const cRate = BraydenCore.Calculation.RatioCalculation.getBiggestCRateValue(
                            dataList
                        )
                        const compVal = BraydenCore.Calculation.RatioCalculation.getBiggestCompValue(
                            dataList
                        )
                        const pos = getPos(
                            cRate,
                            compVal,
                            squareWidth,
                            squareHeight,
                            calibrationResume
                        )
                        const isRecoilValid = BraydenCore.Calculation.RecoilCalculation.isRecoilValid(
                            dataList,
                            calibrationResume
                        )
                        ctx.beginPath()
                        ctx.arc(pos[0], pos[1], 8, 0, 2 * Math.PI, false)
                        ctx.closePath()
                        if (isRecoilValid === true) {
                            ctx.fillStyle = handleColor(
                                cRate,
                                compVal,
                                calibrationResume
                            )
                            ctx.fill()
                        } else {
                            ctx.strokeStyle = handleColor(
                                cRate,
                                compVal,
                                calibrationResume
                            )
                            ctx.stroke()
                        }
                    }
                }
            }
        }
    }
}

export default function HitIndicator(props: IProps) {
    const { t } = useTranslation('locale')
    const calibrationResume = useSelector(
        BraydenCore.Entity.Calibration.CalibrationSelector.getCalibrationResume
    )
    const cardId = useSelector(
        BraydenCore.Entity.Session.SessionSelector.getCurrentTrainingCardId
    )
    const card = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Card.CardSelector.getCardById(state, cardId)
    )
    const [dotList, addDotToDotList] = useState(
        [] as { x: number; y: number; isRecoilValid: boolean; color: string }[]
    )
    const canvasRef = useRef<HTMLCanvasElement>(null)

    const lastChestCompression = useSelector(
        BraydenCore.Entity.Session.SessionSelector.getLastChestCompression
    )
    const lastCompressionList = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Session.SessionSelector.getLastCompressionList(
            state,
            getPreviousCcnt(lastChestCompression),
            lastChestCompression !== undefined
                ? lastChestCompression[RTData.CYCLE]
                : 0
        )
    )
    const dataPushed = useSelector(
        BraydenCore.Entity.Session.SessionSelector.getDataPushed
    )
    const previousLastChestCompression = usePrevious(lastChestCompression)
    const { ref, width = 1, height = 1 } = useResizeObserver<HTMLDivElement>()
    const { active } = props

    useEffect(() => {
        if (
            lastChestCompression === undefined ||
            lastCompressionList.length === 0
        ) {
            return
        }
        const squareWidth = width / 3
        const squareHeight = height / 3
        if (
            active === true &&
            lastChestCompression !== previousLastChestCompression
        ) {
            const cRate = BraydenCore.Calculation.RatioCalculation.getBiggestCRateValue(
                lastCompressionList
            )
            const compVal = BraydenCore.Calculation.RatioCalculation.getBiggestCompValue(
                lastCompressionList
            )
            const pos = getPos(
                cRate,
                compVal,
                squareWidth,
                squareHeight,
                calibrationResume
            )
            const recoil = BraydenCore.Calculation.RecoilCalculation.isRecoilValid(
                lastCompressionList,
                calibrationResume
            )
            addDotToDotList((list) => [
                ...list,
                {
                    x: pos[0],
                    y: pos[1],
                    isRecoilValid: recoil,
                    color: handleColor(cRate, compVal, calibrationResume),
                },
            ])
        }
    }, [
        height,
        width,
        dotList,
        active,
        lastChestCompression,
        previousLastChestCompression,
        lastCompressionList,
        calibrationResume,
    ])

    useEffect(() => {
        if (canvasRef.current !== null) {
            const ctx = canvasRef.current.getContext('2d')
            if (ctx !== null) {
                ctx.clearRect(
                    0,
                    0,
                    canvasRef.current.width,
                    canvasRef.current.height
                )
                dotList.forEach((dot, idx) => {
                    const color = idx < dotList.length - 1 ? dot.color : 'red'
                    ctx.beginPath()
                    ctx.arc(dot.x, dot.y, 8, 0, 2 * Math.PI, false)
                    ctx.closePath()
                    if (dot.isRecoilValid === true) {
                        ctx.fillStyle = color
                        ctx.fill()
                    } else {
                        ctx.strokeStyle = color
                        ctx.stroke()
                    }
                })
            }
        }
    }, [dotList])

    useEffect(() => {
        if (
            props.globalView === true &&
            card.type !== CardTrainingType.VENTILATION_ONLY
        ) {
            drawGlobalHitView(
                canvasRef,
                width,
                height,
                calibrationResume,
                dataPushed
            )
        }
    })
    return (
        <div className={`w-full relative h-auto`} ref={ref}>
            <div className="w-full flex flex-grow-1 h-33pct border-b border-gray-200 dark:border-gray-light-default-text">
                <div className="w-1/3 flex flex-col border-r border-gray-200 dark:border-gray-light-default-text justify-between pb-3"></div>
                <div className="w-1/3 flex border-r border-gray-200 dark:border-gray-light-default-text">
                    <div className="w-full text-gray-100 flex justify-center text-center">
                        {t('RealTimeTrainingPage.Shallow')}
                    </div>
                </div>
                <div className="w-1/3 flex"></div>
            </div>
            <div className="w-full flex flex-grow-1 h-33pct border-b border-gray-200 dark:border-gray-light-default-text">
                <div className="w-1/3 flex border-r border-gray-200 dark:border-gray-light-default-text">
                    <div className="w-full text-gray-100 flex flex-col justify-center">
                        {t('RealTimeTrainingPage.Slow')}
                    </div>
                </div>
                <div className="w-1/3 flex border-r border-gray-100 bg-gray-300 dark:bg-gray-250"></div>
                <div className="w-1/3 flex">
                    <div className="w-full text-gray-100 flex flex-col justify-center ml-auto text-right">
                        {t('RealTimeTrainingPage.Fast')}
                    </div>
                </div>
            </div>
            <div className="w-full flex flex-grow-1 h-33pct">
                <div className="w-1/3 flex flex-col justify-end border-r border-gray-200 dark:border-gray-light-default-text"></div>
                <div className="w-1/3 flex border-r border-gray-200 dark:border-gray-light-default-text">
                    <div className="w-full text-gray-100 flex flex-col justify-end text-center">
                        {t('RealTimeTrainingPage.Deep')}
                    </div>
                </div>
                <div className="w-1/3 flex"></div>
            </div>
            <canvas
                className="absolute inset-0"
                ref={canvasRef}
                height={`${height}px`}
                width={`${width}px`}
            />
        </div>
    )
}
