import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Typography from 'view/component/Typography/Typography'
import Button from 'view/component/Button/Button'
import ModalContainer from '../ModalContainer/ModalContainer'
import { ReactComponent as EmailSentIcon } from 'resource/img/icons/emailSent.svg'
import BraydenCore from '@innosonian/brayden-core'
import { useHistory } from 'react-router-dom'

export default function EmailCheckModal() {
    const { t } = useTranslation('locale')
    const dispatch = useDispatch()
    const history = useHistory()
    const isOpen = useSelector(
        BraydenCore.Entity.Modal.ModalSelector.isRegistrationSuccessOpen
    )
    const handleClose = () => {
        dispatch(
            BraydenCore.Entity.Modal.ModalAction.modalCloseRegistrationSuccess()
        )
        history.push('/login')
    }

    return (
        <ModalContainer isOpen={isOpen} closeButton={false}>
            <div className="flex h-full  w-full lg:px-10 justify-center items-center md:py-16">
                <div className="hidden md:flex md:mr-20">
                    <EmailSentIcon className="md:h-40 md:w-40 xl:h-40 xl:w-40 text-gray-100" />
                </div>
                <div className="w-full md:w-1/2 ">
                    <div className="pb-2 mb-4 border-b border-gray-100">
                        <Typography type="h2">
                            {t('SignUp.RegistrationComplete')}
                        </Typography>
                    </div>
                    <div className="w-full flex flex-grow-1">
                        <Typography>{t('SignUp.CheckEmail')}.</Typography>
                    </div>
                    <div className="w-full flex py-10">
                        <Button
                            shape="rounded-mid"
                            color="secondary"
                            onclick={() => handleClose()}
                            disable={false}
                            size="sm"
                        >
                            {t('SignUp.Login')}
                        </Button>
                    </div>
                </div>
            </div>
        </ModalContainer>
    )
}
