import React from 'react'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import hook from 'hook'
import Classroom from 'core/classroom'
import BraydenCore from '@innosonian/brayden-core'
import Typography from '../Typography/Typography'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'
import { LOCAL_STORAGE_KEY } from '@innosonian/brayden-core/build/src/_localStorage/localStorage.interface'
import { ReactComponent as ScreenShare } from 'resource/img/icons/screen-share.svg'
import { ReactComponent as Mic } from 'resource/img/icons/microphone.svg'
import { ReactComponent as MicMuted } from 'resource/img/icons/muted.svg'
export default function ClassroomLocalCommand() {
    const { t } = useTranslation('locale')
    const userId = localStorage.getItem(LOCAL_STORAGE_KEY.EMAIL) as string
    const userVideoDetail = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Classroom.Selector.getUserById(state, userId)
    )
    const isScreenConnected = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Classroom.Selector.getScreenConnectionStatus(state)
    )
    const { audioForceOff } = hook.useForceMute()

    const muteUnmute = () => {
        if (userVideoDetail.soundEnabled) {
            Classroom.Video.muteLocalMicro()
        } else {
            if (audioForceOff) return
            Classroom.Video.unMuteLocalMicro()
        }
    }
    const startOrStopScreenShare = async () => {
        if (isScreenConnected) {
            try {
                await Classroom.Screen.stopScreenSharing()
            } catch (e) {
                console.log(e)
            }
        } else {
            await Classroom.initScreenShare()
        }
    }

    const handleScreenShareColor = () =>
        isScreenConnected === true
            ? 'text-blue-800 dark:text-red-light'
            : 'text-gray-50 dark:text-gray-light-icon'
    return (
        <div>
            <div className="flex flex-row w-full h-10 border border-gray-100  justify-between items-center px-3">
                <div
                    className="w-full h-full flex flex-col items-start cursor-pointer "
                    onClick={muteUnmute}
                >
                    <div
                        className={`h-full w-30 flex flex-row items-center group `}
                    >
                        {userVideoDetail.soundEnabled === false ? (
                            <MicMuted className="h-8 w-8" />
                        ) : (
                            <Mic className="h-8 w-8 text-green-800 " />
                        )}
                        <Typography
                            type="caption"
                            className={`${
                                audioForceOff
                                    ? 'visible opacity-0 group-hover:opacity-100'
                                    : 'invisible'
                            }  `}
                        >
                            {t('Instructor muted your mic')}
                        </Typography>
                    </div>
                </div>
                <ScreenShare
                    className={`h-10 cursor-pointer text-gray-50 dark:text-gray-light-default-button ${handleScreenShareColor()}`}
                    onClick={startOrStopScreenShare}
                />
            </div>
        </div>
    )
}
