import React, { Suspense } from 'react'
import { Router, Route, Switch } from 'react-router-dom'
import MainLayout from 'view/layout/MainLayout/MainLayout'
import ComponentDisplay from 'view/page/ComponentDisplayer/ComponentDisplay'
import Home from 'view/page/Home/Home'
import ErrorRouter from 'view/page/Error/ErrorRouter'
import Training from 'view/page/Training/Training'
import TrainingList from 'view/page/TrainingList/TrainingList'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ManikinRequiredRoute from 'view/Router/ManikinRequiredRoute/ManikinRequired'
import LogIn from 'view/page/Login/Login'
import Welcome from 'view/page/Welcome/Welcome'
import AuthenticatedRoute from './AuthenticatedRoute/AuthenticatedRoute'
import FallBack from 'view/component/FallBack/FallBack'
import RecordRouter from 'view/page/Record/RecordRouter'
import ResultRouter from '../page/Result/ResultRouter'
import { history } from 'core/history/history.provider'
import { useSelector } from 'react-redux'
import BraydenCore from '@innosonian/brayden-core'
import HomeAdmin from '../page/Home/HomeAdmin'
import SetUp from '../page/SetUp/Setup'
import Configuration from 'view/page/Configuration/Configuration'
import UserClientList from '../page/UserClient/UserClientList'
import RecordConfiguration from '../page/RecordConfiguration/RecordConfiguration'
import CreateCertificate from 'view/page/Certificate/CreateCertificate'
import UpdateCertificate from 'view/page/Certificate/EditCertificate'
import SignUp from '../page/SignUp/SignUp'
import ModalManager from '../component/Modal/ModalManager'

const Certificate = React.lazy(
    () =>
        import(
            /* webpackChunkName: "Certificate" */ 'view/page/Certificate/Certificate'
        )
)
const Records = React.lazy(
    () => import(/* webpackChunkName: "Records" */ '../page/Records/Records')
)
const AdminStats = React.lazy(
    () =>
        import(
            /* webpackChunkName: "AdminStats" */ 'view/page/AdminStats/AdminStats'
        )
)
const UserList = React.lazy(
    () => import(/* webpackChunkName: "UserList" */ 'view/page/User/UserList')
)
const AdminClassroom = React.lazy(
    () =>
        import(
            /* webpackChunkName: "Classroom" */ '../page/AdminClassroom/AdminClassroom'
        )
)

function MainRouter() {
    const isAdmin = useSelector(
        BraydenCore.Entity.Client.ClientSelector.getIsAdmin
    )
    return (
        <Router history={history}>
            <Suspense fallback={<FallBack />}>
                <MainLayout>
                    <Switch>
                        <Route path="/" exact component={Welcome} />
                        <Route path="/login" component={LogIn} />
                        <Route path="/signUp" component={SignUp} />
                        <Route
                            path="/home"
                            exact
                            component={isAdmin === true ? HomeAdmin : Home}
                        />
                        <Route
                            path="/component-displayer"
                            exact
                            component={ComponentDisplay}
                        />
                        <Route
                            path="/trainings"
                            exact
                            component={TrainingList}
                        />
                        <ManikinRequiredRoute
                            path="/trainings/:id"
                            component={Training}
                        />
                        <AuthenticatedRoute
                            path="/records"
                            exact
                            component={Records}
                            type="User"
                        />
                        <AuthenticatedRoute
                            path="/classroom"
                            exact
                            component={AdminClassroom}
                            type="Client"
                        />
                        <AuthenticatedRoute
                            path="/users"
                            exact
                            component={UserList}
                            type="Client"
                        />
                        <AuthenticatedRoute
                            path="/stats"
                            exact
                            component={AdminStats}
                            type="Client"
                        />
                        <AuthenticatedRoute
                            path="/adminManagement"
                            exact
                            component={UserClientList}
                            type="Client"
                        />
                        <AuthenticatedRoute
                            path="/certificates"
                            exact
                            component={Certificate}
                            type="Client"
                        />
                        <AuthenticatedRoute
                            path="/certificates/create/:type"
                            component={CreateCertificate}
                            type="Client"
                        />
                        <AuthenticatedRoute
                            path="/certificates/update/:type"
                            component={UpdateCertificate}
                            type="Client"
                        />
                        <AuthenticatedRoute
                            path="/config"
                            exact
                            component={Configuration}
                            type="Client"
                        />
                        <AuthenticatedRoute
                            path="/record-config"
                            exact
                            component={RecordConfiguration}
                            type="Client"
                        />

                        <AuthenticatedRoute
                            path="/setup"
                            exact
                            component={SetUp}
                            type="User"
                        />

                        <Route path="/records/:id" component={RecordRouter} />
                        <Route path="/result" component={ResultRouter} />
                        <Route component={ErrorRouter} />
                    </Switch>
                    <ModalManager />
                    <ToastContainer />
                </MainLayout>
            </Suspense>
        </Router>
    )
}

export default MainRouter
