const getProdStage = () => {
    return !isDevStage() && !isBetaStage()
}

export const isDevStage = () => {
    return (
        process.env.REACT_APP_STAGE === 'local' ||
        process.env.REACT_APP_STAGE === 'development'
    )
}
export const isBetaStage = () => {
    return process.env.REACT_APP_STAGE === 'beta'
}

export default getProdStage
