import React, { useEffect, useState } from 'react'
import ModalContainer from '../ModalContainer/ModalContainer'
import BraydenCore from '@innosonian/brayden-core'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'view/component/Button/Button'
import { useTranslation } from 'react-i18next'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'
import Input from 'view/component/Input/Input'
import Typography from 'view/component/Typography/Typography'
import { sagaPromise } from '@innosonian/brayden-core/build/src/_reducer/reducer'
import { updateUser } from '@innosonian/brayden-core/build/src/entity/User/User.saga'
import { toast } from 'react-toastify'

export default function EditProfile() {
    const dispatch = useDispatch()
    const userId = useSelector(BraydenCore.Entity.Modal.ModalSelector.getUserId)
    const user = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.User.Selector.getUserById(state, userId as number)
    )

    const { t } = useTranslation('locale')
    const [name, setName] = useState(user.name)
    const [surname, setSurname] = useState(user.surname)
    const [email, setEmail] = useState(user.email)
    const [password, setPassword] = useState(user.password)
    const [isError, setError] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setName(user.name)
        setSurname(user.surname)
        setEmail(user.email)
        setPassword(user.password)
    }, [user])

    const updateAndSaveUser = async () => {
        setError(false)
        setLoading(true)
        try {
            await sagaPromise(updateUser, {
                ...user,
                name,
                surname,
                password,
            })
            toast(t('ToastManager.UpdateUserSuccess'), { type: 'success' })
            dispatch(
                BraydenCore.Entity.Modal.ModalAction.modalCloseEditProfile()
            )
        } catch (e) {
            setError(true)
        } finally {
            setLoading(false)
        }
    }

    return (
        <ModalContainer isOpen={userId !== undefined} closeButton={false}>
            <div className="flex flex-col h-full  w-full lg:px-10 justify-center items-center md:py-4">
                <div className="w-full pb-4">
                    <Typography type="h2" alignment="center">
                        {t('UserList.EditProfile')}
                    </Typography>
                </div>
                <div className="w-full flex flex-col">
                    {isError === true && (
                        <Typography
                            color="red-700"
                            className="w-full my-2"
                            alignment="center"
                        >
                            error
                        </Typography>
                    )}
                    <Input
                        label={t('UserList.Name')}
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="mb-5"
                        autocomplete="off"
                    ></Input>
                    <Input
                        label={t('UserList.Surname')}
                        type="text"
                        value={surname}
                        onChange={(e) => setSurname(e.target.value)}
                        className="mb-5"
                        autocomplete="off"
                    ></Input>
                    <Input
                        label={t('UserList.Email')}
                        type="email"
                        value={email}
                        onChange={(e) => console.log()}
                        isReadyOnly={true}
                        className="mb-5"
                        autocomplete="off"
                        disabled={true}
                    ></Input>
                    <Input
                        label={t('UserList.Password')}
                        type="text"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="mb-5"
                        autocomplete="off"
                    ></Input>
                </div>
                <div className="w-full flex justify-end">
                    <Button
                        disable={loading}
                        className="mr-2"
                        onclick={updateAndSaveUser}
                        type="plain"
                        shape="rounded-mid"
                        padding="sm"
                        color="secondary"
                    >
                        {t('UserList.Save')}
                    </Button>
                    <Button
                        disable={false}
                        onclick={() => {
                            setError(false)
                            dispatch(
                                BraydenCore.Entity.Modal.ModalAction.modalCloseEditProfile()
                            )
                        }}
                        type="plain"
                        shape="rounded-mid"
                        padding="sm"
                        color="danger"
                    >
                        {t('UserList.Cancel')}
                    </Button>
                </div>
            </div>
        </ModalContainer>
    )
}
