import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import BraydenCore from '@innosonian/brayden-core'
import Typography from 'view/component/Typography/Typography'
import TrainingCardItem from 'view/component/TrainingCardItem/TrainingCardItem'
import ManikinCarousel from 'view/component/ManikinCarousel/ManikinCarousel'
import FadeInContainer from '../../layout/FadeInContainer/FadeInContainer'
import AEDConfigModal from 'view/component/Modal/AEDConfigModal/AEDConfigModal'
import Button from 'view/component/Button/Button'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'
import { ModelCourseType } from '@innosonian/brayden-core/build/src/entity/Session/Session.interface'
import { ModelType } from '@innosonian/brayden-core/build/src/_bluetooth/bluetooth.interface'
import { isDevStage } from '../../../helper/getProdStage'

export default function TrainingList() {
    const { t } = useTranslation('locale')
    const dispatch = useDispatch()
    const [openAEDConfigModal, setOpenAEDConfigModal] = useState<boolean>(false)

    const isClassroomConnected = useSelector(
        BraydenCore.Entity.Classroom.Selector.isConnected
    )
    const babyProConnected = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Hardware.HardwareSelector.getManikinByType(
            state,
            'Infant'
        )
    )
    const adultConnected = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Hardware.HardwareSelector.getManikinByType(
            state,
            'Adult'
        )
    )

    const juniorConnected = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Hardware.HardwareSelector.getManikinByType(
            state,
            'Child'
        )
    )

    const [selectedManikin, setSelectedManikin] = useState<
        ModelCourseType | undefined
    >(() => {
        if (adultConnected !== undefined) {
            return 'Adult'
        }
        if (juniorConnected !== undefined) {
            return 'Child'
        }
        if (babyProConnected !== undefined) {
            return 'Infant'
        }
        return undefined
    })
    const trainCourse = useSelector((state: IMainReducer) =>
        selectedManikin !== undefined
            ? BraydenCore.Entity.TrainCourse.TrainCourseSelector.getTrainCourseById(
                  state,
                  selectedManikin
              )
            : selectedManikin
    )
    const trainTestCourse = useSelector((state: IMainReducer) =>
        selectedManikin !== undefined
            ? BraydenCore.Entity.TrainCourse.TrainCourseSelector.getTrainCourseById(
                  state,
                  'Infant'
              )
            : selectedManikin
    )

    useEffect(() => {
        if (
            selectedManikin !== undefined &&
            trainCourse !== undefined &&
            trainCourse.movie !== undefined
        ) {
            dispatch(
                BraydenCore.Entity.Modal.ModalAction.modalPlayVideo(
                    trainCourse.movie.url
                )
            )
        }
    }, [dispatch, trainCourse, selectedManikin])

    useEffect(() => {
        if (
            selectedManikin !== undefined &&
            trainTestCourse !== undefined &&
            trainTestCourse.movie !== undefined
        ) {
            dispatch(
                BraydenCore.Entity.Modal.ModalAction.modalPlayVideo(
                    trainTestCourse.movie.url
                )
            )
        }
    }, [dispatch, trainTestCourse, selectedManikin])

    const handleSelectedType = (modelType: ModelCourseType) => {
        setSelectedManikin(modelType)
    }

    const handleManikinSelection = () => {
        if (trainCourse !== undefined) {
            if (trainCourse.id === 'Adult') {
                dispatch(
                    BraydenCore.Entity.Session.SessionAction.selectManikin(
                        adultConnected as string
                    )
                )
            }
            if (trainCourse.id === 'Child') {
                dispatch(
                    BraydenCore.Entity.Session.SessionAction.selectManikin(
                        juniorConnected as string
                    )
                )
            }
            if (trainCourse.id === 'Infant') {
                dispatch(
                    BraydenCore.Entity.Session.SessionAction.selectManikin(
                        babyProConnected as string
                    )
                )
            }
        }
    }

    const connectAEDTrainer = async () => {
        await BraydenCore.Reducer.sagaPromise(
            BraydenCore.Entity.Session.connectAED,
            ModelType.BRAYDEN_AED
        )
    }

    return (
        <FadeInContainer className="w-full flex flex-col h-full items-center">
            {isDevStage() && (
                <div className="flex flex-col w-full items-end">
                    <div className="flex gap-2 relative">
                        <Button
                            disable={false}
                            shape="square"
                            size="sm"
                            padding="sm"
                            color="secondary"
                            onclick={connectAEDTrainer}
                        >
                            AED-T START
                        </Button>
                        <Button
                            disable={false}
                            shape="square"
                            size="sm"
                            padding="sm"
                            color="gray"
                            onclick={() =>
                                setOpenAEDConfigModal((prev) => !prev)
                            }
                        >
                            AED-T SETTING
                        </Button>
                    </div>
                    <AEDConfigModal
                        isOpen={openAEDConfigModal}
                        onClick={() => setOpenAEDConfigModal(false)}
                    />
                </div>
            )}

            <div
                className={`${
                    (adultConnected !== undefined ||
                        juniorConnected !== undefined ||
                        babyProConnected !== undefined) &&
                    'invisible '
                }`}
            >
                <Typography type="h1" weight="bold" className="pr-0  mb-14">
                    {t('Please connect the manikin')}
                </Typography>
            </div>
            <ManikinCarousel
                onIdxUpdate={(modelType) => handleSelectedType(modelType)}
                defaultValue="Adult"
            />

            <div className="w-full flex flex-col md:flex-row mx-auto md:mx-0 items-center justify-end md:justify-center py-10 md:px-10">
                <Typography
                    type="h2"
                    weight="bold"
                    className="pr-0 md:pr-5  md:mr-0"
                >
                    {t('MenuHome.Title')}
                </Typography>
                <Typography>
                    {trainCourse !== undefined && trainCourse.menuTitleText}
                </Typography>
            </div>
            {trainCourse !== undefined && (
                <div className="w-full xl:px-10 flex flex-wrap justify-center group">
                    {trainCourse.cards.map((id) => {
                        return (
                            <TrainingCardItem
                                isClassroomConnected={isClassroomConnected}
                                id={id}
                                testId={'35dfe71d-f98f-43ec-9a69-c68f3f953c37'}
                                key={id}
                                onClick={handleManikinSelection}
                            />
                        )
                    })}
                </div>
            )}
        </FadeInContainer>
    )
}
