import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import Typography from 'view/component/Typography/Typography'
import Button from 'view/component/Button/Button'
import ModalContainer from '../ModalContainer/ModalContainer'
import { ReactComponent as Certification } from 'resource/img/certificate.svg'
import BraydenCore from '@innosonian/brayden-core'

export default function CertificationModal() {
    const { t } = useTranslation('locale')
    const dispatch = useDispatch()
    const isCertificate = useSelector(
        BraydenCore.Entity.Modal.ModalSelector.getCertificate
    )
    const handleClose = () => {
        dispatch(BraydenCore.Entity.Modal.ModalAction.modalCloseCertification())
    }

    return (
        <ModalContainer isOpen={isCertificate === true} closeButton={false}>
            <div className="flex h-full  w-full lg:px-10 justify-center items-center md:py-16">
                <div className="hidden md:flex md:mr-10">
                    <Certification className="md:h-56 md:w-56 xl:h-96 xl:w-96 text-gray-100" />
                </div>
                <div className="w-full md:w-1/2 ">
                    <div className="pb-2">
                        <Typography type="h2">
                            {t('Notification.Certificate.Title')}
                        </Typography>
                    </div>
                    <div className="w-full flex flex-grow-1">
                        <Typography>
                            {t('Notification.Certificate.Content')}.
                        </Typography>
                    </div>
                    <div className="w-full flex py-10">
                        <Button
                            shape="rounded-mid"
                            color="blue"
                            onclick={() => handleClose()}
                            disable={false}
                        >
                            {t('Notification.Certificate.Close')}
                        </Button>
                    </div>
                </div>
            </div>
        </ModalContainer>
    )
}
