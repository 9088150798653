import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ModalContainer from '../ModalContainer/ModalContainer'
import BraydenCore from '@innosonian/brayden-core'
import Typography from 'view/component/Typography/Typography'
import Lottie from 'view/component/Lottie/Lottie'
import LottieGuideline from 'resource/lottie/cpr-guideline.json'
import { useTranslation } from 'react-i18next'

export default function GuidelineModal() {
    const dispatch = useDispatch()
    const { t } = useTranslation('locale')
    const isOpen = useSelector(
        BraydenCore.Entity.Modal.ModalSelector.isGuidelineOpen
    )

    return (
        <ModalContainer
            isOpen={isOpen}
            closeButton={true}
            onCloseClick={() =>
                dispatch(
                    BraydenCore.Entity.Modal.ModalAction.modalCloseGuideline()
                )
            }
        >
            <div className="w-full flex flex-col items-center">
                <div className="w-full flex justify-center mb-4">
                    <Typography type="h2">{t('Guideline.Title')}</Typography>
                </div>
                <div className="w-100 mx-auto">
                    <Lottie
                        loop={true}
                        animationData={LottieGuideline}
                        play={true}
                    />
                </div>
            </div>
        </ModalContainer>
    )
}
