export const line = {
    textColor: '#9FA2B4',
    fontSize: 11,
    tooltip: {
        container: {
            background: 'white',
            color: '#2B2E36',
            fontSize: 'inherit',
            borderRadius: '2px',
            boxShadow: '0 1px 2px rgba(0, 0, 0, 0.25)',
            padding: '5px 9px',
        },
    },
    axis: {
        domain: {
            line: {
                stroke: '#586071',
                strokeWidth: 1,
            },
        },
        ticks: {
            line: {
                stroke: '#586071',
                strokeWidth: 1,
            },
        },
    },
    grid: {
        line: {
            stroke: '#586071',
            strokeWidth: 1,
        },
    },
}
