import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import 'resource/css/tailwind.css'
import Router from 'view/Router/Router'
import * as serviceWorker from './serviceWorker'
import FallBack from 'view/component/FallBack/FallBack'
import ErrorBoundary from 'view/context/Error/ErrorBoundary'
import BrowserValidator from 'view/context/BrowserValidator/BrowserValidator'
import Modal from 'react-modal'
import BraydenCore from '@innosonian/brayden-core'
import bluetoothProvider from 'core/bluetooth/bluetooth.provider'
import history from 'core/history/history.provider'
import 'core/i18n/i18n'
import localStorageProvider from 'core/localStorage/localStorage.provider'
import configProvider from 'core/config/config.provider'
import eventListenerProvider from 'core/eventListener/eventListener.provider'
import bufferProvider from 'core/buffer/buffer.provider'
import * as Sentry from '@sentry/browser'
import { Integrations } from '@sentry/tracing'
import { provider } from 'core/classroom/'
import ToasterManager from './view/component/ToasterManager/ToasterManager'
import { LOCAL_STORAGE_KEY } from '@innosonian/brayden-core/build/src/_localStorage/localStorage.interface'
function initSentry() {
    const sentryActivated = process.env.REACT_APP_SENTRY_ACTIVATED as string
    if (sentryActivated === 'true') {
        Sentry.init({
            dsn:
                'https://fb39ec6f503b4967b5664475e866f91f@o981966.ingest.sentry.io/5936570',
            integrations: [new Integrations.BrowserTracing()],

            tracesSampleRate: 1.0,
        })
    }
}

async function loadRequirement() {
    initSentry()
    BraydenCore.webRtcManager.updateProvider(provider)
    BraydenCore.bufferManager.updateProvider(bufferProvider)
    BraydenCore.bluetoothManager.updateProvider(bluetoothProvider)
    BraydenCore.history.updateProvider(history)
    BraydenCore.eventListenerManager.updateProvider(eventListenerProvider)
    BraydenCore.localStorageManager.updateProvider(localStorageProvider)
    BraydenCore.configManager.updateProvider(configProvider)
    BraydenCore.Reducer.sagaMiddleware.run(BraydenCore.rootSaga)
    if (localStorage.getItem(LOCAL_STORAGE_KEY.REMEMBER_TOKEN) !== null) {
        BraydenCore.Reducer.store.dispatch(
            BraydenCore.Entity.Oauth.Action.setIsSwitched()
        )
    }
    if (BraydenCore.Authentication.isAuthenticated() === true) {
        BraydenCore.Reducer.store.dispatch(
            BraydenCore.Entity.Client.ClientAction.getUserRequest()
        )
        if (localStorage.getItem(LOCAL_STORAGE_KEY.IS_ADMIN) !== null) {
            BraydenCore.Reducer.store.dispatch(
                BraydenCore.Entity.Client.ClientAction.setAdmin()
            )
            BraydenCore.Reducer.store.dispatch(
                BraydenCore.Entity.Oauth.Action.oauthGetClientInfoRequest()
            )
        } else {
        }
        return Promise.resolve()
    } else {
        return BraydenCore.Reducer.sagaPromise(
            BraydenCore.Entity.Client.getClient,
            {
                clientId: process.env.REACT_APP_CLIENT_ID as string,
                passcode: process.env.REACT_APP_CLIENT_PASS_CODE as string,
            }
        )
    }
}

loadRequirement().then(() => {
    Modal.setAppElement('#root')
    ReactDOM.render(
        <React.StrictMode>
            <Suspense fallback={<FallBack />}>
                <Provider store={BraydenCore.Reducer.store}>
                    <ErrorBoundary>
                        <ToasterManager />
                        <BrowserValidator>
                            <Router />
                        </BrowserValidator>
                    </ErrorBoundary>
                </Provider>
            </Suspense>
        </React.StrictMode>,
        document.getElementById('root')
    )
})

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
