import { IProps } from './AuthenticatedRoute.type'
import { Redirect, Route } from 'react-router-dom'
import React from 'react'
import { LOCAL_STORAGE_KEY } from '@innosonian/brayden-core/build/src/_localStorage/localStorage.interface'

export default function AuthenticatedRoute({
    component: Component,
    ...rest
}: IProps) {
    if (localStorage.getItem(LOCAL_STORAGE_KEY.ACCESS_TOKEN) !== null) {
        if (
            localStorage.getItem(LOCAL_STORAGE_KEY.IS_ADMIN) !== null &&
            rest.type === 'Client'
        ) {
            return (
                <Route {...rest} render={(props) => <Component {...props} />} />
            )
        }
        if (
            localStorage.getItem(LOCAL_STORAGE_KEY.IS_ADMIN) === null &&
            rest.type === 'User'
        ) {
            return (
                <Route {...rest} render={(props) => <Component {...props} />} />
            )
        }
    }
    return <Redirect to="/" />
}
