import {
    HeaderType,
    IBlueToothCommunication,
    IBluetoothProvider,
    ModelType,
} from '@innosonian/brayden-core/build/src/_bluetooth/bluetooth.interface'
import BraydenCore from '@innosonian/brayden-core'
import { ErrorType } from '@innosonian/brayden-core/build/src/entity/Error'
import { GuidelineEventType } from '@innosonian/brayden-core/build/src/_bluetooth/bluetooth.interface'
import { AedConfigLanguageType } from '@innosonian/brayden-core/build/src/_bluetooth/bluetooth.interface'

type BluetoothCom = IBlueToothCommunication<
    BluetoothDevice,
    BluetoothRemoteGATTCharacteristic,
    BluetoothRemoteGATTCharacteristic
>
let bluetoothCommunication: BluetoothCom[] = []

async function writeOnDevice(
    deviceProvider: BluetoothCom | undefined,
    buffer: Buffer
) {
    if (deviceProvider !== undefined) {
        try {
            if (deviceProvider.write.writeValueWithoutResponse !== undefined) {
                await deviceProvider.write.writeValueWithoutResponse(buffer)
            } else {
                await deviceProvider.write.writeValue(buffer)
            }
        } catch (e) {
            console.log(e)
        }
    }
}

async function getStatus(connectionNumber: number, type: number, id: string) {
    await handleConnection(id)
    const buffer = Buffer.alloc(3)
    buffer[0] = HeaderType.STATUS
    buffer[1] = connectionNumber
    buffer[2] = type
    const deviceProvider = getDeviceById(id)
    await writeOnDevice(deviceProvider, buffer)
}
async function getConfigData(id: string) {
    const deviceProvider = getDeviceById(id)
    console.error('Device Provider', deviceProvider)
    // const data: DataView = deviceProvider.notify.value
    // return data
}

function handleDisconnection(id: string) {
    removeDeviceById(id)
    BraydenCore.Reducer.store.dispatch(
        BraydenCore.Entity.Session.SessionAction.manikinDisconnected(id)
    )
}

function addDeviceToList(ble: BluetoothCom) {
    const idx = bluetoothCommunication.findIndex((_ble) => ble.id === _ble.id)
    if (idx !== -1) {
        bluetoothCommunication.splice(idx, 1)
    }
    bluetoothCommunication.push(ble)
}

async function getWriteCharacteristic(services: BluetoothRemoteGATTService[]) {
    const matrixCharacteristics = await Promise.all(
        services.map((service) => service.getCharacteristics())
    )
    const characteristics = matrixCharacteristics.reduce((prev, next) =>
        prev.concat(next)
    )
    return characteristics.find(
        (characteristic) =>
            characteristic.properties &&
            characteristic.properties.writeWithoutResponse === true
    ) as BluetoothRemoteGATTCharacteristic
}

async function getNotifyCharacteristic(services: BluetoothRemoteGATTService[]) {
    const matrixCharacteristics = await Promise.all(
        services.map((service) => service.getCharacteristics())
    )
    const characteristics = matrixCharacteristics.reduce((prev, next) =>
        prev.concat(next)
    )
    return characteristics.find(
        (characteristic) =>
            characteristic.properties &&
            characteristic.properties.notify === true
    ) as BluetoothRemoteGATTCharacteristic
}

export function getDeviceById(id: string): BluetoothCom | undefined {
    const idx = bluetoothCommunication.findIndex((ble) => ble.id === id)
    if (idx !== -1) {
        return bluetoothCommunication[idx]
    }
    return undefined
}

export function updateDeviceById(
    newBluetoothProvider: BluetoothCom,
    id: string
) {
    const idx = bluetoothCommunication.findIndex((ble) => ble.id === id)
    if (idx !== -1) {
        bluetoothCommunication[idx] = newBluetoothProvider
    }
    throw new Error(ErrorType.ERROR_MANIKIN_DISCONNECTED)
}

async function handleConnection(id: string) {
    const bluetoothProvider = getDeviceById(id)
    if (
        bluetoothProvider !== undefined &&
        bluetoothProvider.device.gatt &&
        bluetoothProvider.device.gatt.connected === false
    ) {
        console.log('reconnection', bluetoothProvider)
        const deviceReco = await bluetoothProvider.device.gatt.connect()
        const services = await deviceReco.getPrimaryServices()
        const writeCharacteristic = await getWriteCharacteristic(services)
        const notifyCharacteristic = await getNotifyCharacteristic(services)
        updateDeviceById(
            {
                ...bluetoothProvider,
                write: writeCharacteristic,
                notify: notifyCharacteristic,
            },
            id
        )
    }
}

function handleNotification(
    bluetoothEvent: Event,
    id: string,
    deviceId: string
) {
    const target = bluetoothEvent.target as any
    const event = new CustomEvent(deviceId === 'AED' ? 'aedData' : 'rtData', {
        detail: {
            value: target.value,
            id: id,
            deviceId: deviceId,
        },
    })
    document.dispatchEvent(event)
}

function handleFilter(filter: ModelType) {
    switch (filter) {
        case ModelType.BRAYDEN_BABY:
            return 'Brayden Baby'
        case ModelType.BRAYDEN_BABY_PRO:
            return 'Brayden Baby'
        case ModelType.BRAYDEN_PRO:
            return 'Brayden pro'
        case ModelType.BRAYDEN_AED:
            return 'Brayden AED'
        default:
            return 'Brayden pro'
    }
}
function handleLanguage(lang: string) {
    switch (lang) {
        case 'korean':
            return AedConfigLanguageType.KOREAN
        case 'english':
            return AedConfigLanguageType.ENGLISH
        default:
            return AedConfigLanguageType.ENGLISH
    }
}

function removeDeviceById(id: string) {
    const idx = bluetoothCommunication.findIndex((ble) => ble.id === id)
    bluetoothCommunication.splice(idx, 1)
}

const blueToothProvider: IBluetoothProvider<ModelType> = {
    getBluetoothDevice: async (filter: ModelType) => {
        const device = await navigator.bluetooth.requestDevice({
            filters: [
                filter === 'Brayden AED'
                    ? { name: 'Brayden pro AED' }
                    : {
                          namePrefix: handleFilter(filter),
                      },
            ],
            optionalServices: [
                '6e400001-b5a3-f393-e0a9-e50e24dcca9e',
                0xfe59,
                0x180a,
            ],
        })
        if (!device.gatt || device.name === undefined) {
            throw new Error(ErrorType.DEVICE_GATT_NOT_FOUND)
        }

        device.addEventListener('gattserverdisconnected', () => {
            handleDisconnection(device.id)
        })
        const deviceId = device.name.split(' ')[2]
        const GATTServer = await device.gatt.connect()
        const services = await GATTServer.getPrimaryServices()
        const writeCharacteristic = await getWriteCharacteristic(services)
        const notifyCharacteristic = await getNotifyCharacteristic(services)
        const notify = await notifyCharacteristic.startNotifications()
        notifyCharacteristic.addEventListener(
            'characteristicvaluechanged',
            (bluetoothEvent) => {
                handleNotification(bluetoothEvent, device.id, deviceId)
            }
        )

        addDeviceToList({
            device: device,
            notify: notify,
            write: writeCharacteristic,
            id: device.id,
        })
        if (device.name) {
            return { name: device.name.split(' ')[2], id: device.id }
        }
        return { name: '', id: device.id }
    },
    getStatus: getStatus,
    getConfigData: getConfigData,
    sendAEDShock: async (id: string) => {
        await handleConnection(id)
        const buffer = Buffer.alloc(2)
        buffer[0] = HeaderType.EMIT_AED_SHOCK
        buffer[1] = GuidelineEventType.SHOCK_DELIVERED
        const deviceProvider = getDeviceById(id)
        await writeOnDevice(deviceProvider, buffer)
    },
    updateAEDConfig: async (
        id: string,
        data: { volume: number; lang: string }
    ) => {
        await handleConnection(id)
        const buffer = Buffer.alloc(3)
        buffer[0] = HeaderType.AED_CONFIG_UPDATE
        buffer[1] = data.volume
        buffer[2] = handleLanguage(data.lang)
        const deviceProvider = getDeviceById(id)
        await writeOnDevice(deviceProvider, buffer)
    },
    getSimulationSetup: async (id: string) => {
        console.log(id)
    },
    rtStart: async (operationMode: number, id: string, speed: number) => {
        console.log(
            `Real time start with operation mode ${operationMode} id: ${id} speed ${speed}`
        )
        await handleConnection(id)
        const buffer = Buffer.alloc(2)
        buffer[0] =
            speed === 50 ? HeaderType.RT_START : HeaderType.RT_START_100_MS
        buffer[1] = operationMode
        const deviceProvider = getDeviceById(id)
        await writeOnDevice(deviceProvider, buffer)
    },

    calibrationSetup: async (id: string, ref: number[]) => {
        await handleConnection(id)
        const buffer = Buffer.from(ref)
        const deviceProvider = getDeviceById(id)
        await writeOnDevice(deviceProvider, buffer)
    },
    rtStop: async (id: string) => {
        await handleConnection(id)
        const buffer = Buffer.alloc(1)
        buffer[0] = HeaderType.RT_STOP
        const deviceProvider = getDeviceById(id)
        await writeOnDevice(deviceProvider, buffer)
    },
    disconnect: async (id: string) => {
        const deviceProvider = getDeviceById(id)
        if (
            deviceProvider !== undefined &&
            deviceProvider.device.gatt &&
            deviceProvider.device.gatt.connected
        ) {
            deviceProvider.device.gatt.disconnect()
            removeDeviceById(id)
        }
    },
    settingDevice: async (id: string) => {
        console.log(id)
    },
    speedTest: async (id: string, speed: number, dataNumber: number) => {
        await handleConnection(id)
        const buffer = Buffer.alloc(3)
        buffer[0] = HeaderType.START_DATA_TEST
        buffer[1] = speed
        buffer[2] = dataNumber
        const deviceProvider = getDeviceById(id)
        await writeOnDevice(deviceProvider, buffer)
    },
}

export default blueToothProvider
