import React, { useEffect } from 'react'
import { Route, Switch, useRouteMatch } from 'react-router-dom'
import ResultRecord from 'view/page/Record/RecordResult'
import CprQuality from 'view/page/Record/CprQuality'
import { useDispatch } from 'react-redux'
import { useParams } from 'react-router'
import BraydenCore from '@innosonian/brayden-core'

export default function RecordRouter() {
    const { path } = useRouteMatch()
    const { id } = useParams<{ id: string }>()
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(
            BraydenCore.Entity.Record.RecordAction.getRecordByIdRequest(id)
        )
        dispatch(
            BraydenCore.Entity.CprQuality.CprQualityAction.getCprQualityRequest(
                id
            )
        )
    }, [dispatch, id])

    return (
        <Switch>
            <Route path={`${path}`} exact component={ResultRecord} />
            <Route path={`${path}/cpr-quality`} component={CprQuality} />
        </Switch>
    )
}
