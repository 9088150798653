import React, { Suspense } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import FallBack from 'view/component/FallBack/FallBack'
import FadeInContainer from '../../layout/FadeInContainer/FadeInContainer'
import QualityCpr from 'view/component/CprQuality/QualityCpr/QualityCpr'
import BraydenCore from '@innosonian/brayden-core'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'
import Button from 'view/component/Button/Button'
import { useTranslation } from 'react-i18next'
import Typography from 'view/component/Typography/Typography'
import { ReactComponent as Star } from 'resource/img/icons/star.svg'
import { ReactComponent as HalfStar } from 'resource/img/icons/star-half.svg'
import { ReactComponent as EmptyStar } from 'resource/img/icons/star-empty.svg'
import { ReactComponent as StarDark } from 'resource/img/icons/star-dark.svg'
import { ReactComponent as HalfStarDark } from 'resource/img/icons/star-half-dark.svg'
import { ReactComponent as EmptyStarDark } from 'resource/img/icons/star-empty-dark.svg'
import hook from 'hook'

export default function CprQuality() {
    const history = useHistory()
    const { t } = useTranslation('locale')
    const { id } = useParams<{ id: string }>()
    const [theme] = hook.useDarkMode()

    const cprQuality = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.CprQuality.CprQualitySelector.getCprQualityById(
            state,
            id
        )
    )
    const getStars = () => Math.trunc(cprQuality.metaData.conclusion / 2)

    const drawStars = (cnt: number) => {
        const lists = []
        for (let i = 0; i < cnt; i++) {
            lists.push(
                theme === 'dark' ? (
                    <Star className="h-5 w-5  mr-2" />
                ) : (
                    <StarDark className="h-5 w-5 mr-2" />
                )
            )
        }
        return lists
    }

    const drawMissingStars = (cnt: number) => {
        const lists = []
        const totalStars =
            cnt + (cprQuality.metaData.conclusion % 2 === 1 ? 1 : 0)
        for (let i = 0; i < 5 - totalStars; i++) {
            lists.push(
                theme === 'dark' ? (
                    <EmptyStar className="h-5 w-5 mr-2" />
                ) : (
                    <EmptyStarDark className="h-5 w-5 mr-2" />
                )
            )
        }
        return lists
    }

    const drawHalfStar = () => {
        if (cprQuality.metaData.conclusion % 2 === 1) {
            return theme === 'dark' ? (
                <HalfStar className="h-5 w-5  mr-2" />
            ) : (
                <HalfStarDark className="h-5 w-5  mr-2" />
            )
        } else {
            return <div className="hidden"></div>
        }
    }

    const getTotalTime = () => {
        const totalSeconds = cprQuality.metaData.train_time_ms / 1000
        const min = Math.trunc(totalSeconds / 60)
        const seconds = Math.round(totalSeconds % 60)
        return (
            <Typography
                type="h3"
                alignment="center"
                weight="bold"
                className="pl-2"
            >
                {min}' {seconds}"
            </Typography>
        )
    }

    return (
        <Suspense fallback={<FallBack />}>
            <FadeInContainer className="w-full h-full flex flex-col">
                <div className="w-full flex flex-col  md:flex-row py-5 items-center">
                    <div className="w-full mb-4 md:mb-0 md:w-3/5 lg:w-2/5 bg-gray-850 rounded-lg dark:bg-gray-light-bg rounded-lg flex flex-col justify-center lg:mr-10 border border-gray-100">
                        <Typography
                            type="h3"
                            color="white dark:text-gray-light-text"
                            alignment="center"
                            className="bg-green-700 dark:bg-gray-light-default-button py-2 rounded-t-lg"
                        >
                            {t('QualityOfCpr.Ranking')}
                        </Typography>
                        <div className="w-full flex flex-col md:flex-row border-t border-gray-100">
                            <div className="flex-grow flex justify-center items-center border-b md:border-b-0 md:border-r border-gray-100">
                                <Typography
                                    type="h1"
                                    weight="bold"
                                    alignment="center"
                                    color="green-700 dark:text-green-light pl-4"
                                >
                                    {cprQuality.metaData.per_above_threshold}
                                </Typography>
                                <Typography type="h2" className="pr-4">
                                    %
                                </Typography>
                            </div>
                            <div className="w-full md:w-2/3 flex flex-col justify-center ">
                                {cprQuality.metaData.conclusion !== -1 && (
                                    <div className="w-full flex flex-row py-4 justify-center border-b border-gray-100">
                                        {drawStars(getStars())}
                                        {drawHalfStar()}
                                        {drawMissingStars(getStars())}
                                    </div>
                                )}
                                {cprQuality.metaData.conclusion === -1 && (
                                    <div className="w-full flex flex-row py-4 justify-center border-b border-gray-100">
                                        <Typography>---------</Typography>
                                    </div>
                                )}

                                <div className="flex w-full justify-center py-4">
                                    <Typography type="h3">Time: </Typography>
                                    {getTotalTime()}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className=" lg:my-0 md:mx-10 flex flex-col lg:flex-row items-center w-full flex-shrink items-center lg:mr-auto  items-center">
                        <Button
                            disable={false}
                            shape="rounded-mid"
                            onclick={() => {
                                history.push(`/trainings`)
                            }}
                            color="blue"
                            size="full"
                            className="mb-4 lg:mb-0 mx-auto md:mx-2"
                        >
                            {t('WebOverwrite.GoTrainingList')}
                        </Button>
                        <Button
                            disable={false}
                            shape="rounded-mid"
                            onclick={() => {
                                history.push(`/records/${id}`)
                            }}
                            color="secondary"
                            size="full"
                            className="mb-4 lg:mb-0 mx-auto md:mx-2"
                        >
                            {t('WebOverwrite.ResultLink')}
                        </Button>
                        <Button
                            disable={false}
                            shape="rounded-mid"
                            onclick={() => {
                                history.push('/records')
                            }}
                            color="danger"
                            size="full"
                            className="mb-4 lg:mb-0 mx-auto md:mx-2"
                        >
                            {t('Record.Navigation.GoBack')}
                        </Button>
                    </div>
                </div>
                <QualityCpr
                    cprQuality={cprQuality.data}
                    metaData={cprQuality.metaData}
                />
            </FadeInContainer>
        </Suspense>
    )
}
