import React from 'react'
import { IProps } from './Item.type'
import { useTranslation } from 'react-i18next'
import { ReactComponent as BabyIcon } from 'resource/img/icons/baby-icon.svg'
import { ReactComponent as AdultIcon } from 'resource/img/icons/adult-icon.svg'
import { ReactComponent as JuniorIcon } from 'resource/img/icons/junior-icon.svg'
import { ReactComponent as BabyConnectedIcon } from 'resource/img/icons/baby-connected.svg'
import { ReactComponent as AdultConnectedIcon } from 'resource/img/icons/adult-connected.svg'
import { ReactComponent as JuniorConnectedIcon } from 'resource/img/icons/junior-connected.svg'
import { motion } from 'framer-motion'
import { useSelector } from 'react-redux'
import BraydenCore from '@innosonian/brayden-core'
import Typography from 'view/component/Typography/Typography'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'
import { ModelCourseType } from '@innosonian/brayden-core/build/src/entity/Session/Session.interface'
import ButtonContainer from '../../ButtonContainer/ButtonContainer'

export default function Item(props: IProps) {
    const { t } = useTranslation('locale')
    const { isConnected, isSelected } = props
    const hardwareId = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Hardware.HardwareSelector.getManikinByType(
            state,
            props.type
        )
    )
    const hardware = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Hardware.HardwareSelector.getConnectedHardwareById(
            state,
            hardwareId as string
        )
    )

    const displayManikin = (
        modelCourseType: ModelCourseType,
        _isConnected: boolean
    ) => {
        const _className = `w-full h-full cursor-pointer ${
            _isConnected === true && hardware.battery > 50
                ? 'text-green-800 hover:text-green-button-hover active:text-green-button-click'
                : ''
        } ${
            _isConnected === true && hardware.battery < 50
                ? 'text-orange-800'
                : ''
        }`
        switch (modelCourseType) {
            case 'Child':
                return isConnected === true ? (
                    <JuniorConnectedIcon
                        className={`${_className} dark:text-green-light-svg`}
                    />
                ) : (
                    <JuniorIcon
                        className={`${_className} text-gray-50 dark:text-gray-light-default-button hover:text-yellow-900 active:text-gray-button-click dark:hover:text-yellow-light-900 dark:active:text-gray-light-icon-click dark:text-gray-light-default-button`}
                    />
                )
            case 'Adult':
                return isConnected === true ? (
                    <AdultConnectedIcon
                        className={`${_className} dark:text-green-light-svg`}
                    />
                ) : (
                    <AdultIcon
                        className={`${_className} text-gray-50 dark:text-gray-light-default-button hover:text-yellow-900 active:text-gray-button-click dark:hover:text-yellow-light-900 dark:active:text-gray-light-icon-click dark:text-gray-light-default-button`}
                    />
                )
            case 'Infant':
                return isConnected === true ? (
                    <BabyConnectedIcon
                        className={`${_className} dark:text-green-light-svg hover:text-green-button-hover active:text-green-button-click dark:hover:text-yellow-light-900 dark:active:text-gray-light-icon-click dark:text-gray-light-default-button`}
                    />
                ) : (
                    <BabyIcon
                        className={`${_className} text-gray-50 dark:hover:text-yellow-light-900 dark:active:text-gray-light-icon-click dark:text-gray-light-default-button hover:text-yellow-900 active:text-gray-button-click`}
                    />
                )
            default:
        }
    }

    return (
        <motion.div
            className="flex relative w-28 h-32"
            animate={{
                scale: isSelected === true ? 1.5 : 1,
                opacity: isSelected === true ? 1 : 0.5,
            }}
            onClick={() => {
                props.onClick()
            }}
        >
            <ButtonContainer
                selected={props.isSelected}
                className=" flex flex-col h-24 w-24 items-center gap-1"
            >
                {displayManikin(props.type, isConnected)}
                <Typography type="caption">
                    {t(`Classroom.${props.type}`)}
                </Typography>
            </ButtonContainer>
        </motion.div>
    )
}
