import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from 'view/component/Button/Button'
import Typography from 'view/component/Typography/Typography'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ConfigImg } from 'resource/img/config-manikin.svg'
import ModalContainer from '../ModalContainer/ModalContainer'
import BraydenCore from '@innosonian/brayden-core'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'

export default function ManikinDetailModal() {
    const dispatch = useDispatch()
    const { t } = useTranslation('locale')
    const manikinId = useSelector(
        BraydenCore.Entity.Modal.ModalSelector.getManikinId
    )
    const hardware = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Hardware.HardwareSelector.getConnectedHardwareById(
            state,
            manikinId as string
        )
    )

    const generateText = (title: string, data: string) => {
        return (
            <div className="w-full flex mb-2 justify-between">
                <Typography>{t(`Hardware.${title}`)}: </Typography>
                <Typography className="pl-1">{data}</Typography>
            </div>
        )
    }

    return (
        <ModalContainer
            isOpen={manikinId !== undefined}
            closeButton={true}
            onCloseClick={() =>
                dispatch(
                    BraydenCore.Entity.Modal.ModalAction.modalCloseManikinDetail()
                )
            }
        >
            {hardware !== undefined && (
                <div className="flex h-full flex-col w-full lg:px-5 pb-10">
                    <div className="w-full flex items-center justify-center">
                        <div className="hidden md:flex flex-col justify-center">
                            <ConfigImg className="md:h-56 md:w-56 xl:h-96 xl:w-96 text-gray-100 dark:text-gray-light-svg" />
                            <Button
                                disable={false}
                                className="self-center mt-10 -ml-5 "
                                type="outline"
                                color="danger"
                                shape="rounded-mid"
                                noShadow={true}
                                onclick={() => {
                                    dispatch(
                                        BraydenCore.Entity.Session.SessionAction.disconnectManikinRequest(
                                            hardware.modelType
                                        )
                                    )
                                    dispatch(
                                        BraydenCore.Entity.Modal.ModalAction.modalCloseManikinDetail()
                                    )
                                }}
                                padding="sm"
                                size="sm"
                            >
                                {t('Hardware.Disconnect')}
                            </Button>
                        </div>
                        <div className="w-full md:w-1/2 flex flex-col">
                            <div className="w-full flex mb-6 border-b border-gray-100">
                                <Typography type="h3">
                                    Manikin detail
                                </Typography>
                            </div>
                            <div className="w-full flex flex-col mb-6">
                                {generateText('Firmware', hardware.firmware)}
                                {generateText('Circuit', hardware.circuit)}
                                {generateText('DeviceID', hardware.deviceID)}
                                {generateText(
                                    'Battery',
                                    `${hardware.battery.toString()} %`
                                )}
                                {generateText('Model', hardware.model)}
                                {generateText('ConnectedAs', hardware.type)}
                            </div>
                            <div className="flex justify-center md:hidden">
                                <Button
                                    disable={false}
                                    className="self-center mt-10 -ml-5 "
                                    type="outline"
                                    color="gray"
                                    onclick={() => {
                                        dispatch(
                                            BraydenCore.Entity.Session.SessionAction.disconnectManikinRequest(
                                                hardware.modelType
                                            )
                                        )
                                        dispatch(
                                            BraydenCore.Entity.Modal.ModalAction.modalCloseManikinDetail()
                                        )
                                    }}
                                    padding="sm"
                                    size="sm"
                                >
                                    {t('Hardware.Disconnect')}
                                </Button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </ModalContainer>
    )
}
