import React, { Suspense, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import BraydenCore from '@innosonian/brayden-core'
import FallBack from '../FallBack/FallBack'
import Item from './Item/Item'
import { IParsedTrainCourse } from '@innosonian/brayden-core/build/src/entity/TrainCourse/TrainCourse.interface'
import { ModelCourseType } from '@innosonian/brayden-core/build/src/entity/Session/Session.interface'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'
import { ModelType } from '@innosonian/brayden-core/build/src/_bluetooth/bluetooth.interface'
import { IProps } from './ManikinCarousel.type'

export default function ManikinCarousel(props: IProps) {
    const { onIdxUpdate } = props
    const dispatch = useDispatch()
    const adultCard = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.TrainCourse.TrainCourseSelector.getTrainCourseById(
            state,
            'Adult'
        )
    )
    const infantCard = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.TrainCourse.TrainCourseSelector.getTrainCourseById(
            state,
            'Infant'
        )
    )
    const childCard = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.TrainCourse.TrainCourseSelector.getTrainCourseById(
            state,
            'Child'
        )
    )

    const createItemList = (
        adult: IParsedTrainCourse,
        infant: IParsedTrainCourse,
        child: IParsedTrainCourse,
        allManikin?: boolean
    ): ModelCourseType[] => {
        if (allManikin === true) {
            return ['Adult', 'Infant', 'Child'] as ModelCourseType[]
        }
        const tmp: ModelCourseType[] = []
        if (adult.cards.length > 0) {
            tmp.push('Adult')
        }
        if (infant.cards.length > 0) {
            tmp.push('Infant')
        }
        if (child.cards.length > 0) {
            tmp.push('Child')
        }

        return tmp
    }

    const itemList = createItemList(
        adultCard,
        infantCard,
        childCard,
        props.allManikin
    )

    const babyProConnected = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Hardware.HardwareSelector.getManikinByType(
            state,
            'Infant'
        )
    )
    const adultConnected = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Hardware.HardwareSelector.getManikinByType(
            state,
            'Adult'
        )
    )
    const juniorConnected = useSelector((state: IMainReducer) =>
        BraydenCore.Entity.Hardware.HardwareSelector.getManikinByType(
            state,
            'Child'
        )
    )
    const manikinId = useSelector(
        BraydenCore.Entity.Session.SessionSelector.getSelectedManikin
    )
    const [currentIdx, setCurrentIdx] = useState(() => {
        if (manikinId) {
            return [
                adultConnected,
                babyProConnected,
                juniorConnected,
            ].findIndex((e) => e === manikinId)
        } else {
            return itemList.findIndex((i) => i === props.defaultValue)
        }
    })

    useEffect(() => {
        onIdxUpdate(itemList[currentIdx])
    }, [currentIdx, itemList, onIdxUpdate])

    const handleConnectClick = async (
        manikinType: ModelType,
        isJunior: boolean,
        connectedId: string | undefined
    ) => {
        if (connectedId === undefined) {
            try {
                await BraydenCore.Reducer.sagaPromise(
                    BraydenCore.Entity.Session.connectManikin,
                    { modelRequest: manikinType, isJunior: isJunior }
                )
            } catch (e) {
                console.log(e)
                dispatch(
                    BraydenCore.Entity.Classroom.Action.connectManikin(false)
                )
            }
        } else {
            dispatch(
                BraydenCore.Entity.Modal.ModalAction.modalOpenManikinDetail(
                    connectedId as string
                )
            )
        }
    }

    const connectOrDisconnect = (index: number) => {
        if (itemList[index] === 'Adult') {
            handleConnectClick(ModelType.BRAYDEN_PRO, false, adultConnected)
        }
        if (itemList[index] === 'Infant') {
            handleConnectClick(
                ModelType.BRAYDEN_BABY_PRO,
                false,
                babyProConnected
            )
        }
        if (itemList[index] === 'Child') {
            handleConnectClick(ModelType.BRAYDEN_PRO, true, juniorConnected)
        }
    }

    const isConnected = (modelType: ModelCourseType) => {
        switch (modelType) {
            case 'Adult':
                return adultConnected !== undefined
            case 'Child':
                return juniorConnected !== undefined
            case 'Infant':
                return babyProConnected !== undefined
            default:
                return false
        }
    }

    return (
        <Suspense fallback={<FallBack />}>
            <div className="flex w-full flex w-full justify-around items-center mt-10">
                {itemList.map((item, idx) => (
                    <Item
                        key={item}
                        isConnected={isConnected(itemList[idx])}
                        onClick={() => {
                            setCurrentIdx(idx)
                            if (props.isConnectable !== false) {
                                connectOrDisconnect(idx)
                            }
                        }}
                        type={itemList[idx]}
                        isSelected={currentIdx === idx}
                    />
                ))}
            </div>
        </Suspense>
    )
}
