import React, { Fragment, useState } from 'react'
import Typography from '../Typography/Typography'
import { IProps } from './ListContainer.type'
import { ReactComponent as ExpendIcon } from 'resource/img/icons/expend.svg'
import { motion } from 'framer-motion'

export default function ListContainer(props: IProps) {
    const [isExpended, setIsExpended] = useState(false)
    const { cycleClick } = props

    const handleClick = (cycle: number) => {
        if (cycleClick !== undefined) {
            cycleClick(cycle)
        }
    }

    const createCycleCard = (cycle: { cycle: number }) => {
        return (
            <div
                onClick={() => handleClick(cycle.cycle)}
                className="w-full flex flex-col flex-grow-1 p-4 bg-gray-850 border-b last:border-0 justify-center border-gray-200 cursor-pointer"
                key={cycle.cycle}
            >
                <Typography
                    type="h3"
                    alignment="center"
                    color={
                        props.selectedCycle !== undefined &&
                        props.selectedCycle === cycle.cycle
                            ? 'orange-700'
                            : 'white'
                    }
                >
                    cycle {cycle.cycle}
                </Typography>
            </div>
        )
    }

    const createCard = (
        card: { title: string; value: number | string },
        opacityStart: 0 | 1
    ) => {
        return (
            <motion.div
                initial={{ opacity: opacityStart }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="w-full flex flex-col p-8 rounded-lg flex-grow-1 items-center justify-center bg-gray-800 border border-gray-200 mb-2 lg:mb-4 last:mb-0"
                key={card.title}
            >
                <Typography
                    type="p"
                    alignment="center"
                    color="white"
                    className="mb-4"
                >
                    {card.title}
                </Typography>
                <Typography type="h2" alignment="center" color="white">
                    {card.value}
                </Typography>
            </motion.div>
        )
    }

    const createToggler = () => {
        return (
            <div className="w-full flex flex-col p-4 bg-gray-850 border-b justify-center border-gray-200">
                <Typography type="p" alignment="center" color="white">
                    {props.cycleTitle}
                </Typography>
                {/* {isExpended === false && ( */}
                <div className="w-full flex mt-4 items-center">
                    <div className="w-1/4 hidden lg:flex border-t border-gray-200"></div>
                    <div className="w-full lg:w-2/4 p-1 flex justify-center items-center">
                        <button
                            className="mx-1 px-3 py-2 flex items-center justify-center rounded-lg border border-gray-200 focus:outline-none"
                            onClick={() => setIsExpended((e) => !e)}
                        >
                            <motion.div
                                className="mr-1"
                                animate={{
                                    rotate: isExpended === true ? 540 : 0,
                                }}
                            >
                                <ExpendIcon className="w-4 h-4" />
                            </motion.div>
                            {isExpended === false && (
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                >
                                    <Typography type="span">expend</Typography>
                                </motion.div>
                            )}
                            {isExpended === true && (
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                >
                                    <Typography type="span">return</Typography>
                                </motion.div>
                            )}
                        </button>
                    </div>
                    <div className="w-1/4 hidden lg:flex border-t border-gray-200"></div>
                </div>
            </div>
        )
    }

    return (
        <div className="w-full flex flex-col mb-4 ">
            <div className="w-full flex flex-row">
                <div className="hidden lg:flex w-1/5 flex-col lg:mr-8 md:mr-2 items-stretch">
                    {isExpended === true && (
                        <Fragment>
                            {props.cardList.map((card, idx) =>
                                createCard(card, idx === 0 ? 1 : 0)
                            )}
                        </Fragment>
                    )}
                    {isExpended === false &&
                        props.cardList.length > 0 &&
                        createCard(props.cardList[0], 1)}
                </div>
                <div className="flex flex-row flex-grow-1 rounded-lg border border-gray-200">
                    <motion.div
                        className={`flex flex-grow-1 bg-gray-800 rounded-l-lg ${
                            isExpended === false ? 'h-44 lg:h-40' : ''
                        }`}
                    >
                        {isExpended === false && props.collapsed}
                        {isExpended === true && props.expended}
                    </motion.div>

                    <div className="w-1/4  flex flex-col items-stretch border-l rounded-r-lg border-gray-200 bg-gray-850">
                        {createToggler()}
                        {isExpended === true && (
                            <motion.div
                                className="w-full flex flex-col flex-grow-1 items-stretch"
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                            >
                                {props.cycleList.map((cycle) =>
                                    createCycleCard(cycle)
                                )}
                            </motion.div>
                        )}
                    </div>
                </div>
            </div>
            {isExpended === true && (
                <motion.div
                    className="w-full flex mt-4 items-center"
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                >
                    <div className="flex-grow-1 h-0 border-t border-gray-200"></div>
                    <div className="w-1/6 p-1 flex justify-center items-center">
                        <button
                            className="mx-1 px-3 py-2 flex items-center justify-center rounded-lg border border-gray-200"
                            onClick={() => setIsExpended(false)}
                        >
                            <ExpendIcon className="w-4 h-4 mr-1 transform rotate-180" />
                            <Typography type="span">return</Typography>
                        </button>
                    </div>
                    <div className="flex-grow-1 h-0 border-t border-gray-200"></div>
                </motion.div>
            )}
        </div>
    )
}
