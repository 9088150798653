import React from 'react'
import Typography from '../Typography/Typography'
import { IProps } from './Tabs.type'

export default function Tabs(props: IProps) {
    const handleActiveBg = (active: boolean) =>
        active === true
            ? 'bg-gray-active-tab dark:bg-gray-light-active-tab'
            : ''
    const handleMargin = (idx: number) =>
        idx < props.link.length - 1 ? 'mb-5 md:mr-5 md:mb-0' : ''
    const handleDisableColor = (idx: number) =>
        props.link[idx].disable !== undefined &&
        props.link[idx].disable === true
            ? 'text-gray-200 dark:text-gray-200'
            : 'white dark:text-gray-light-text'
    const handleClick = (idx: number) => {
        if (props.link[idx].disable !== true) {
            return props.link[idx].onClick()
        }
    }
    const handlePointer = (idx: number) =>
        props.link[idx].disable !== undefined &&
        props.link[idx].disable === true
            ? 'cursor-not-allowed'
            : 'cursor-pointer'
    return (
        <div className={`w-full flex flex-col md:flex-row items-stretch`}>
            {props.link.map((link, idx) => (
                <div
                    key={link.title}
                    onClick={() => handleClick(idx)}
                    className={`border border-gray-100 py-8 w-full md:w-1/${
                        props.link.length
                    } ${handleActiveBg(link.active)} ${handlePointer(
                        idx
                    )} flex flex-grow-1 ${handleMargin(idx)} shadow-dark`}
                >
                    <Typography
                        color={`${handleDisableColor(idx)}`}
                        alignment="center"
                        className="w-full flex-wrap"
                        type="h3"
                    >
                        {link.title}
                    </Typography>
                </div>
            ))}
        </div>
    )
}
