import { IEventListenerProvider } from '@innosonian/brayden-core/build/src/_eventListener/eventListener.interface'

const eventListenerProvider: IEventListenerProvider = {
    addEventListener: (eventName: string, fct: any) => {
        console.log('connect eventListener')
        document.addEventListener(eventName, fct)
    },

    removeEventListener: (eventName: string, fct: any) =>
        document.removeEventListener(eventName, fct),
}

export default eventListenerProvider
