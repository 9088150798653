import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Button from 'view/component/Button/Button'
import Typography from 'view/component/Typography/Typography'
import { useHistory } from 'react-router-dom'
import { ReactComponent as Logo } from 'resource/img/logo.svg'
import { ReactComponent as LogoLight } from 'resource/img/logo-light.svg'
import Input from 'view/component/Input/Input'
import Spinner from 'view/component/Spinner/Spinner'
import hook from 'hook'
import Select from 'view/component/Select/Select'
import { FreeUserClientId } from '@innosonian/brayden-core/build/src/entity/Oauth/Oauth.interface'
import { sagaPromise } from '@innosonian/brayden-core/build/src/_reducer/reducer'
import { signUp } from '@innosonian/brayden-core/build/src/entity/Oauth/Oauth.saga'
import { useDispatch } from 'react-redux'
import BraydenCore from '@innosonian/brayden-core'

export default function SignUp() {
    const [client, setClient] = useState<FreeUserClientId>('arc2021')
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
    const [surname, setSurname] = useState('')
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const { t } = useTranslation('locale')
    const history = useHistory()
    const dispatch = useDispatch()
    const [theme] = hook.useDarkMode()
    const handleConnect = async (e: React.MouseEvent<any>) => {
        setLoading(true)
        setError(false)
        try {
            await sagaPromise(signUp, {
                clientID: client,
                email: email,
                name: name,
                surname: surname,
            })
            dispatch(
                BraydenCore.Entity.Modal.ModalAction.modalOpenRegistrationSuccess()
            )
        } catch (e) {
            setError(true)
        }
        setLoading(false)
    }
    useEffect(() => {
        return () => {
            setError(false)
        }
    }, [])

    return (
        <div className="w-full pb-5  md:w-2/3 lg:w-1/2 xl:w-1/3 mx-auto mt-20 rounded-xl border border-gray-100 p-4">
            <form className="w-full flex flex-col md:p-4">
                {theme === 'light' && <Logo className="w-40 mx-auto my-8" />}
                {theme === 'dark' && (
                    <LogoLight className="w-40 mx-auto my-8" />
                )}
                <Typography color="red-800" className="mx-auto mb-4">
                    {error === true ? t('Login.Error') : ''}
                </Typography>
                <div className="w-full flex mb-10">
                    <Typography
                        className="lg:w-1/4 pr-4 md:mt-2"
                        alignment="right"
                    >
                        {t('SignUp.ClientId')}
                    </Typography>
                    <div className="flex-grow">
                        <Select
                            id="type"
                            noPadding={true}
                            selectedValue={client}
                            name="type"
                            value={['erc2021', 'arc2021', 'old2015']}
                            onChange={(e) => setClient(e as FreeUserClientId)}
                        />
                    </div>
                </div>
                <Input
                    label={t('SignUp.Email')}
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="mb-5"
                    autocomplete="off"
                ></Input>
                <Input
                    label={t('SignUp.Name')}
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="mb-5"
                    autocomplete="off"
                ></Input>
                <Input
                    label={t('SignUp.Surname')}
                    type="text"
                    value={surname}
                    onChange={(e) => setSurname(e.target.value)}
                    className="mb-5"
                    autocomplete="off"
                ></Input>
                <div className="flex w-full justify-between mt-4">
                    {loading !== true && (
                        <Button
                            disable={loading}
                            onclick={(e) => {
                                handleConnect(e)
                            }}
                            shape="rounded-mid"
                            size="sm"
                            noShadow={true}
                            type="outline"
                            color={theme === 'light' ? 'secondary' : 'warning'}
                            textColor="gray-700 dark:text-gray-light-secondary-text"
                            padding="sm"
                        >
                            {t('SignUp.SignUp')}
                        </Button>
                    )}
                    {loading === true && (
                        <div className="flex w-1/2 items-center justify-center">
                            <Spinner size="sm" />
                        </div>
                    )}
                    <Button
                        disable={false}
                        onclick={() => {
                            history.push('/')
                        }}
                        shape="rounded-mid"
                        size="sm"
                        noShadow={true}
                        type="outline"
                        color="gray"
                        textColor="gray-700"
                        padding="sm"
                    >
                        {t('Login.Cancel')}
                    </Button>
                </div>
            </form>
        </div>
    )
}
