import React from 'react'
import { ITextAreaProps } from './TextArea.type'

export default function TextArea(props: ITextAreaProps) {
    return (
        <textarea
            className="w-full flex p-1 rounded-lg text-gray-100 dark:text-gray-light-primary-text"
            value={props.value}
            onChange={(e) => props.onChange(e.target.value)}
            rows={props.row || 5}
        ></textarea>
    )
}
