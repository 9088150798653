import React from 'react'
import { IListItemProps } from './ListemItem.type'

export default function ListItem(
    props: React.PropsWithChildren<IListItemProps>
) {
    return (
        <div
            className={`w-full items-center flex flex-row p-4 ${
                props.onClick !== undefined ? 'cursor-pointer' : ''
            } border-b border-gray-100 `}
            onClick={props.onClick}
        >
            {props.children}
        </div>
    )
}
