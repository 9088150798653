import React, { useEffect, useState } from 'react'
import FadeInContainer from '../../layout/FadeInContainer/FadeInContainer'
import BraydenCore from '@innosonian/brayden-core'
import { useDispatch, useSelector } from 'react-redux'
import Typography from 'view/component/Typography/Typography'
import { useTranslation } from 'react-i18next'
import Button from 'view/component/Button/Button'
import { Waypoint } from 'react-waypoint'
import { IMainReducer } from '@innosonian/brayden-core/build/src/_reducer/reducer.interface'
import UserClientListItem from '../../component/UserClientListItem/UserClientListItem'

export default function UserClientList() {
    const { t } = useTranslation('locale')
    const dispatch = useDispatch()
    const deletedUserClientIdList = useSelector(
        BraydenCore.Entity.UserClient.Selector.getDeletedUserClientIds
    )
    const [pageNumber, setPageNumber] = useState(1)
    const [isDeletedUser, setIsDeletedUser] = useState(false)
    const userClientIdsList = useSelector(
        BraydenCore.Entity.UserClient.Selector.getUserClientIds
    )
    useEffect(() => {
        dispatch(
            BraydenCore.Entity.UserClient.Action.getUserClientListRequest(
                pageNumber,
                30,
                isDeletedUser
            )
        )
    }, [dispatch, pageNumber, isDeletedUser])

    useEffect(() => {
        setPageNumber(1)
    }, [isDeletedUser])

    const recordApiState = useSelector((state: IMainReducer) => {
        if (isDeletedUser === false) {
            return BraydenCore.Entity.Error.ErrorSelector.getApiState(
                state,
                '@USER/GET_USER_LIST'
            )
        } else {
            return BraydenCore.Entity.Error.ErrorSelector.getApiState(
                state,
                '@USER/GET_DELETED_USER'
            )
        }
    })

    return (
        <FadeInContainer className="w-full h-full flex flex-col">
            <div className="w-full xl:w-3/4 self-center">
                <div>
                    <Typography type="h1" className="pb-4" alignment="center">
                        {t('TopBar.Admin')}
                    </Typography>
                </div>
                <div className="w-full flex flex-col justify-between mb-4 py-4 justify-end border border-gray-100  px-4">
                    <div className="w-full flex justify-end mb-4">
                        <Button
                            disable={false}
                            type="plain"
                            shape="rounded-mid"
                            color="blue"
                            padding="sm"
                            className="ml-2 self-center"
                            onclick={() =>
                                dispatch(
                                    BraydenCore.Entity.Modal.ModalAction.modalOpenCreateUserClient()
                                )
                            }
                        >
                            {t('UserClientList.CreateUser')}
                        </Button>
                    </div>
                    <div className="flex items-center self-end mb-2 mr-4">
                        <input
                            id="deletedUser"
                            type="radio"
                            value=""
                            checked={isDeletedUser === true}
                            onClick={() => setIsDeletedUser((e) => !e)}
                        />
                        <label
                            htmlFor="deletedUser"
                            className="text-gray-100 dark:text-gray-light-default-text ml-2"
                        >
                            {t('UserClientList.DeletedUserClient')}
                        </label>
                    </div>
                    <div className="flex w-full flex-col border-t border-gray-100">
                        <div className="w-full flex p-4">
                            <div className="flex justify-center w-1/6">
                                <Typography>
                                    {t('UserClientList.Name')}
                                </Typography>
                            </div>
                            <div className="flex justify-center w-1/6">
                                <Typography>
                                    {t('UserClientList.Rank')}
                                </Typography>
                            </div>
                            <div className="flex justify-center w-1/6">
                                <Typography>
                                    {t('UserClientList.ClientType')}
                                </Typography>
                            </div>
                            <div className="flex justify-center w-1/6">
                                <Typography>
                                    {t('UserClientList.Organization')}
                                </Typography>
                            </div>
                            <div className="flex justify-center w-1/6">
                                <Typography>
                                    {t('UserClientList.ClientId')}
                                </Typography>
                            </div>
                            <div className="flex justify-center w-1/6">
                                <Typography>
                                    {t('UserClientList.PassCode')}
                                </Typography>
                            </div>
                            <div className="flex justify-center w-1/6">
                                <Typography>
                                    {t('UserClientList.Action')}
                                </Typography>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="flex w-full  flex-col">
                    {isDeletedUser === false &&
                        userClientIdsList.map((id) => (
                            <UserClientListItem
                                isDeleted={false}
                                key={id}
                                id={id}
                            />
                        ))}
                    {isDeletedUser === true &&
                        deletedUserClientIdList.map((id) => (
                            <UserClientListItem
                                isDeleted={true}
                                key={id}
                                id={id}
                            />
                        ))}
                </div>
                {userClientIdsList.length > 0 && (
                    <Waypoint
                        onEnter={() => {
                            if (recordApiState !== 'REQUEST') {
                                setPageNumber((e) => e + 1)
                            }
                        }}
                    />
                )}
                {userClientIdsList.length === 0 &&
                    recordApiState !== 'REQUEST' &&
                    isDeletedUser === false && (
                        <Typography>{t('UserClientList.NotFound')}</Typography>
                    )}
                {deletedUserClientIdList.length === 0 &&
                    recordApiState !== 'REQUEST' &&
                    isDeletedUser === true && (
                        <Typography
                            className="w-full"
                            alignment="center"
                            type="h2"
                        >
                            {t('UserClientList.NotFound')}
                        </Typography>
                    )}
            </div>
        </FadeInContainer>
    )
}
