import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import BraydenCore from '@innosonian/brayden-core'
import Input from 'view/component/Input/Input'
import Select from 'view/component/Select/Select'
import Button from 'view/component/Button/Button'
import Typography from 'view/component/Typography/Typography'
import { useTranslation } from 'react-i18next'
import { sagaPromise } from '@innosonian/brayden-core/build/src/_reducer/reducer'
import { createUserClient } from '@innosonian/brayden-core/build/src/entity/UserClient/UserClient.saga'
import { toast } from 'react-toastify'

export default function CreateCoach() {
    const { t } = useTranslation('locale')
    const [error, setError] = useState(false)
    const [loading, setLoading] = useState(false)
    const dispatch = useDispatch()
    const [organizationName, setOrganizationName] = useState('')
    const [rank, setRank] = useState(1)
    const [password, setPassword] = useState('')
    const [clientId, setClientId] = useState('')
    const [name, setName] = useState('')

    const createUser = async () => {
        setError(false)
        setLoading(true)
        try {
            await sagaPromise(createUserClient, {
                passcode: password,
                name: name,
                clientID: clientId,
                client_type: 'coach',
                rank: rank,
                organization_name: organizationName,
            })
            toast(
                <Typography color="white">
                    {t('ToastManager.CreateUserClientSuccess')},
                </Typography>,
                {
                    type: 'success',
                }
            )
            dispatch(
                BraydenCore.Entity.Modal.ModalAction.modalCloseCreateUserClient()
            )
        } catch (e) {
            setError(true)
        } finally {
            setLoading(false)
        }
    }
    return (
        <div className="w-full flex flex-col">
            {error && (
                <div className="w-full flex">
                    <Typography alignment="center" color="red-700">
                        Error
                    </Typography>
                </div>
            )}
            <div className="w-full flex flex-col">
                <div className="w-full flex flex-col">
                    <Typography>
                        {t('UserClientList.OrganizationName')}
                    </Typography>
                    <Input
                        noMargin={true}
                        type="text"
                        value={organizationName}
                        onChange={(e) => {
                            const value = e.target.value
                            setOrganizationName(value)
                        }}
                        className="mb-5"
                        autocomplete="off"
                    />
                </div>
                <div className="w-full flex flex-col">
                    <Typography>{t('UserClientList.ClientId')}</Typography>
                    <Input
                        noMargin={true}
                        type="text"
                        value={organizationName + '_' + clientId}
                        onChange={(e) =>
                            setClientId(
                                e.target.value.replace(
                                    organizationName + '_',
                                    ''
                                )
                            )
                        }
                        className="mb-5"
                        autocomplete="off"
                    />
                </div>
                <div className="w-full flex flex-col">
                    <Typography>{t('UserClientList.Rank')}</Typography>
                    <Select
                        value={[1, 2, 3, 4]}
                        selectedValue={rank}
                        onChange={(e) => setRank(e as number)}
                        name="rank"
                        id="rank"
                    />
                </div>
                <div className="w-full flex flex-col">
                    <Typography>{t('UserList.Name')}</Typography>
                    <Input
                        noMargin={true}
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        className="mb-5"
                        autocomplete="off"
                    />
                </div>
                <div className="w-full flex flex-col">
                    <Typography>{t('UserList.Password')}</Typography>
                    <Input
                        noMargin={true}
                        type="text"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="mb-5"
                        autocomplete="off"
                    />
                </div>
            </div>
            <div className="w-full flex justify-between gap-3">
                <Button
                    disable={loading}
                    size="full"
                    onclick={createUser}
                    type="plain"
                    shape="rounded-mid"
                    padding="sm"
                    color="secondary"
                >
                    {t('UserClientList.Create')}
                </Button>
                <Button
                    disable={false}
                    size="full"
                    onclick={() => {
                        setError(false)
                        dispatch(
                            BraydenCore.Entity.Modal.ModalAction.modalCloseCreateUserClient()
                        )
                    }}
                    type="plain"
                    shape="rounded-mid"
                    padding="sm"
                    color="blue"
                >
                    {t('UserClientList.Cancel')}
                </Button>
            </div>
        </div>
    )
}
