import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Typography from 'view/component/Typography/Typography'
import Button from 'view/component/Button/Button'
import ModalContainer from '../ModalContainer/ModalContainer'
import BraydenCore from '@innosonian/brayden-core'
import { ErrorType } from '@innosonian/brayden-core/build/src/entity/Error'

export default function ErrorModal() {
    const error = useSelector(BraydenCore.Entity.Error.ErrorSelector.getError)
    const { t } = useTranslation('locale')
    const dispatch = useDispatch()
    const history = useHistory()

    const selectTitleFromError = () => {
        switch (error) {
            case ErrorType.ERROR_TEST:
                return 'TEST_MESSAGE'
            case ErrorType.ERROR_MANIKIN_DISCONNECTED:
                return 'Error.Title.ManikinDisconnected'
            case ErrorType.DEVICE_GATT_NOT_FOUND:
                return 'Error.Title.GattNotFound'
            case ErrorType.SERVER_NO_RESPONSE:
                return 'Error.Title.ServerNoResponse'
            case ErrorType.INVALID_TOKEN:
                return 'Error.Title.InvalidToken'
            case ErrorType.REQUEST_NOT_FOUND:
                return 'Error.Title.RequestNotFound'
            default:
                return 'Error.Title.UnexpectedError'
        }
    }

    const selectMessageFromError = () => {
        switch (error) {
            case ErrorType.ERROR_TEST:
                return 'TEST_MESSAGE'
            case ErrorType.ERROR_MANIKIN_DISCONNECTED:
                return 'Error.ManikinDisconnected'
            case ErrorType.DEVICE_GATT_NOT_FOUND:
                return 'Error.GattNotFound'
            case ErrorType.SERVER_NO_RESPONSE:
                return 'Error.ServerNoResponse'
            case ErrorType.INVALID_TOKEN:
                return 'Your session has expired or you are logged in with another browser.'
            case ErrorType.REQUEST_NOT_FOUND:
                return 'Error.RequestNotFound'
            default:
                return 'Error.UnexpectedError'
        }
    }

    const handleClose = () => {
        dispatch(BraydenCore.Entity.Error.ErrorAction.resetError())
        history.push('/')
    }

    return (
        <ModalContainer isOpen={error !== undefined} closeButton={false}>
            <div className="flex h-full flex-col w-full">
                <div className="border-gray-100 border-b-2 pb-2">
                    <Typography type="h3">
                        {t(selectTitleFromError())}.
                    </Typography>
                </div>
                <div className="w-full flex flex-grow-1 pt-10">
                    <Typography>{t(selectMessageFromError())}.</Typography>
                </div>
                <div className="w-full flex justify-end self-end">
                    <Button
                        disable={false}
                        shape="rounded-mid"
                        padding="sm"
                        color="gray"
                        onclick={() => handleClose()}
                    >
                        Close
                    </Button>
                </div>
            </div>
        </ModalContainer>
    )
}
