import React from 'react'
import Typography from '../Typography/Typography'
import { IProps } from './Button.type'

export default function Button(props: React.PropsWithChildren<IProps>) {
    const handleBg = () => {
        return props.type === 'outline' ? 'bg-transparent' : ''
    }

    const handleRounded = () => {
        if (props.shape === 'square') {
            return
        }
        if (props.shape === 'rounded-mid') {
            return 'rounded-md'
        }
        return 'rounded-card'
    }

    const handleIcon = () => {
        return props.icon !== undefined ? (
            <div
                className={`flex flex-col items-center ${
                    props.iconPosition !== 'top' && 'mr-2'
                }`}
            >
                {props.icon}
            </div>
        ) : (
            <span className="hidden" />
        )
    }

    const handleClassName = () => {
        return props.className !== undefined ? props.className : ''
    }

    const handleSize = () => {
        switch (props.size) {
            case 'lg':
                return 'w-72'
            case 'md':
                return 'w-64'
            case 'sm':
                return 'w-40'
            case 'xs':
                return 'w-28 h-8'
            case 'xl':
                return 'sm:w-64 md:w-72 lg:w-96 xl:w-72'
            case 'full':
                return 'w-full'
            default:
                return 'w-64'
        }
    }

    const handleColor = () => {
        switch (props.color) {
            case 'primary':
                return 'bg-gray-800 border-gray-800 dark:bg-gray-light-default-button'
            case 'secondary':
                return 'bg-green-800 border-green-800 dark:bg-green-light-button'
            case 'danger':
                return 'bg-red-800 border-red-800 dark:bg-red-500'
            case 'warning':
                return 'bg-orange-800 border-orange-800 dark:bg-orange-light-default-button'
            case 'gray':
                return 'bg-gray-mid-dark border-gray-mid-dark dark:bg-gray-light-default-button'
            case 'light-blue':
                return 'bg-blue-light-sea border-blue-light-sea dark:border-white'
            case 'dark-blue':
                return 'bg-blue-dark border-blue-dark dark:bg-blue-850'
            case 'blue':
                return 'bg-blue-800 border-blue-800 dark:bg-blue-light-button'
            case 'yellow-mustard':
                return 'bg-yellow-mustard border-yellow-mustard dark:border-white'
            case 'pink-light':
                return 'bg-pink-light border-pink-light dark:border-white'
            case 'green-grass':
                return 'bg-green-grass border-green-grass dark:border-white'

            default:
                return 'bg-gray-50 border-gray-50 dark:bg-gray-light-default-button'
        }
    }

    const handleCursor = () => {
        return props.disable === true ? 'cursor-not-allowed' : 'cursor-pointer'
    }

    const handleClick = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (props.disable !== true) {
            props.onclick(ev)
        }
    }

    const handleShadow = () => {
        if (props.noShadow !== true) {
            return 'shadow-dark'
        }
        return ''
    }

    const handlePadding = () => {
        switch (props.padding) {
            case 'sm':
                return 'py-2'
            case 'md':
                return 'py-4'
            case 'xs':
                return 'py-1'
            default:
                return 'py-3'
        }
    }

    const handleFontSize = () => {
        if (props.fontSize === 'xxs') {
            return 'caption'
        }
        if (props.fontSize === 'xs') {
            return 'span'
        } else if (props.fontSize === 'sm') {
            return 'p'
        } else if (props.fontSize === 'md') {
            return 'h2'
        } else {
            return 'h3'
        }
    }

    const handleIconPosition = () => {
        if (props.iconPosition === 'top') {
            return 'flex-col'
        }
        return 'flex'
    }

    return (
        <div
            className={`${handlePadding()} flex-shrink inline-flex self-start items-center justify-center duration-500 border border-transparent  ${
                props.padding === 'xs' ? 'border-0' : 'border-2'
            } ${handleShadow()} ${handleCursor()} ${handleColor()} ${handleSize()} ${handleBg()} ${handleRounded()} ${handleClassName()}`}
            onClick={(ev) => handleClick(ev)}
        >
            <button
                type={props.buttonType || 'button'}
                className={`${handleCursor()} focus:outline-none ${handleIconPosition()} w-4/4 justify-center items-center justify-items-center ${
                    props.disable === true && 'opacity-50 disabled'
                } }`}
            >
                {handleIcon()}
                <Typography
                    alignment={`${props.disable ? 'left' : 'center'}`}
                    color={
                        props.textColor ? `text-${props.textColor}` : 'white'
                    }
                    weight={props.weight}
                    type={handleFontSize()}
                >
                    {props.children}
                </Typography>
            </button>
        </div>
    )
}
